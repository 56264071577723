import { getWebVersion } from '@/api/commonMethods'

class WebVersion {

  constructor() {
    this.response = null;
    this.isError = null;
    this.apiWebVrsion = null;
    this.currentWebVerion = process.env.VUE_APP_VERSION;
    this.currentDomain = window.location.hostname;
  }

  async init() {
    await getWebVersion((res) => {
      this.responseHandler(res)
    }, (err) => {
      this.errorHandler(err)
    })
  }

  check() {
    if(!this.apiWebVrsion) {
      return false;
    }

    if(this._checkReloadOnce()) {
      if (this.currentWebVerion !== this.apiWebVrsion) {
        localStorage.setItem('webVersion', 'REFRESH')
        this._reloadWeb();
      }
    } else {
      console.log('WEB WERSION UPDATED')
      localStorage.removeItem('webVersion')
    }
  }

  responseHandler(response) {
    this.response = response.data;
    this._parseVersion();
  }

  errorHandler(error) {
    this.isError = error;
    console.error(error);
  }

  _parseVersion() {
    if(this.response?.servers_versions) {
      this.response.servers_versions.forEach(version => {
        if (Object.keys(version)[0] === this.currentDomain) {
          this.apiWebVrsion = version[this.currentDomain]
          console.log('VebVersion from API', this.apiWebVrsion)
        }
      })
    }
  }

  _checkReloadOnce() {
    const webVersion = localStorage.getItem('webVersion');
    return !webVersion;
  }

  _reloadWeb() {
    let documentUrl = new URL(document.location.href)
    const dateTime = new Date().getTime()
    const params = new URLSearchParams(documentUrl.search)
    params.set('up', dateTime.toString())
    documentUrl.search = params.toString()
    document.location.href = documentUrl.toString()
  }
}

export default WebVersion;
