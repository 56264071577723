import HTTP from '@/api/http'

const getBranding = function (responseHandler, errorHandler) {
  HTTP.get('/branding').then(responseHandler).catch(errorHandler)
}

const login = function (formData, responseHandler, errorHandler) {
  HTTP.post('/login', formData).then(responseHandler).catch(errorHandler)
}
const getTermsQR = function (code, responseHandler, errorHandler) {
  HTTP.get(`/user_agreement?code=${code}`, { responseType: 'blob' })
    .then(responseHandler)
    .catch(errorHandler)
}
const getTerms = function (code, responseHandler, errorHandler) {
  HTTP.get(`/user_agreement?company_id=${code}`, { responseType: 'blob' })
    .then(responseHandler)
    .catch(errorHandler)
}
const crateCode = function (formData, responseHandler, errorHandler) {
  HTTP.post('/boxes/create_code', formData)
    .then(responseHandler)
    .catch(errorHandler)
}
const createUnitBox = function (formData, responseHandler, errorHandler) {
  HTTP.post('/boxes/create_unit', formData)
    .then(responseHandler)
    .catch(errorHandler)
}
const demoVersion = function (formData, responseHandler, errorHandler) {
  HTTP.post('/login', formData).then(responseHandler).catch(errorHandler)
}

const logout = function (responseHandler, errorHandler) {
  HTTP.put('/logout').then(responseHandler).catch(errorHandler)
}

const registrate = function (formData, responseHandler, errorHandler) {
  HTTP.post('/registrate', formData).then(responseHandler).catch(errorHandler)
}

const getMe = function (responseHandler, errorHandler) {
  HTTP.get('/me').then(responseHandler).catch(errorHandler)
}

const recovery = function (formData, responseHandler, errorHandler) {
  HTTP.post('/recovery', formData).then(responseHandler).catch(errorHandler)
}

const recoveryAuth = function (hash, responseHandler, errorHandler) {
  HTTP.get(`/recovery/${hash}`).then(responseHandler).catch(errorHandler)
}

const approve = function (hash, responseHandler, errorHandler) {
  HTTP.get(`/approve/${hash}`).then(responseHandler).catch(errorHandler)
}

const changeMyself = (formData, responseHandler, errorHandler) => {
  HTTP.put('/me', formData).then(responseHandler).catch(errorHandler)
}

const changeMyPassword = (formData, responseHandler, errorHandler) => {
  HTTP.put('/change_my_password', formData)
    .then(responseHandler)
    .catch(errorHandler)
}
const resetPassword = (id, formData, responseHandler, errorHandler) => {
  HTTP.put(`/reset_password/${id}`, formData)
    .then(responseHandler)
    .catch(errorHandler)
}
const getCaptcha = (responseHandler, errorHandler) => {
  HTTP.get('/captcha', { responseType: 'arraybuffer' })
    .then(responseHandler)
    .catch(errorHandler)
}

const uploadAvatar = async function (formData) {
  const { data } = await HTTP.post('/users/photos', formData)
  return data
}
export default {
  changeMyself,
  login,
  logout,
  registrate,
  getMe,
  recovery,
  recoveryAuth,
  approve,
  demoVersion,
  changeMyPassword,
  resetPassword,
  getCaptcha,
  crateCode,
  createUnitBox,
  getTermsQR,
  getTerms,
  uploadAvatar,
  getBranding
}
