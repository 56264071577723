import axios from 'axios'
import eventBus from '@/eventBus.js'
import Vue from 'vue'

const HTTP = axios.create({
  baseURL: '/api_v1/'
})

HTTP.interceptors.response.use(null, function(error) {
  const { config, response } = error

  if (config && config.needAuth && response.status === 401) {
    setTimeout(() => {
      console.log('error!')
      location.reload()
    }, 2000)
  }

  if (response.status === 504) {
    setTimeout(() => {
      const translatedMessage = Vue.i18n.translate('request.error.504', Vue.i18n.locale())

      eventBus.$showError(translatedMessage)
    }, 100)
  }

  if (response.status === 500) {
    if (window.showError) {
      window.showError(error)
    }
  }
  return Promise.reject(error)
})

export default HTTP
