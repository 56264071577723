const state = {
  isShortDescription: false,
}

const actions = {}

const mutations = {
  setShortDescription(state, payload) {
    console.log(payload, 'payload');
    state.isShortDescription = payload
  }
}

const getters = {
  isShortDescription: state => {
    return state.isShortDescription
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}