import { dealersApi } from '@/api'
import eventBus from '@/eventBus.js'
import router from '../../router/router'

const defaultState = {}
const getters = {}
const actions = {
  // Регистрация
  registrateDealer(_, formData) {
    return new Promise(function(resolve, reject) {
      dealersApi.registerDealer(
        formData,
        function(response) {
          // router.push('confirm')
          resolve(response.data)
        },
        function(error) {
          // eventBus.$showError(error.response.data.message)
          reject(error.response.data)
          // router.push('register')
        }
      )
    })
  },
  async createDealer(_, formData) {
    try {
      const data = await dealersApi.createDealer(formData)
      return data
    } catch(error) {
      return error.response
    }
  },

  async addUser(_, formData) {
    try {
      return await dealersApi.addUser(formData)
    } catch(error) {
      return error.response
    }
  }
}
const mutations = {}
export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations
}
