import { racesApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  geozones: [],
  existingGeozones: [],
  shape: { polygon: false },
  isDrawing: false,
  endEdit: false,
  fitBoundsToGeozone: { '': false },
  currentGeozone: {},
  points: [],
  races: [],
  race: null,
  addGeozoneEvent: { '': false },
  routesPoints: [],
  currentRaceId: null,
  editedGeozone: null,
  pointsToDraw: [],
}

const actions = {
  EDITED_GEOZONE({ commit }, geozone) {
    console.log('from action', geozone)
    commit('edited_geozone', geozone)
  },
  SET_EDIT_RACE({ commit }, race) {
    commit('set_edit_race', race)
  },
  GET_RARCE_ID({ commit }, raceId) {
    commit('get_race_id', raceId.id)
  },
  EDIT_RACE({ commit }, formData) {
    commit('edit_race', formData)
  },
  GET_RACE({ commit }, formData) {
    commit('get_race', formData)
  },
  GET_POINTS_RACE({ commit }, formData) {
    racesApi.getRace(
      formData.id,
      function (response) {
        // console.log('race correct ', response.data)
        commit('SET_RACE', response.data)
      },
      function (error) {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  CREATE_GEOZONE({ commit }, formData) {
    commit('create_geozone', formData)
  },
  ADD_FROM_EXISTING_GEOZONE({ commit }, formData) {
    commit('add_from_existing_geozone', formData)
  },
  ADD_POINTS_FROM_ROUTES({ commit }, formData) {
    console.log('ADD_POINTS_FROM_ROUTES', formData)
    commit('add_points_from_routes', formData)
  },
  GET_RACES({ commit }, formData) {
    return new Promise(function (resolve) {
      racesApi.getRaces(
        { formData },
        (response) => {
          commit('SET_RACES', response.data)
          resolve(response.data.list)
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  DELETE_POINTS({ commit }) {
    commit('DELETE_POINTS')
  },
  CREATE_RACE({ commit }, formData) {
    commit('ADD_RACE', formData)
    //  racesApi.createRace(
    //   { formData },
    //   response=> {
    //     console.log('sucesss')
    //     return commit('ADD_RACE', response.data)
    //   },
    //   error=> {
    //     console.log('***error',error.response.data.message)
    //    return  eventBus.$showError(error.response.data.message)
    //   }
    // )
  },
  UPDATE_RACE({ commit }, formData) {
    // console.log('done',formData)
    commit('UPDATE_RACE', formData)
  },
  WEBSOCKET_CHANGE({ commit }, formData) {
    if (formData.operation === 'DELETE') {
      commit('DELETE_RACE', [formData.id])
    } else {
      racesApi.getRace(
        formData.id,
        (response) => {
          if (formData.operation === 'POST') {
            commit('ADD_RACE', response.data)
          } else {
            commit('UPDATE_RACE', response.data)
          }
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  DELETE_RACES({ commit }, races) {
    let promiseList = []
    races.forEach((item) => {
      promiseList.push(
        new Promise(function (resolve, reject) {
          racesApi.deleteRace(item, resolve, reject)
        })
      )
    })
    Promise.all(promiseList)
      .then(function () {
        commit('DELETE_RACE', races)
      })
      .catch(function (error) {
        eventBus.$showError(error.response.data.message)
      })
  },
  END_EDITING({ commit }, status) {
    commit('END_EDITING', status)
  },
}

const mutations = {
  edited_geozone(state, geozone) {
    console.log('geozones to push the newGeo', state.geozones)
    console.log('the newGeo', geozone)
  },
  set_edit_race(state, race) {
    state.race = race
    // console.log('state race', state.race)
  },
  get_race_id(state, raceId) {
    state.currentRaceId = raceId
  },
  SET_RACE(state, race) {
    state.pointsToDraw = race
    console.log('Points to draw Race', state.pointsToDraw)
  },
  create_geozone(state, geozone) {
    // console.log('trigged', geozone)
    state.geozones = [geozone]
    if (!state.geozones.some((x) => x.id === geozone.id)) {
      state.addGeozoneEvent = {
        [geozone.id]: !Object.values(state.addGeozoneEvent)[0],
      }
    }
  },
  add_from_existing_geozone(state, formData) {
    state.existingGeozones = formData
  },
  add_points_from_routes(state, formData) {
    state.routesPoints = formData
    console.log('points to add', formData)
  },
  edit_race(state, formData) {
    state.race = formData
  },

  SET_RACES(state, races) {
    state.races = races.list
    state.maxRows = races.max
  },
  ADD_RACE(state, race) {
    if (!state.races.some((x) => x.id === race.id)) {
      state.races = [...state.races, race]
    }
  },

  DELETE_POINTS(state, formData) {
    // let points = [...state.existingGeozones, state.geozone]
  },
  DELETE_RACE(state, races) {
    console.log('race deleted', races)
    state.races = state.races.filter(
      (item) => !races.map((k) => k).includes(item.id)
    )
  },
  UPDATE_RACE(state, race) {
    const indexOfRace = state.races.findIndex((elem) => elem.id === race.id)
    state.races = [
      ...state.races.slice(0, indexOfRace),
      race,
      ...state.races.slice(indexOfRace + 1),
    ]
  },

  FITBOUNDS_TO_GEOZONE(state, fitBoundsToGeozone) {
    state.fitBoundsToGeozone = {
      [fitBoundsToGeozone]: !Object.values(state.fitBoundsToGeozone)[0],
    }
  },
  // SET_COLOR(state, color) {
  //   state.color = {
  //     [color]: !Object.values(state.color)[0]
  //   }
  // },
  SET_WIDTH(state, width) {
    state.width = {
      [width]: !Object.values(state.width)[0],
    }
  },
  SHAPE_CHANGE(state, shape) {
    state.shape = {
      [shape]: !Object.values(state.shape)[0],
    }
  },
  TYPE_CHANGE(state, type) {
    state.type = {
      [type]: !Object.values(state.type)[0],
    }
  },
  SET_GEOZONE_COORDS(state, editedGeozone) {
    state.editedGeozone = editedGeozone
    console.log(
      '***********************************edited geo from store',
      editedGeozone
    )
  },
  START_DRAWING(state) {
    state.isDrawing = true
  },
  END_DRAWING(state) {
    state.isDrawing = false
  },
  START_EDITING(state, geozoneId) {
    state.isEditing = { [geozoneId]: true }
  },
  END_EDITING(state, status) {
    state.endEdit = status.value
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
