import HTTP from '@/api/http'

const getUnitTrailers = function (unitId, responseHandler, errorHandler) {
  HTTP.post(
    '/units_trailers/list',
    {
      sortField: 'date_from',
      sortDesc: 'false',
      conditions: [
        {
          field: 'unit.id',
          value: unitId,
        },
      ],
    },
    {
      needAuth: true,
    }
  )
    .then(responseHandler)
    .catch(errorHandler)
}

const createUnitTrailer = function (formData, responseHandler, errorHandler) {
  HTTP.post('/units_trailers', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const searchUnitTrailer = function (
  { formData },
  responseHandler,
  errorHandler
) {
  HTTP.post('/units_trailers/list', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateUnitTrailer = function (formData, responseHandler, errorHandler) {
  HTTP.put(`/units_trailers/${formData.id}`, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteUnitTrailer = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/units_trailers`, { data: { ids: [id] } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getWorkTypes = function (responseHandler, errorHandler) {
  HTTP.get(
    '/work_types',
  )
    .then(responseHandler)
    .catch(errorHandler)
}

const getObjectCouplings = function (formData, responseHandler, errorHandler) {
  HTTP.post(
    '/units_trailers/list',
    {
      fields: formData.fields,
      sortField: 'date_from',
      sortDesc: 'false',
      conditions: [
        {
          field: 'unit.id',
          value: formData.unitId,
        },
        {
          field: 'date_from',
          value: formData.date_from,
        },
        {
          field: 'date_to',
          value: formData.date_to,
        },
      ],
    },
    {
      needAuth: true,
    }
  )
    .then(responseHandler)
    .catch(errorHandler)
}


export default {
  getUnitTrailers,
  createUnitTrailer,
  deleteUnitTrailer,
  updateUnitTrailer,
  searchUnitTrailer,
  getWorkTypes,
  getObjectCouplings
}
