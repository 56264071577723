<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#22AD6A" />
    <path
      d="M5 9.8L8.75 14L15 7"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
export default {
  name: 'CheckIcon'
}
</script>
