import { getMarkerColor } from '@/utils/getMarkerColor'
import { mapMutations } from 'vuex'
import L from '@/utils/leaflet.js'

export default {
  methods: {
    ...mapMutations({
      setVuexAnnotationUnit: 'monitoringNew/setVuexAnnotationUnit',
      setVuexIsAnnotation: 'monitoringNew/setVuexIsAnnotation'
    }),

    getColorIcon(status) {
      if (status === 10 || status === 9 || status === 8) {
        return '#4ABC96'
      }

      if (status === 7 || status === 6 || status === 5) {
        return '#F2C94C'
      }

      return '#A0A0A0'
    },

    getIcon(iconUrl, angle, status, marker_unit_id = `unit_id_${Math.random()}`) {
      const index = iconUrl.lastIndexOf('_')
      const correctIconUrl = iconUrl.substring(0, index).split('/').pop()

      const urlColor = iconUrl.split('_').pop()

      const img = `
        <div class='skif-marker__img-box'>
          <span class="skif-marker__item">
            <svg class="skif-marker__img" width="56" height="50" viewBox="0 -4 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="/static/car_icon-new/cars.svg#${correctIconUrl}" fill="${getMarkerColor(
        urlColor
      )}"></use>
            </svg>
          </span>
        </div>
      `

      const isShowArrow = angle !== -1 ? `block` : `none`
      const angleStr = `style='transform: rotate(${angle}deg); -moz-transform: rotate(${angle}deg); display: ${isShowArrow}'`
      const arrow = `<div id='unit_id_${marker_unit_id}' class="skif-marker__arrow-box" ${angleStr}></div>`

      const colorStr = this.getColorIcon(status)
        ? `style='color: ${this.getColorIcon(status)};'`
        : ''
      return `<div class='skif-marker__box' ${colorStr}'>${arrow}${img}</div>`
    },

    helperGetMarker(item, marker_unit_id) {

      if (!item.columns) return
      const minSpeedColumns = item.columns.find(
        (column) => column.key === 'speed'
      )
      const minSpeedCustomFields = item.custom_fields.find(
        (field) => field.key === 'tripdetector_minmovespeed'
      )

      if (!minSpeedCustomFields || !minSpeedCustomFields.value) return

      const icon = L.divIcon({
        iconSize: [45, 61],
        iconAnchor: [13, 36],
        className: 'skif-marker --arrows',
        iconUrl: `/static/car_icon-new/cars.svg#${item.icon.key}`,
        html:
          minSpeedColumns.value > Number(minSpeedCustomFields.value)
            ? this.getIcon(item.icon.key, item.angle, item.status, marker_unit_id)
            : this.getIcon(item.icon.key, (item.angle = -1), item.status, marker_unit_id)
      })

      const marker = new L.Marker(item.latlon.value, {
        icon,
        draggable: false
      }).on('click', () => {
        this.setVuexAnnotationUnit(item)
        this.setVuexIsAnnotation(true)
      })

      const tooltipContent = document.createElement('div')
      tooltipContent.className = 'custom-tooltip'

      const shortText = document.createElement('div')
      shortText.className = 'short-text'
      const maxLength = 24
      const shortName = item.name.substr(0, maxLength)
      shortText.textContent = shortName
      tooltipContent.appendChild(shortText)

      const expandArrow = document.createElement('div')
      expandArrow.innerHTML =
        '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="12" height="12" rx="2" fill="#EDEFF3"/><circle cx="3" cy="6" r="1" fill="#3A7CCA"/><circle cx="6" cy="6" r="1" fill="#3A7CCA"/><circle cx="9" cy="6" r="1" fill="#3A7CCA"/></svg>'
      expandArrow.classList.add('cursor-pointer')
      expandArrow.classList.add('ml-2')
      tooltipContent.appendChild(expandArrow)

      const fullText = document.createElement('div')
      fullText.className = 'full-text'
      fullText.textContent = item.name
      tooltipContent.appendChild(fullText)

      const backArrow = document.createElement('div')
      backArrow.innerHTML =
        '<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect width="12" height="12" rx="2" fill="#EDEFF3"/> <path d="M6.18182 3C5.67273 3.48 3.84848 5.2 3 6M3 6L6.18182 9M3 6H10" stroke="#3A7CCA"/> </svg>'

      backArrow.classList.add('cursor-pointer')
      backArrow.classList.add('expand-arroww')
      tooltipContent.appendChild(backArrow)
      backArrow.style.display = 'none'

      marker.bindTooltip(tooltipContent, {
        permanent: true,
        direction: 'right',
        offset: [-40, 20]
      })

      expandArrow.addEventListener('click', (event) => {
        tooltipContent.classList.add('expanded')
        expandArrow.style.display = 'none'
        backArrow.style.display = 'inline'
        fullText.style.maxWidth = '100%'

        event.stopPropagation()
      })

      backArrow.addEventListener('click', (event) => {
        tooltipContent.classList.remove('expanded')
        expandArrow.style.display = 'inline'
        backArrow.style.display = 'none'
        fullText.style.maxWidth = '0'

        event.stopPropagation()
      })

      return marker
    }
  }
}
