<template>
  <div id="app" class="h-screen">
    <div class="absolute top-5 left-5"></div>
    <div v-if="isMobile && isQRcodeActive !== 'active' && !isLocatorView && !isUserApproved" class="flex w-full justify-center">
      <MobileSecuritySystem @open-mobile-version="showAppInMobile" />
    </div>
    <div v-else-if="isUserApproved" class="align-middle table-cell">
      <Approved />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Approved from '@/views/approved/Approved.vue'
import MonitorSvg from '@/assets/icons/MonitorIcon.vue'
import ArrowScreen from '@/assets/icons/ArrowMainScreenIcon.vue'
import MobileSecuritySystem from './components/login/MobileSecuritySystem.vue'
import localeRu from 'element-ui/lib/locale/lang/ru-RU.js'
import localeEn from 'element-ui/lib/locale/lang/en'
import localeKz from 'element-ui/lib/locale/lang/kz'
import localeBg from 'element-ui/lib/locale/lang/bg'
import { Metrika } from '@/services/metrika/metrika'
import WebVersion from '@/services/common/webVersion'


export default {
  name: 'App',
  components: {
    Approved,
    MonitorSvg,
    ArrowScreen,
    MobileSecuritySystem
  },
  data() {
    return {
      mobileSize: 735,
      isMobile: false,
      isQRcode: false,
      isUserApproved: false
    }
  },
  computed: {
    ...mapState('login', {
      successInvite: state => state.successInvite,
      isQRcodeActive: state => state.isQRcodeActive,
      me: state => state.me
    }),
    ...mapState('map', {
      isLocatorView: state => state.isLocatorView
    }),
    ...mapGetters('login', {
      me: 'me',
      branding: 'getBranding'
    })
  },
  watch: {
    'me.lang.key': {
      handler(val) {
        if (val === 'ru') {
          localeRu.el.datepicker.confirm = 'Применить'
          localeRu.el.datepicker.clear = 'Отменить'
        }
        if (val === 'en') {
          localeEn.el.datepicker.confirm = 'Apply'
          localeEn.el.datepicker.clear = 'Cancel'
        }
        if (val === 'kz') {
          localeKz.el.datepicker.confirm = 'ОК'
          localeKz.el.datepicker.clear = 'Болдырмау'
        }
        if (val === 'bg') {
          localeBg.el.datepicker.confirm = 'Приложи'
          localeBg.el.datepicker.clear = 'Отказ'
        }
      }
    }
  },

  methods: {
    ...mapActions({
      setLocatorView: 'map/setLocatorView'
    }),
    showAppInMobile() {
      this.mobileSize = 1
      this.isMobile = false
    },
    onResize() {
      this.isMobile = window.innerWidth < this.mobileSize && this.$route.path !== '/changePassword' && this.$route.path !== '/dealer' && this.$route.path !== '/confirm'
    },
    removeOpenedReports() {
      localStorage.removeItem('openedReports')
    },
    metrikaInit() {
      if (this.branding?.yandex_metrika_key && process.env.NODE_ENV === 'production') {
        console.log('M.Init ', this.branding?.yandex_metrika_key)
        Metrika.Init(this.branding?.yandex_metrika_key, true)
      } else {
        console.log('Not M.Init')
      }
    },
    async checkWebVersion() {
      const webVersion = new WebVersion()
      await webVersion.init();
      webVersion.check();
    }
  },
  beforeMount() {
    this.checkWebVersion();

    this.onResize()
    const isApprove = localStorage.getItem('isApproveUrl')
    const isUserLogged = localStorage.getItem('typeLogged')
    const url = window.location.href
    if (isApprove && this.isMobile) {
      this.isUserApproved = true
    }
    if (url.includes('locator')) {
      this.setLocatorView(true)
    }
    if ((isUserLogged && isUserLogged === 'fullLogin') || this.$route.fullPath === '/dealer' || this.$route.fullPath === '/c' || url.includes('/locator?id=')) {
      const regex = /\/company\/change\/([^/]+)(?:\/([^/]+))?/
      const matches = url.match(regex)
      if (matches) {
        const companyId = matches[1]
        const moduleName = matches[2] || null

        if (companyId && !moduleName) {
          // companyId exists, but moduleName doesn't
          this.$store.dispatch('login/setCompanyId', { companyId })
        }

        if (companyId && moduleName) {
          // companyId and moduleName both exist
          this.$store.dispatch('login/openModuleAdminPanel', {
            companyId,
            moduleName
          })
        }
      }
    } else {
      this.$router.push('/')
    }
  },
  beforeCreate() {

    this.$store.dispatch('login/getBranding').then(() => {
      if (this.branding) {
        this.metrikaInit()
      }
      if (this.branding && this.branding.is_powered) {
        // Remove any existing favicon links
        const existingFavicons = document.querySelectorAll("link[rel*='icon']")
        existingFavicons.forEach(link => link.remove())

        const cacheBuster = Date.now()
        const newLink = document.createElement('link')
        newLink.type = 'image/x-icon'
        newLink.rel = 'shortcut icon'
        newLink.href = `/api_v1/${this.branding.logo}`
        document.getElementsByTagName('head')[0].appendChild(newLink)
        document.title = this.branding.title
      } else {
        const oldTabIcon = document.querySelector("link[rel~='icon']")
        oldTabIcon.href = 'favicon-32x32.png'
      }
    })
  },
  mounted() {
    window.addEventListener('beforeunload', this.removeOpenedReports)
    window.addEventListener('resize', this.onResize)
    // eslint-disable-next-line no-unused-expressions
    this.successInvite && this.$showSuccess('Ваш e-mail подтвержден. Добро пожаловать!')
    this.isNewUser = true

    setTimeout(() => {
      this.onResize()
    }, 1000)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.removeOpenedReports)
  }
}
</script>
