import { refs } from '@/components/unitsNew/helpers/index.js'

export default function validateSection(tab, callback) {
  const sectionFormRef = refs[`${tab + 'Validation'}`]

  if (sectionFormRef && sectionFormRef.value)
    sectionFormRef.value.validate(callback)

  return sectionFormRef.value
}
