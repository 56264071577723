<template>
  <div class="mx-auto p-6 flex flex-col">
    <div class="confirm-box">
      <h1 class="header-txt">
        {{ $t('dealerTranslater.confirmHeader') }}
      </h1>
      <p class="warning-msg">
        {{ $t('dealerTranslater.confirmMsg') }}
      </p>
      <p class="p-txt mt-5 mb-2">
        {{ $t('dealerTranslater.confirmMaterials') }}
      </p>
      <ol class="columns-2">
        <li>
          <a href="//wiki.skif.pro/ru/quick" target="_blank">
            {{ $t('dealerTranslater.confirmFastStart') }}
          </a>
        </li>
        <li>
          <a href="//wiki.skif.pro/ru/billing" target="_blank">
            {{ $t('dealerTranslater.confirmDescBill') }}
          </a>
        </li>
        <li>
          <a href="//wiki.skif.pro/ru/data/repo/adminpanel" target="_blank">
            {{ $t('dealerTranslater.confirmDescAdmin') }}
          </a>
        </li>
        <li>
          <a href="//wiki.skif.pro/ru/interface" target="_blank">
            {{ $t('dealerTranslater.confirmDescUi') }}
          </a>
        </li>
      </ol>
      <p class="p-txt mt-5 mb-2">
        {{ $t('dealerTranslater.confirmContacts') }}
      </p>
      <ol class="columns-2">
        <li>
          <a href="tel:+88001017543">
            8 800 101 75 43
          </a>
        </li>
        <li>
          <a href="mailto:info@skif.pro">
            info@skif.pro
          </a>
        </li>
      </ol>
      <a class="admin-panel-btn" href="/login" target="_blank">
        <skif-button class="mt-6" width="full">
          {{ $t('dealerTranslater.confirmGoToApp') }}
        </skif-button>
      </a>
    </div>
    <div class="flex qr-box">
      <div class="flex-1 flex flex-col justify-center items-center">
        <p class="p-txt-bold pt-5">
          iOS {{ $t('dealerTranslater.confirmApp') }}
        </p>
        <svg class="mt-5" width="212" height="212" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60.4688 4.45312V1.51435C60.4688 0.678238 61.1466 0.000434875 61.9827 0.000434875H63.4075C64.2436 0.000434875 64.9214 0.678238 64.9214 1.51435V4.45312H60.4688Z" fill="black"/>
            <path d="M76.5195 4.36277V4.45182H77.8776V3.09375H77.7885C77.7885 3.79728 77.2186 4.36277 76.5195 4.36277Z" fill="black"/>
            <path d="M77.7422 4.45312V1.51435C77.7422 0.678238 78.42 0.000434875 79.2561 0.000434875H80.681C81.5171 0.000434875 82.1949 0.678238 82.1949 1.51435V4.45312H77.7422Z" fill="black"/>
            <path d="M85.1562 4.36277V4.45182H86.5143V3.09375H86.4253C86.4253 3.79728 85.8553 4.36277 85.1562 4.36277Z" fill="black"/>
            <path d="M86.3828 4.45312V1.51435C86.3828 0.678238 87.0606 0.000434875 87.8967 0.000434875H89.3216C90.1577 0.000434875 90.8355 0.678238 90.8355 1.51435V4.45312H86.3828Z" fill="black"/>
            <path d="M99.4727 4.45312H96.5339C95.6978 4.45312 95.02 3.77532 95.02 2.93921V1.51435C95.02 0.678238 95.6978 0.000434875 96.5339 0.000434875H99.4727V4.45312Z" fill="black"/>
            <path d="M103.793 0H99.3398V4.45269H103.793V0Z" fill="black"/>
            <path d="M108.109 0H103.656V4.45269H108.109V0Z" fill="black"/>
            <path d="M107.977 4.45312V1.51435C107.977 0.678238 108.654 0.000434875 109.49 0.000434875H110.915C111.751 0.000434875 112.429 0.678238 112.429 1.51435V4.45312H107.977Z" fill="black"/>
            <path d="M115.391 4.36277V4.45182H116.749V3.09375H116.66C116.66 3.79728 116.09 4.36277 115.391 4.36277Z" fill="black"/>
            <path d="M116.617 4.45312V1.51435C116.617 0.678238 117.295 0.000434875 118.131 0.000434875H119.556C120.392 0.000434875 121.07 0.678238 121.07 1.51435V4.45312H116.617Z" fill="black"/>
            <path d="M129.707 4.45312H126.768C125.932 4.45312 125.254 3.77532 125.254 2.93921V1.51435C125.254 0.678238 125.932 0.000434875 126.768 0.000434875H129.707V4.45312Z" fill="black"/>
            <path d="M134.027 0H129.574V4.45269H134.027V0Z" fill="black"/>
            <path d="M138.343 0H133.891V4.45269H138.343V0Z" fill="black"/>
            <path d="M142.664 0H138.211V4.45269H142.664V0Z" fill="black"/>
            <path d="M142.531 4.45312V0.000434875H145.47C146.306 0.000434875 146.984 0.678238 146.984 1.51435V4.45312H142.531Z" fill="black"/>
            <path d="M171.539 4.36277V4.45182H172.897V3.09375H172.808C172.808 3.79728 172.238 4.36277 171.539 4.36277Z" fill="black"/>
            <path d="M172.766 4.45312V1.51435C172.766 0.678238 173.443 0.000434875 174.28 0.000434875H175.704C176.541 0.000434875 177.218 0.678238 177.218 1.51435V4.45312H172.766Z" fill="black"/>
            <path d="M39.0078 8.77344H36.069C35.2329 8.77344 34.5551 8.09563 34.5551 7.25952V5.83466C34.5551 4.99855 35.2329 4.32075 36.069 4.32075H39.0078V8.77344Z" fill="black"/>
            <path d="M43.3238 4.32031H38.8711V8.773H43.3238V4.32031Z" fill="black"/>
            <path d="M43.1914 8.77344V5.83466C43.1914 4.99855 43.8692 4.32075 44.7053 4.32075H46.1302C46.9663 4.32075 47.6441 4.99855 47.6441 5.83466V8.77344H43.1914Z" fill="black"/>
            <path d="M64.9219 4.32031V8.773H61.9831C61.147 8.773 60.4692 8.0952 60.4692 7.25909V4.32031H64.9219Z" fill="black"/>
            <path d="M64.7891 8.77344V4.32075H67.7278C68.5639 4.32075 69.2418 4.99855 69.2418 5.83466V8.77344H64.7891Z" fill="black"/>
            <path d="M72.2031 8.68308V8.77213H73.5612V7.41406H73.4721C73.4721 8.11759 72.9022 8.68308 72.2031 8.68308Z" fill="black"/>
            <path d="M77.8789 8.77344H73.4262V5.83466C73.4262 4.99855 74.104 4.32075 74.9401 4.32075H77.8789V8.77344Z" fill="black"/>
            <path d="M82.1949 4.32031H77.7422V8.773H82.1949V4.32031Z" fill="black"/>
            <path d="M86.5152 4.32031H82.0625V8.773H86.5152V4.32031Z" fill="black"/>
            <path d="M90.8355 4.32031H86.3828V8.773H90.8355V4.32031Z" fill="black"/>
            <path d="M111.07 8.68308V8.77213H112.428V7.41406H112.339C112.339 8.11759 111.769 8.68308 111.07 8.68308Z" fill="black"/>
            <path d="M116.75 8.77344H112.297V5.83466C112.297 4.99855 112.975 4.32075 113.811 4.32075H116.75V8.77344Z" fill="black"/>
            <path d="M121.07 4.32031H116.617V8.773H121.07V4.32031Z" fill="black"/>
            <path d="M120.938 8.77344V5.83466C120.938 4.99855 121.615 4.32075 122.451 4.32075H123.876C124.712 4.32075 125.39 4.99855 125.39 5.83466V8.77344H120.938Z" fill="black"/>
            <path d="M134.027 4.32031V7.25909C134.027 8.0952 133.35 8.773 132.513 8.773H131.089C130.252 8.773 129.575 8.0952 129.575 7.25909V4.32031H134.027Z" fill="black"/>
            <path d="M142.664 4.32031V8.773H139.725C138.889 8.773 138.211 8.0952 138.211 7.25909V4.32031H142.664Z" fill="black"/>
            <path d="M146.984 4.32031H142.531V8.773H146.984V4.32031Z" fill="black"/>
            <path d="M146.852 8.77344V5.83466C146.852 4.99855 147.529 4.32075 148.365 4.32075H149.79C150.626 4.32075 151.304 4.99855 151.304 5.83466V8.77344H146.852Z" fill="black"/>
            <path d="M159.941 8.77344H155.489V5.83466C155.489 4.99855 156.167 4.32075 157.003 4.32075H159.941V8.77344Z" fill="black"/>
            <path d="M164.257 4.32031H159.805V8.773H164.257V4.32031Z" fill="black"/>
            <path d="M168.578 4.32031H164.125V8.773H168.578V4.32031Z" fill="black"/>
            <path d="M172.898 4.32031H168.445V8.773H172.898V4.32031Z" fill="black"/>
            <path d="M177.218 4.32031H172.766V8.773H177.218V4.32031Z" fill="black"/>
            <path d="M51.9648 13.0938H49.0261C48.19 13.0938 47.5122 12.4159 47.5122 11.5798V10.155C47.5122 9.31886 48.19 8.64106 49.0261 8.64106H51.9648V13.0938Z" fill="black"/>
            <path d="M51.8281 13.0938V10.155C51.8281 9.31886 52.5059 8.64106 53.342 8.64106H54.7669C55.603 8.64106 56.2808 9.31886 56.2808 10.155V13.0938H51.8281Z" fill="black"/>
            <path d="M69.2418 8.64062H64.7891V13.0933H69.2418V8.64062Z" fill="black"/>
            <path d="M73.5582 8.64062H69.1055V13.0933H73.5582V8.64062Z" fill="black"/>
            <path d="M77.8785 8.64062H73.4258V13.0933H77.8785V8.64062Z" fill="black"/>
            <path d="M82.1949 8.64062H77.7422V13.0933H82.1949V8.64062Z" fill="black"/>
            <path d="M86.5152 8.64062H82.0625V13.0933H86.5152V8.64062Z" fill="black"/>
            <path d="M90.8355 8.64062H86.3828V13.0933H90.8355V8.64062Z" fill="black"/>
            <path d="M95.1558 8.64062H90.7031V13.0933H95.1558V8.64062Z" fill="black"/>
            <path d="M99.4722 8.64062H95.0195V13.0933H99.4722V8.64062Z" fill="black"/>
            <path d="M99.3398 13.0938V8.64106H102.279C103.115 8.64106 103.793 9.31886 103.793 10.155V13.0938H99.3398Z" fill="black"/>
            <path d="M106.754 13.0034V13.0924H108.112V11.7344H108.023C108.023 12.4379 107.453 13.0034 106.754 13.0034Z" fill="black"/>
            <path d="M112.434 13.0938H107.981V10.155C107.981 9.31886 108.659 8.64106 109.495 8.64106H112.434V13.0938Z" fill="black"/>
            <path d="M116.75 8.64062H112.297V13.0933H116.75V8.64062Z" fill="black"/>
            <path d="M159.941 8.64062V11.5794C159.941 12.4155 159.264 13.0933 158.427 13.0933H157.003C156.167 13.0933 155.489 12.4155 155.489 11.5794V8.64062H159.941Z" fill="black"/>
            <path d="M172.898 8.64062H168.445V13.0933H172.898V8.64062Z" fill="black"/>
            <path d="M172.766 8.64062H177.218V11.5794C177.218 12.4155 176.541 13.0933 175.704 13.0933H172.766V8.64062Z" fill="black"/>
            <path d="M43.3242 17.4141H40.3854C39.5493 17.4141 38.8715 16.7363 38.8715 15.9001V14.4753C38.8715 13.6392 39.5493 12.9614 40.3854 12.9614H43.3242V17.4141Z" fill="black"/>
            <path d="M43.1914 17.4141V12.9614H46.1302C46.9663 12.9614 47.6441 13.6392 47.6441 14.4753V17.4141H43.1914Z" fill="black"/>
            <path d="M60.6016 17.4141H57.6628C56.8267 17.4141 56.1489 16.7363 56.1489 15.9001V14.4753C56.1489 13.6392 56.8267 12.9614 57.6628 12.9614H60.6016V17.4141Z" fill="black"/>
            <path d="M64.9214 12.9609H60.4688V17.4136H64.9214V12.9609Z" fill="black"/>
            <path d="M69.2418 12.9609H64.7891V17.4136H69.2418V12.9609Z" fill="black"/>
            <path d="M77.8785 12.9609H73.4258V17.4136H77.8785V12.9609Z" fill="black"/>
            <path d="M86.5152 12.9609H82.0625V17.4136H86.5152V12.9609Z" fill="black"/>
            <path d="M95.1562 12.9609V15.8997C95.1562 16.7358 94.4784 17.4136 93.6423 17.4136H92.2175C91.3814 17.4136 90.7036 16.7358 90.7036 15.8997V12.9609H95.1562Z" fill="black"/>
            <path d="M103.793 12.9609H99.3398V17.4136H103.793V12.9609Z" fill="black"/>
            <path d="M108.109 12.9609H103.656V17.4136H108.109V12.9609Z" fill="black"/>
            <path d="M112.429 12.9609H107.977V17.4136H112.429V12.9609Z" fill="black"/>
            <path d="M116.75 12.9609H112.297V17.4136H116.75V12.9609Z" fill="black"/>
            <path d="M116.617 17.4141V12.9614H119.556C120.392 12.9614 121.07 13.6392 121.07 14.4753V17.4141H116.617Z" fill="black"/>
            <path d="M129.707 17.4141H125.254V14.4753C125.254 13.6392 125.932 12.9614 126.768 12.9614H129.707V17.4141Z" fill="black"/>
            <path d="M134.027 12.9609H129.574V17.4136H134.027V12.9609Z" fill="black"/>
            <path d="M138.343 12.9609H133.891V17.4136H138.343V12.9609Z" fill="black"/>
            <path d="M138.211 17.4141V14.4753C138.211 13.6392 138.889 12.9614 139.725 12.9614H141.15C141.986 12.9614 142.664 13.6392 142.664 14.4753V17.4141H138.211Z" fill="black"/>
            <path d="M172.898 12.9609V15.8997C172.898 16.7358 172.221 17.4136 171.385 17.4136H169.96C169.124 17.4136 168.446 16.7358 168.446 15.8997V12.9609H172.898Z" fill="black"/>
            <path d="M47.6441 17.2734H43.1914V21.7261H47.6441V17.2734Z" fill="black"/>
            <path d="M64.9219 17.2734V21.7261H61.9831C61.147 21.7261 60.4692 21.0483 60.4692 20.2122V17.2734H64.9219Z" fill="black"/>
            <path d="M69.2418 17.2734H64.7891V21.7261H69.2418V17.2734Z" fill="black"/>
            <path d="M77.8785 17.2734H73.4258V21.7261H77.8785V17.2734Z" fill="black"/>
            <path d="M86.5156 17.2734V20.2122C86.5156 21.0483 85.8378 21.7261 85.0017 21.7261H83.5768C82.7407 21.7261 82.0629 21.0483 82.0629 20.2122V17.2734H86.5156Z" fill="black"/>
            <path d="M99.4727 21.7266H95.02V18.7878C95.02 17.9517 95.6978 17.2739 96.5339 17.2739H99.4727V21.7266Z" fill="black"/>
            <path d="M103.793 17.2734H99.3398V21.7261H103.793V17.2734Z" fill="black"/>
            <path d="M108.109 17.2734H103.656V21.7261H108.109V17.2734Z" fill="black"/>
            <path d="M112.429 17.2734H107.977V21.7261H112.429V17.2734Z" fill="black"/>
            <path d="M116.75 17.2734H112.297V21.7261H116.75V17.2734Z" fill="black"/>
            <path d="M116.617 17.2734H121.07V20.2122C121.07 21.0483 120.392 21.7261 119.556 21.7261H116.617V17.2734Z" fill="black"/>
            <path d="M129.707 17.2734V20.2122C129.707 21.0483 129.029 21.7261 128.193 21.7261H126.768C125.932 21.7261 125.254 21.0483 125.254 20.2122V17.2734H129.707Z" fill="black"/>
            <path d="M138.348 17.2734V20.2122C138.348 21.0483 137.67 21.7261 136.834 21.7261H135.409C134.573 21.7261 133.895 21.0483 133.895 20.2122V17.2734H138.348Z" fill="black"/>
            <path d="M155.488 21.7266V18.7878C155.488 17.9517 156.166 17.2739 157.002 17.2739H158.427C159.263 17.2739 159.941 17.9517 159.941 18.7878V21.7266H155.488Z" fill="black"/>
            <path d="M39.0078 26.0469H34.5551V23.1081C34.5551 22.272 35.2329 21.5942 36.069 21.5942H39.0078V26.0469Z" fill="black"/>
            <path d="M43.3238 21.5938H38.8711V26.0464H43.3238V21.5938Z" fill="black"/>
            <path d="M47.6441 21.5938H43.1914V26.0464H47.6441V21.5938Z" fill="black"/>
            <path d="M56.2852 26.0469H51.8325V23.1081C51.8325 22.272 52.5103 21.5942 53.3464 21.5942H56.2852V26.0469Z" fill="black"/>
            <path d="M56.1484 26.0469V23.1081C56.1484 22.272 56.8262 21.5942 57.6624 21.5942H59.0872C59.9233 21.5942 60.6011 22.272 60.6011 23.1081V26.0469H56.1484Z" fill="black"/>
            <path d="M69.2422 21.5938V24.5325C69.2422 25.3686 68.5644 26.0464 67.7283 26.0464H66.3034C65.4673 26.0464 64.7895 25.3686 64.7895 24.5325V21.5938H69.2422Z" fill="black"/>
            <path d="M77.8789 21.5938V26.0464H74.9401C74.104 26.0464 73.4262 25.3686 73.4262 24.5325V21.5938H77.8789Z" fill="black"/>
            <path d="M77.7422 26.0469V21.5942H80.681C81.5171 21.5942 82.1949 22.272 82.1949 23.1081V26.0469H77.7422Z" fill="black"/>
            <path d="M86.3828 26.0469V23.1081C86.3828 22.272 87.0606 21.5942 87.8967 21.5942H89.3216C90.1577 21.5942 90.8355 22.272 90.8355 23.1081V26.0469H86.3828Z" fill="black"/>
            <path d="M99.4722 21.5938H95.0195V26.0464H99.4722V21.5938Z" fill="black"/>
            <path d="M116.75 21.5938H112.297V26.0464H116.75V21.5938Z" fill="black"/>
            <path d="M129.574 26.0469V23.1081C129.574 22.272 130.252 21.5942 131.088 21.5942H132.513C133.349 21.5942 134.027 22.272 134.027 23.1081V26.0469H129.574Z" fill="black"/>
            <path d="M138.211 26.0469V23.1081C138.211 22.272 138.889 21.5942 139.725 21.5942H141.15C141.986 21.5942 142.664 22.272 142.664 23.1081V26.0469H138.211Z" fill="black"/>
            <path d="M146.852 26.0469V23.1081C146.852 22.272 147.529 21.5942 148.365 21.5942H149.79C150.626 21.5942 151.304 22.272 151.304 23.1081V26.0469H146.852Z" fill="black"/>
            <path d="M159.941 21.5938H155.488V26.0464H159.941V21.5938Z" fill="black"/>
            <path d="M164.257 21.5938H159.805V26.0464H164.257V21.5938Z" fill="black"/>
            <path d="M164.125 26.0469V21.5942H167.064C167.9 21.5942 168.578 22.272 168.578 23.1081V26.0469H164.125Z" fill="black"/>
            <path d="M39.0074 25.9141H34.5547V30.3668H39.0074V25.9141Z" fill="black"/>
            <path d="M47.6445 25.9141V28.8528C47.6445 29.6889 46.9667 30.3668 46.1306 30.3668H44.7058C43.8696 30.3668 43.1918 29.6889 43.1918 28.8528V25.9141H47.6445Z" fill="black"/>
            <path d="M56.2808 25.9141H51.8281V30.3668H56.2808V25.9141Z" fill="black"/>
            <path d="M60.4688 30.3672V27.4284C60.4688 26.5923 61.1466 25.9145 61.9827 25.9145H63.4075C64.2436 25.9145 64.9214 26.5923 64.9214 27.4284V30.3672H60.4688Z" fill="black"/>
            <path d="M69.1055 27.1875V29.0977C69.1055 29.8013 69.6754 30.3668 70.3789 30.3668H72.2891C72.9971 30.3668 73.5626 29.7968 73.5626 29.0933V27.1875C73.5582 26.4796 72.9882 25.9141 72.2847 25.9141H70.3789C69.6754 25.9141 69.1055 26.484 69.1055 27.1875Z" fill="black"/>
            <path d="M82.1949 25.9141H77.7422V30.3668H82.1949V25.9141Z" fill="black"/>
            <path d="M90.8359 25.9141V28.8528C90.8359 29.6889 90.1581 30.3668 89.322 30.3668H87.8972C87.0611 30.3668 86.3832 29.6889 86.3832 28.8528V25.9141H90.8359Z" fill="black"/>
            <path d="M99.4722 25.9141H95.0195V30.3668H99.4722V25.9141Z" fill="black"/>
            <path d="M103.656 27.1875V29.0977C103.656 29.8013 104.226 30.3668 104.93 30.3668H106.84C107.548 30.3668 108.113 29.7968 108.113 29.0933V27.1875C108.109 26.4796 107.539 25.9141 106.835 25.9141H104.93C104.226 25.9141 103.656 26.484 103.656 27.1875Z" fill="black"/>
            <path d="M116.75 25.9141H112.297V30.3668H116.75V25.9141Z" fill="black"/>
            <path d="M120.938 27.1875V29.0977C120.938 29.8013 121.507 30.3668 122.211 30.3668H124.121C124.829 30.3668 125.395 29.7968 125.395 29.0933V27.1875C125.39 26.4796 124.82 25.9141 124.117 25.9141H122.211C121.507 25.9141 120.938 26.484 120.938 27.1875Z" fill="black"/>
            <path d="M134.027 25.9141V28.8528C134.027 29.6889 133.35 30.3668 132.513 30.3668H131.089C130.252 30.3668 129.575 29.6889 129.575 28.8528V25.9141H134.027Z" fill="black"/>
            <path d="M136.984 30.2768V30.3659H138.342V29.0078H138.253C138.253 29.7113 137.683 30.2768 136.984 30.2768Z" fill="black"/>
            <path d="M142.664 25.9141H138.211V30.3668H142.664V25.9141Z" fill="black"/>
            <path d="M151.305 25.9141V28.8528C151.305 29.6889 150.627 30.3668 149.791 30.3668H148.366C147.53 30.3668 146.852 29.6889 146.852 28.8528V25.9141H151.305Z" fill="black"/>
            <path d="M159.941 25.9141V28.8528C159.941 29.6889 159.264 30.3668 158.427 30.3668H157.003C156.167 30.3668 155.489 29.6889 155.489 28.8528V25.9141H159.941Z" fill="black"/>
            <path d="M168.582 25.9141V28.8528C168.582 29.6889 167.904 30.3668 167.068 30.3668H165.643C164.807 30.3668 164.129 29.6889 164.129 28.8528V25.9141H168.582Z" fill="black"/>
            <path d="M172.766 30.3672V27.4284C172.766 26.5923 173.443 25.9145 174.28 25.9145H175.704C176.541 25.9145 177.218 26.5923 177.218 27.4284V30.3672H172.766Z" fill="black"/>
            <path d="M39.0078 30.2344V34.6871H36.069C35.2329 34.6871 34.5551 34.0093 34.5551 33.1732V30.2344H39.0078Z" fill="black"/>
            <path d="M38.8711 34.6875V31.7487C38.8711 30.9126 39.5489 30.2348 40.385 30.2348H41.8099C42.646 30.2348 43.3238 30.9126 43.3238 31.7487V34.6875H38.8711Z" fill="black"/>
            <path d="M56.2852 30.2344V33.1732C56.2852 34.0093 55.6074 34.6871 54.7712 34.6871H53.3464C52.5103 34.6871 51.8325 34.0093 51.8325 33.1732V30.2344H56.2852Z" fill="black"/>
            <path d="M64.9219 30.2344V33.1732C64.9219 34.0093 64.2441 34.6871 63.408 34.6871H61.9831C61.147 34.6871 60.4692 34.0093 60.4692 33.1732V30.2344H64.9219Z" fill="black"/>
            <path d="M77.8789 34.6875H74.9401C74.104 34.6875 73.4262 34.0097 73.4262 33.1736V31.7487C73.4262 30.9126 74.104 30.2348 74.9401 30.2348H77.8789V34.6875Z" fill="black"/>
            <path d="M82.1949 30.2344H77.7422V34.6871H82.1949V30.2344Z" fill="black"/>
            <path d="M82.0625 34.6875V31.7487C82.0625 30.9126 82.7403 30.2348 83.5764 30.2348H85.0013C85.8374 30.2348 86.5152 30.9126 86.5152 31.7487V34.6875H82.0625Z" fill="black"/>
            <path d="M93.7969 34.5971V34.6862H95.1549V33.3281H95.0659C95.0659 34.0317 94.4959 34.5971 93.7969 34.5971Z" fill="black"/>
            <path d="M99.4722 30.2344H95.0195V34.6871H99.4722V30.2344Z" fill="black"/>
            <path d="M111.07 34.5971V34.6862H112.428V33.3281H112.339C112.339 34.0317 111.769 34.5971 111.07 34.5971Z" fill="black"/>
            <path d="M116.75 30.2344H112.297V34.6871H116.75V30.2344Z" fill="black"/>
            <path d="M125.254 34.6875V31.7487C125.254 30.9126 125.932 30.2348 126.768 30.2348H128.193C129.029 30.2348 129.707 30.9126 129.707 31.7487V34.6875H125.254Z" fill="black"/>
            <path d="M132.668 34.5971V34.6862H134.026V33.3281H133.937C133.937 34.0317 133.367 34.5971 132.668 34.5971Z" fill="black"/>
            <path d="M138.348 34.6875H133.895V31.7487C133.895 30.9126 134.573 30.2348 135.409 30.2348H138.348V34.6875Z" fill="black"/>
            <path d="M142.664 30.2344H138.211V34.6871H142.664V30.2344Z" fill="black"/>
            <path d="M149.945 34.5971V34.6862H151.303V33.3281H151.214C151.214 34.0317 150.644 34.5971 149.945 34.5971Z" fill="black"/>
            <path d="M151.168 34.6875V31.7487C151.168 30.9126 151.846 30.2348 152.682 30.2348H154.107C154.943 30.2348 155.621 30.9126 155.621 31.7487V34.6875H151.168Z" fill="black"/>
            <path d="M177.218 30.2344H172.766V34.6871H177.218V30.2344Z" fill="black"/>
            <path d="M21.7305 39.0078H17.2778V36.069C17.2778 35.2329 17.9556 34.5551 18.7917 34.5551H21.7305V39.0078Z" fill="black"/>
            <path d="M26.0464 34.5547H21.5938V39.0074H26.0464V34.5547Z" fill="black"/>
            <path d="M30.3668 34.5547H25.9141V39.0074H30.3668V34.5547Z" fill="black"/>
            <path d="M30.2344 39.0078V36.069C30.2344 35.2329 30.9122 34.5551 31.7483 34.5551H33.1732C34.0093 34.5551 34.6871 35.2329 34.6871 36.069V39.0078H30.2344Z" fill="black"/>
            <path d="M47.5078 35.8282V37.7384C47.5078 38.4419 48.0778 39.0074 48.7813 39.0074H50.6915C51.3995 39.0074 51.965 38.4374 51.965 37.7339V35.8282C51.9605 35.1202 51.3906 34.5547 50.687 34.5547H48.7813C48.0778 34.5547 47.5078 35.1246 47.5078 35.8282Z" fill="black"/>
            <path d="M64.7891 35.8282V37.7384C64.7891 38.4419 65.359 39.0074 66.0625 39.0074H67.9727C68.6807 39.0074 69.2462 38.4374 69.2462 37.7339V35.8282C69.2418 35.1202 68.6718 34.5547 67.9683 34.5547H66.0625C65.359 34.5547 64.7891 35.1246 64.7891 35.8282Z" fill="black"/>
            <path d="M90.8359 39.0078H87.8972C87.0611 39.0078 86.3832 38.33 86.3832 37.4939V36.069C86.3832 35.2329 87.0611 34.5551 87.8972 34.5551H90.8359V39.0078Z" fill="black"/>
            <path d="M95.1558 34.5547H90.7031V39.0074H95.1558V34.5547Z" fill="black"/>
            <path d="M99.4722 34.5547H95.0195V39.0074H99.4722V34.5547Z" fill="black"/>
            <path d="M103.793 34.5547H99.3398V39.0074H103.793V34.5547Z" fill="black"/>
            <path d="M108.109 34.5547H103.656V39.0074H108.109V34.5547Z" fill="black"/>
            <path d="M112.429 34.5547H107.977V39.0074H112.429V34.5547Z" fill="black"/>
            <path d="M116.75 34.5547H112.297V39.0074H116.75V34.5547Z" fill="black"/>
            <path d="M116.617 39.0078V34.5551H119.556C120.392 34.5551 121.07 35.2329 121.07 36.069V39.0078H116.617Z" fill="black"/>
            <path d="M129.707 34.5547V39.0074H126.768C125.932 39.0074 125.254 38.3296 125.254 37.4935V34.5547H129.707Z" fill="black"/>
            <path d="M134.027 34.5547H129.574V39.0074H134.027V34.5547Z" fill="black"/>
            <path d="M138.343 34.5547H133.891V39.0074H138.343V34.5547Z" fill="black"/>
            <path d="M142.664 34.5547H138.211V39.0074H142.664V34.5547Z" fill="black"/>
            <path d="M146.984 34.5547H142.531V39.0074H146.984V34.5547Z" fill="black"/>
            <path d="M151.304 34.5547H146.852V39.0074H151.304V34.5547Z" fill="black"/>
            <path d="M151.168 34.5547H155.621V37.4935C155.621 38.3296 154.943 39.0074 154.107 39.0074H151.168V34.5547Z" fill="black"/>
            <path d="M159.805 39.0078V36.069C159.805 35.2329 160.482 34.5551 161.319 34.5551H162.743C163.58 34.5551 164.257 35.2329 164.257 36.069V39.0078H159.805Z" fill="black"/>
            <path d="M177.219 34.5547V37.4935C177.219 38.3296 176.541 39.0074 175.705 39.0074H174.28C173.444 39.0074 172.766 38.3296 172.766 37.4935V34.5547H177.219Z" fill="black"/>
            <path d="M185.855 39.0078H181.403V36.069C181.403 35.2329 182.081 34.5551 182.917 34.5551H185.855V39.0078Z" fill="black"/>
            <path d="M185.723 39.0078V34.5551H188.661C189.498 34.5551 190.175 35.2329 190.175 36.069V39.0078H185.723Z" fill="black"/>
            <path d="M201.773 38.9175V39.0065H203.132V37.6484H203.042C203.042 38.352 202.473 38.9175 201.773 38.9175Z" fill="black"/>
            <path d="M203 39.0078V36.069C203 35.2329 203.678 34.5551 204.514 34.5551H205.939C206.775 34.5551 207.453 35.2329 207.453 36.069V39.0078H203Z" fill="black"/>
            <path d="M8.77344 43.3281H5.83466C4.99855 43.3281 4.32075 42.6503 4.32075 41.8142V40.3893C4.32075 39.5532 4.99855 38.8754 5.83466 38.8754H8.77344V43.3281Z" fill="black"/>
            <path d="M8.64062 43.3281V40.3893C8.64062 39.5532 9.31843 38.8754 10.1545 38.8754H11.5794C12.4155 38.8754 13.0933 39.5532 13.0933 40.3893V43.3281H8.64062Z" fill="black"/>
            <path d="M21.7305 38.875V41.8138C21.7305 42.6499 21.0527 43.3277 20.2166 43.3277H18.7917C17.9556 43.3277 17.2778 42.6499 17.2778 41.8138V38.875H21.7305Z" fill="black"/>
            <path d="M34.5547 43.3281V40.3893C34.5547 39.5532 35.2325 38.8754 36.0686 38.8754H37.4935C38.3296 38.8754 39.0074 39.5532 39.0074 40.3893V43.3281H34.5547Z" fill="black"/>
            <path d="M41.9688 43.2378V43.3268H43.3268V41.9688H43.2378C43.2378 42.6723 42.6678 43.2378 41.9688 43.2378Z" fill="black"/>
            <path d="M43.1914 43.3281V40.3893C43.1914 39.5532 43.8692 38.8754 44.7053 38.8754H46.1302C46.9663 38.8754 47.6441 39.5532 47.6441 40.3893V43.3281H43.1914Z" fill="black"/>
            <path d="M56.1484 40.1485V42.0587C56.1484 42.7622 56.7184 43.3277 57.4219 43.3277H59.3321C60.0401 43.3277 60.6056 42.7577 60.6056 42.0542V40.1485C60.6011 39.4405 60.0312 38.875 59.3277 38.875H57.4219C56.7184 38.875 56.1484 39.4449 56.1484 40.1485Z" fill="black"/>
            <path d="M73.5586 43.3281H69.1059V40.3893C69.1059 39.5532 69.7837 38.8754 70.6198 38.8754H73.5586V43.3281Z" fill="black"/>
            <path d="M77.8785 38.875H73.4258V43.3277H77.8785V38.875Z" fill="black"/>
            <path d="M77.7422 43.3281V38.8754H80.681C81.5171 38.8754 82.1949 39.5532 82.1949 40.3893V43.3281H77.7422Z" fill="black"/>
            <path d="M99.4727 38.875V43.3277H96.5339C95.6978 43.3277 95.02 42.6499 95.02 41.8138V38.875H99.4727Z" fill="black"/>
            <path d="M103.793 38.875H99.3398V43.3277H103.793V38.875Z" fill="black"/>
            <path d="M108.109 38.875H103.656V43.3277H108.109V38.875Z" fill="black"/>
            <path d="M112.429 38.875H107.977V43.3277H112.429V38.875Z" fill="black"/>
            <path d="M116.75 38.875H112.297V43.3277H116.75V38.875Z" fill="black"/>
            <path d="M121.07 38.875H116.617V43.3277H121.07V38.875Z" fill="black"/>
            <path d="M120.938 43.3281V40.3893C120.938 39.5532 121.615 38.8754 122.451 38.8754H123.876C124.712 38.8754 125.39 39.5532 125.39 40.3893V43.3281H120.938Z" fill="black"/>
            <path d="M146.984 38.875V41.8138C146.984 42.6499 146.307 43.3277 145.47 43.3277H144.046C143.209 43.3277 142.532 42.6499 142.532 41.8138V38.875H146.984Z" fill="black"/>
            <path d="M158.582 43.2378V43.3268H159.94V41.9688H159.851C159.851 42.6723 159.281 43.2378 158.582 43.2378Z" fill="black"/>
            <path d="M164.257 38.875H159.805V43.3277H164.257V38.875Z" fill="black"/>
            <path d="M168.578 38.875H164.125V43.3277H168.578V38.875Z" fill="black"/>
            <path d="M168.445 43.3281V40.3893C168.445 39.5532 169.123 38.8754 169.959 38.8754H171.384C172.22 38.8754 172.898 39.5532 172.898 40.3893V43.3281H168.445Z" fill="black"/>
            <path d="M185.855 38.875H181.402V43.3277H185.855V38.875Z" fill="black"/>
            <path d="M190.175 38.875H185.723V43.3277H190.175V38.875Z" fill="black"/>
            <path d="M190.039 43.3281V40.3893C190.039 39.5532 190.717 38.8754 191.553 38.8754H192.978C193.814 38.8754 194.492 39.5532 194.492 40.3893V43.3281H190.039Z" fill="black"/>
            <path d="M203.133 43.3281H198.68V40.3893C198.68 39.5532 199.358 38.8754 200.194 38.8754H203.133V43.3281Z" fill="black"/>
            <path d="M207.453 38.875H203V43.3277H207.453V38.875Z" fill="black"/>
            <path d="M0 44.461V46.3712C0 47.0747 0.569944 47.6402 1.27347 47.6402H3.18367C3.89165 47.6402 4.45714 47.0702 4.45714 46.3667V44.461C4.45269 43.753 3.88275 43.1875 3.17922 43.1875H1.27347C0.569944 43.1875 0 43.7574 0 44.461Z" fill="black"/>
            <path d="M12.957 44.461V46.3712C12.957 47.0747 13.527 47.6402 14.2305 47.6402H16.1407C16.8487 47.6402 17.4142 47.0702 17.4142 46.3667V44.461C17.4097 43.753 16.8398 43.1875 16.1363 43.1875H14.2305C13.527 43.1875 12.957 43.7574 12.957 44.461Z" fill="black"/>
            <path d="M26.0508 47.6406H23.112C22.2759 47.6406 21.5981 46.9628 21.5981 46.1267V44.7018C21.5981 43.8657 22.2759 43.1879 23.112 43.1879H26.0508V47.6406Z" fill="black"/>
            <path d="M25.9141 47.6406V44.7018C25.9141 43.8657 26.5919 43.1879 27.428 43.1879H28.8528C29.6889 43.1879 30.3668 43.8657 30.3668 44.7018V47.6406H25.9141Z" fill="black"/>
            <path d="M39.0078 43.1875V47.6402H36.069C35.2329 47.6402 34.5551 46.9624 34.5551 46.1263V43.1875H39.0078Z" fill="black"/>
            <path d="M43.3238 43.1875H38.8711V47.6402H43.3238V43.1875Z" fill="black"/>
            <path d="M47.6441 43.1875H43.1914V47.6402H47.6441V43.1875Z" fill="black"/>
            <path d="M47.5078 47.6406V43.1879H50.4466C51.2827 43.1879 51.9605 43.8657 51.9605 44.7018V47.6406H47.5078Z" fill="black"/>
            <path d="M59.2422 47.5581V47.6471H60.6003V46.2891H60.5112C60.5112 46.9926 59.9413 47.5581 59.2422 47.5581Z" fill="black"/>
            <path d="M60.4688 47.6406V44.7018C60.4688 43.8657 61.1466 43.1879 61.9827 43.1879H63.4075C64.2436 43.1879 64.9214 43.8657 64.9214 44.7018V47.6406H60.4688Z" fill="black"/>
            <path d="M73.5582 43.1875H69.1055V47.6402H73.5582V43.1875Z" fill="black"/>
            <path d="M77.8785 43.1875H73.4258V47.6402H77.8785V43.1875Z" fill="black"/>
            <path d="M77.7422 43.1875H82.1949V46.1263C82.1949 46.9624 81.5171 47.6402 80.681 47.6402H77.7422V43.1875Z" fill="black"/>
            <path d="M86.3828 44.461V46.3712C86.3828 47.0747 86.9528 47.6402 87.6563 47.6402H89.5665C90.2745 47.6402 90.84 47.0702 90.84 46.3667V44.461C90.8355 43.753 90.2656 43.1875 89.562 43.1875H87.6563C86.9528 43.1875 86.3828 43.7574 86.3828 44.461Z" fill="black"/>
            <path d="M116.75 43.1875H112.297V47.6402H116.75V43.1875Z" fill="black"/>
            <path d="M128.352 47.5581V47.6471H129.71V46.2891H129.621C129.621 46.9926 129.051 47.5581 128.352 47.5581Z" fill="black"/>
            <path d="M129.574 47.6406V44.7018C129.574 43.8657 130.252 43.1879 131.088 43.1879H132.513C133.349 43.1879 134.027 43.8657 134.027 44.7018V47.6406H129.574Z" fill="black"/>
            <path d="M159.941 47.6406H155.489V44.7018C155.489 43.8657 156.167 43.1879 157.003 43.1879H159.941V47.6406Z" fill="black"/>
            <path d="M164.257 43.1875H159.805V47.6402H164.257V43.1875Z" fill="black"/>
            <path d="M168.578 43.1875H164.125V47.6402H168.578V43.1875Z" fill="black"/>
            <path d="M185.855 43.1875V46.1263C185.855 46.9624 185.178 47.6402 184.342 47.6402H182.917C182.081 47.6402 181.403 46.9624 181.403 46.1263V43.1875H185.855Z" fill="black"/>
            <path d="M203.132 43.1875H198.68V47.6402H203.132V43.1875Z" fill="black"/>
            <path d="M203 43.1875H207.453V46.1263C207.453 46.9624 206.775 47.6402 205.939 47.6402H203V43.1875Z" fill="black"/>
            <path d="M8.77344 51.9609H5.83466C4.99855 51.9609 4.32075 51.2831 4.32075 50.447V49.0222C4.32075 48.1861 4.99855 47.5082 5.83466 47.5082H8.77344V51.9609Z" fill="black"/>
            <path d="M8.64062 51.9609V47.5082H11.5794C12.4155 47.5082 13.0933 48.1861 13.0933 49.0222V51.9609H8.64062Z" fill="black"/>
            <path d="M16.0547 51.8706V51.9596H17.4128V50.6016H17.3237C17.3237 51.3051 16.7538 51.8706 16.0547 51.8706Z" fill="black"/>
            <path d="M17.2773 51.9609V49.0222C17.2773 48.1861 17.9551 47.5082 18.7913 47.5082H20.2161C21.0522 47.5082 21.73 48.1861 21.73 49.0222V51.9609H17.2773Z" fill="black"/>
            <path d="M29.0078 51.8706V51.9596H30.3659V50.6016H30.2768C30.2768 51.3051 29.7069 51.8706 29.0078 51.8706Z" fill="black"/>
            <path d="M30.2344 51.9609V49.0222C30.2344 48.1861 30.9122 47.5082 31.7483 47.5082H33.1732C34.0093 47.5082 34.6871 48.1861 34.6871 49.0222V51.9609H30.2344Z" fill="black"/>
            <path d="M47.6445 47.5078V51.9605H44.7058C43.8696 51.9605 43.1918 51.2827 43.1918 50.4466V47.5078H47.6445Z" fill="black"/>
            <path d="M51.9605 47.5078H47.5078V51.9605H51.9605V47.5078Z" fill="black"/>
            <path d="M56.2808 47.5078H51.8281V51.9605H56.2808V47.5078Z" fill="black"/>
            <path d="M60.6011 47.5078H56.1484V51.9605H60.6011V47.5078Z" fill="black"/>
            <path d="M60.4688 47.5078H64.9214V50.4466C64.9214 51.2827 64.2436 51.9605 63.4075 51.9605H60.4688V47.5078Z" fill="black"/>
            <path d="M73.5586 47.5078V51.9605H70.6198C69.7837 51.9605 69.1059 51.2827 69.1059 50.4466V47.5078H73.5586Z" fill="black"/>
            <path d="M73.4258 47.5078H77.8785V50.4466C77.8785 51.2827 77.2007 51.9605 76.3646 51.9605H73.4258V47.5078Z" fill="black"/>
            <path d="M93.7969 51.8706V51.9596H95.1549V50.6016H95.0659C95.0659 51.3051 94.4959 51.8706 93.7969 51.8706Z" fill="black"/>
            <path d="M99.4727 51.9609H95.02V49.0222C95.02 48.1861 95.6978 47.5082 96.5339 47.5082H99.4727V51.9609Z" fill="black"/>
            <path d="M103.793 47.5078H99.3398V51.9605H103.793V47.5078Z" fill="black"/>
            <path d="M103.656 51.9609V49.0222C103.656 48.1861 104.334 47.5082 105.17 47.5082H106.595C107.431 47.5082 108.109 48.1861 108.109 49.0222V51.9609H103.656Z" fill="black"/>
            <path d="M111.07 51.8706V51.9596H112.428V50.6016H112.339C112.339 51.3051 111.769 51.8706 111.07 51.8706Z" fill="black"/>
            <path d="M116.75 47.5078H112.297V51.9605H116.75V47.5078Z" fill="black"/>
            <path d="M116.617 51.9609V47.5082H119.556C120.392 47.5082 121.07 48.1861 121.07 49.0222V51.9609H116.617Z" fill="black"/>
            <path d="M129.707 51.9609H125.254V49.0222C125.254 48.1861 125.932 47.5082 126.768 47.5082H129.707V51.9609Z" fill="black"/>
            <path d="M129.574 47.5078H134.027V50.4466C134.027 51.2827 133.349 51.9605 132.513 51.9605H129.574V47.5078Z" fill="black"/>
            <path d="M142.531 48.7813V50.6915C142.531 51.395 143.101 51.9605 143.805 51.9605H145.715C146.423 51.9605 146.988 51.3906 146.988 50.687V48.7813C146.984 48.0733 146.414 47.5078 145.71 47.5078H143.805C143.101 47.5078 142.531 48.0778 142.531 48.7813Z" fill="black"/>
            <path d="M159.941 47.5078H155.488V51.9605H159.941V47.5078Z" fill="black"/>
            <path d="M168.578 47.5078H164.125V51.9605H168.578V47.5078Z" fill="black"/>
            <path d="M172.898 47.5078H168.445V51.9605H172.898V47.5078Z" fill="black"/>
            <path d="M172.766 51.9609V47.5082H175.704C176.541 47.5082 177.218 48.1861 177.218 49.0222V51.9609H172.766Z" fill="black"/>
            <path d="M190.039 51.9609V49.0222C190.039 48.1861 190.717 47.5082 191.553 47.5082H192.978C193.814 47.5082 194.492 48.1861 194.492 49.0222V51.9609H190.039Z" fill="black"/>
            <path d="M203.132 47.5078H198.68V51.9605H203.132V47.5078Z" fill="black"/>
            <path d="M0 56.2812L0 53.3425C0 52.5064 0.677803 51.8286 1.51391 51.8286H2.93878C3.77489 51.8286 4.45269 52.5064 4.45269 53.3425V56.2812H0Z" fill="black"/>
            <path d="M13.0938 51.8281V56.2808H10.155C9.31886 56.2808 8.64106 55.603 8.64106 54.7669V51.8281H13.0938Z" fill="black"/>
            <path d="M17.4097 51.8281H12.957V56.2808H17.4097V51.8281Z" fill="black"/>
            <path d="M21.73 51.8281H17.2773V56.2808H21.73V51.8281Z" fill="black"/>
            <path d="M26.0464 51.8281H21.5938V56.2808H26.0464V51.8281Z" fill="black"/>
            <path d="M30.3668 51.8281H25.9141V56.2808H30.3668V51.8281Z" fill="black"/>
            <path d="M34.6871 51.8281H30.2344V56.2808H34.6871V51.8281Z" fill="black"/>
            <path d="M56.2852 51.8281V54.7669C56.2852 55.603 55.6074 56.2808 54.7712 56.2808H53.3464C52.5103 56.2808 51.8325 55.603 51.8325 54.7669V51.8281H56.2852Z" fill="black"/>
            <path d="M63.5625 56.1909V56.2799H64.9206V54.9219H64.8315C64.8315 55.6254 64.2616 56.1909 63.5625 56.1909Z" fill="black"/>
            <path d="M64.7891 56.2812V53.3425C64.7891 52.5064 65.4669 51.8286 66.303 51.8286H67.7278C68.5639 51.8286 69.2418 52.5064 69.2418 53.3425V56.2812H64.7891Z" fill="black"/>
            <path d="M82.1992 56.2812H77.7465V53.3425C77.7465 52.5064 78.4243 51.8286 79.2604 51.8286H82.1992V56.2812Z" fill="black"/>
            <path d="M82.0625 56.2812V51.8286H85.0013C85.8374 51.8286 86.5152 52.5064 86.5152 53.3425V56.2812H82.0625Z" fill="black"/>
            <path d="M89.4766 56.1909V56.2799H90.8346V54.9219H90.7456C90.7456 55.6254 90.1756 56.1909 89.4766 56.1909Z" fill="black"/>
            <path d="M95.1562 56.2812H90.7036V53.3425C90.7036 52.5064 91.3814 51.8286 92.2175 51.8286H95.1562V56.2812Z" fill="black"/>
            <path d="M95.0195 51.8281H99.4722V54.7669C99.4722 55.603 98.7944 56.2808 97.9583 56.2808H95.0195V51.8281Z" fill="black"/>
            <path d="M112.434 56.2812H109.495C108.659 56.2812 107.981 55.6034 107.981 54.7673V53.3425C107.981 52.5064 108.659 51.8286 109.495 51.8286H112.434V56.2812Z" fill="black"/>
            <path d="M116.75 51.8281H112.297V56.2808H116.75V51.8281Z" fill="black"/>
            <path d="M121.07 51.8281H116.617V56.2808H121.07V51.8281Z" fill="black"/>
            <path d="M129.707 51.8281V54.7669C129.707 55.603 129.029 56.2808 128.193 56.2808H126.768C125.932 56.2808 125.254 55.603 125.254 54.7669V51.8281H129.707Z" fill="black"/>
            <path d="M154.266 56.1909V56.2799H155.624V54.9219H155.535C155.535 55.6254 154.965 56.1909 154.266 56.1909Z" fill="black"/>
            <path d="M159.941 51.8281H155.488V56.2808H159.941V51.8281Z" fill="black"/>
            <path d="M164.257 51.8281H159.805V56.2808H164.257V51.8281Z" fill="black"/>
            <path d="M168.578 51.8281H164.125V56.2808H168.578V51.8281Z" fill="black"/>
            <path d="M177.219 51.8281V54.7669C177.219 55.603 176.541 56.2808 175.705 56.2808H174.28C173.444 56.2808 172.766 55.603 172.766 54.7669V51.8281H177.219Z" fill="black"/>
            <path d="M180.18 56.1909V56.2799H181.538V54.9219H181.449C181.449 55.6254 180.879 56.1909 180.18 56.1909Z" fill="black"/>
            <path d="M181.402 56.2812V53.3425C181.402 52.5064 182.08 51.8286 182.916 51.8286H184.341C185.177 51.8286 185.855 52.5064 185.855 53.3425V56.2812H181.402Z" fill="black"/>
            <path d="M194.496 51.8281V54.7669C194.496 55.603 193.818 56.2808 192.982 56.2808H191.557C190.721 56.2808 190.043 55.603 190.043 54.7669V51.8281H194.496Z" fill="black"/>
            <path d="M203.133 51.8281V54.7669C203.133 55.603 202.455 56.2808 201.619 56.2808H200.194C199.358 56.2808 198.68 55.603 198.68 54.7669V51.8281H203.133Z" fill="black"/>
            <path d="M207.316 53.1016V55.0118C207.316 55.7153 207.886 56.2808 208.59 56.2808H210.5C211.208 56.2808 211.774 55.7109 211.774 55.0073V53.1016C211.769 52.3936 211.199 51.8281 210.496 51.8281H208.59C207.886 51.8281 207.316 52.3981 207.316 53.1016Z" fill="black"/>
            <path d="M4.45269 56.1484H0V60.6011H4.45269V56.1484Z" fill="black"/>
            <path d="M4.32031 60.6016V56.1489H7.25909C8.0952 56.1489 8.773 56.8267 8.773 57.6628V60.6016H4.32031Z" fill="black"/>
            <path d="M17.4097 56.1484H12.957V60.6011H17.4097V56.1484Z" fill="black"/>
            <path d="M26.0508 56.1484V59.0872C26.0508 59.9233 25.373 60.6011 24.5369 60.6011H23.112C22.2759 60.6011 21.5981 59.9233 21.5981 59.0872V56.1484H26.0508Z" fill="black"/>
            <path d="M34.6871 56.1484H30.2344V60.6011H34.6871V56.1484Z" fill="black"/>
            <path d="M37.6484 60.5112V60.6003H39.0065V59.2422H38.9175C38.9175 59.9457 38.3475 60.5112 37.6484 60.5112Z" fill="black"/>
            <path d="M43.3242 60.6016H38.8715V57.6628C38.8715 56.8267 39.5493 56.1489 40.3854 56.1489H43.3242V60.6016Z" fill="black"/>
            <path d="M43.1914 60.6016V56.1489H46.1302C46.9663 56.1489 47.6441 56.8267 47.6441 57.6628V60.6016H43.1914Z" fill="black"/>
            <path d="M64.9219 60.6016H60.4692V57.6628C60.4692 56.8267 61.147 56.1489 61.9831 56.1489H64.9219V60.6016Z" fill="black"/>
            <path d="M69.2418 56.1484H64.7891V60.6011H69.2418V56.1484Z" fill="black"/>
            <path d="M69.1055 60.6016V56.1489H72.0442C72.8804 56.1489 73.5582 56.8267 73.5582 57.6628V60.6016H69.1055Z" fill="black"/>
            <path d="M82.1992 56.1484V60.6011H79.2604C78.4243 60.6011 77.7465 59.9233 77.7465 59.0872V56.1484H82.1992Z" fill="black"/>
            <path d="M86.5152 56.1484H82.0625V60.6011H86.5152V56.1484Z" fill="black"/>
            <path d="M90.8355 56.1484H86.3828V60.6011H90.8355V56.1484Z" fill="black"/>
            <path d="M90.7031 56.1484H95.1558V59.0872C95.1558 59.9233 94.478 60.6011 93.6419 60.6011H90.7031V56.1484Z" fill="black"/>
            <path d="M121.07 56.1484V60.6011H118.132C117.295 60.6011 116.618 59.9233 116.618 59.0872V56.1484H121.07Z" fill="black"/>
            <path d="M120.938 60.6016V57.6628C120.938 56.8267 121.615 56.1489 122.451 56.1489H123.876C124.712 56.1489 125.39 56.8267 125.39 57.6628V60.6016H120.938Z" fill="black"/>
            <path d="M132.668 60.5112V60.6003H134.026V59.2422H133.937C133.937 59.9457 133.367 60.5112 132.668 60.5112Z" fill="black"/>
            <path d="M133.891 60.6016V57.6628C133.891 56.8267 134.568 56.1489 135.405 56.1489H136.829C137.666 56.1489 138.343 56.8267 138.343 57.6628V60.6016H133.891Z" fill="black"/>
            <path d="M142.531 60.6016V57.6628C142.531 56.8267 143.209 56.1489 144.045 56.1489H145.47C146.306 56.1489 146.984 56.8267 146.984 57.6628V60.6016H142.531Z" fill="black"/>
            <path d="M155.621 60.6016H152.682C151.846 60.6016 151.168 59.9238 151.168 59.0876V57.6628C151.168 56.8267 151.846 56.1489 152.682 56.1489H155.621V60.6016Z" fill="black"/>
            <path d="M155.488 56.1484H159.941V59.0872C159.941 59.9233 159.263 60.6011 158.427 60.6011H155.488V56.1484Z" fill="black"/>
            <path d="M168.578 56.1484H164.125V60.6011H168.578V56.1484Z" fill="black"/>
            <path d="M181.539 60.6016H178.6C177.764 60.6016 177.086 59.9238 177.086 59.0876V57.6628C177.086 56.8267 177.764 56.1489 178.6 56.1489H181.539V60.6016Z" fill="black"/>
            <path d="M185.855 56.1484H181.402V60.6011H185.855V56.1484Z" fill="black"/>
            <path d="M203 57.4219V59.3321C203 60.0356 203.57 60.6011 204.273 60.6011H206.184C206.892 60.6011 207.457 60.0312 207.457 59.3277V57.4219C207.453 56.7139 206.883 56.1484 206.179 56.1484H204.273C203.57 56.1484 203 56.7184 203 57.4219Z" fill="black"/>
            <path d="M4.45312 60.4688V64.9214H1.51435C0.678238 64.9214 0.000434875 64.2436 0.000434875 63.4075V60.4688H4.45312Z" fill="black"/>
            <path d="M8.773 60.4688H4.32031V64.9214H8.773V60.4688Z" fill="black"/>
            <path d="M11.7344 64.8315V64.9206H13.0924V63.5625H13.0034C13.0034 64.266 12.4334 64.8315 11.7344 64.8315Z" fill="black"/>
            <path d="M17.4097 60.4688H12.957V64.9214H17.4097V60.4688Z" fill="black"/>
            <path d="M17.2773 64.9219V61.9831C17.2773 61.147 17.9551 60.4692 18.7913 60.4692H20.2161C21.0522 60.4692 21.73 61.147 21.73 61.9831V64.9219H17.2773Z" fill="black"/>
            <path d="M30.3672 64.9219H27.4284C26.5923 64.9219 25.9145 64.2441 25.9145 63.408V61.9831C25.9145 61.147 26.5923 60.4692 27.4284 60.4692H30.3672V64.9219Z" fill="black"/>
            <path d="M34.6871 60.4688H30.2344V64.9214H34.6871V60.4688Z" fill="black"/>
            <path d="M39.0074 60.4688H34.5547V64.9214H39.0074V60.4688Z" fill="black"/>
            <path d="M43.3238 60.4688H38.8711V64.9214H43.3238V60.4688Z" fill="black"/>
            <path d="M47.6441 60.4688H43.1914V64.9214H47.6441V60.4688Z" fill="black"/>
            <path d="M47.5078 64.9219V60.4692H50.4466C51.2827 60.4692 51.9605 61.147 51.9605 61.9831V64.9219H47.5078Z" fill="black"/>
            <path d="M64.9219 60.4688V63.4075C64.9219 64.2436 64.2441 64.9214 63.408 64.9214H61.9831C61.147 64.9214 60.4692 64.2436 60.4692 63.4075V60.4688H64.9219Z" fill="black"/>
            <path d="M73.5582 60.4688H69.1055V64.9214H73.5582V60.4688Z" fill="black"/>
            <path d="M73.4258 64.9219V60.4692H76.3646C77.2007 60.4692 77.8785 61.147 77.8785 61.9831V64.9219H73.4258Z" fill="black"/>
            <path d="M90.8355 60.4688H86.3828V64.9214H90.8355V60.4688Z" fill="black"/>
            <path d="M95.0195 61.7422V63.6524C95.0195 64.3559 95.5895 64.9214 96.293 64.9214H98.2032C98.9112 64.9214 99.4767 64.3515 99.4767 63.648V61.7422C99.4722 61.0342 98.9023 60.4688 98.1988 60.4688H96.293C95.5895 60.4688 95.0195 61.0387 95.0195 61.7422Z" fill="black"/>
            <path d="M107.977 61.7422V63.6524C107.977 64.3559 108.547 64.9214 109.25 64.9214H111.16C111.868 64.9214 112.434 64.3515 112.434 63.648V61.7422C112.429 61.0342 111.859 60.4688 111.156 60.4688H109.25C108.547 60.4688 107.977 61.0387 107.977 61.7422Z" fill="black"/>
            <path d="M124.031 64.8315V64.9206H125.389V63.5625H125.3C125.3 64.266 124.73 64.8315 124.031 64.8315Z" fill="black"/>
            <path d="M129.707 64.9219H125.254V61.9831C125.254 61.147 125.932 60.4692 126.768 60.4692H129.707V64.9219Z" fill="black"/>
            <path d="M134.027 60.4688H129.574V64.9214H134.027V60.4688Z" fill="black"/>
            <path d="M138.343 60.4688H133.891V64.9214H138.343V60.4688Z" fill="black"/>
            <path d="M146.984 60.4688H142.531V64.9214H146.984V60.4688Z" fill="black"/>
            <path d="M164.262 64.9219H161.323C160.487 64.9219 159.809 64.2441 159.809 63.408V61.9831C159.809 61.147 160.487 60.4692 161.323 60.4692H164.262V64.9219Z" fill="black"/>
            <path d="M168.578 60.4688H164.125V64.9214H168.578V60.4688Z" fill="black"/>
            <path d="M168.445 64.9219V60.4692H171.384C172.22 60.4692 172.898 61.147 172.898 61.9831V64.9219H168.445Z" fill="black"/>
            <path d="M185.855 60.4688H181.402V64.9214H185.855V60.4688Z" fill="black"/>
            <path d="M197.453 64.8315V64.9206H198.811V63.5625H198.722C198.722 64.266 198.152 64.8315 197.453 64.8315Z" fill="black"/>
            <path d="M198.68 64.9219V61.9831C198.68 61.147 199.357 60.4692 200.194 60.4692H201.618C202.455 60.4692 203.132 61.147 203.132 61.9831V64.9219H198.68Z" fill="black"/>
            <path d="M8.773 64.7891H4.32031V69.2418H8.773V64.7891Z" fill="black"/>
            <path d="M13.0933 64.7891H8.64062V69.2418H13.0933V64.7891Z" fill="black"/>
            <path d="M12.957 64.7891H17.4097V67.7278C17.4097 68.5639 16.7319 69.2418 15.8958 69.2418H12.957V64.7891Z" fill="black"/>
            <path d="M21.5938 69.2422V66.3034C21.5938 65.4673 22.2716 64.7895 23.1077 64.7895H24.5325C25.3686 64.7895 26.0464 65.4673 26.0464 66.3034V69.2422H21.5938Z" fill="black"/>
            <path d="M47.6441 64.7891H43.1914V69.2418H47.6441V64.7891Z" fill="black"/>
            <path d="M51.9605 64.7891H47.5078V69.2418H51.9605V64.7891Z" fill="black"/>
            <path d="M56.2808 64.7891H51.8281V69.2418H56.2808V64.7891Z" fill="black"/>
            <path d="M56.1484 69.2422V66.3034C56.1484 65.4673 56.8262 64.7895 57.6624 64.7895H59.0872C59.9233 64.7895 60.6011 65.4673 60.6011 66.3034V69.2422H56.1484Z" fill="black"/>
            <path d="M73.5586 64.7891V69.2418H70.6198C69.7837 69.2418 69.1059 68.5639 69.1059 67.7278V64.7891H73.5586Z" fill="black"/>
            <path d="M77.8785 64.7891H73.4258V69.2418H77.8785V64.7891Z" fill="black"/>
            <path d="M82.1949 64.7891H77.7422V69.2418H82.1949V64.7891Z" fill="black"/>
            <path d="M86.5152 64.7891H82.0625V69.2418H86.5152V64.7891Z" fill="black"/>
            <path d="M90.8355 64.7891H86.3828V69.2418H90.8355V64.7891Z" fill="black"/>
            <path d="M90.7031 69.2422V64.7895H93.6419C94.478 64.7895 95.1558 65.4673 95.1558 66.3034V69.2422H90.7031Z" fill="black"/>
            <path d="M98.1172 69.1518V69.2409H99.4753V67.8828H99.3862C99.3862 68.5863 98.8163 69.1518 98.1172 69.1518Z" fill="black"/>
            <path d="M103.793 69.2422H99.3403V66.3034C99.3403 65.4673 100.018 64.7895 100.854 64.7895H103.793V69.2422Z" fill="black"/>
            <path d="M103.656 69.2422V64.7895H106.595C107.431 64.7895 108.109 65.4673 108.109 66.3034V69.2422H103.656Z" fill="black"/>
            <path d="M125.391 69.2422H122.452C121.616 69.2422 120.938 68.5644 120.938 67.7283V66.3034C120.938 65.4673 121.616 64.7895 122.452 64.7895H125.391V69.2422Z" fill="black"/>
            <path d="M129.707 64.7891H125.254V69.2418H129.707V64.7891Z" fill="black"/>
            <path d="M138.348 64.7891V67.7278C138.348 68.5639 137.67 69.2418 136.834 69.2418H135.409C134.573 69.2418 133.895 68.5639 133.895 67.7278V64.7891H138.348Z" fill="black"/>
            <path d="M141.305 69.1518V69.2409H142.663V67.8828H142.574C142.574 68.5863 142.004 69.1518 141.305 69.1518Z" fill="black"/>
            <path d="M146.984 64.7891H142.531V69.2418H146.984V64.7891Z" fill="black"/>
            <path d="M146.852 69.2422V66.3034C146.852 65.4673 147.529 64.7895 148.365 64.7895H149.79C150.626 64.7895 151.304 65.4673 151.304 66.3034V69.2422H146.852Z" fill="black"/>
            <path d="M155.488 69.2422V66.3034C155.488 65.4673 156.166 64.7895 157.002 64.7895H158.427C159.263 64.7895 159.941 65.4673 159.941 66.3034V69.2422H155.488Z" fill="black"/>
            <path d="M172.898 64.7891V67.7278C172.898 68.5639 172.221 69.2418 171.385 69.2418H169.96C169.124 69.2418 168.446 68.5639 168.446 67.7278V64.7891H172.898Z" fill="black"/>
            <path d="M185.855 64.7891V67.7278C185.855 68.5639 185.178 69.2418 184.342 69.2418H182.917C182.081 69.2418 181.403 68.5639 181.403 67.7278V64.7891H185.855Z" fill="black"/>
            <path d="M194.496 69.2422H190.043V66.3034C190.043 65.4673 190.721 64.7895 191.557 64.7895H194.496V69.2422Z" fill="black"/>
            <path d="M198.812 64.7891H194.359V69.2418H198.812V64.7891Z" fill="black"/>
            <path d="M203.132 64.7891H198.68V69.2418H203.132V64.7891Z" fill="black"/>
            <path d="M203 69.2422V66.3034C203 65.4673 203.678 64.7895 204.514 64.7895H205.939C206.775 64.7895 207.453 65.4673 207.453 66.3034V69.2422H203Z" fill="black"/>
            <path d="M4.45312 73.5625H1.51435C0.678238 73.5625 0.000434875 72.8847 0.000434875 72.0486V70.6237C0.000434875 69.7876 0.678238 69.1098 1.51435 69.1098H4.45313L4.45312 73.5625Z" fill="black"/>
            <path d="M8.773 69.1094H4.32031V73.5621H8.773V69.1094Z" fill="black"/>
            <path d="M8.64062 69.1094H13.0933V72.0482C13.0933 72.8843 12.4155 73.5621 11.5794 73.5621H8.64062V69.1094Z" fill="black"/>
            <path d="M26.0508 69.1094V73.5621H23.112C22.2759 73.5621 21.5981 72.8843 21.5981 72.0482V69.1094H26.0508Z" fill="black"/>
            <path d="M25.9141 73.5625V70.6237C25.9141 69.7876 26.5919 69.1098 27.428 69.1098H28.8528C29.6889 69.1098 30.3668 69.7876 30.3668 70.6237V73.5625H25.9141Z" fill="black"/>
            <path d="M33.3281 73.4721V73.5612H34.6862V72.2031H34.5971C34.5971 72.9067 34.0272 73.4721 33.3281 73.4721Z" fill="black"/>
            <path d="M39.0078 73.5625H34.5551V70.6237C34.5551 69.7876 35.2329 69.1098 36.069 69.1098H39.0078V73.5625Z" fill="black"/>
            <path d="M43.3238 69.1094H38.8711V73.5621H43.3238V69.1094Z" fill="black"/>
            <path d="M47.6441 69.1094H43.1914V73.5621H47.6441V69.1094Z" fill="black"/>
            <path d="M47.5078 69.1094H51.9605V72.0482C51.9605 72.8843 51.2827 73.5621 50.4466 73.5621H47.5078V69.1094Z" fill="black"/>
            <path d="M64.9219 73.5625H61.9831C61.147 73.5625 60.4692 72.8847 60.4692 72.0486V70.6237C60.4692 69.7876 61.147 69.1098 61.9831 69.1098H64.9219V73.5625Z" fill="black"/>
            <path d="M64.7891 73.5625V69.1098H67.7278C68.5639 69.1098 69.2418 69.7876 69.2418 70.6237V73.5625H64.7891Z" fill="black"/>
            <path d="M77.8789 69.1094V73.5621H74.9401C74.104 73.5621 73.4262 72.8843 73.4262 72.0482V69.1094H77.8789Z" fill="black"/>
            <path d="M82.1949 69.1094H77.7422V73.5621H82.1949V69.1094Z" fill="black"/>
            <path d="M86.5152 69.1094H82.0625V73.5621H86.5152V69.1094Z" fill="black"/>
            <path d="M90.8355 69.1094H86.3828V73.5621H90.8355V69.1094Z" fill="black"/>
            <path d="M95.1558 69.1094H90.7031V73.5621H95.1558V69.1094Z" fill="black"/>
            <path d="M99.4722 69.1094H95.0195V73.5621H99.4722V69.1094Z" fill="black"/>
            <path d="M103.793 69.1094H99.3398V73.5621H103.793V69.1094Z" fill="black"/>
            <path d="M103.656 69.1094H108.109V72.0482C108.109 72.8843 107.431 73.5621 106.595 73.5621H103.656V69.1094Z" fill="black"/>
            <path d="M116.75 73.5625H112.297V70.6237C112.297 69.7876 112.975 69.1098 113.811 69.1098H116.75V73.5625Z" fill="black"/>
            <path d="M116.617 73.5625V69.1098H119.556C120.392 69.1098 121.07 69.7876 121.07 70.6237V73.5625H116.617Z" fill="black"/>
            <path d="M129.707 69.1094V73.5621H126.768C125.932 73.5621 125.254 72.8843 125.254 72.0482V69.1094H129.707Z" fill="black"/>
            <path d="M129.574 73.5625V70.6237C129.574 69.7876 130.252 69.1098 131.088 69.1098H132.513C133.349 69.1098 134.027 69.7876 134.027 70.6237V73.5625H129.574Z" fill="black"/>
            <path d="M142.664 73.5625H139.725C138.889 73.5625 138.211 72.8847 138.211 72.0486V70.6237C138.211 69.7876 138.889 69.1098 139.725 69.1098H142.664V73.5625Z" fill="black"/>
            <path d="M142.531 69.1094H146.984V72.0482C146.984 72.8843 146.306 73.5621 145.47 73.5621H142.531V69.1094Z" fill="black"/>
            <path d="M159.941 69.1094H155.488V73.5621H159.941V69.1094Z" fill="black"/>
            <path d="M162.902 73.4721V73.5612H164.26V72.2031H164.171C164.171 72.9067 163.601 73.4721 162.902 73.4721Z" fill="black"/>
            <path d="M164.125 73.5625V70.6237C164.125 69.7876 164.803 69.1098 165.639 69.1098H167.064C167.9 69.1098 168.578 69.7876 168.578 70.6237V73.5625H164.125Z" fill="black"/>
            <path d="M188.816 73.4721V73.5612H190.174V72.2031H190.085C190.085 72.9067 189.515 73.4721 188.816 73.4721Z" fill="black"/>
            <path d="M194.492 69.1094H190.039V73.5621H194.492V69.1094Z" fill="black"/>
            <path d="M194.359 69.1094H198.812V72.0482C198.812 72.8843 198.134 73.5621 197.298 73.5621H194.359V69.1094Z" fill="black"/>
            <path d="M207.316 70.3828V72.293C207.316 72.9966 207.886 73.5621 208.59 73.5621H210.5C211.208 73.5621 211.774 72.9921 211.774 72.2886V70.3828C211.769 69.6749 211.199 69.1094 210.496 69.1094H208.59C207.886 69.1094 207.316 69.6793 207.316 70.3828Z" fill="black"/>
            <path d="M12.957 74.6953V76.6055C12.957 77.3091 13.527 77.8746 14.2305 77.8746H16.1407C16.8487 77.8746 17.4142 77.3046 17.4142 76.6011V74.6953C17.4097 73.9874 16.8398 73.4219 16.1363 73.4219H14.2305C13.527 73.4219 12.957 73.9918 12.957 74.6953Z" fill="black"/>
            <path d="M29.0078 77.7846V77.8737H30.3659V76.5156H30.2768C30.2768 77.2192 29.7069 77.7846 29.0078 77.7846Z" fill="black"/>
            <path d="M34.6875 77.875H30.2348V74.9362C30.2348 74.1001 30.9126 73.4223 31.7487 73.4223H34.6875V77.875Z" fill="black"/>
            <path d="M39.0074 73.4219H34.5547V77.8746H39.0074V73.4219Z" fill="black"/>
            <path d="M43.3238 73.4219H38.8711V77.8746H43.3238V73.4219Z" fill="black"/>
            <path d="M43.1914 73.4219H47.6441V76.3607C47.6441 77.1968 46.9663 77.8746 46.1302 77.8746H43.1914V73.4219Z" fill="black"/>
            <path d="M56.2852 77.875H53.3464C52.5103 77.875 51.8325 77.1972 51.8325 76.3611V74.9362C51.8325 74.1001 52.5103 73.4223 53.3464 73.4223H56.2852V77.875Z" fill="black"/>
            <path d="M56.1484 77.875V73.4223H59.0872C59.9233 73.4223 60.6011 74.1001 60.6011 74.9362V77.875H56.1484Z" fill="black"/>
            <path d="M69.2418 73.4219H64.7891V77.8746H69.2418V73.4219Z" fill="black"/>
            <path d="M69.1055 77.875V74.9362C69.1055 74.1001 69.7833 73.4223 70.6194 73.4223H72.0442C72.8804 73.4223 73.5582 74.1001 73.5582 74.9362V77.875H69.1055Z" fill="black"/>
            <path d="M82.1949 73.4219H77.7422V77.8746H82.1949V73.4219Z" fill="black"/>
            <path d="M86.5152 73.4219H82.0625V77.8746H86.5152V73.4219Z" fill="black"/>
            <path d="M90.8355 73.4219H86.3828V77.8746H90.8355V73.4219Z" fill="black"/>
            <path d="M90.7031 73.4219H95.1558V76.3607C95.1558 77.1968 94.478 77.8746 93.6419 77.8746H90.7031V73.4219Z" fill="black"/>
            <path d="M116.75 73.4219V77.8746H113.811C112.975 77.8746 112.297 77.1968 112.297 76.3607V73.4219H116.75Z" fill="black"/>
            <path d="M116.617 73.4219H121.07V76.3607C121.07 77.1968 120.392 77.8746 119.556 77.8746H116.617V73.4219Z" fill="black"/>
            <path d="M159.941 73.4219V77.8746H157.003C156.167 77.8746 155.489 77.1968 155.489 76.3607V73.4219H159.941Z" fill="black"/>
            <path d="M164.257 73.4219H159.805V77.8746H164.257V73.4219Z" fill="black"/>
            <path d="M168.578 73.4219H164.125V77.8746H168.578V73.4219Z" fill="black"/>
            <path d="M172.898 73.4219H168.445V77.8746H172.898V73.4219Z" fill="black"/>
            <path d="M177.218 73.4219H172.766V77.8746H177.218V73.4219Z" fill="black"/>
            <path d="M177.086 77.875V73.4223H180.025C180.861 73.4223 181.539 74.1001 181.539 74.9362V77.875H177.086Z" fill="black"/>
            <path d="M190.176 77.875H185.723V74.9362C185.723 74.1001 186.401 73.4223 187.237 73.4223H190.176V77.875Z" fill="black"/>
            <path d="M194.492 73.4219H190.039V77.8746H194.492V73.4219Z" fill="black"/>
            <path d="M197.453 77.7846V77.8737H198.811V76.5156H198.722C198.722 77.2192 198.152 77.7846 197.453 77.7846Z" fill="black"/>
            <path d="M203.133 77.875H198.68V74.9362C198.68 74.1001 199.358 73.4223 200.194 73.4223H203.133V77.875Z" fill="black"/>
            <path d="M203 77.875V74.9362C203 74.1001 203.678 73.4223 204.514 73.4223H205.939C206.775 73.4223 207.453 74.1001 207.453 74.9362V77.875H203Z" fill="black"/>
            <path d="M4.45312 82.1953H0.000434875V79.2565C0.000434875 78.4204 0.678238 77.7426 1.51435 77.7426H4.45313L4.45312 82.1953Z" fill="black"/>
            <path d="M8.773 77.7422H4.32031V82.1949H8.773V77.7422Z" fill="black"/>
            <path d="M8.64062 82.1953V79.2565C8.64062 78.4204 9.31843 77.7426 10.1545 77.7426H11.5794C12.4155 77.7426 13.0933 78.4204 13.0933 79.2565V82.1953H8.64062Z" fill="black"/>
            <path d="M26.0508 82.1953H23.112C22.2759 82.1953 21.5981 81.5175 21.5981 80.6814V79.2565C21.5981 78.4204 22.2759 77.7426 23.112 77.7426H26.0508V82.1953Z" fill="black"/>
            <path d="M30.3668 77.7422H25.9141V82.1949H30.3668V77.7422Z" fill="black"/>
            <path d="M34.6871 77.7422H30.2344V82.1949H34.6871V77.7422Z" fill="black"/>
            <path d="M60.6016 77.7422V80.681C60.6016 81.5171 59.9238 82.1949 59.0876 82.1949H57.6628C56.8267 82.1949 56.1489 81.5171 56.1489 80.681V77.7422H60.6016Z" fill="black"/>
            <path d="M69.2418 77.7422H64.7891V82.1949H69.2418V77.7422Z" fill="black"/>
            <path d="M82.1992 77.7422V80.681C82.1992 81.5171 81.5214 82.1949 80.6853 82.1949H79.2604C78.4243 82.1949 77.7465 81.5171 77.7465 80.681V77.7422H82.1992Z" fill="black"/>
            <path d="M99.4727 82.1953H95.02V79.2565C95.02 78.4204 95.6978 77.7426 96.5339 77.7426H99.4727V82.1953Z" fill="black"/>
            <path d="M99.3398 82.1953V79.2565C99.3398 78.4204 100.018 77.7426 100.854 77.7426H102.279C103.115 77.7426 103.793 78.4204 103.793 79.2565V82.1953H99.3398Z" fill="black"/>
            <path d="M107.977 79.0157V80.9259C107.977 81.6294 108.547 82.1949 109.25 82.1949H111.16C111.868 82.1949 112.434 81.6249 112.434 80.9214V79.0157C112.429 78.3077 111.859 77.7422 111.156 77.7422H109.25C108.547 77.7422 107.977 78.3121 107.977 79.0157Z" fill="black"/>
            <path d="M120.938 79.0157V80.9259C120.938 81.6294 121.507 82.1949 122.211 82.1949H124.121C124.829 82.1949 125.395 81.6249 125.395 80.9214V79.0157C125.39 78.3077 124.82 77.7422 124.117 77.7422H122.211C121.507 77.7422 120.938 78.3121 120.938 79.0157Z" fill="black"/>
            <path d="M129.574 82.1953V79.2565C129.574 78.4204 130.252 77.7426 131.088 77.7426H132.513C133.349 77.7426 134.027 78.4204 134.027 79.2565V82.1953H129.574Z" fill="black"/>
            <path d="M145.625 82.105V82.194H146.983V80.8359H146.894C146.894 81.5395 146.324 82.105 145.625 82.105Z" fill="black"/>
            <path d="M146.852 82.1953V79.2565C146.852 78.4204 147.529 77.7426 148.365 77.7426H149.79C150.626 77.7426 151.304 78.4204 151.304 79.2565V82.1953H146.852Z" fill="black"/>
            <path d="M164.257 77.7422H159.805V82.1949H164.257V77.7422Z" fill="black"/>
            <path d="M172.898 77.7422V80.681C172.898 81.5171 172.221 82.1949 171.385 82.1949H169.96C169.124 82.1949 168.446 81.5171 168.446 80.681V77.7422H172.898Z" fill="black"/>
            <path d="M181.539 77.7422H177.086V82.1949H181.539V77.7422Z" fill="black"/>
            <path d="M190.175 77.7422H185.723V82.1949H190.175V77.7422Z" fill="black"/>
            <path d="M194.492 77.7422H190.039V82.1949H194.492V77.7422Z" fill="black"/>
            <path d="M198.812 77.7422H194.359V82.1949H198.812V77.7422Z" fill="black"/>
            <path d="M203.132 77.7422H198.68V82.1949H203.132V77.7422Z" fill="black"/>
            <path d="M4.45312 82.0625V85.0013C4.45312 85.8374 3.77532 86.5152 2.93921 86.5152H1.51435C0.678238 86.5152 0.000434875 85.8374 0.000434875 85.0013V82.0625H4.45312Z" fill="black"/>
            <path d="M11.7344 86.4253V86.5143H13.0924V85.1562H13.0034C13.0034 85.8598 12.4334 86.4253 11.7344 86.4253Z" fill="black"/>
            <path d="M17.4102 86.5156H12.9575V83.5768C12.9575 82.7407 13.6353 82.0629 14.4714 82.0629H17.4102V86.5156Z" fill="black"/>
            <path d="M17.2773 86.5156V83.5768C17.2773 82.7407 17.9551 82.0629 18.7913 82.0629H20.2161C21.0522 82.0629 21.73 82.7407 21.73 83.5768V86.5156H17.2773Z" fill="black"/>
            <path d="M34.6871 82.0625H30.2344V86.5152H34.6871V82.0625Z" fill="black"/>
            <path d="M43.3242 86.5156H38.8715V83.5768C38.8715 82.7407 39.5493 82.0629 40.3854 82.0629H43.3242V86.5156Z" fill="black"/>
            <path d="M47.6441 82.0625H43.1914V86.5152H47.6441V82.0625Z" fill="black"/>
            <path d="M51.9605 82.0625H47.5078V86.5152H51.9605V82.0625Z" fill="black"/>
            <path d="M51.8281 86.5156V83.5768C51.8281 82.7407 52.5059 82.0629 53.342 82.0629H54.7669C55.603 82.0629 56.2808 82.7407 56.2808 83.5768V86.5156H51.8281Z" fill="black"/>
            <path d="M69.2418 82.0625H64.7891V86.5152H69.2418V82.0625Z" fill="black"/>
            <path d="M73.5582 82.0625H69.1055V86.5152H73.5582V82.0625Z" fill="black"/>
            <path d="M73.4258 86.5156V83.5768C73.4258 82.7407 74.1036 82.0629 74.9397 82.0629H76.3646C77.2007 82.0629 77.8785 82.7407 77.8785 83.5768V86.5156H73.4258Z" fill="black"/>
            <path d="M86.5156 86.5156H83.5768C82.7407 86.5156 82.0629 85.8378 82.0629 85.0017V83.5768C82.0629 82.7407 82.7407 82.0629 83.5768 82.0629H86.5156V86.5156Z" fill="black"/>
            <path d="M86.3828 86.5156V83.5768C86.3828 82.7407 87.0606 82.0629 87.8967 82.0629H89.3216C90.1577 82.0629 90.8355 82.7407 90.8355 83.5768V86.5156H86.3828Z" fill="black"/>
            <path d="M99.4722 82.0625H95.0195V86.5152H99.4722V82.0625Z" fill="black"/>
            <path d="M103.656 86.5156V83.5768C103.656 82.7407 104.334 82.0629 105.17 82.0629H106.595C107.431 82.0629 108.109 82.7407 108.109 83.5768V86.5156H103.656Z" fill="black"/>
            <path d="M116.617 83.336V85.2462C116.617 85.9497 117.187 86.5152 117.891 86.5152H119.801C120.509 86.5152 121.074 85.9452 121.074 85.2417V83.336C121.07 82.628 120.5 82.0625 119.796 82.0625H117.891C117.187 82.0625 116.617 82.6324 116.617 83.336Z" fill="black"/>
            <path d="M134.027 82.0625V86.5152H131.089C130.252 86.5152 129.575 85.8374 129.575 85.0013V82.0625H134.027Z" fill="black"/>
            <path d="M138.343 82.0625H133.891V86.5152H138.343V82.0625Z" fill="black"/>
            <path d="M142.664 82.0625H138.211V86.5152H142.664V82.0625Z" fill="black"/>
            <path d="M146.984 82.0625H142.531V86.5152H146.984V82.0625Z" fill="black"/>
            <path d="M151.304 82.0625H146.852V86.5152H151.304V82.0625Z" fill="black"/>
            <path d="M155.621 82.0625H151.168V86.5152H155.621V82.0625Z" fill="black"/>
            <path d="M159.941 82.0625H155.488V86.5152H159.941V82.0625Z" fill="black"/>
            <path d="M164.257 82.0625H159.805V86.5152H164.257V82.0625Z" fill="black"/>
            <path d="M164.125 86.5156V83.5768C164.125 82.7407 164.803 82.0629 165.639 82.0629H167.064C167.9 82.0629 168.578 82.7407 168.578 83.5768V86.5156H164.125Z" fill="black"/>
            <path d="M181.539 82.0625V85.0013C181.539 85.8374 180.861 86.5152 180.025 86.5152H178.6C177.764 86.5152 177.086 85.8374 177.086 85.0013V82.0625H181.539Z" fill="black"/>
            <path d="M190.176 82.0625V86.5152H187.237C186.401 86.5152 185.723 85.8374 185.723 85.0013V82.0625H190.176Z" fill="black"/>
            <path d="M194.492 82.0625H190.039V86.5152H194.492V82.0625Z" fill="black"/>
            <path d="M203.132 82.0625H198.68V86.5152H203.132V82.0625Z" fill="black"/>
            <path d="M7.41406 90.7456V90.8346H8.77213V89.4766H8.68308C8.68308 90.1801 8.11313 90.7456 7.41406 90.7456Z" fill="black"/>
            <path d="M13.0938 90.8359H8.64106V87.8972C8.64106 87.0611 9.31886 86.3832 10.155 86.3832H13.0938V90.8359Z" fill="black"/>
            <path d="M17.4097 86.3828H12.957V90.8355H17.4097V86.3828Z" fill="black"/>
            <path d="M30.3672 90.8359H27.4284C26.5923 90.8359 25.9145 90.1581 25.9145 89.322V87.8972C25.9145 87.0611 26.5923 86.3832 27.4284 86.3832H30.3672V90.8359Z" fill="black"/>
            <path d="M34.6871 86.3828H30.2344V90.8355H34.6871V86.3828Z" fill="black"/>
            <path d="M37.6484 90.7456V90.8346H39.0065V89.4766H38.9175C38.9175 90.1801 38.3475 90.7456 37.6484 90.7456Z" fill="black"/>
            <path d="M43.3238 86.3828H38.8711V90.8355H43.3238V86.3828Z" fill="black"/>
            <path d="M43.1914 86.3828H47.6441V89.3216C47.6441 90.1577 46.9663 90.8355 46.1302 90.8355H43.1914V86.3828Z" fill="black"/>
            <path d="M63.5625 90.7456V90.8346H64.9206V89.4766H64.8315C64.8315 90.1801 64.2616 90.7456 63.5625 90.7456Z" fill="black"/>
            <path d="M69.2418 86.3828H64.7891V90.8355H69.2418V86.3828Z" fill="black"/>
            <path d="M77.7422 90.8359V87.8972C77.7422 87.0611 78.42 86.3832 79.2561 86.3832H80.681C81.5171 86.3832 82.1949 87.0611 82.1949 87.8972V90.8359H77.7422Z" fill="black"/>
            <path d="M93.7969 90.7456V90.8346H95.1549V89.4766H95.0659C95.0659 90.1801 94.4959 90.7456 93.7969 90.7456Z" fill="black"/>
            <path d="M99.4722 86.3828H95.0195V90.8355H99.4722V86.3828Z" fill="black"/>
            <path d="M108.113 86.3828V89.3216C108.113 90.1577 107.435 90.8355 106.599 90.8355H105.175C104.338 90.8355 103.661 90.1577 103.661 89.3216V86.3828H108.113Z" fill="black"/>
            <path d="M120.938 87.6563V89.5665C120.938 90.27 121.507 90.8355 122.211 90.8355H124.121C124.829 90.8355 125.395 90.2656 125.395 89.562V87.6563C125.39 86.9483 124.82 86.3828 124.117 86.3828H122.211C121.507 86.3828 120.938 86.9528 120.938 87.6563Z" fill="black"/>
            <path d="M142.664 86.3828H138.211V90.8355H142.664V86.3828Z" fill="black"/>
            <path d="M155.621 86.3828V90.8355H152.682C151.846 90.8355 151.168 90.1577 151.168 89.3216V86.3828H155.621Z" fill="black"/>
            <path d="M155.488 86.3828H159.941V89.3216C159.941 90.1577 159.263 90.8355 158.427 90.8355H155.488V86.3828Z" fill="black"/>
            <path d="M172.766 87.6563V89.5665C172.766 90.27 173.336 90.8355 174.039 90.8355H175.949C176.657 90.8355 177.223 90.2656 177.223 89.562V87.6563C177.218 86.9483 176.648 86.3828 175.945 86.3828H174.039C173.336 86.3828 172.766 86.9528 172.766 87.6563Z" fill="black"/>
            <path d="M180.18 90.7456V90.8346H181.538V89.4766H181.449C181.449 90.1801 180.879 90.7456 180.18 90.7456Z" fill="black"/>
            <path d="M181.402 90.8359V87.8972C181.402 87.0611 182.08 86.3832 182.916 86.3832H184.341C185.177 86.3832 185.855 87.0611 185.855 87.8972V90.8359H181.402Z" fill="black"/>
            <path d="M188.816 90.7456V90.8346H190.174V89.4766H190.085C190.085 90.1801 189.515 90.7456 188.816 90.7456Z" fill="black"/>
            <path d="M194.492 86.3828H190.039V90.8355H194.492V86.3828Z" fill="black"/>
            <path d="M198.812 86.3828H194.359V90.8355H198.812V86.3828Z" fill="black"/>
            <path d="M198.68 86.3828H203.132V89.3216C203.132 90.1577 202.455 90.8355 201.618 90.8355H198.68V86.3828Z" fill="black"/>
            <path d="M207.316 87.6563V89.5665C207.316 90.27 207.886 90.8355 208.59 90.8355H210.5C211.208 90.8355 211.774 90.2656 211.774 89.562V87.6563C211.769 86.9483 211.199 86.3828 210.496 86.3828H208.59C207.886 86.3828 207.316 86.9528 207.316 87.6563Z" fill="black"/>
            <path d="M4.45312 95.1562H1.51435C0.678238 95.1562 0.000434875 94.4784 0.000434875 93.6423V92.2175C0.000434875 91.3814 0.678238 90.7036 1.51435 90.7036H4.45313L4.45312 95.1562Z" fill="black"/>
            <path d="M8.773 90.7031H4.32031V95.1558H8.773V90.7031Z" fill="black"/>
            <path d="M13.0933 90.7031H8.64062V95.1558H13.0933V90.7031Z" fill="black"/>
            <path d="M17.4097 90.7031H12.957V95.1558H17.4097V90.7031Z" fill="black"/>
            <path d="M17.2773 95.1562V90.7036H20.2161C21.0522 90.7036 21.73 91.3814 21.73 92.2175V95.1562H17.2773Z" fill="black"/>
            <path d="M34.6871 90.7031H30.2344V95.1558H34.6871V90.7031Z" fill="black"/>
            <path d="M39.0074 90.7031H34.5547V95.1558H39.0074V90.7031Z" fill="black"/>
            <path d="M38.8711 90.7031H43.3238V93.6419C43.3238 94.478 42.646 95.1558 41.8099 95.1558H38.8711V90.7031Z" fill="black"/>
            <path d="M47.5078 91.9766V93.8868C47.5078 94.5903 48.0778 95.1558 48.7813 95.1558H50.6915C51.3995 95.1558 51.965 94.5859 51.965 93.8823V91.9766C51.9605 91.2686 51.3906 90.7031 50.687 90.7031H48.7813C48.0778 90.7031 47.5078 91.2731 47.5078 91.9766Z" fill="black"/>
            <path d="M60.6016 95.1562H57.6628C56.8267 95.1562 56.1489 94.4784 56.1489 93.6423V92.2175C56.1489 91.3814 56.8267 90.7036 57.6628 90.7036H60.6016V95.1562Z" fill="black"/>
            <path d="M64.9214 90.7031H60.4688V95.1558H64.9214V90.7031Z" fill="black"/>
            <path d="M69.2418 90.7031H64.7891V95.1558H69.2418V90.7031Z" fill="black"/>
            <path d="M69.1055 95.1562V90.7036H72.0442C72.8804 90.7036 73.5582 91.3814 73.5582 92.2175V95.1562H69.1055Z" fill="black"/>
            <path d="M82.1992 90.7031V93.6419C82.1992 94.478 81.5214 95.1558 80.6853 95.1558H79.2604C78.4243 95.1558 77.7465 94.478 77.7465 93.6419V90.7031H82.1992Z" fill="black"/>
            <path d="M90.8359 95.1562H86.3832V92.2175C86.3832 91.3814 87.0611 90.7036 87.8972 90.7036H90.8359V95.1562Z" fill="black"/>
            <path d="M95.1558 90.7031H90.7031V95.1558H95.1558V90.7031Z" fill="black"/>
            <path d="M99.4722 90.7031H95.0195V95.1558H99.4722V90.7031Z" fill="black"/>
            <path d="M106.754 95.0659V95.1549H108.112V93.7969H108.023C108.023 94.5004 107.453 95.0659 106.754 95.0659Z" fill="black"/>
            <path d="M107.977 95.1562V92.2175C107.977 91.3814 108.654 90.7036 109.49 90.7036H110.915C111.751 90.7036 112.429 91.3814 112.429 92.2175V95.1562H107.977Z" fill="black"/>
            <path d="M116.617 91.9766V93.8868C116.617 94.5903 117.187 95.1558 117.891 95.1558H119.801C120.509 95.1558 121.074 94.5859 121.074 93.8823V91.9766C121.07 91.2686 120.5 90.7031 119.796 90.7031H117.891C117.187 90.7031 116.617 91.2731 116.617 91.9766Z" fill="black"/>
            <path d="M134.027 95.1562H129.575V92.2175C129.575 91.3814 130.252 90.7036 131.089 90.7036H134.027V95.1562Z" fill="black"/>
            <path d="M138.343 90.7031H133.891V95.1558H138.343V90.7031Z" fill="black"/>
            <path d="M142.664 90.7031H138.211V95.1558H142.664V90.7031Z" fill="black"/>
            <path d="M142.531 95.1562V92.2175C142.531 91.3814 143.209 90.7036 144.045 90.7036H145.47C146.306 90.7036 146.984 91.3814 146.984 92.2175V95.1562H142.531Z" fill="black"/>
            <path d="M164.262 95.1562H161.323C160.487 95.1562 159.809 94.4784 159.809 93.6423V92.2175C159.809 91.3814 160.487 90.7036 161.323 90.7036H164.262V95.1562Z" fill="black"/>
            <path d="M168.578 90.7031H164.125V95.1558H168.578V90.7031Z" fill="black"/>
            <path d="M168.445 95.1562V90.7036H171.384C172.22 90.7036 172.898 91.3814 172.898 92.2175V95.1562H168.445Z" fill="black"/>
            <path d="M175.859 95.0659V95.1549H177.217V93.7969H177.128C177.128 94.5004 176.558 95.0659 175.859 95.0659Z" fill="black"/>
            <path d="M181.539 95.1562H177.086V92.2175C177.086 91.3814 177.764 90.7036 178.6 90.7036H181.539V95.1562Z" fill="black"/>
            <path d="M185.855 90.7031H181.402V95.1558H185.855V90.7031Z" fill="black"/>
            <path d="M190.175 90.7031H185.723V95.1558H190.175V90.7031Z" fill="black"/>
            <path d="M194.492 90.7031H190.039V95.1558H194.492V90.7031Z" fill="black"/>
            <path d="M8.77344 95.0234V99.4761H5.83466C4.99855 99.4761 4.32075 98.7983 4.32075 97.9622V95.0234H8.77344Z" fill="black"/>
            <path d="M13.0933 95.0234H8.64062V99.4761H13.0933V95.0234Z" fill="black"/>
            <path d="M21.73 95.0234H17.2773V99.4761H21.73V95.0234Z" fill="black"/>
            <path d="M26.0464 95.0234H21.5938V99.4761H26.0464V95.0234Z" fill="black"/>
            <path d="M30.3668 95.0234H25.9141V99.4761H30.3668V95.0234Z" fill="black"/>
            <path d="M34.6871 95.0234H30.2344V99.4761H34.6871V95.0234Z" fill="black"/>
            <path d="M39.0074 95.0234H34.5547V99.4761H39.0074V95.0234Z" fill="black"/>
            <path d="M51.8281 96.2969V98.2071C51.8281 98.9106 52.3981 99.4761 53.1016 99.4761H55.0118C55.7198 99.4761 56.2853 98.9062 56.2853 98.2027V96.2969C56.2808 95.5889 55.7109 95.0234 55.0073 95.0234H53.1016C52.3981 95.0234 51.8281 95.5934 51.8281 96.2969Z" fill="black"/>
            <path d="M69.2418 95.0234H64.7891V99.4761H69.2418V95.0234Z" fill="black"/>
            <path d="M73.5582 95.0234H69.1055V99.4761H73.5582V95.0234Z" fill="black"/>
            <path d="M90.8359 95.0234V99.4761H87.8972C87.0611 99.4761 86.3832 98.7983 86.3832 97.9622V95.0234H90.8359Z" fill="black"/>
            <path d="M95.1558 95.0234H90.7031V99.4761H95.1558V95.0234Z" fill="black"/>
            <path d="M99.4722 95.0234H95.0195V99.4761H99.4722V95.0234Z" fill="black"/>
            <path d="M103.793 95.0234H99.3398V99.4761H103.793V95.0234Z" fill="black"/>
            <path d="M108.109 95.0234H103.656V99.4761H108.109V95.0234Z" fill="black"/>
            <path d="M112.429 95.0234H107.977V99.4761H112.429V95.0234Z" fill="black"/>
            <path d="M112.297 99.4766V95.0239H115.236C116.072 95.0239 116.75 95.7017 116.75 96.5378V99.4766H112.297Z" fill="black"/>
            <path d="M134.027 95.0234H129.574V99.4761H134.027V95.0234Z" fill="black"/>
            <path d="M151.168 96.2969V98.2071C151.168 98.9106 151.738 99.4761 152.441 99.4761H154.352C155.06 99.4761 155.625 98.9062 155.625 98.2027V96.2969C155.621 95.5889 155.051 95.0234 154.347 95.0234H152.441C151.738 95.0234 151.168 95.5934 151.168 96.2969Z" fill="black"/>
            <path d="M168.578 95.0234H164.125V99.4761H168.578V95.0234Z" fill="black"/>
            <path d="M172.898 95.0234H168.445V99.4761H172.898V95.0234Z" fill="black"/>
            <path d="M177.218 95.0234H172.766V99.4761H177.218V95.0234Z" fill="black"/>
            <path d="M181.539 95.0234H177.086V99.4761H181.539V95.0234Z" fill="black"/>
            <path d="M185.855 95.0234H181.402V99.4761H185.855V95.0234Z" fill="black"/>
            <path d="M190.175 95.0234H185.723V99.4761H190.175V95.0234Z" fill="black"/>
            <path d="M194.492 95.0234H190.039V99.4761H194.492V95.0234Z" fill="black"/>
            <path d="M198.812 95.0234H194.359V99.4761H198.812V95.0234Z" fill="black"/>
            <path d="M198.68 99.4766V95.0239H201.618C202.455 95.0239 203.132 95.7017 203.132 96.5378V99.4766H198.68Z" fill="black"/>
            <path d="M13.0938 99.3359V102.275C13.0938 103.111 12.4159 103.789 11.5798 103.789H10.155C9.31886 103.789 8.64106 103.111 8.64106 102.275V99.3359H13.0938Z" fill="black"/>
            <path d="M16.0547 103.707V103.796H17.4128V102.438H17.3237C17.3237 103.141 16.7538 103.707 16.0547 103.707Z" fill="black"/>
            <path d="M21.73 99.3359H17.2773V103.789H21.73V99.3359Z" fill="black"/>
            <path d="M39.0074 99.3359H34.5547V103.789H39.0074V99.3359Z" fill="black"/>
            <path d="M38.8711 103.789V100.85C38.8711 100.014 39.5489 99.3364 40.385 99.3364H41.8099C42.646 99.3364 43.3238 100.014 43.3238 100.85V103.789H38.8711Z" fill="black"/>
            <path d="M46.2852 103.707V103.796H47.6432V102.438H47.5542C47.5542 103.141 46.9842 103.707 46.2852 103.707Z" fill="black"/>
            <path d="M47.5078 103.789V100.85C47.5078 100.014 48.1856 99.3364 49.0217 99.3364H50.4466C51.2827 99.3364 51.9605 100.014 51.9605 100.85V103.789H47.5078Z" fill="black"/>
            <path d="M56.1484 103.789V100.85C56.1484 100.014 56.8262 99.3364 57.6624 99.3364H59.0872C59.9233 99.3364 60.6011 100.014 60.6011 100.85V103.789H56.1484Z" fill="black"/>
            <path d="M69.2422 99.3359V103.789H66.3034C65.4673 103.789 64.7895 103.111 64.7895 102.275V99.3359H69.2422Z" fill="black"/>
            <path d="M69.1055 99.3359H73.5582V102.275C73.5582 103.111 72.8804 103.789 72.0442 103.789H69.1055V99.3359Z" fill="black"/>
            <path d="M82.1992 103.789H77.7465V100.85C77.7465 100.014 78.4243 99.3364 79.2604 99.3364H82.1992V103.789Z" fill="black"/>
            <path d="M82.0625 103.789V100.85C82.0625 100.014 82.7403 99.3364 83.5764 99.3364H85.0013C85.8374 99.3364 86.5152 100.014 86.5152 100.85V103.789H82.0625Z" fill="black"/>
            <path d="M99.4722 99.3359H95.0195V103.789H99.4722V99.3359Z" fill="black"/>
            <path d="M116.75 99.3359H112.297V103.789H116.75V99.3359Z" fill="black"/>
            <path d="M120.938 103.789V100.85C120.938 100.014 121.615 99.3364 122.451 99.3364H123.876C124.712 99.3364 125.39 100.014 125.39 100.85V103.789H120.938Z" fill="black"/>
            <path d="M128.352 103.707V103.796H129.71V102.438H129.621C129.621 103.141 129.051 103.707 128.352 103.707Z" fill="black"/>
            <path d="M134.027 99.3359H129.574V103.789H134.027V99.3359Z" fill="black"/>
            <path d="M146.852 100.609V102.52C146.852 103.223 147.422 103.789 148.125 103.789H150.035C150.743 103.789 151.309 103.219 151.309 102.515V100.609C151.304 99.9014 150.734 99.3359 150.031 99.3359H148.125C147.422 99.3359 146.852 99.9059 146.852 100.609Z" fill="black"/>
            <path d="M154.266 103.707V103.796H155.624V102.438H155.535C155.535 103.141 154.965 103.707 154.266 103.707Z" fill="black"/>
            <path d="M159.941 103.789H155.489V100.85C155.489 100.014 156.167 99.3364 157.003 99.3364H159.941V103.789Z" fill="black"/>
            <path d="M164.257 99.3359H159.805V103.789H164.257V99.3359Z" fill="black"/>
            <path d="M164.125 99.3359H168.578V102.275C168.578 103.111 167.9 103.789 167.064 103.789H164.125V99.3359Z" fill="black"/>
            <path d="M177.218 99.3359H172.766V103.789H177.218V99.3359Z" fill="black"/>
            <path d="M194.492 99.3359H190.039V103.789H194.492V99.3359Z" fill="black"/>
            <path d="M203.132 99.3359H198.68V103.789H203.132V99.3359Z" fill="black"/>
            <path d="M4.32031 108.109V105.171C4.32031 104.334 4.99812 103.657 5.83423 103.657H7.25909C8.0952 103.657 8.773 104.334 8.773 105.171V108.109H4.32031Z" fill="black"/>
            <path d="M17.4102 108.109H12.9575V105.171C12.9575 104.334 13.6353 103.657 14.4714 103.657H17.4102V108.109Z" fill="black"/>
            <path d="M21.73 103.656H17.2773V108.109H21.73V103.656Z" fill="black"/>
            <path d="M25.9141 104.93V106.84C25.9141 107.543 26.484 108.109 27.1875 108.109H29.0977C29.8057 108.109 30.3712 107.539 30.3712 106.835V104.93C30.3668 104.222 29.7968 103.656 29.0933 103.656H27.1875C26.484 103.656 25.9141 104.226 25.9141 104.93Z" fill="black"/>
            <path d="M39.0074 103.656H34.5547V108.109H39.0074V103.656Z" fill="black"/>
            <path d="M41.9688 108.019V108.108H43.3268V106.75H43.2378C43.2378 107.454 42.6678 108.019 41.9688 108.019Z" fill="black"/>
            <path d="M47.6445 108.109H43.1918V105.171C43.1918 104.334 43.8696 103.657 44.7058 103.657H47.6445V108.109Z" fill="black"/>
            <path d="M51.9605 103.656H47.5078V108.109H51.9605V103.656Z" fill="black"/>
            <path d="M54.9219 108.019V108.108H56.2799V106.75H56.1909C56.1909 107.454 55.6209 108.019 54.9219 108.019Z" fill="black"/>
            <path d="M60.6011 103.656H56.1484V108.109H60.6011V103.656Z" fill="black"/>
            <path d="M60.4688 108.109V103.657H63.4075C64.2436 103.657 64.9214 104.334 64.9214 105.171V108.109H60.4688Z" fill="black"/>
            <path d="M82.1992 103.656V106.595C82.1992 107.431 81.5214 108.109 80.6853 108.109H79.2604C78.4243 108.109 77.7465 107.431 77.7465 106.595V103.656H82.1992Z" fill="black"/>
            <path d="M95.1562 108.109H92.2175C91.3814 108.109 90.7036 107.432 90.7036 106.595V105.171C90.7036 104.334 91.3814 103.657 92.2175 103.657H95.1562V108.109Z" fill="black"/>
            <path d="M99.4722 103.656H95.0195V108.109H99.4722V103.656Z" fill="black"/>
            <path d="M103.656 104.93V106.84C103.656 107.543 104.226 108.109 104.93 108.109H106.84C107.548 108.109 108.113 107.539 108.113 106.835V104.93C108.109 104.222 107.539 103.656 106.835 103.656H104.93C104.226 103.656 103.656 104.226 103.656 104.93Z" fill="black"/>
            <path d="M116.75 103.656H112.297V108.109H116.75V103.656Z" fill="black"/>
            <path d="M125.391 103.656V108.109H122.452C121.616 108.109 120.938 107.431 120.938 106.595V103.656H125.391Z" fill="black"/>
            <path d="M129.707 103.656H125.254V108.109H129.707V103.656Z" fill="black"/>
            <path d="M129.574 103.656H134.027V106.595C134.027 107.431 133.349 108.109 132.513 108.109H129.574V103.656Z" fill="black"/>
            <path d="M142.531 104.93V106.84C142.531 107.543 143.101 108.109 143.805 108.109H145.715C146.423 108.109 146.988 107.539 146.988 106.835V104.93C146.984 104.222 146.414 103.656 145.71 103.656H143.805C143.101 103.656 142.531 104.226 142.531 104.93Z" fill="black"/>
            <path d="M155.621 108.109H151.168V105.171C151.168 104.334 151.846 103.657 152.682 103.657H155.621V108.109Z" fill="black"/>
            <path d="M155.488 103.656H159.941V106.595C159.941 107.431 159.263 108.109 158.427 108.109H155.488V103.656Z" fill="black"/>
            <path d="M172.898 108.109H169.96C169.124 108.109 168.446 107.432 168.446 106.595V105.171C168.446 104.334 169.124 103.657 169.96 103.657H172.898V108.109Z" fill="black"/>
            <path d="M177.218 103.656H172.766V108.109H177.218V103.656Z" fill="black"/>
            <path d="M181.402 104.93V106.84C181.402 107.543 181.972 108.109 182.676 108.109H184.586C185.294 108.109 185.859 107.539 185.859 106.835V104.93C185.855 104.222 185.285 103.656 184.582 103.656H182.676C181.972 103.656 181.402 104.226 181.402 104.93Z" fill="black"/>
            <path d="M194.492 103.656H190.039V108.109H194.492V103.656Z" fill="black"/>
            <path d="M198.812 103.656H194.359V108.109H198.812V103.656Z" fill="black"/>
            <path d="M203.132 103.656H198.68V108.109H203.132V103.656Z" fill="black"/>
            <path d="M207.453 103.656H203V108.109H207.453V103.656Z" fill="black"/>
            <path d="M207.316 108.109V103.657H210.255C211.091 103.657 211.769 104.334 211.769 105.171V108.109H207.316Z" fill="black"/>
            <path d="M8.773 107.977H4.32031V112.429H8.773V107.977Z" fill="black"/>
            <path d="M17.4102 107.977V112.429H14.4714C13.6353 112.429 12.9575 111.751 12.9575 110.915V107.977H17.4102Z" fill="black"/>
            <path d="M21.73 107.977H17.2773V112.429H21.73V107.977Z" fill="black"/>
            <path d="M33.3281 112.339V112.428H34.6862V111.07H34.5971C34.5971 111.774 34.0272 112.339 33.3281 112.339Z" fill="black"/>
            <path d="M39.0074 107.977H34.5547V112.429H39.0074V107.977Z" fill="black"/>
            <path d="M43.3238 107.977H38.8711V112.429H43.3238V107.977Z" fill="black"/>
            <path d="M47.6441 107.977H43.1914V112.429H47.6441V107.977Z" fill="black"/>
            <path d="M51.9605 107.977H47.5078V112.429H51.9605V107.977Z" fill="black"/>
            <path d="M56.2808 107.977H51.8281V112.429H56.2808V107.977Z" fill="black"/>
            <path d="M60.6011 107.977H56.1484V112.429H60.6011V107.977Z" fill="black"/>
            <path d="M64.9214 107.977H60.4688V112.429H64.9214V107.977Z" fill="black"/>
            <path d="M64.7891 112.43V109.491C64.7891 108.655 65.4669 107.977 66.303 107.977H67.7278C68.5639 107.977 69.2418 108.655 69.2418 109.491V112.43H64.7891Z" fill="black"/>
            <path d="M82.0625 109.25V111.16C82.0625 111.864 82.6324 112.429 83.336 112.429H85.2462C85.9542 112.429 86.5196 111.859 86.5196 111.156V109.25C86.5152 108.542 85.9452 107.977 85.2417 107.977H83.336C82.6324 107.977 82.0625 108.547 82.0625 109.25Z" fill="black"/>
            <path d="M99.4722 107.977H95.0195V112.429H99.4722V107.977Z" fill="black"/>
            <path d="M111.07 112.339V112.428H112.428V111.07H112.339C112.339 111.774 111.769 112.339 111.07 112.339Z" fill="black"/>
            <path d="M116.75 107.977H112.297V112.429H116.75V107.977Z" fill="black"/>
            <path d="M116.617 112.43V109.491C116.617 108.655 117.295 107.977 118.131 107.977H119.556C120.392 107.977 121.07 108.655 121.07 109.491V112.43H116.617Z" fill="black"/>
            <path d="M132.668 112.339V112.428H134.026V111.07H133.937C133.937 111.774 133.367 112.339 132.668 112.339Z" fill="black"/>
            <path d="M133.891 112.43V109.491C133.891 108.655 134.568 107.977 135.405 107.977H136.829C137.666 107.977 138.343 108.655 138.343 109.491V112.43H133.891Z" fill="black"/>
            <path d="M155.621 107.977V110.915C155.621 111.751 154.943 112.429 154.107 112.429H152.682C151.846 112.429 151.168 111.751 151.168 110.915V107.977H155.621Z" fill="black"/>
            <path d="M164.125 112.43V109.491C164.125 108.655 164.803 107.977 165.639 107.977H167.064C167.9 107.977 168.578 108.655 168.578 109.491V112.43H164.125Z" fill="black"/>
            <path d="M177.218 107.977H172.766V112.429H177.218V107.977Z" fill="black"/>
            <path d="M188.816 112.339V112.428H190.174V111.07H190.085C190.085 111.774 189.515 112.339 188.816 112.339Z" fill="black"/>
            <path d="M194.492 107.977H190.039V112.429H194.492V107.977Z" fill="black"/>
            <path d="M211.769 107.977H207.316V112.429H211.769V107.977Z" fill="black"/>
            <path d="M3.09375 116.66V116.749H4.45182V115.391H4.36277C4.36277 116.094 3.79282 116.66 3.09375 116.66Z" fill="black"/>
            <path d="M8.773 112.297H4.32031V116.75H8.773V112.297Z" fill="black"/>
            <path d="M21.73 112.297H17.2773V116.75H21.73V112.297Z" fill="black"/>
            <path d="M26.0464 112.297H21.5938V116.75H26.0464V112.297Z" fill="black"/>
            <path d="M30.3668 112.297H25.9141V116.75H30.3668V112.297Z" fill="black"/>
            <path d="M34.6871 112.297H30.2344V116.75H34.6871V112.297Z" fill="black"/>
            <path d="M34.5547 112.297H39.0074V115.236C39.0074 116.072 38.3296 116.75 37.4935 116.75H34.5547V112.297Z" fill="black"/>
            <path d="M73.5586 116.75H70.6198C69.7837 116.75 69.1059 116.072 69.1059 115.236V113.811C69.1059 112.975 69.7837 112.297 70.6198 112.297H73.5586V116.75Z" fill="black"/>
            <path d="M73.4258 116.75V113.811C73.4258 112.975 74.1036 112.297 74.9397 112.297H76.3646C77.2007 112.297 77.8785 112.975 77.8785 113.811V116.75H73.4258Z" fill="black"/>
            <path d="M85.1562 116.66V116.749H86.5143V115.391H86.4253C86.4253 116.094 85.8553 116.66 85.1562 116.66Z" fill="black"/>
            <path d="M90.8359 116.75H86.3832V113.811C86.3832 112.975 87.0611 112.297 87.8972 112.297H90.8359V116.75Z" fill="black"/>
            <path d="M95.1558 112.297H90.7031V116.75H95.1558V112.297Z" fill="black"/>
            <path d="M99.4722 112.297H95.0195V116.75H99.4722V112.297Z" fill="black"/>
            <path d="M103.793 112.297H99.3398V116.75H103.793V112.297Z" fill="black"/>
            <path d="M108.109 112.297H103.656V116.75H108.109V112.297Z" fill="black"/>
            <path d="M112.429 112.297H107.977V116.75H112.429V112.297Z" fill="black"/>
            <path d="M116.75 112.297H112.297V116.75H116.75V112.297Z" fill="black"/>
            <path d="M129.707 116.75H126.768C125.932 116.75 125.254 116.072 125.254 115.236V113.811C125.254 112.975 125.932 112.297 126.768 112.297H129.707V116.75Z" fill="black"/>
            <path d="M134.027 112.297H129.574V116.75H134.027V112.297Z" fill="black"/>
            <path d="M138.343 112.297H133.891V116.75H138.343V112.297Z" fill="black"/>
            <path d="M142.664 112.297H138.211V116.75H142.664V112.297Z" fill="black"/>
            <path d="M142.531 116.75V113.811C142.531 112.975 143.209 112.297 144.045 112.297H145.47C146.306 112.297 146.984 112.975 146.984 113.811V116.75H142.531Z" fill="black"/>
            <path d="M155.488 116.75V113.811C155.488 112.975 156.166 112.297 157.002 112.297H158.427C159.263 112.297 159.941 112.975 159.941 113.811V116.75H155.488Z" fill="black"/>
            <path d="M162.902 116.66V116.749H164.26V115.391H164.171C164.171 116.094 163.601 116.66 162.902 116.66Z" fill="black"/>
            <path d="M168.578 112.297H164.125V116.75H168.578V112.297Z" fill="black"/>
            <path d="M177.218 112.297H172.766V116.75H177.218V112.297Z" fill="black"/>
            <path d="M181.539 112.297H177.086V116.75H181.539V112.297Z" fill="black"/>
            <path d="M185.855 112.297H181.402V116.75H185.855V112.297Z" fill="black"/>
            <path d="M190.175 112.297H185.723V116.75H190.175V112.297Z" fill="black"/>
            <path d="M194.492 112.297H190.039V116.75H194.492V112.297Z" fill="black"/>
            <path d="M198.812 112.297H194.359V116.75H198.812V112.297Z" fill="black"/>
            <path d="M198.68 116.75V112.297H201.618C202.455 112.297 203.132 112.975 203.132 113.811V116.75H198.68Z" fill="black"/>
            <path d="M206.094 116.66V116.749H207.452V115.391H207.363C207.363 116.094 206.793 116.66 206.094 116.66Z" fill="black"/>
            <path d="M211.769 112.297H207.316V116.75H211.769V112.297Z" fill="black"/>
            <path d="M4.45312 121.07H1.51435C0.678238 121.07 0.000434875 120.393 0.000434875 119.556V118.132C0.000434875 117.295 0.678238 116.618 1.51435 116.618H4.45313L4.45312 121.07Z" fill="black"/>
            <path d="M8.773 116.617H4.32031V121.07H8.773V116.617Z" fill="black"/>
            <path d="M16.0547 120.98V121.069H17.4128V119.711H17.3237C17.3237 120.414 16.7538 120.98 16.0547 120.98Z" fill="black"/>
            <path d="M21.73 116.617H17.2773V121.07H21.73V116.617Z" fill="black"/>
            <path d="M21.5938 116.617H26.0464V119.556C26.0464 120.392 25.3686 121.07 24.5325 121.07H21.5938V116.617Z" fill="black"/>
            <path d="M38.8711 117.891V119.801C38.8711 120.504 39.441 121.07 40.1446 121.07H42.0548C42.7627 121.07 43.3282 120.5 43.3282 119.796V117.891C43.3238 117.183 42.7538 116.617 42.0503 116.617H40.1446C39.441 116.617 38.8711 117.187 38.8711 117.891Z" fill="black"/>
            <path d="M60.6016 121.07H57.6628C56.8267 121.07 56.1489 120.393 56.1489 119.556V118.132C56.1489 117.295 56.8267 116.618 57.6628 116.618H60.6016V121.07Z" fill="black"/>
            <path d="M64.9214 116.617H60.4688V121.07H64.9214V116.617Z" fill="black"/>
            <path d="M64.7891 121.07V116.618H67.7278C68.5639 116.618 69.2418 117.295 69.2418 118.132V121.07H64.7891Z" fill="black"/>
            <path d="M86.5156 121.07H83.5768C82.7407 121.07 82.0629 120.393 82.0629 119.556V118.132C82.0629 117.295 82.7407 116.618 83.5768 116.618H86.5156V121.07Z" fill="black"/>
            <path d="M90.8355 116.617H86.3828V121.07H90.8355V116.617Z" fill="black"/>
            <path d="M95.1558 116.617H90.7031V121.07H95.1558V116.617Z" fill="black"/>
            <path d="M103.793 116.617V119.556C103.793 120.392 103.115 121.07 102.279 121.07H100.854C100.018 121.07 99.3403 120.392 99.3403 119.556V116.617H103.793Z" fill="black"/>
            <path d="M116.75 116.617V119.556C116.75 120.392 116.072 121.07 115.236 121.07H113.811C112.975 121.07 112.297 120.392 112.297 119.556V116.617H116.75Z" fill="black"/>
            <path d="M120.938 117.891V119.801C120.938 120.504 121.507 121.07 122.211 121.07H124.121C124.829 121.07 125.395 120.5 125.395 119.796V117.891C125.39 117.183 124.82 116.617 124.117 116.617H122.211C121.507 116.617 120.938 117.187 120.938 117.891Z" fill="black"/>
            <path d="M154.266 120.98V121.069H155.624V119.711H155.535C155.535 120.414 154.965 120.98 154.266 120.98Z" fill="black"/>
            <path d="M159.941 116.617H155.488V121.07H159.941V116.617Z" fill="black"/>
            <path d="M164.257 116.617H159.805V121.07H164.257V116.617Z" fill="black"/>
            <path d="M168.578 116.617H164.125V121.07H168.578V116.617Z" fill="black"/>
            <path d="M177.219 116.617V119.556C177.219 120.392 176.541 121.07 175.705 121.07H174.28C173.444 121.07 172.766 120.392 172.766 119.556V116.617H177.219Z" fill="black"/>
            <path d="M185.855 116.617H181.402V121.07H185.855V116.617Z" fill="black"/>
            <path d="M185.723 116.617H190.175V119.556C190.175 120.392 189.498 121.07 188.661 121.07H185.723V116.617Z" fill="black"/>
            <path d="M198.812 116.617V121.07H195.874C195.038 121.07 194.36 120.392 194.36 119.556V116.617H198.812Z" fill="black"/>
            <path d="M203.132 116.617H198.68V121.07H203.132V116.617Z" fill="black"/>
            <path d="M207.453 116.617H203V121.07H207.453V116.617Z" fill="black"/>
            <path d="M207.316 116.617H211.769V119.556C211.769 120.392 211.091 121.07 210.255 121.07H207.316V116.617Z" fill="black"/>
            <path d="M8.773 120.938H4.32031V125.39H8.773V120.938Z" fill="black"/>
            <path d="M13.0933 120.938H8.64062V125.39H13.0933V120.938Z" fill="black"/>
            <path d="M17.4097 120.938H12.957V125.39H17.4097V120.938Z" fill="black"/>
            <path d="M17.2773 120.938H21.73V123.876C21.73 124.712 21.0522 125.39 20.2161 125.39H17.2773V120.938Z" fill="black"/>
            <path d="M25.9141 122.211V124.121C25.9141 124.825 26.484 125.39 27.1875 125.39H29.0977C29.8057 125.39 30.3712 124.82 30.3712 124.117V122.211C30.3668 121.503 29.7968 120.938 29.0933 120.938H27.1875C26.484 120.938 25.9141 121.507 25.9141 122.211Z" fill="black"/>
            <path d="M34.5547 125.391V122.452C34.5547 121.616 35.2325 120.938 36.0686 120.938H37.4935C38.3296 120.938 39.0074 121.616 39.0074 122.452V125.391H34.5547Z" fill="black"/>
            <path d="M43.1914 122.211V124.121C43.1914 124.825 43.7614 125.39 44.4649 125.39H46.3751C47.0831 125.39 47.6485 124.82 47.6485 124.117V122.211C47.6441 121.503 47.0742 120.938 46.3706 120.938H44.4649C43.7614 120.938 43.1914 121.507 43.1914 122.211Z" fill="black"/>
            <path d="M69.2418 120.938H64.7891V125.39H69.2418V120.938Z" fill="black"/>
            <path d="M90.8359 120.938V125.39H87.8972C87.0611 125.39 86.3832 124.712 86.3832 123.876V120.938H90.8359Z" fill="black"/>
            <path d="M95.1558 120.938H90.7031V125.39H95.1558V120.938Z" fill="black"/>
            <path d="M116.617 125.391V122.452C116.617 121.616 117.295 120.938 118.131 120.938H119.556C120.392 120.938 121.07 121.616 121.07 122.452V125.391H116.617Z" fill="black"/>
            <path d="M138.348 125.391H133.895V122.452C133.895 121.616 134.573 120.938 135.409 120.938H138.348V125.391Z" fill="black"/>
            <path d="M142.664 120.938H138.211V125.39H142.664V120.938Z" fill="black"/>
            <path d="M146.984 120.938H142.531V125.39H146.984V120.938Z" fill="black"/>
            <path d="M151.304 120.938H146.852V125.39H151.304V120.938Z" fill="black"/>
            <path d="M155.621 120.938H151.168V125.39H155.621V120.938Z" fill="black"/>
            <path d="M159.941 120.938H155.488V125.39H159.941V120.938Z" fill="black"/>
            <path d="M168.578 120.938H164.125V125.39H168.578V120.938Z" fill="black"/>
            <path d="M181.539 125.391H177.086V122.452C177.086 121.616 177.764 120.938 178.6 120.938H181.539V125.391Z" fill="black"/>
            <path d="M185.855 120.938H181.402V125.39H185.855V120.938Z" fill="black"/>
            <path d="M188.816 125.3V125.389H190.174V124.031H190.085C190.085 124.735 189.515 125.3 188.816 125.3Z" fill="black"/>
            <path d="M190.039 125.391V122.452C190.039 121.616 190.717 120.938 191.553 120.938H192.978C193.814 120.938 194.492 121.616 194.492 122.452V125.391H190.039Z" fill="black"/>
            <path d="M203.133 120.938V125.39H200.194C199.358 125.39 198.68 124.712 198.68 123.876V120.938H203.133Z" fill="black"/>
            <path d="M203 120.938H207.453V123.876C207.453 124.712 206.775 125.39 205.939 125.39H203V120.938Z" fill="black"/>
            <path d="M8.77344 125.258V129.711H5.83466C4.99855 129.711 4.32075 129.033 4.32075 128.197V125.258H8.77344Z" fill="black"/>
            <path d="M13.0933 125.258H8.64062V129.711H13.0933V125.258Z" fill="black"/>
            <path d="M12.957 125.258H17.4097V128.197C17.4097 129.033 16.7319 129.711 15.8958 129.711H12.957V125.258Z" fill="black"/>
            <path d="M21.5938 126.531V128.441C21.5938 129.145 22.1637 129.711 22.8672 129.711H24.7774C25.4854 129.711 26.0509 129.141 26.0509 128.437V126.531C26.0464 125.823 25.4765 125.258 24.773 125.258H22.8672C22.1637 125.258 21.5938 125.828 21.5938 126.531Z" fill="black"/>
            <path d="M39.0074 125.258H34.5547V129.711H39.0074V125.258Z" fill="black"/>
            <path d="M56.2852 129.711H51.8325V126.772C51.8325 125.936 52.5103 125.258 53.3464 125.258H56.2852V129.711Z" fill="black"/>
            <path d="M60.6011 125.258H56.1484V129.711H60.6011V125.258Z" fill="black"/>
            <path d="M64.9214 125.258H60.4688V129.711H64.9214V125.258Z" fill="black"/>
            <path d="M69.2418 125.258H64.7891V129.711H69.2418V125.258Z" fill="black"/>
            <path d="M73.5582 125.258H69.1055V129.711H73.5582V125.258Z" fill="black"/>
            <path d="M77.8785 125.258H73.4258V129.711H77.8785V125.258Z" fill="black"/>
            <path d="M77.7422 129.711V125.258H80.681C81.5171 125.258 82.1949 125.936 82.1949 126.772V129.711H77.7422Z" fill="black"/>
            <path d="M95.1558 125.258H90.7031V129.711H95.1558V125.258Z" fill="black"/>
            <path d="M95.0195 129.711V125.258H97.9583C98.7944 125.258 99.4722 125.936 99.4722 126.772V129.711H95.0195Z" fill="black"/>
            <path d="M121.07 125.258H116.617V129.711H121.07V125.258Z" fill="black"/>
            <path d="M120.938 129.711V125.258H123.876C124.712 125.258 125.39 125.936 125.39 126.772V129.711H120.938Z" fill="black"/>
            <path d="M138.348 125.258V129.711H135.409C134.573 129.711 133.895 129.033 133.895 128.197V125.258H138.348Z" fill="black"/>
            <path d="M142.664 125.258H138.211V129.711H142.664V125.258Z" fill="black"/>
            <path d="M146.984 125.258H142.531V129.711H146.984V125.258Z" fill="black"/>
            <path d="M155.621 125.258H151.168V129.711H155.621V125.258Z" fill="black"/>
            <path d="M155.488 125.258H159.941V128.197C159.941 129.033 159.263 129.711 158.427 129.711H155.488V125.258Z" fill="black"/>
            <path d="M162.902 129.621V129.71H164.26V128.352H164.171C164.171 129.055 163.601 129.621 162.902 129.621Z" fill="black"/>
            <path d="M168.578 125.258H164.125V129.711H168.578V125.258Z" fill="black"/>
            <path d="M175.859 129.621V129.71H177.217V128.352H177.128C177.128 129.055 176.558 129.621 175.859 129.621Z" fill="black"/>
            <path d="M181.539 125.258H177.086V129.711H181.539V125.258Z" fill="black"/>
            <path d="M185.855 125.258H181.402V129.711H185.855V125.258Z" fill="black"/>
            <path d="M190.175 125.258H185.723V129.711H190.175V125.258Z" fill="black"/>
            <path d="M194.492 125.258H190.039V129.711H194.492V125.258Z" fill="black"/>
            <path d="M194.359 129.711V126.772C194.359 125.936 195.037 125.258 195.873 125.258H197.298C198.134 125.258 198.812 125.936 198.812 126.772V129.711H194.359Z" fill="black"/>
            <path d="M0 130.844V132.754C0 133.458 0.569944 134.023 1.27347 134.023H3.18367C3.89165 134.023 4.45714 133.453 4.45714 132.75V130.844C4.45269 130.136 3.88275 129.57 3.17922 129.57H1.27347C0.569944 129.57 0 130.14 0 130.844Z" fill="black"/>
            <path d="M17.2773 134.023V131.085C17.2773 130.249 17.9551 129.571 18.7913 129.571H20.2161C21.0522 129.571 21.73 130.249 21.73 131.085V134.023H17.2773Z" fill="black"/>
            <path d="M25.9141 130.844V132.754C25.9141 133.458 26.484 134.023 27.1875 134.023H29.0977C29.8057 134.023 30.3712 133.453 30.3712 132.75V130.844C30.3668 130.136 29.7968 129.57 29.0933 129.57H27.1875C26.484 129.57 25.9141 130.14 25.9141 130.844Z" fill="black"/>
            <path d="M39.0074 129.57H34.5547V134.023H39.0074V129.57Z" fill="black"/>
            <path d="M38.8711 134.023V129.571H41.8099C42.646 129.571 43.3238 130.249 43.3238 131.085V134.023H38.8711Z" fill="black"/>
            <path d="M56.2808 129.57H51.8281V134.023H56.2808V129.57Z" fill="black"/>
            <path d="M64.9214 129.57H60.4688V134.023H64.9214V129.57Z" fill="black"/>
            <path d="M82.1949 129.57H77.7422V134.023H82.1949V129.57Z" fill="black"/>
            <path d="M82.0625 134.023V129.571H85.0013C85.8374 129.571 86.5152 130.249 86.5152 131.085V134.023H82.0625Z" fill="black"/>
            <path d="M95.1558 129.57H90.7031V134.023H95.1558V129.57Z" fill="black"/>
            <path d="M99.4722 129.57H95.0195V134.023H99.4722V129.57Z" fill="black"/>
            <path d="M99.3398 134.023V131.085C99.3398 130.249 100.018 129.571 100.854 129.571H102.279C103.115 129.571 103.793 130.249 103.793 131.085V134.023H99.3398Z" fill="black"/>
            <path d="M121.07 129.57V134.023H118.132C117.295 134.023 116.618 133.345 116.618 132.509V129.57H121.07Z" fill="black"/>
            <path d="M120.938 129.57H125.39V132.509C125.39 133.345 124.712 134.023 123.876 134.023H120.938V129.57Z" fill="black"/>
            <path d="M129.574 134.023V131.085C129.574 130.249 130.252 129.571 131.088 129.571H132.513C133.349 129.571 134.027 130.249 134.027 131.085V134.023H129.574Z" fill="black"/>
            <path d="M146.984 129.57H142.531V134.023H146.984V129.57Z" fill="black"/>
            <path d="M155.621 129.57V132.509C155.621 133.345 154.943 134.023 154.107 134.023H152.682C151.846 134.023 151.168 133.345 151.168 132.509V129.57H155.621Z" fill="black"/>
            <path d="M164.262 134.023H161.323C160.487 134.023 159.809 133.346 159.809 132.51V131.085C159.809 130.249 160.487 129.571 161.323 129.571H164.262V134.023Z" fill="black"/>
            <path d="M168.578 129.57H164.125V134.023H168.578V129.57Z" fill="black"/>
            <path d="M172.898 129.57H168.445V134.023H172.898V129.57Z" fill="black"/>
            <path d="M177.218 129.57H172.766V134.023H177.218V129.57Z" fill="black"/>
            <path d="M181.539 129.57H177.086V134.023H181.539V129.57Z" fill="black"/>
            <path d="M190.176 129.57V132.509C190.176 133.345 189.498 134.023 188.662 134.023H187.237C186.401 134.023 185.723 133.345 185.723 132.509V129.57H190.176Z" fill="black"/>
            <path d="M197.453 133.933V134.022H198.811V132.664H198.722C198.722 133.368 198.152 133.933 197.453 133.933Z" fill="black"/>
            <path d="M198.68 134.023V131.085C198.68 130.249 199.357 129.571 200.194 129.571H201.618C202.455 129.571 203.132 130.249 203.132 131.085V134.023H198.68Z" fill="black"/>
            <path d="M8.64062 138.344V135.405C8.64062 134.569 9.31843 133.891 10.1545 133.891H11.5794C12.4155 133.891 13.0933 134.569 13.0933 135.405V138.344H8.64062Z" fill="black"/>
            <path d="M16.0547 138.253V138.342H17.4128V136.984H17.3237C17.3237 137.688 16.7538 138.253 16.0547 138.253Z" fill="black"/>
            <path d="M21.73 133.891H17.2773V138.343H21.73V133.891Z" fill="black"/>
            <path d="M39.0074 133.891H34.5547V138.343H39.0074V133.891Z" fill="black"/>
            <path d="M43.3238 133.891H38.8711V138.343H43.3238V133.891Z" fill="black"/>
            <path d="M43.1914 138.344V133.891H46.1302C46.9663 133.891 47.6441 134.569 47.6441 135.405V138.344H43.1914Z" fill="black"/>
            <path d="M56.2852 133.891V136.829C56.2852 137.666 55.6074 138.343 54.7712 138.343H53.3464C52.5103 138.343 51.8325 137.666 51.8325 136.829V133.891H56.2852Z" fill="black"/>
            <path d="M64.9219 133.891V136.829C64.9219 137.666 64.2441 138.343 63.408 138.343H61.9831C61.147 138.343 60.4692 137.666 60.4692 136.829V133.891H64.9219Z" fill="black"/>
            <path d="M73.5586 138.344H70.6198C69.7837 138.344 69.1059 137.666 69.1059 136.83V135.405C69.1059 134.569 69.7837 133.891 70.6198 133.891H73.5586V138.344Z" fill="black"/>
            <path d="M77.8785 133.891H73.4258V138.343H77.8785V133.891Z" fill="black"/>
            <path d="M82.1949 133.891H77.7422V138.343H82.1949V133.891Z" fill="black"/>
            <path d="M86.5152 133.891H82.0625V138.343H86.5152V133.891Z" fill="black"/>
            <path d="M89.4766 138.253V138.342H90.8346V136.984H90.7456C90.7456 137.688 90.1756 138.253 89.4766 138.253Z" fill="black"/>
            <path d="M95.1558 133.891H90.7031V138.343H95.1558V133.891Z" fill="black"/>
            <path d="M99.4722 133.891H95.0195V138.343H99.4722V133.891Z" fill="black"/>
            <path d="M107.977 138.344V135.405C107.977 134.569 108.654 133.891 109.49 133.891H110.915C111.751 133.891 112.429 134.569 112.429 135.405V138.344H107.977Z" fill="black"/>
            <path d="M134.027 133.891H129.574V138.343H134.027V133.891Z" fill="black"/>
            <path d="M133.891 138.344V133.891H136.829C137.666 133.891 138.343 134.569 138.343 135.405V138.344H133.891Z" fill="black"/>
            <path d="M141.305 138.253V138.342H142.663V136.984H142.574C142.574 137.688 142.004 138.253 141.305 138.253Z" fill="black"/>
            <path d="M146.984 133.891H142.531V138.343H146.984V133.891Z" fill="black"/>
            <path d="M181.539 133.891V138.343H178.6C177.764 138.343 177.086 137.666 177.086 136.829V133.891H181.539Z" fill="black"/>
            <path d="M181.402 138.344V135.405C181.402 134.569 182.08 133.891 182.916 133.891H184.341C185.177 133.891 185.855 134.569 185.855 135.405V138.344H181.402Z" fill="black"/>
            <path d="M198.812 138.344H194.36V135.405C194.36 134.569 195.038 133.891 195.874 133.891H198.812V138.344Z" fill="black"/>
            <path d="M203.132 133.891H198.68V138.343H203.132V133.891Z" fill="black"/>
            <path d="M207.453 133.891H203V138.343H207.453V133.891Z" fill="black"/>
            <path d="M207.316 138.344V133.891H210.255C211.091 133.891 211.769 134.569 211.769 135.405V138.344H207.316Z" fill="black"/>
            <path d="M0 139.484V141.395C0 142.098 0.569944 142.664 1.27347 142.664H3.18367C3.89165 142.664 4.45714 142.094 4.45714 141.39V139.484C4.45269 138.776 3.88275 138.211 3.17922 138.211H1.27347C0.569944 138.211 0 138.781 0 139.484Z" fill="black"/>
            <path d="M13.0933 138.211H8.64062V142.664H13.0933V138.211Z" fill="black"/>
            <path d="M17.4097 138.211H12.957V142.664H17.4097V138.211Z" fill="black"/>
            <path d="M21.73 138.211H17.2773V142.664H21.73V138.211Z" fill="black"/>
            <path d="M26.0464 138.211H21.5938V142.664H26.0464V138.211Z" fill="black"/>
            <path d="M25.9141 142.664V139.725C25.9141 138.889 26.5919 138.211 27.428 138.211H28.8528C29.6889 138.211 30.3668 138.889 30.3668 139.725V142.664H25.9141Z" fill="black"/>
            <path d="M39.0078 138.211V141.15C39.0078 141.986 38.33 142.664 37.4939 142.664H36.069C35.2329 142.664 34.5551 141.986 34.5551 141.15V138.211H39.0078Z" fill="black"/>
            <path d="M47.6445 138.211V141.15C47.6445 141.986 46.9667 142.664 46.1306 142.664H44.7058C43.8696 142.664 43.1918 141.986 43.1918 141.15V138.211H47.6445Z" fill="black"/>
            <path d="M82.1992 138.211V142.664H79.2604C78.4243 142.664 77.7465 141.986 77.7465 141.15V138.211H82.1992Z" fill="black"/>
            <path d="M86.5152 138.211H82.0625V142.664H86.5152V138.211Z" fill="black"/>
            <path d="M90.8355 138.211H86.3828V142.664H90.8355V138.211Z" fill="black"/>
            <path d="M95.1558 138.211H90.7031V142.664H95.1558V138.211Z" fill="black"/>
            <path d="M99.4722 138.211H95.0195V142.664H99.4722V138.211Z" fill="black"/>
            <path d="M112.429 138.211H107.977V142.664H112.429V138.211Z" fill="black"/>
            <path d="M116.617 139.484V141.395C116.617 142.098 117.187 142.664 117.891 142.664H119.801C120.509 142.664 121.074 142.094 121.074 141.39V139.484C121.07 138.776 120.5 138.211 119.796 138.211H117.891C117.187 138.211 116.617 138.781 116.617 139.484Z" fill="black"/>
            <path d="M134.027 138.211H129.574V142.664H134.027V138.211Z" fill="black"/>
            <path d="M138.343 138.211H133.891V142.664H138.343V138.211Z" fill="black"/>
            <path d="M142.664 138.211H138.211V142.664H142.664V138.211Z" fill="black"/>
            <path d="M146.984 138.211H142.531V142.664H146.984V138.211Z" fill="black"/>
            <path d="M154.266 142.574V142.663H155.624V141.305H155.535C155.535 142.008 154.965 142.574 154.266 142.574Z" fill="black"/>
            <path d="M159.941 142.664H155.489V139.725C155.489 138.889 156.167 138.211 157.003 138.211H159.941V142.664Z" fill="black"/>
            <path d="M159.805 142.664V138.211H162.743C163.58 138.211 164.257 138.889 164.257 139.725V142.664H159.805Z" fill="black"/>
            <path d="M172.766 139.484V141.395C172.766 142.098 173.336 142.664 174.039 142.664H175.949C176.657 142.664 177.223 142.094 177.223 141.39V139.484C177.218 138.776 176.648 138.211 175.945 138.211H174.039C173.336 138.211 172.766 138.781 172.766 139.484Z" fill="black"/>
            <path d="M185.723 139.484V141.395C185.723 142.098 186.293 142.664 186.996 142.664H188.906C189.614 142.664 190.18 142.094 190.18 141.39V139.484C190.175 138.776 189.605 138.211 188.902 138.211H186.996C186.293 138.211 185.723 138.781 185.723 139.484Z" fill="black"/>
            <path d="M193.133 142.574V142.663H194.491V141.305H194.402C194.402 142.008 193.832 142.574 193.133 142.574Z" fill="black"/>
            <path d="M198.812 138.211H194.359V142.664H198.812V138.211Z" fill="black"/>
            <path d="M198.68 138.211H203.132V141.15C203.132 141.986 202.455 142.664 201.618 142.664H198.68V138.211Z" fill="black"/>
            <path d="M211.77 138.211V141.15C211.77 141.986 211.092 142.664 210.256 142.664H208.831C207.995 142.664 207.317 141.986 207.317 141.15V138.211H211.77Z" fill="black"/>
            <path d="M13.0938 142.531V145.47C13.0938 146.306 12.4159 146.984 11.5798 146.984H10.155C9.31886 146.984 8.64106 146.306 8.64106 145.47V142.531H13.0938Z" fill="black"/>
            <path d="M26.0508 142.531V145.47C26.0508 146.306 25.373 146.984 24.5369 146.984H23.112C22.2759 146.984 21.5981 146.306 21.5981 145.47V142.531H26.0508Z" fill="black"/>
            <path d="M37.6484 146.894V146.983H39.0065V145.625H38.9175C38.9175 146.329 38.3475 146.894 37.6484 146.894Z" fill="black"/>
            <path d="M38.8711 146.984V144.046C38.8711 143.209 39.5489 142.532 40.385 142.532H41.8099C42.646 142.532 43.3238 143.209 43.3238 144.046V146.984H38.8711Z" fill="black"/>
            <path d="M56.1484 146.984V144.046C56.1484 143.209 56.8262 142.532 57.6624 142.532H59.0872C59.9233 142.532 60.6011 143.209 60.6011 144.046V146.984H56.1484Z" fill="black"/>
            <path d="M69.1055 143.805V145.715C69.1055 146.418 69.6754 146.984 70.3789 146.984H72.2891C72.9971 146.984 73.5626 146.414 73.5626 145.71V143.805C73.5582 143.097 72.9882 142.531 72.2847 142.531H70.3789C69.6754 142.531 69.1055 143.101 69.1055 143.805Z" fill="black"/>
            <path d="M99.4727 142.531V145.47C99.4727 146.306 98.7949 146.984 97.9587 146.984H96.5339C95.6978 146.984 95.02 146.306 95.02 145.47V142.531H99.4727Z" fill="black"/>
            <path d="M112.429 142.531H107.977V146.984H112.429V142.531Z" fill="black"/>
            <path d="M112.297 146.984V142.532H115.236C116.072 142.532 116.75 143.209 116.75 144.046V146.984H112.297Z" fill="black"/>
            <path d="M134.027 142.531V145.47C134.027 146.306 133.35 146.984 132.513 146.984H131.089C130.252 146.984 129.575 146.306 129.575 145.47V142.531H134.027Z" fill="black"/>
            <path d="M142.664 142.531V146.984H139.725C138.889 146.984 138.211 146.306 138.211 145.47V142.531H142.664Z" fill="black"/>
            <path d="M142.531 142.531H146.984V145.47C146.984 146.306 146.306 146.984 145.47 146.984H142.531V142.531Z" fill="black"/>
            <path d="M155.621 146.984H151.168V144.046C151.168 143.209 151.846 142.532 152.682 142.532H155.621V146.984Z" fill="black"/>
            <path d="M159.941 142.531H155.488V146.984H159.941V142.531Z" fill="black"/>
            <path d="M164.257 142.531H159.805V146.984H164.257V142.531Z" fill="black"/>
            <path d="M164.125 146.984V142.532H167.064C167.9 142.532 168.578 143.209 168.578 144.046V146.984H164.125Z" fill="black"/>
            <path d="M177.086 143.805V145.715C177.086 146.418 177.656 146.984 178.359 146.984H180.27C180.978 146.984 181.543 146.414 181.543 145.71V143.805C181.539 143.097 180.969 142.531 180.265 142.531H178.359C177.656 142.531 177.086 143.101 177.086 143.805Z" fill="black"/>
            <path d="M194.496 146.984H191.557C190.721 146.984 190.043 146.307 190.043 145.47V144.046C190.043 143.209 190.721 142.532 191.557 142.532H194.496V146.984Z" fill="black"/>
            <path d="M198.812 142.531H194.359V146.984H198.812V142.531Z" fill="black"/>
            <path d="M201.773 146.894V146.983H203.132V145.625H203.042C203.042 146.329 202.473 146.894 201.773 146.894Z" fill="black"/>
            <path d="M203 146.984V144.046C203 143.209 203.678 142.532 204.514 142.532H205.939C206.775 142.532 207.453 143.209 207.453 144.046V146.984H203Z" fill="black"/>
            <path d="M0 148.125V150.035C0 150.739 0.569944 151.304 1.27347 151.304H3.18367C3.89165 151.304 4.45714 150.734 4.45714 150.031V148.125C4.45269 147.417 3.88275 146.852 3.17922 146.852H1.27347C0.569944 146.852 0 147.422 0 148.125Z" fill="black"/>
            <path d="M17.2773 151.305V148.366C17.2773 147.53 17.9551 146.852 18.7913 146.852H20.2161C21.0522 146.852 21.73 147.53 21.73 148.366V151.305H17.2773Z" fill="black"/>
            <path d="M30.3672 151.305H27.4284C26.5923 151.305 25.9145 150.627 25.9145 149.791V148.366C25.9145 147.53 26.5923 146.852 27.4284 146.852H30.3672V151.305Z" fill="black"/>
            <path d="M34.6871 146.852H30.2344V151.304H34.6871V146.852Z" fill="black"/>
            <path d="M39.0074 146.852H34.5547V151.304H39.0074V146.852Z" fill="black"/>
            <path d="M43.3238 146.852H38.8711V151.304H43.3238V146.852Z" fill="black"/>
            <path d="M60.6011 146.852H56.1484V151.304H60.6011V146.852Z" fill="black"/>
            <path d="M60.4688 151.305V148.366C60.4688 147.53 61.1466 146.852 61.9827 146.852H63.4075C64.2436 146.852 64.9214 147.53 64.9214 148.366V151.305H60.4688Z" fill="black"/>
            <path d="M77.7422 151.305V148.366C77.7422 147.53 78.42 146.852 79.2561 146.852H80.681C81.5171 146.852 82.1949 147.53 82.1949 148.366V151.305H77.7422Z" fill="black"/>
            <path d="M90.8359 151.305H87.8972C87.0611 151.305 86.3832 150.627 86.3832 149.791V148.366C86.3832 147.53 87.0611 146.852 87.8972 146.852H90.8359V151.305Z" fill="black"/>
            <path d="M90.7031 151.305V148.366C90.7031 147.53 91.3809 146.852 92.217 146.852H93.6419C94.478 146.852 95.1558 147.53 95.1558 148.366V151.305H90.7031Z" fill="black"/>
            <path d="M99.3398 148.125V150.035C99.3398 150.739 99.9098 151.304 100.613 151.304H102.524C103.231 151.304 103.797 150.734 103.797 150.031V148.125C103.793 147.417 103.223 146.852 102.519 146.852H100.613C99.9098 146.852 99.3398 147.422 99.3398 148.125Z" fill="black"/>
            <path d="M112.434 146.852V151.304H109.495C108.659 151.304 107.981 150.626 107.981 149.79V146.852H112.434Z" fill="black"/>
            <path d="M112.297 146.852H116.75V149.79C116.75 150.626 116.072 151.304 115.236 151.304H112.297V146.852Z" fill="black"/>
            <path d="M125.391 151.305H120.938V148.366C120.938 147.53 121.616 146.852 122.452 146.852H125.391V151.305Z" fill="black"/>
            <path d="M125.254 151.305V148.366C125.254 147.53 125.932 146.852 126.768 146.852H128.193C129.029 146.852 129.707 147.53 129.707 148.366V151.305H125.254Z" fill="black"/>
            <path d="M155.621 146.852H151.168V151.304H155.621V146.852Z" fill="black"/>
            <path d="M164.262 146.852V151.304H161.323C160.487 151.304 159.809 150.626 159.809 149.79V146.852H164.262Z" fill="black"/>
            <path d="M164.125 146.852H168.578V149.79C168.578 150.626 167.9 151.304 167.064 151.304H164.125V146.852Z" fill="black"/>
            <path d="M172.766 148.125V150.035C172.766 150.739 173.336 151.304 174.039 151.304H175.949C176.657 151.304 177.223 150.734 177.223 150.031V148.125C177.218 147.417 176.648 146.852 175.945 146.852H174.039C173.336 146.852 172.766 147.422 172.766 148.125Z" fill="black"/>
            <path d="M184.5 151.214V151.303H185.858V149.945H185.769C185.769 150.649 185.199 151.214 184.5 151.214Z" fill="black"/>
            <path d="M185.723 151.305V148.366C185.723 147.53 186.4 146.852 187.237 146.852H188.661C189.498 146.852 190.175 147.53 190.175 148.366V151.305H185.723Z" fill="black"/>
            <path d="M193.133 151.214V151.303H194.491V149.945H194.402C194.402 150.649 193.832 151.214 193.133 151.214Z" fill="black"/>
            <path d="M198.812 146.852H194.359V151.304H198.812V146.852Z" fill="black"/>
            <path d="M203.132 146.852H198.68V151.304H203.132V146.852Z" fill="black"/>
            <path d="M207.453 146.852H203V151.304H207.453V146.852Z" fill="black"/>
            <path d="M8.64062 152.445V154.356C8.64062 155.059 9.21057 155.625 9.91409 155.625H11.8243C12.5323 155.625 13.0978 155.055 13.0978 154.351V152.445C13.0933 151.737 12.5234 151.172 11.8198 151.172H9.91409C9.21057 151.172 8.64062 151.742 8.64062 152.445Z" fill="black"/>
            <path d="M21.7305 151.172V154.111C21.7305 154.947 21.0527 155.625 20.2166 155.625H18.7917C17.9556 155.625 17.2778 154.947 17.2778 154.111V151.172H21.7305Z" fill="black"/>
            <path d="M34.6871 151.172H30.2344V155.625H34.6871V151.172Z" fill="black"/>
            <path d="M43.3238 151.172H38.8711V155.625H43.3238V151.172Z" fill="black"/>
            <path d="M43.1914 155.625V152.686C43.1914 151.85 43.8692 151.172 44.7053 151.172H46.1302C46.9663 151.172 47.6441 151.85 47.6441 152.686V155.625H43.1914Z" fill="black"/>
            <path d="M54.9219 155.535V155.624H56.2799V154.266H56.1909C56.1909 154.969 55.6209 155.535 54.9219 155.535Z" fill="black"/>
            <path d="M60.6011 151.172H56.1484V155.625H60.6011V151.172Z" fill="black"/>
            <path d="M69.1055 152.445V154.356C69.1055 155.059 69.6754 155.625 70.3789 155.625H72.2891C72.9971 155.625 73.5626 155.055 73.5626 154.351V152.445C73.5582 151.737 72.9882 151.172 72.2847 151.172H70.3789C69.6754 151.172 69.1055 151.742 69.1055 152.445Z" fill="black"/>
            <path d="M82.1992 151.172V155.625H79.2604C78.4243 155.625 77.7465 154.947 77.7465 154.111V151.172H82.1992Z" fill="black"/>
            <path d="M82.0625 155.625V151.172H85.0013C85.8374 151.172 86.5152 151.85 86.5152 152.686V155.625H82.0625Z" fill="black"/>
            <path d="M103.656 152.445V154.356C103.656 155.059 104.226 155.625 104.93 155.625H106.84C107.548 155.625 108.113 155.055 108.113 154.351V152.445C108.109 151.737 107.539 151.172 106.835 151.172H104.93C104.226 151.172 103.656 151.742 103.656 152.445Z" fill="black"/>
            <path d="M125.391 151.172V154.111C125.391 154.947 124.713 155.625 123.877 155.625H122.452C121.616 155.625 120.938 154.947 120.938 154.111V151.172H125.391Z" fill="black"/>
            <path d="M138.348 155.625H135.409C134.573 155.625 133.895 154.947 133.895 154.111V152.686C133.895 151.85 134.573 151.172 135.409 151.172H138.348V155.625Z" fill="black"/>
            <path d="M142.664 151.172H138.211V155.625H142.664V151.172Z" fill="black"/>
            <path d="M142.531 155.625V152.686C142.531 151.85 143.209 151.172 144.045 151.172H145.47C146.306 151.172 146.984 151.85 146.984 152.686V155.625H142.531Z" fill="black"/>
            <path d="M149.945 155.535V155.624H151.303V154.266H151.214C151.214 154.969 150.644 155.535 149.945 155.535Z" fill="black"/>
            <path d="M155.621 151.172H151.168V155.625H155.621V151.172Z" fill="black"/>
            <path d="M181.539 155.625H177.086V152.686C177.086 151.85 177.764 151.172 178.6 151.172H181.539V155.625Z" fill="black"/>
            <path d="M185.855 151.172H181.402V155.625H185.855V151.172Z" fill="black"/>
            <path d="M190.175 151.172H185.723V155.625H190.175V151.172Z" fill="black"/>
            <path d="M194.492 151.172H190.039V155.625H194.492V151.172Z" fill="black"/>
            <path d="M198.812 151.172H194.359V155.625H198.812V151.172Z" fill="black"/>
            <path d="M203.132 151.172H198.68V155.625H203.132V151.172Z" fill="black"/>
            <path d="M207.453 151.172H203V155.625H207.453V151.172Z" fill="black"/>
            <path d="M12.957 159.938V156.999C12.957 156.163 13.6348 155.485 14.4709 155.485H15.8958C16.7319 155.485 17.4097 156.163 17.4097 156.999V159.938H12.957Z" fill="black"/>
            <path d="M20.3711 159.855V159.944H21.7292V158.586H21.6401C21.6401 159.289 21.0702 159.855 20.3711 159.855Z" fill="black"/>
            <path d="M26.0508 159.938H21.5981V156.999C21.5981 156.163 22.2759 155.485 23.112 155.485H26.0508V159.938Z" fill="black"/>
            <path d="M30.3668 155.484H25.9141V159.937H30.3668V155.484Z" fill="black"/>
            <path d="M30.2344 155.484H34.6871V158.423C34.6871 159.259 34.0093 159.937 33.1732 159.937H30.2344V155.484Z" fill="black"/>
            <path d="M43.3242 155.484V158.423C43.3242 159.259 42.6464 159.937 41.8103 159.937H40.3854C39.5493 159.937 38.8715 159.259 38.8715 158.423V155.484H43.3242Z" fill="black"/>
            <path d="M46.2852 159.855V159.944H47.6432V158.586H47.5542C47.5542 159.289 46.9842 159.855 46.2852 159.855Z" fill="black"/>
            <path d="M51.9648 159.938H47.5122V156.999C47.5122 156.163 48.19 155.485 49.0261 155.485H51.9648V159.938Z" fill="black"/>
            <path d="M56.2808 155.484H51.8281V159.937H56.2808V155.484Z" fill="black"/>
            <path d="M60.6011 155.484H56.1484V159.937H60.6011V155.484Z" fill="black"/>
            <path d="M60.4688 159.938V156.999C60.4688 156.163 61.1466 155.485 61.9827 155.485H63.4075C64.2436 155.485 64.9214 156.163 64.9214 156.999V159.938H60.4688Z" fill="black"/>
            <path d="M86.5152 155.484H82.0625V159.937H86.5152V155.484Z" fill="black"/>
            <path d="M99.3398 156.758V158.668C99.3398 159.372 99.9098 159.937 100.613 159.937H102.524C103.231 159.937 103.797 159.367 103.797 158.664V156.758C103.793 156.05 103.223 155.484 102.519 155.484H100.613C99.9098 155.484 99.3398 156.054 99.3398 156.758Z" fill="black"/>
            <path d="M116.617 159.938V156.999C116.617 156.163 117.295 155.485 118.131 155.485H119.556C120.392 155.485 121.07 156.163 121.07 156.999V159.938H116.617Z" fill="black"/>
            <path d="M125.254 159.938V156.999C125.254 156.163 125.932 155.485 126.768 155.485H128.193C129.029 155.485 129.707 156.163 129.707 156.999V159.938H125.254Z" fill="black"/>
            <path d="M142.664 155.484V158.423C142.664 159.259 141.986 159.937 141.15 159.937H139.725C138.889 159.937 138.211 159.259 138.211 158.423V155.484H142.664Z" fill="black"/>
            <path d="M151.305 159.938H148.366C147.53 159.938 146.852 159.26 146.852 158.424V156.999C146.852 156.163 147.53 155.485 148.366 155.485H151.305V159.938Z" fill="black"/>
            <path d="M155.621 155.484H151.168V159.937H155.621V155.484Z" fill="black"/>
            <path d="M155.488 159.938V155.485H158.427C159.263 155.485 159.941 156.163 159.941 156.999V159.938H155.488Z" fill="black"/>
            <path d="M162.902 159.855V159.944H164.26V158.586H164.171C164.171 159.289 163.601 159.855 162.902 159.855Z" fill="black"/>
            <path d="M164.125 159.938V156.999C164.125 156.163 164.803 155.485 165.639 155.485H167.064C167.9 155.485 168.578 156.163 168.578 156.999V159.938H164.125Z" fill="black"/>
            <path d="M181.539 155.484V159.937H178.6C177.764 159.937 177.086 159.259 177.086 158.423V155.484H181.539Z" fill="black"/>
            <path d="M181.402 155.484H185.855V158.423C185.855 159.259 185.177 159.937 184.341 159.937H181.402V155.484Z" fill="black"/>
            <path d="M198.812 155.484H194.359V159.937H198.812V155.484Z" fill="black"/>
            <path d="M203.132 155.484H198.68V159.937H203.132V155.484Z" fill="black"/>
            <path d="M207.453 155.484H203V159.937H207.453V155.484Z" fill="black"/>
            <path d="M207.316 159.938V156.999C207.316 156.163 207.994 155.485 208.83 155.485H210.255C211.091 155.485 211.769 156.163 211.769 156.999V159.938H207.316Z" fill="black"/>
            <path d="M17.4102 159.805V164.257H14.4714C13.6353 164.257 12.9575 163.58 12.9575 162.743V159.805H17.4102Z" fill="black"/>
            <path d="M21.73 159.805H17.2773V164.257H21.73V159.805Z" fill="black"/>
            <path d="M26.0464 159.805H21.5938V164.257H26.0464V159.805Z" fill="black"/>
            <path d="M34.5547 164.258V161.319C34.5547 160.483 35.2325 159.805 36.0686 159.805H37.4935C38.3296 159.805 39.0074 160.483 39.0074 161.319V164.258H34.5547Z" fill="black"/>
            <path d="M47.6445 164.258H43.1918V161.319C43.1918 160.483 43.8696 159.805 44.7058 159.805H47.6445V164.258Z" fill="black"/>
            <path d="M51.9605 159.805H47.5078V164.257H51.9605V159.805Z" fill="black"/>
            <path d="M51.8281 159.805H56.2808V162.743C56.2808 163.58 55.603 164.257 54.7669 164.257H51.8281V159.805Z" fill="black"/>
            <path d="M82.1992 164.258H77.7465V161.319C77.7465 160.483 78.4243 159.805 79.2604 159.805H82.1992V164.258Z" fill="black"/>
            <path d="M86.5152 159.805H82.0625V164.257H86.5152V159.805Z" fill="black"/>
            <path d="M86.3828 164.258V159.805H89.3216C90.1577 159.805 90.8355 160.483 90.8355 161.319V164.258H86.3828Z" fill="black"/>
            <path d="M108.113 164.258H103.661V161.319C103.661 160.483 104.338 159.805 105.175 159.805H108.113V164.258Z" fill="black"/>
            <path d="M107.977 164.258V159.805H110.915C111.751 159.805 112.429 160.483 112.429 161.319V164.258H107.977Z" fill="black"/>
            <path d="M121.07 159.805V162.743C121.07 163.58 120.393 164.257 119.556 164.257H118.132C117.295 164.257 116.618 163.58 116.618 162.743V159.805H121.07Z" fill="black"/>
            <path d="M129.707 159.805V162.743C129.707 163.58 129.029 164.257 128.193 164.257H126.768C125.932 164.257 125.254 163.58 125.254 162.743V159.805H129.707Z" fill="black"/>
            <path d="M133.891 161.078V162.988C133.891 163.692 134.461 164.257 135.164 164.257H137.074C137.782 164.257 138.348 163.687 138.348 162.984V161.078C138.343 160.37 137.773 159.805 137.07 159.805H135.164C134.461 159.805 133.891 160.375 133.891 161.078Z" fill="black"/>
            <path d="M142.531 164.258V161.319C142.531 160.483 143.209 159.805 144.045 159.805H145.47C146.306 159.805 146.984 160.483 146.984 161.319V164.258H142.531Z" fill="black"/>
            <path d="M149.945 164.167V164.257H151.303V162.898H151.214C151.214 163.602 150.644 164.167 149.945 164.167Z" fill="black"/>
            <path d="M155.621 159.805H151.168V164.257H155.621V159.805Z" fill="black"/>
            <path d="M159.941 159.805H155.488V164.257H159.941V159.805Z" fill="black"/>
            <path d="M164.257 159.805H159.805V164.257H164.257V159.805Z" fill="black"/>
            <path d="M168.578 159.805H164.125V164.257H168.578V159.805Z" fill="black"/>
            <path d="M172.898 159.805H168.445V164.257H172.898V159.805Z" fill="black"/>
            <path d="M172.766 164.258V161.319C172.766 160.483 173.443 159.805 174.28 159.805H175.704C176.541 159.805 177.218 160.483 177.218 161.319V164.258H172.766Z" fill="black"/>
            <path d="M190.176 164.258H187.237C186.401 164.258 185.723 163.58 185.723 162.744V161.319C185.723 160.483 186.401 159.805 187.237 159.805H190.176V164.258Z" fill="black"/>
            <path d="M194.492 159.805H190.039V164.257H194.492V159.805Z" fill="black"/>
            <path d="M198.812 159.805H194.359V164.257H198.812V159.805Z" fill="black"/>
            <path d="M203.132 159.805H198.68V164.257H203.132V159.805Z" fill="black"/>
            <path d="M203 159.805H207.453V162.743C207.453 163.58 206.775 164.257 205.939 164.257H203V159.805Z" fill="black"/>
            <path d="M4.32031 168.578V165.639C4.32031 164.803 4.99812 164.125 5.83423 164.125H7.25909C8.0952 164.125 8.773 164.803 8.773 165.639V168.578H4.32031Z" fill="black"/>
            <path d="M26.0508 164.125V168.578H23.112C22.2759 168.578 21.5981 167.9 21.5981 167.064V164.125H26.0508Z" fill="black"/>
            <path d="M25.9141 168.578V165.639C25.9141 164.803 26.5919 164.125 27.428 164.125H28.8528C29.6889 164.125 30.3668 164.803 30.3668 165.639V168.578H25.9141Z" fill="black"/>
            <path d="M33.3281 168.488V168.577H34.6862V167.219H34.5971C34.5971 167.922 34.0272 168.488 33.3281 168.488Z" fill="black"/>
            <path d="M39.0074 164.125H34.5547V168.578H39.0074V164.125Z" fill="black"/>
            <path d="M41.9688 168.488V168.577H43.3268V167.219H43.2378C43.2378 167.922 42.6678 168.488 41.9688 168.488Z" fill="black"/>
            <path d="M47.6441 164.125H43.1914V168.578H47.6441V164.125Z" fill="black"/>
            <path d="M47.5078 164.125H51.9605V167.064C51.9605 167.9 51.2827 168.578 50.4466 168.578H47.5078V164.125Z" fill="black"/>
            <path d="M54.9219 168.488V168.577H56.2799V167.219H56.1909C56.1909 167.922 55.6209 168.488 54.9219 168.488Z" fill="black"/>
            <path d="M56.1484 168.578V165.639C56.1484 164.803 56.8262 164.125 57.6624 164.125H59.0872C59.9233 164.125 60.6011 164.803 60.6011 165.639V168.578H56.1484Z" fill="black"/>
            <path d="M64.7891 165.398V167.309C64.7891 168.012 65.359 168.578 66.0625 168.578H67.9727C68.6807 168.578 69.2462 168.008 69.2462 167.304V165.398C69.2418 164.69 68.6718 164.125 67.9683 164.125H66.0625C65.359 164.125 64.7891 164.695 64.7891 165.398Z" fill="black"/>
            <path d="M82.1992 164.125V167.064C82.1992 167.9 81.5214 168.578 80.6853 168.578H79.2604C78.4243 168.578 77.7465 167.9 77.7465 167.064V164.125H82.1992Z" fill="black"/>
            <path d="M90.8355 164.125H86.3828V168.578H90.8355V164.125Z" fill="black"/>
            <path d="M95.0195 165.398V167.309C95.0195 168.012 95.5895 168.578 96.293 168.578H98.2032C98.9112 168.578 99.4767 168.008 99.4767 167.304V165.398C99.4722 164.69 98.9023 164.125 98.1988 164.125H96.293C95.5895 164.125 95.0195 164.695 95.0195 165.398Z" fill="black"/>
            <path d="M102.434 168.488V168.577H103.792V167.219H103.703C103.703 167.922 103.133 168.488 102.434 168.488Z" fill="black"/>
            <path d="M108.109 164.125H103.656V168.578H108.109V164.125Z" fill="black"/>
            <path d="M112.429 164.125H107.977V168.578H112.429V164.125Z" fill="black"/>
            <path d="M112.297 168.578V164.125H115.236C116.072 164.125 116.75 164.803 116.75 165.639V168.578H112.297Z" fill="black"/>
            <path d="M129.574 165.398V167.309C129.574 168.012 130.144 168.578 130.848 168.578H132.758C133.466 168.578 134.031 168.008 134.031 167.304V165.398C134.027 164.69 133.457 164.125 132.753 164.125H130.848C130.144 164.125 129.574 164.695 129.574 165.398Z" fill="black"/>
            <path d="M146.984 164.125V168.578H144.046C143.209 168.578 142.532 167.9 142.532 167.064V164.125H146.984Z" fill="black"/>
            <path d="M151.304 164.125H146.852V168.578H151.304V164.125Z" fill="black"/>
            <path d="M155.621 164.125H151.168V168.578H155.621V164.125Z" fill="black"/>
            <path d="M159.941 164.125H155.488V168.578H159.941V164.125Z" fill="black"/>
            <path d="M164.257 164.125H159.805V168.578H164.257V164.125Z" fill="black"/>
            <path d="M168.578 164.125H164.125V168.578H168.578V164.125Z" fill="black"/>
            <path d="M177.086 165.398V167.309C177.086 168.012 177.656 168.578 178.359 168.578H180.27C180.978 168.578 181.543 168.008 181.543 167.304V165.398C181.539 164.69 180.969 164.125 180.265 164.125H178.359C177.656 164.125 177.086 164.695 177.086 165.398Z" fill="black"/>
            <path d="M198.812 164.125V167.064C198.812 167.9 198.135 168.578 197.299 168.578H195.874C195.038 168.578 194.36 167.9 194.36 167.064V164.125H198.812Z" fill="black"/>
            <path d="M3.09375 172.808V172.897H4.45182V171.539H4.36277C4.36277 172.243 3.79282 172.808 3.09375 172.808Z" fill="black"/>
            <path d="M8.773 168.445H4.32031V172.898H8.773V168.445Z" fill="black"/>
            <path d="M13.0933 168.445H8.64062V172.898H13.0933V168.445Z" fill="black"/>
            <path d="M12.957 172.898V169.96C12.957 169.124 13.6348 168.446 14.4709 168.446H15.8958C16.7319 168.446 17.4097 169.124 17.4097 169.96V172.898H12.957Z" fill="black"/>
            <path d="M34.6875 172.898H31.7487C30.9126 172.898 30.2348 172.221 30.2348 171.385V169.96C30.2348 169.124 30.9126 168.446 31.7487 168.446H34.6875V172.898Z" fill="black"/>
            <path d="M39.0074 168.445H34.5547V172.898H39.0074V168.445Z" fill="black"/>
            <path d="M43.3238 168.445H38.8711V172.898H43.3238V168.445Z" fill="black"/>
            <path d="M47.6441 168.445H43.1914V172.898H47.6441V168.445Z" fill="black"/>
            <path d="M50.6055 172.808V172.897H51.9635V171.539H51.8745C51.8745 172.243 51.3045 172.808 50.6055 172.808Z" fill="black"/>
            <path d="M56.2852 172.898H51.8325V169.96C51.8325 169.124 52.5103 168.446 53.3464 168.446H56.2852V172.898Z" fill="black"/>
            <path d="M60.6011 168.445H56.1484V172.898H60.6011V168.445Z" fill="black"/>
            <path d="M60.4688 172.898V169.96C60.4688 169.124 61.1466 168.446 61.9827 168.446H63.4075C64.2436 168.446 64.9214 169.124 64.9214 169.96V172.898H60.4688Z" fill="black"/>
            <path d="M73.5586 172.898H69.1059V169.96C69.1059 169.124 69.7837 168.446 70.6198 168.446H73.5586V172.898Z" fill="black"/>
            <path d="M73.4258 172.898V169.96C73.4258 169.124 74.1036 168.446 74.9397 168.446H76.3646C77.2007 168.446 77.8785 169.124 77.8785 169.96V172.898H73.4258Z" fill="black"/>
            <path d="M90.8355 168.445H86.3828V172.898H90.8355V168.445Z" fill="black"/>
            <path d="M90.7031 172.898V169.96C90.7031 169.124 91.3809 168.446 92.217 168.446H93.6419C94.478 168.446 95.1558 169.124 95.1558 169.96V172.898H90.7031Z" fill="black"/>
            <path d="M98.1172 172.808V172.897H99.4753V171.539H99.3862C99.3862 172.243 98.8163 172.808 98.1172 172.808Z" fill="black"/>
            <path d="M103.793 172.898H99.3403V169.96C99.3403 169.124 100.018 168.446 100.854 168.446H103.793V172.898Z" fill="black"/>
            <path d="M108.109 168.445H103.656V172.898H108.109V168.445Z" fill="black"/>
            <path d="M112.429 168.445H107.977V172.898H112.429V168.445Z" fill="black"/>
            <path d="M116.75 168.445H112.297V172.898H116.75V168.445Z" fill="black"/>
            <path d="M124.031 172.808V172.897H125.389V171.539H125.3C125.3 172.243 124.73 172.808 124.031 172.808Z" fill="black"/>
            <path d="M125.254 172.898V169.96C125.254 169.124 125.932 168.446 126.768 168.446H128.193C129.029 168.446 129.707 169.124 129.707 169.96V172.898H125.254Z" fill="black"/>
            <path d="M138.348 172.898H135.409C134.573 172.898 133.895 172.221 133.895 171.385V169.96C133.895 169.124 134.573 168.446 135.409 168.446H138.348V172.898Z" fill="black"/>
            <path d="M138.211 172.898V168.446H141.15C141.986 168.446 142.664 169.124 142.664 169.96V172.898H138.211Z" fill="black"/>
            <path d="M159.941 168.445H155.488V172.898H159.941V168.445Z" fill="black"/>
            <path d="M164.257 168.445H159.805V172.898H164.257V168.445Z" fill="black"/>
            <path d="M164.125 168.445H168.578V171.384C168.578 172.22 167.9 172.898 167.064 172.898H164.125V168.445Z" fill="black"/>
            <path d="M172.766 172.898V169.96C172.766 169.124 173.443 168.446 174.28 168.446H175.704C176.541 168.446 177.218 169.124 177.218 169.96V172.898H172.766Z" fill="black"/>
            <path d="M180.18 172.808V172.897H181.538V171.539H181.449C181.449 172.243 180.879 172.808 180.18 172.808Z" fill="black"/>
            <path d="M185.855 172.898H181.403V169.96C181.403 169.124 182.081 168.446 182.917 168.446H185.855V172.898Z" fill="black"/>
            <path d="M185.723 172.898V168.446H188.661C189.498 168.446 190.175 169.124 190.175 169.96V172.898H185.723Z" fill="black"/>
            <path d="M4.45312 177.219H1.51435C0.678238 177.219 0.000434875 176.541 0.000434875 175.705V174.28C0.000434875 173.444 0.678238 172.766 1.51435 172.766H4.45313L4.45312 177.219Z" fill="black"/>
            <path d="M8.773 172.766H4.32031V177.218H8.773V172.766Z" fill="black"/>
            <path d="M8.64062 172.766H13.0933V175.704C13.0933 176.541 12.4155 177.218 11.5794 177.218H8.64062V172.766Z" fill="black"/>
            <path d="M25.9141 174.039V175.949C25.9141 176.653 26.484 177.218 27.1875 177.218H29.0977C29.8057 177.218 30.3712 176.648 30.3712 175.945V174.039C30.3668 173.331 29.7968 172.766 29.0933 172.766H27.1875C26.484 172.766 25.9141 173.336 25.9141 174.039Z" fill="black"/>
            <path d="M39.0074 172.766H34.5547V177.218H39.0074V172.766Z" fill="black"/>
            <path d="M47.6445 172.766V177.218H44.7058C43.8696 177.218 43.1918 176.541 43.1918 175.704V172.766H47.6445Z" fill="black"/>
            <path d="M51.9605 172.766H47.5078V177.218H51.9605V172.766Z" fill="black"/>
            <path d="M56.2808 172.766H51.8281V177.218H56.2808V172.766Z" fill="black"/>
            <path d="M73.5586 172.766V175.704C73.5586 176.541 72.8808 177.218 72.0447 177.218H70.6198C69.7837 177.218 69.1059 176.541 69.1059 175.704V172.766H73.5586Z" fill="black"/>
            <path d="M90.8355 172.766H86.3828V177.218H90.8355V172.766Z" fill="black"/>
            <path d="M99.4727 177.219H95.02V174.28C95.02 173.444 95.6978 172.766 96.5339 172.766H99.4727V177.219Z" fill="black"/>
            <path d="M103.793 172.766H99.3398V177.218H103.793V172.766Z" fill="black"/>
            <path d="M108.109 172.766H103.656V177.218H108.109V172.766Z" fill="black"/>
            <path d="M112.429 172.766H107.977V177.218H112.429V172.766Z" fill="black"/>
            <path d="M116.75 172.766H112.297V177.218H116.75V172.766Z" fill="black"/>
            <path d="M121.07 172.766H116.617V177.218H121.07V172.766Z" fill="black"/>
            <path d="M125.39 172.766H120.938V177.218H125.39V172.766Z" fill="black"/>
            <path d="M125.254 172.766H129.707V175.704C129.707 176.541 129.029 177.218 128.193 177.218H125.254V172.766Z" fill="black"/>
            <path d="M142.664 172.766H138.211V177.218H142.664V172.766Z" fill="black"/>
            <path d="M145.625 177.128V177.217H146.983V175.859H146.894C146.894 176.563 146.324 177.128 145.625 177.128Z" fill="black"/>
            <path d="M146.852 177.219V174.28C146.852 173.444 147.529 172.766 148.365 172.766H149.79C150.626 172.766 151.304 173.444 151.304 174.28V177.219H146.852Z" fill="black"/>
            <path d="M154.266 177.128V177.217H155.624V175.859H155.535C155.535 176.563 154.965 177.128 154.266 177.128Z" fill="black"/>
            <path d="M159.941 172.766H155.488V177.218H159.941V172.766Z" fill="black"/>
            <path d="M159.805 172.766H164.257V175.704C164.257 176.541 163.58 177.218 162.743 177.218H159.805V172.766Z" fill="black"/>
            <path d="M171.539 177.128V177.217H172.897V175.859H172.808C172.808 176.563 172.238 177.128 171.539 177.128Z" fill="black"/>
            <path d="M177.218 172.766H172.766V177.218H177.218V172.766Z" fill="black"/>
            <path d="M181.539 172.766H177.086V177.218H181.539V172.766Z" fill="black"/>
            <path d="M185.855 172.766H181.402V177.218H185.855V172.766Z" fill="black"/>
            <path d="M190.175 172.766H185.723V177.218H190.175V172.766Z" fill="black"/>
            <path d="M190.039 177.219V172.766H192.978C193.814 172.766 194.492 173.444 194.492 174.28V177.219H190.039Z" fill="black"/>
            <path d="M207.316 174.039V175.949C207.316 176.653 207.886 177.218 208.59 177.218H210.5C211.208 177.218 211.774 176.648 211.774 175.945V174.039C211.769 173.331 211.199 172.766 210.496 172.766H208.59C207.886 172.766 207.316 173.336 207.316 174.039Z" fill="black"/>
            <path d="M39.0074 177.086H34.5547V181.539H39.0074V177.086Z" fill="black"/>
            <path d="M38.8711 181.539V178.6C38.8711 177.764 39.5489 177.086 40.385 177.086H41.8099C42.646 177.086 43.3238 177.764 43.3238 178.6V181.539H38.8711Z" fill="black"/>
            <path d="M56.2808 177.086H51.8281V181.539H56.2808V177.086Z" fill="black"/>
            <path d="M64.7891 178.359V180.27C64.7891 180.973 65.359 181.539 66.0625 181.539H67.9727C68.6807 181.539 69.2462 180.969 69.2462 180.265V178.359C69.2418 177.651 68.6718 177.086 67.9683 177.086H66.0625C65.359 177.086 64.7891 177.656 64.7891 178.359Z" fill="black"/>
            <path d="M77.7422 178.359V180.27C77.7422 180.973 78.3121 181.539 79.0157 181.539H80.9259C81.6338 181.539 82.1993 180.969 82.1993 180.265V178.359C82.1949 177.651 81.6249 177.086 80.9214 177.086H79.0157C78.3121 177.086 77.7422 177.656 77.7422 178.359Z" fill="black"/>
            <path d="M90.8355 177.086H86.3828V181.539H90.8355V177.086Z" fill="black"/>
            <path d="M99.4722 177.086H95.0195V181.539H99.4722V177.086Z" fill="black"/>
            <path d="M116.75 177.086H112.297V181.539H116.75V177.086Z" fill="black"/>
            <path d="M121.07 177.086H116.617V181.539H121.07V177.086Z" fill="black"/>
            <path d="M120.938 177.086H125.39V180.025C125.39 180.861 124.712 181.539 123.876 181.539H120.938V177.086Z" fill="black"/>
            <path d="M129.574 178.359V180.27C129.574 180.973 130.144 181.539 130.848 181.539H132.758C133.466 181.539 134.031 180.969 134.031 180.265V178.359C134.027 177.651 133.457 177.086 132.753 177.086H130.848C130.144 177.086 129.574 177.656 129.574 178.359Z" fill="black"/>
            <path d="M142.664 177.086V181.539H139.725C138.889 181.539 138.211 180.861 138.211 180.025V177.086H142.664Z" fill="black"/>
            <path d="M146.984 177.086H142.531V181.539H146.984V177.086Z" fill="black"/>
            <path d="M151.304 177.086H146.852V181.539H151.304V177.086Z" fill="black"/>
            <path d="M155.621 177.086H151.168V181.539H155.621V177.086Z" fill="black"/>
            <path d="M155.488 177.086H159.941V180.025C159.941 180.861 159.263 181.539 158.427 181.539H155.488V177.086Z" fill="black"/>
            <path d="M172.898 181.539H169.96C169.124 181.539 168.446 180.861 168.446 180.025V178.6C168.446 177.764 169.124 177.086 169.96 177.086H172.898V181.539Z" fill="black"/>
            <path d="M177.218 177.086H172.766V181.539H177.218V177.086Z" fill="black"/>
            <path d="M194.492 177.086H190.039V181.539H194.492V177.086Z" fill="black"/>
            <path d="M194.359 181.539V177.086H197.298C198.134 177.086 198.812 177.764 198.812 178.6V181.539H194.359Z" fill="black"/>
            <path d="M39.0074 181.406H34.5547V185.859H39.0074V181.406Z" fill="black"/>
            <path d="M56.2852 181.406V185.859H53.3464C52.5103 185.859 51.8325 185.181 51.8325 184.345V181.406H56.2852Z" fill="black"/>
            <path d="M60.6011 181.406H56.1484V185.859H60.6011V181.406Z" fill="black"/>
            <path d="M60.4688 185.859V181.407H63.4075C64.2436 181.407 64.9214 182.084 64.9214 182.921V185.859H60.4688Z" fill="black"/>
            <path d="M73.4258 182.68V184.59C73.4258 185.293 73.9957 185.859 74.6993 185.859H76.6095C77.3174 185.859 77.8829 185.289 77.8829 184.585V182.68C77.8785 181.972 77.3085 181.406 76.605 181.406H74.6993C73.9957 181.406 73.4258 181.976 73.4258 182.68Z" fill="black"/>
            <path d="M85.1562 185.769V185.858H86.5143V184.5H86.4253C86.4253 185.204 85.8553 185.769 85.1562 185.769Z" fill="black"/>
            <path d="M90.8355 181.406H86.3828V185.859H90.8355V181.406Z" fill="black"/>
            <path d="M99.4722 181.406H95.0195V185.859H99.4722V181.406Z" fill="black"/>
            <path d="M103.656 182.68V184.59C103.656 185.293 104.226 185.859 104.93 185.859H106.84C107.548 185.859 108.113 185.289 108.113 184.585V182.68C108.109 181.972 107.539 181.406 106.835 181.406H104.93C104.226 181.406 103.656 181.976 103.656 182.68Z" fill="black"/>
            <path d="M116.75 181.406H112.297V185.859H116.75V181.406Z" fill="black"/>
            <path d="M116.617 181.406H121.07V184.345C121.07 185.181 120.392 185.859 119.556 185.859H116.617V181.406Z" fill="black"/>
            <path d="M146.984 181.406H142.531V185.859H146.984V181.406Z" fill="black"/>
            <path d="M151.304 181.406H146.852V185.859H151.304V181.406Z" fill="black"/>
            <path d="M151.168 181.406H155.621V184.345C155.621 185.181 154.943 185.859 154.107 185.859H151.168V181.406Z" fill="black"/>
            <path d="M177.218 181.406H172.766V185.859H177.218V181.406Z" fill="black"/>
            <path d="M181.402 182.68V184.59C181.402 185.293 181.972 185.859 182.676 185.859H184.586C185.294 185.859 185.859 185.289 185.859 184.585V182.68C185.855 181.972 185.285 181.406 184.582 181.406H182.676C181.972 181.406 181.402 181.976 181.402 182.68Z" fill="black"/>
            <path d="M194.492 181.406H190.039V185.859H194.492V181.406Z" fill="black"/>
            <path d="M198.812 181.406H194.359V185.859H198.812V181.406Z" fill="black"/>
            <path d="M198.68 185.859V181.407H201.618C202.455 181.407 203.132 182.084 203.132 182.921V185.859H198.68Z" fill="black"/>
            <path d="M39.0074 185.719H34.5547V190.171H39.0074V185.719Z" fill="black"/>
            <path d="M43.1914 186.992V188.902C43.1914 189.606 43.7614 190.171 44.4649 190.171H46.3751C47.0831 190.171 47.6485 189.601 47.6485 188.898V186.992C47.6441 186.284 47.0742 185.719 46.3706 185.719H44.4649C43.7614 185.719 43.1914 186.289 43.1914 186.992Z" fill="black"/>
            <path d="M64.9219 185.719V190.171H61.9831C61.147 190.171 60.4692 189.494 60.4692 188.658V185.719H64.9219Z" fill="black"/>
            <path d="M69.2418 185.719H64.7891V190.171H69.2418V185.719Z" fill="black"/>
            <path d="M69.1055 190.172V185.719H72.0442C72.8804 185.719 73.5582 186.397 73.5582 187.233V190.172H69.1055Z" fill="black"/>
            <path d="M76.5195 190.082V190.171H77.8776V188.812H77.7885C77.7885 189.516 77.2186 190.082 76.5195 190.082Z" fill="black"/>
            <path d="M82.1992 190.172H77.7465V187.233C77.7465 186.397 78.4243 185.719 79.2604 185.719H82.1992V190.172Z" fill="black"/>
            <path d="M86.5152 185.719H82.0625V190.171H86.5152V185.719Z" fill="black"/>
            <path d="M90.8355 185.719H86.3828V190.171H90.8355V185.719Z" fill="black"/>
            <path d="M99.4722 185.719H95.0195V190.171H99.4722V185.719Z" fill="black"/>
            <path d="M111.07 190.082V190.171H112.428V188.812H112.339C112.339 189.516 111.769 190.082 111.07 190.082Z" fill="black"/>
            <path d="M116.75 185.719H112.297V190.171H116.75V185.719Z" fill="black"/>
            <path d="M125.391 190.172H122.452C121.616 190.172 120.938 189.494 120.938 188.658V187.233C120.938 186.397 121.616 185.719 122.452 185.719H125.391V190.172Z" fill="black"/>
            <path d="M129.707 185.719H125.254V190.171H129.707V185.719Z" fill="black"/>
            <path d="M129.574 190.172V187.233C129.574 186.397 130.252 185.719 131.088 185.719H132.513C133.349 185.719 134.027 186.397 134.027 187.233V190.172H129.574Z" fill="black"/>
            <path d="M142.664 190.172H138.211V187.233C138.211 186.397 138.889 185.719 139.725 185.719H142.664V190.172Z" fill="black"/>
            <path d="M146.984 185.719H142.531V190.171H146.984V185.719Z" fill="black"/>
            <path d="M151.304 185.719H146.852V190.171H151.304V185.719Z" fill="black"/>
            <path d="M154.266 190.082V190.171H155.624V188.812H155.535C155.535 189.516 154.965 190.082 154.266 190.082Z" fill="black"/>
            <path d="M159.941 190.172H155.489V187.233C155.489 186.397 156.167 185.719 157.003 185.719H159.941V190.172Z" fill="black"/>
            <path d="M164.257 185.719H159.805V190.171H164.257V185.719Z" fill="black"/>
            <path d="M168.578 185.719H164.125V190.171H168.578V185.719Z" fill="black"/>
            <path d="M172.898 185.719H168.445V190.171H172.898V185.719Z" fill="black"/>
            <path d="M177.218 185.719H172.766V190.171H177.218V185.719Z" fill="black"/>
            <path d="M188.816 190.082V190.171H190.174V188.812H190.085C190.085 189.516 189.515 190.082 188.816 190.082Z" fill="black"/>
            <path d="M194.492 185.719H190.039V190.171H194.492V185.719Z" fill="black"/>
            <path d="M203.132 185.719H198.68V190.171H203.132V185.719Z" fill="black"/>
            <path d="M207.453 185.719H203V190.171H207.453V185.719Z" fill="black"/>
            <path d="M207.316 190.172V187.233C207.316 186.397 207.994 185.719 208.83 185.719H210.255C211.091 185.719 211.769 186.397 211.769 187.233V190.172H207.316Z" fill="black"/>
            <path d="M39.0078 190.039V194.492H36.069C35.2329 194.492 34.5551 193.814 34.5551 192.978V190.039H39.0078Z" fill="black"/>
            <path d="M38.8711 194.492V191.553C38.8711 190.717 39.5489 190.039 40.385 190.039H41.8099C42.646 190.039 43.3238 190.717 43.3238 191.553V194.492H38.8711Z" fill="black"/>
            <path d="M50.6055 194.402V194.491H51.9635V193.133H51.8745C51.8745 193.836 51.3045 194.402 50.6055 194.402Z" fill="black"/>
            <path d="M56.2852 194.492H51.8325V191.553C51.8325 190.717 52.5103 190.039 53.3464 190.039H56.2852V194.492Z" fill="black"/>
            <path d="M56.1484 194.492V191.553C56.1484 190.717 56.8262 190.039 57.6624 190.039H59.0872C59.9233 190.039 60.6011 190.717 60.6011 191.553V194.492H56.1484Z" fill="black"/>
            <path d="M69.2422 190.039V194.492H66.3034C65.4673 194.492 64.7895 193.814 64.7895 192.978V190.039H69.2422Z" fill="black"/>
            <path d="M73.5582 190.039H69.1055V194.492H73.5582V190.039Z" fill="black"/>
            <path d="M77.8785 190.039H73.4258V194.492H77.8785V190.039Z" fill="black"/>
            <path d="M82.1949 190.039H77.7422V194.492H82.1949V190.039Z" fill="black"/>
            <path d="M86.5152 190.039H82.0625V194.492H86.5152V190.039Z" fill="black"/>
            <path d="M86.3828 190.039H90.8355V192.978C90.8355 193.814 90.1577 194.492 89.3216 194.492H86.3828V190.039Z" fill="black"/>
            <path d="M99.4727 190.039V194.492H96.5339C95.6978 194.492 95.02 193.814 95.02 192.978V190.039H99.4727Z" fill="black"/>
            <path d="M103.793 190.039H99.3398V194.492H103.793V190.039Z" fill="black"/>
            <path d="M108.109 190.039H103.656V194.492H108.109V190.039Z" fill="black"/>
            <path d="M112.429 190.039H107.977V194.492H112.429V190.039Z" fill="black"/>
            <path d="M112.297 190.039H116.75V192.978C116.75 193.814 116.072 194.492 115.236 194.492H112.297V190.039Z" fill="black"/>
            <path d="M142.664 190.039H138.211V194.492H142.664V190.039Z" fill="black"/>
            <path d="M146.984 190.039H142.531V194.492H146.984V190.039Z" fill="black"/>
            <path d="M151.304 190.039H146.852V194.492H151.304V190.039Z" fill="black"/>
            <path d="M155.621 190.039H151.168V194.492H155.621V190.039Z" fill="black"/>
            <path d="M159.941 190.039H155.488V194.492H159.941V190.039Z" fill="black"/>
            <path d="M177.219 190.039V194.492H174.28C173.444 194.492 172.766 193.814 172.766 192.978V190.039H177.219Z" fill="black"/>
            <path d="M181.539 190.039H177.086V194.492H181.539V190.039Z" fill="black"/>
            <path d="M185.855 190.039H181.402V194.492H185.855V190.039Z" fill="black"/>
            <path d="M190.175 190.039H185.723V194.492H190.175V190.039Z" fill="black"/>
            <path d="M194.492 190.039H190.039V194.492H194.492V190.039Z" fill="black"/>
            <path d="M198.812 190.039H194.359V194.492H198.812V190.039Z" fill="black"/>
            <path d="M203.132 190.039H198.68V194.492H203.132V190.039Z" fill="black"/>
            <path d="M203 190.039H207.453V192.978C207.453 193.814 206.775 194.492 205.939 194.492H203V190.039Z" fill="black"/>
            <path d="M41.9688 198.722V198.811H43.3268V197.453H43.2378C43.2378 198.157 42.6678 198.722 41.9688 198.722Z" fill="black"/>
            <path d="M47.6445 198.812H43.1918V195.874C43.1918 195.038 43.8696 194.36 44.7058 194.36H47.6445V198.812Z" fill="black"/>
            <path d="M51.9605 194.359H47.5078V198.812H51.9605V194.359Z" fill="black"/>
            <path d="M56.2808 194.359H51.8281V198.812H56.2808V194.359Z" fill="black"/>
            <path d="M73.5582 194.359H69.1055V198.812H73.5582V194.359Z" fill="black"/>
            <path d="M86.5156 194.359V197.298C86.5156 198.134 85.8378 198.812 85.0017 198.812H83.5768C82.7407 198.812 82.0629 198.134 82.0629 197.298V194.359H86.5156Z" fill="black"/>
            <path d="M103.793 194.359V198.812H100.854C100.018 198.812 99.3403 198.134 99.3403 197.298V194.359H103.793Z" fill="black"/>
            <path d="M108.109 194.359H103.656V198.812H108.109V194.359Z" fill="black"/>
            <path d="M116.617 195.633V197.543C116.617 198.247 117.187 198.812 117.891 198.812H119.801C120.509 198.812 121.074 198.242 121.074 197.539V195.633C121.07 194.925 120.5 194.359 119.796 194.359H117.891C117.187 194.359 116.617 194.929 116.617 195.633Z" fill="black"/>
            <path d="M125.254 195.633V197.543C125.254 198.247 125.824 198.812 126.527 198.812H128.438C129.146 198.812 129.711 198.242 129.711 197.539V195.633C129.707 194.925 129.137 194.359 128.433 194.359H126.527C125.824 194.359 125.254 194.929 125.254 195.633Z" fill="black"/>
            <path d="M142.664 194.359H138.211V198.812H142.664V194.359Z" fill="black"/>
            <path d="M159.941 194.359V197.298C159.941 198.134 159.264 198.812 158.427 198.812H157.003C156.167 198.812 155.489 198.134 155.489 197.298V194.359H159.941Z" fill="black"/>
            <path d="M168.582 198.812H164.129V195.874C164.129 195.038 164.807 194.36 165.643 194.36H168.582V198.812Z" fill="black"/>
            <path d="M168.445 198.812V194.36H171.384C172.22 194.36 172.898 195.038 172.898 195.874V198.812H168.445Z" fill="black"/>
            <path d="M181.539 194.359V198.812H178.6C177.764 198.812 177.086 198.134 177.086 197.298V194.359H181.539Z" fill="black"/>
            <path d="M181.402 194.359H185.855V197.298C185.855 198.134 185.177 198.812 184.341 198.812H181.402V194.359Z" fill="black"/>
            <path d="M207.316 195.633V197.543C207.316 198.247 207.886 198.812 208.59 198.812H210.5C211.208 198.812 211.774 198.242 211.774 197.539V195.633C211.769 194.925 211.199 194.359 210.496 194.359H208.59C207.886 194.359 207.316 194.929 207.316 195.633Z" fill="black"/>
            <path d="M43.3242 203.133H40.3854C39.5493 203.133 38.8715 202.455 38.8715 201.619V200.194C38.8715 199.358 39.5493 198.68 40.3854 198.68H43.3242V203.133Z" fill="black"/>
            <path d="M47.6441 198.68H43.1914V203.132H47.6441V198.68Z" fill="black"/>
            <path d="M56.2852 198.68V203.132H53.3464C52.5103 203.132 51.8325 202.455 51.8325 201.618V198.68H56.2852Z" fill="black"/>
            <path d="M60.6011 198.68H56.1484V203.132H60.6011V198.68Z" fill="black"/>
            <path d="M64.9214 198.68H60.4688V203.132H64.9214V198.68Z" fill="black"/>
            <path d="M69.2418 198.68H64.7891V203.132H69.2418V198.68Z" fill="black"/>
            <path d="M73.5582 198.68H69.1055V203.132H73.5582V198.68Z" fill="black"/>
            <path d="M73.4258 203.133V200.194C73.4258 199.358 74.1036 198.68 74.9397 198.68H76.3646C77.2007 198.68 77.8785 199.358 77.8785 200.194V203.133H73.4258Z" fill="black"/>
            <path d="M86.3828 199.953V201.863C86.3828 202.567 86.9528 203.132 87.6563 203.132H89.5665C90.2745 203.132 90.84 202.562 90.84 201.859V199.953C90.8355 199.245 90.2656 198.68 89.562 198.68H87.6563C86.9528 198.68 86.3828 199.25 86.3828 199.953Z" fill="black"/>
            <path d="M95.0195 199.953V201.863C95.0195 202.567 95.5895 203.132 96.293 203.132H98.2032C98.9112 203.132 99.4767 202.562 99.4767 201.859V199.953C99.4722 199.245 98.9023 198.68 98.1988 198.68H96.293C95.5895 198.68 95.0195 199.25 95.0195 199.953Z" fill="black"/>
            <path d="M108.113 198.68V203.132H105.175C104.338 203.132 103.661 202.455 103.661 201.618V198.68H108.113Z" fill="black"/>
            <path d="M107.977 203.133V198.68H110.915C111.751 198.68 112.429 199.358 112.429 200.194V203.133H107.977Z" fill="black"/>
            <path d="M142.664 198.68H138.211V203.132H142.664V198.68Z" fill="black"/>
            <path d="M142.531 203.133V200.194C142.531 199.358 143.209 198.68 144.045 198.68H145.47C146.306 198.68 146.984 199.358 146.984 200.194V203.133H142.531Z" fill="black"/>
            <path d="M151.168 203.133V200.194C151.168 199.358 151.846 198.68 152.682 198.68H154.107C154.943 198.68 155.621 199.358 155.621 200.194V203.133H151.168Z" fill="black"/>
            <path d="M162.902 203.042V203.132H164.26V201.773H164.171C164.171 202.477 163.601 203.042 162.902 203.042Z" fill="black"/>
            <path d="M168.578 198.68H164.125V203.132H168.578V198.68Z" fill="black"/>
            <path d="M172.898 198.68H168.445V203.132H172.898V198.68Z" fill="black"/>
            <path d="M172.766 203.133V198.68H175.704C176.541 198.68 177.218 199.358 177.218 200.194V203.133H172.766Z" fill="black"/>
            <path d="M185.723 203.133V200.194C185.723 199.358 186.4 198.68 187.237 198.68H188.661C189.498 198.68 190.175 199.358 190.175 200.194V203.133H185.723Z" fill="black"/>
            <path d="M198.812 203.133H195.874C195.038 203.133 194.36 202.455 194.36 201.619V200.194C194.36 199.358 195.038 198.68 195.874 198.68H198.812V203.133Z" fill="black"/>
            <path d="M198.68 203.133V198.68H201.618C202.455 198.68 203.132 199.358 203.132 200.194V203.133H198.68Z" fill="black"/>
            <path d="M47.6441 203H43.1914V207.453H47.6441V203Z" fill="black"/>
            <path d="M47.5078 207.453V204.514C47.5078 203.678 48.1856 203 49.0217 203H50.4466C51.2827 203 51.9605 203.678 51.9605 204.514V207.453H47.5078Z" fill="black"/>
            <path d="M60.6011 203H56.1484V207.453H60.6011V203Z" fill="black"/>
            <path d="M64.9214 203H60.4688V207.453H64.9214V203Z" fill="black"/>
            <path d="M64.7891 203H69.2418V205.939C69.2418 206.775 68.5639 207.453 67.7278 207.453H64.7891V203Z" fill="black"/>
            <path d="M82.1992 207.453H79.2604C78.4243 207.453 77.7465 206.775 77.7465 205.939V204.514C77.7465 203.678 78.4243 203 79.2604 203H82.1992V207.453Z" fill="black"/>
            <path d="M82.0625 207.453V203H85.0013C85.8374 203 86.5152 203.678 86.5152 204.514V207.453H82.0625Z" fill="black"/>
            <path d="M112.429 203H107.977V207.453H112.429V203Z" fill="black"/>
            <path d="M112.297 207.453V204.514C112.297 203.678 112.975 203 113.811 203H115.236C116.072 203 116.75 203.678 116.75 204.514V207.453H112.297Z" fill="black"/>
            <path d="M125.391 207.453H122.452C121.616 207.453 120.938 206.775 120.938 205.939V204.514C120.938 203.678 121.616 203 122.452 203H125.391V207.453Z" fill="black"/>
            <path d="M129.707 203H125.254V207.453H129.707V203Z" fill="black"/>
            <path d="M129.574 207.453V204.514C129.574 203.678 130.252 203 131.088 203H132.513C133.349 203 134.027 203.678 134.027 204.514V207.453H129.574Z" fill="black"/>
            <path d="M142.664 203H138.211V207.453H142.664V203Z" fill="black"/>
            <path d="M155.621 203V207.453H152.682C151.846 207.453 151.168 206.775 151.168 205.939V203H155.621Z" fill="black"/>
            <path d="M159.941 203H155.488V207.453H159.941V203Z" fill="black"/>
            <path d="M164.257 203H159.805V207.453H164.257V203Z" fill="black"/>
            <path d="M168.578 203H164.125V207.453H168.578V203Z" fill="black"/>
            <path d="M177.219 203V205.939C177.219 206.775 176.541 207.453 175.705 207.453H174.28C173.444 207.453 172.766 206.775 172.766 205.939V203H177.219Z" fill="black"/>
            <path d="M190.176 203V207.453H187.237C186.401 207.453 185.723 206.775 185.723 205.939V203H190.176Z" fill="black"/>
            <path d="M190.039 207.453V204.514C190.039 203.678 190.717 203 191.553 203H192.978C193.814 203 194.492 203.678 194.492 204.514V207.453H190.039Z" fill="black"/>
            <path d="M203.132 203H198.68V207.453H203.132V203Z" fill="black"/>
            <path d="M203 207.453V203H205.939C206.775 203 207.453 203.678 207.453 204.514V207.453H203Z" fill="black"/>
            <path d="M43.3242 211.773H40.3854C39.5493 211.773 38.8715 211.096 38.8715 210.26V208.835C38.8715 207.999 39.5493 207.321 40.3854 207.321H43.3242V211.773Z" fill="black"/>
            <path d="M43.1914 207.32H47.6441V210.259C47.6441 211.095 46.9663 211.773 46.1302 211.773H43.1914V207.32Z" fill="black"/>
            <path d="M60.6016 207.32V211.773H57.6628C56.8267 211.773 56.1489 211.095 56.1489 210.259V207.32H60.6016Z" fill="black"/>
            <path d="M60.4688 207.32H64.9214V210.259C64.9214 211.095 64.2436 211.773 63.4075 211.773H60.4688V207.32Z" fill="black"/>
            <path d="M73.5586 211.773H70.6198C69.7837 211.773 69.1059 211.096 69.1059 210.26V208.835C69.1059 207.999 69.7837 207.321 70.6198 207.321H73.5586V211.773Z" fill="black"/>
            <path d="M73.4258 211.773V208.835C73.4258 207.999 74.1036 207.321 74.9397 207.321H76.3646C77.2007 207.321 77.8785 207.999 77.8785 208.835V211.773H73.4258Z" fill="black"/>
            <path d="M86.5156 207.32V211.773H83.5768C82.7407 211.773 82.0629 211.095 82.0629 210.259V207.32H86.5156Z" fill="black"/>
            <path d="M90.8355 207.32H86.3828V211.773H90.8355V207.32Z" fill="black"/>
            <path d="M95.1558 207.32H90.7031V211.773H95.1558V207.32Z" fill="black"/>
            <path d="M95.0195 211.773V208.835C95.0195 207.999 95.6973 207.321 96.5334 207.321H97.9583C98.7944 207.321 99.4722 207.999 99.4722 208.835V211.773H95.0195Z" fill="black"/>
            <path d="M112.434 207.32V210.259C112.434 211.095 111.756 211.773 110.92 211.773H109.495C108.659 211.773 107.981 211.095 107.981 210.259V207.32H112.434Z" fill="black"/>
            <path d="M116.617 208.594V210.504C116.617 211.208 117.187 211.773 117.891 211.773H119.801C120.509 211.773 121.074 211.203 121.074 210.5V208.594C121.07 207.886 120.5 207.32 119.796 207.32H117.891C117.187 207.32 116.617 207.89 116.617 208.594Z" fill="black"/>
            <path d="M142.664 207.32V211.773H139.725C138.889 211.773 138.211 211.095 138.211 210.259V207.32H142.664Z" fill="black"/>
            <path d="M146.984 207.32H142.531V211.773H146.984V207.32Z" fill="black"/>
            <path d="M146.852 211.773V208.835C146.852 207.999 147.529 207.321 148.365 207.321H149.79C150.626 207.321 151.304 207.999 151.304 208.835V211.773H146.852Z" fill="black"/>
            <path d="M159.941 207.32V210.259C159.941 211.095 159.264 211.773 158.427 211.773H157.003C156.167 211.773 155.489 211.095 155.489 210.259V207.32H159.941Z" fill="black"/>
            <path d="M168.582 207.32V210.259C168.582 211.095 167.904 211.773 167.068 211.773H165.643C164.807 211.773 164.129 211.095 164.129 210.259V207.32H168.582Z" fill="black"/>
            <path d="M203.133 207.32V211.773H200.194C199.358 211.773 198.68 211.095 198.68 210.259V207.32H203.133Z" fill="black"/>
            <path d="M207.453 207.32H203V211.773H207.453V207.32Z" fill="black"/>
            <path d="M207.316 211.773V208.835C207.316 207.999 207.994 207.321 208.83 207.321H210.255C211.091 207.321 211.769 207.999 211.769 208.835V211.773H207.316Z" fill="black"/>
            <path d="M26.7162 20.3635V10.8048C26.7162 6.56204 26.139 5.5268 25.8908 5.27801C25.642 5.02978 24.6068 4.45261 20.3635 4.45261H10.8054C6.56204 4.45261 5.5268 5.02978 5.27857 5.27801C5.03034 5.5268 4.45261 6.56204 4.45261 10.8048V20.3635C4.45261 24.6068 5.03034 25.642 5.27857 25.8908C5.5268 26.1385 6.56204 26.7162 10.8054 26.7162H20.3635C24.6068 26.7162 25.642 26.1385 25.8908 25.8908C26.139 25.642 26.7162 24.6068 26.7162 20.3635ZM20.3635 0C29.0911 0 31.1688 2.0777 31.1688 10.8048C31.1688 19.5325 31.1688 11.6363 31.1688 20.3635C31.1688 29.0911 29.0911 31.1688 20.3635 31.1688C11.6363 31.1688 19.5325 31.1688 10.8054 31.1688C2.0777 31.1688 0 29.0911 0 20.3635C0 11.6363 0 15.6788 0 10.8048C0 2.0777 2.0777 0 10.8054 0C19.5325 0 11.6363 0 20.3635 0ZM17.6325 8.90538C21.3731 8.90538 22.2635 9.79629 22.2635 13.5358C22.2635 17.2764 22.2635 13.8925 22.2635 17.6325C22.2635 21.3725 21.3731 22.2635 17.6325 22.2635C13.8925 22.2635 17.2764 22.2635 13.5363 22.2635C9.79629 22.2635 8.90538 21.3725 8.90538 17.6325C8.90538 13.8925 8.90538 15.6248 8.90538 13.5358C8.90538 9.79629 9.79629 8.90538 13.5363 8.90538C17.2764 8.90538 13.8925 8.90538 17.6325 8.90538Z" fill="black"/>
            <path d="M206.247 20.3635V10.8048C206.247 6.56204 205.67 5.5268 205.422 5.27801C205.173 5.02978 204.138 4.45261 199.895 4.45261H190.337C186.093 4.45261 185.058 5.02978 184.81 5.27801C184.562 5.5268 183.984 6.56204 183.984 10.8048V20.3635C183.984 24.6068 184.562 25.642 184.81 25.8908C185.058 26.1385 186.093 26.7162 190.337 26.7162H199.895C204.138 26.7162 205.173 26.1385 205.422 25.8908C205.67 25.642 206.247 24.6068 206.247 20.3635ZM199.895 0C208.622 0 210.7 2.0777 210.7 10.8048C210.7 19.5325 210.7 11.6363 210.7 20.3635C210.7 29.0911 208.622 31.1688 199.895 31.1688C191.168 31.1688 199.064 31.1688 190.337 31.1688C181.609 31.1688 179.531 29.0911 179.531 20.3635C179.531 11.6363 179.531 15.6788 179.531 10.8048C179.531 2.0777 181.609 0 190.337 0C199.064 0 191.168 0 199.895 0ZM197.164 8.90538C200.904 8.90538 201.795 9.79629 201.795 13.5358C201.795 17.2764 201.795 13.8925 201.795 17.6325C201.795 21.3725 200.904 22.2635 197.164 22.2635C193.424 22.2635 196.808 22.2635 193.068 22.2635C189.328 22.2635 188.437 21.3725 188.437 17.6325C188.437 13.8925 188.437 15.6248 188.437 13.5358C188.437 9.79629 189.328 8.90538 193.068 8.90538C196.808 8.90538 193.424 8.90538 197.164 8.90538Z" fill="black"/>
            <path d="M26.7162 199.895V190.336C26.7162 186.093 26.139 185.058 25.8908 184.809C25.642 184.561 24.6068 183.984 20.3635 183.984H10.8054C6.56204 183.984 5.5268 184.561 5.27857 184.809C5.03034 185.058 4.45261 186.093 4.45261 190.336V199.895C4.45261 204.138 5.03034 205.173 5.27857 205.422C5.5268 205.67 6.56204 206.247 10.8054 206.247H20.3635C24.6068 206.247 25.642 205.67 25.8908 205.422C26.139 205.173 26.7162 204.138 26.7162 199.895ZM20.3635 179.531C29.0911 179.531 31.1688 181.609 31.1688 190.336C31.1688 199.064 31.1688 191.168 31.1688 199.895C31.1688 208.622 29.0911 210.7 20.3635 210.7C11.6363 210.7 19.5325 210.7 10.8054 210.7C2.0777 210.7 0 208.622 0 199.895C0 191.168 0 195.21 0 190.336C0 181.609 2.0777 179.531 10.8054 179.531C19.5325 179.531 11.6363 179.531 20.3635 179.531ZM17.6325 188.437C21.3731 188.437 22.2635 189.328 22.2635 193.067C22.2635 196.808 22.2635 193.424 22.2635 197.164C22.2635 200.904 21.3731 201.795 17.6325 201.795C13.8925 201.795 17.2764 201.795 13.5363 201.795C9.79629 201.795 8.90538 200.904 8.90538 197.164C8.90538 193.424 8.90538 195.156 8.90538 193.067C8.90538 189.328 9.79629 188.437 13.5363 188.437C17.2764 188.437 13.8925 188.437 17.6325 188.437Z" fill="black"/>
          </svg>
      </div>
      <div class="flex-1 flex flex-col justify-center items-center">
        <p class="p-txt-bold pt-5">
          Android {{ $t('dealerTranslater.confirmApp') }}
        </p>
        <svg class="mt-5" width="212" height="212" viewBox="0 0 212 212" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.9453 4.11719V1.40021C31.9453 0.627197 32.572 0.000549316 33.345 0.000549316H34.6623C35.4353 0.000549316 36.062 0.627197 36.062 1.40021V4.11719H31.9453Z" fill="black"/>
            <path d="M48.043 4.11719L43.9263 4.11719V1.40021C43.9263 0.627197 44.553 0.000549316 45.326 0.000549316H48.043V4.11719Z" fill="black"/>
            <path d="M52.0346 0H47.918V4.11664H52.0346V0Z" fill="black"/>
            <path d="M56.0268 0H51.9102V4.11664H56.0268V0Z" fill="black"/>
            <path d="M55.9062 4.11719V0.000549316H58.6232C59.3962 0.000549316 60.0229 0.627197 60.0229 1.40021V4.11719H55.9062Z" fill="black"/>
            <path d="M62.7578 4.03262V4.11495H64.0134V2.85938H63.9311C63.9311 3.5098 63.4041 4.03262 62.7578 4.03262Z" fill="black"/>
            <path d="M63.8906 4.11719V1.40021C63.8906 0.627197 64.5173 0.000549316 65.2903 0.000549316H66.6076C67.3806 0.000549316 68.0073 0.627197 68.0073 1.40021V4.11719H63.8906Z" fill="black"/>
            <path d="M82.7266 4.03262V4.11495H83.9821V2.85938H83.8998C83.8998 3.5098 83.3729 4.03262 82.7266 4.03262Z" fill="black"/>
            <path d="M87.9727 4.11719L83.856 4.11719V1.40021C83.856 0.627197 84.4827 0.000549316 85.2557 0.000549316H87.9727V4.11719Z" fill="black"/>
            <path d="M91.9682 0H87.8516V4.11664H91.9682V0Z" fill="black"/>
            <path d="M95.9604 0H91.8438V4.11664H95.9604V0Z" fill="black"/>
            <path d="M95.8359 4.11719V0.000549316H98.5529C99.3259 0.000549316 99.9526 0.627197 99.9526 1.40021V4.11719H95.8359Z" fill="black"/>
            <path d="M110.676 4.03262V4.11495H111.931V2.85938H111.849C111.849 3.5098 111.322 4.03262 110.676 4.03262Z" fill="black"/>
            <path d="M115.926 4.11719L111.809 4.11719V1.40021C111.809 0.627197 112.436 0.000549316 113.209 0.000549316H115.926V4.11719Z" fill="black"/>
            <path d="M119.917 0H115.801V4.11664H119.917V0Z" fill="black"/>
            <path d="M123.914 0H119.797V4.11664H123.914V0Z" fill="black"/>
            <path d="M123.789 4.11719V0.000549316H126.506C127.279 0.000549316 127.906 0.627197 127.906 1.40021V4.11719H123.789Z" fill="black"/>
            <path d="M130.641 4.03262V4.11495H131.896V2.85938H131.814C131.814 3.5098 131.287 4.03262 130.641 4.03262Z" fill="black"/>
            <path d="M135.891 4.11719L131.774 4.11719V1.40021C131.774 0.627197 132.401 0.000549316 133.174 0.000549316H135.891V4.11719Z" fill="black"/>
            <path d="M135.766 4.11719V1.40021C135.766 0.627197 136.392 0.000549316 137.165 0.000549316H138.483C139.256 0.000549316 139.882 0.627197 139.882 1.40021V4.11719H135.766Z" fill="black"/>
            <path d="M146.613 4.03262V4.11495H147.869V2.85938H147.787C147.787 3.5098 147.26 4.03262 146.613 4.03262Z" fill="black"/>
            <path d="M147.746 4.11719V1.40021C147.746 0.627197 148.373 0.000549316 149.146 0.000549316H150.463C151.236 0.000549316 151.863 0.627197 151.863 1.40021V4.11719H147.746Z" fill="black"/>
            <path d="M154.602 4.03262V4.11495H155.857V2.85938H155.775C155.775 3.5098 155.248 4.03262 154.602 4.03262Z" fill="black"/>
            <path d="M155.734 4.11719V1.40021C155.734 0.627197 156.361 0.000549316 157.134 0.000549316H158.451C159.224 0.000549316 159.851 0.627197 159.851 1.40021V4.11719H155.734Z" fill="black"/>
            <path d="M167.836 4.11719L163.719 4.11719V1.40021C163.719 0.627197 164.346 0.000549316 165.119 0.000549316H167.836V4.11719Z" fill="black"/>
            <path d="M167.711 4.11719V0.000549316H170.428C171.201 0.000549316 171.828 0.627197 171.828 1.40021V4.11719H167.711Z" fill="black"/>
            <path d="M36.062 3.99219H31.9453V8.10883H36.062V3.99219Z" fill="black"/>
            <path d="M35.9375 8.10938V5.39239C35.9375 4.61938 36.5641 3.99274 37.3372 3.99274H38.6545C39.4275 3.99274 40.0541 4.61938 40.0541 5.39239V8.10938H35.9375Z" fill="black"/>
            <path d="M48.043 3.99219V6.70917C48.043 7.48218 47.4163 8.10883 46.6433 8.10883H45.326C44.553 8.10883 43.9263 7.48218 43.9263 6.70917V3.99219L48.043 3.99219Z" fill="black"/>
            <path d="M60.0234 3.99219V8.10883H57.3065C56.5334 8.10883 55.9068 7.48218 55.9068 6.70917V3.99219L60.0234 3.99219Z" fill="black"/>
            <path d="M64.0151 3.99219H59.8984V8.10883H64.0151V3.99219Z" fill="black"/>
            <path d="M68.0073 3.99219H63.8906V8.10883H68.0073V3.99219Z" fill="black"/>
            <path d="M67.8828 8.10938V3.99274H70.5998C71.3728 3.99274 71.9995 4.61938 71.9995 5.39239V8.10938H67.8828Z" fill="black"/>
            <path d="M79.9883 8.10938H75.8716V5.39239C75.8716 4.61938 76.4983 3.99274 77.2713 3.99274H79.9883V8.10938Z" fill="black"/>
            <path d="M83.9799 3.99219H79.8633V8.10883H83.9799V3.99219Z" fill="black"/>
            <path d="M87.9721 3.99219H83.8555V8.10883H87.9721V3.99219Z" fill="black"/>
            <path d="M91.9682 3.99219H87.8516V8.10883H91.9682V3.99219Z" fill="black"/>
            <path d="M95.9604 3.99219H91.8438V8.10883H95.9604V3.99219Z" fill="black"/>
            <path d="M99.9526 3.99219H95.8359V8.10883H99.9526V3.99219Z" fill="black"/>
            <path d="M107.941 8.10938H103.825V5.39239C103.825 4.61938 104.451 3.99274 105.224 3.99274H107.941V8.10938Z" fill="black"/>
            <path d="M111.929 3.99219H107.812V8.10883H111.929V3.99219Z" fill="black"/>
            <path d="M111.809 3.99219H115.925V6.70917C115.925 7.48218 115.299 8.10883 114.526 8.10883H111.809V3.99219Z" fill="black"/>
            <path d="M127.906 3.99219V8.10883H125.189C124.416 8.10883 123.79 7.48218 123.79 6.70917V3.99219L127.906 3.99219Z" fill="black"/>
            <path d="M131.898 3.99219H127.781V8.10883H131.898V3.99219Z" fill="black"/>
            <path d="M135.89 3.99219H131.773V8.10883H135.89V3.99219Z" fill="black"/>
            <path d="M143.879 8.10938H141.162C140.389 8.10938 139.762 7.48273 139.762 6.70972V5.39239C139.762 4.61938 140.389 3.99274 141.162 3.99274H143.879V8.10938Z" fill="black"/>
            <path d="M147.871 3.99219H143.754V8.10883H147.871V3.99219Z" fill="black"/>
            <path d="M151.863 3.99219H147.746V8.10883H151.863V3.99219Z" fill="black"/>
            <path d="M155.859 3.99219H151.742V8.10883H155.859V3.99219Z" fill="black"/>
            <path d="M159.851 3.99219H155.734V8.10883H159.851V3.99219Z" fill="black"/>
            <path d="M167.836 3.99219V8.10883H165.119C164.346 8.10883 163.719 7.48218 163.719 6.70917V3.99219L167.836 3.99219Z" fill="black"/>
            <path d="M171.828 3.99219H167.711V8.10883H171.828V3.99219Z" fill="black"/>
            <path d="M171.703 8.10938V3.99274H174.42C175.193 3.99274 175.82 4.61938 175.82 5.39239V8.10938H171.703Z" fill="black"/>
            <path d="M36.0625 7.98438V10.7014C36.0625 11.4744 35.4359 12.101 34.6628 12.101H33.3455C32.5725 12.101 31.9459 11.4744 31.9459 10.7014V7.98438H36.0625Z" fill="black"/>
            <path d="M38.7969 12.017V12.0993H40.0524V10.8438H39.9701C39.9701 11.4942 39.4432 12.017 38.7969 12.017Z" fill="black"/>
            <path d="M39.9297 12.1016V9.38458C39.9297 8.61157 40.5563 7.98492 41.3293 7.98492H42.6467C43.4197 7.98492 44.0463 8.61157 44.0463 9.38458V12.1016H39.9297Z" fill="black"/>
            <path d="M72 7.98438V10.7014C72 11.4744 71.3734 12.101 70.6003 12.101H69.283C68.51 12.101 67.8834 11.4744 67.8834 10.7014V7.98438H72Z" fill="black"/>
            <path d="M79.9838 7.98438H75.8672V12.101H79.9838V7.98438Z" fill="black"/>
            <path d="M91.9682 7.98438H87.8516V12.101H91.9682V7.98438Z" fill="black"/>
            <path d="M99.9531 7.98438V10.7014C99.9531 11.4744 99.3265 12.101 98.5535 12.101H97.2361C96.4631 12.101 95.8365 11.4744 95.8365 10.7014V7.98438H99.9531Z" fill="black"/>
            <path d="M107.937 7.98438H103.82V12.101H107.937V7.98438Z" fill="black"/>
            <path d="M131.898 7.98438V12.101H129.181C128.408 12.101 127.782 11.4744 127.782 10.7014V7.98438H131.898Z" fill="black"/>
            <path d="M135.89 7.98438H131.773V12.101H135.89V7.98438Z" fill="black"/>
            <path d="M135.766 12.1016V7.98492H138.483C139.256 7.98492 139.882 8.61157 139.882 9.38458V12.1016H135.766Z" fill="black"/>
            <path d="M147.871 7.98438V10.7014C147.871 11.4744 147.244 12.101 146.471 12.101H145.154C144.381 12.101 143.754 11.4744 143.754 10.7014V7.98438H147.871Z" fill="black"/>
            <path d="M155.859 7.98438V12.101H153.142C152.369 12.101 151.743 11.4744 151.743 10.7014V7.98438H155.859Z" fill="black"/>
            <path d="M159.851 7.98438H155.734V12.101H159.851V7.98438Z" fill="black"/>
            <path d="M159.727 12.1016V9.38458C159.727 8.61157 160.353 7.98492 161.126 7.98492H162.444C163.217 7.98492 163.843 8.61157 163.843 9.38458V12.1016H159.727Z" fill="black"/>
            <path d="M175.824 7.98438V10.7014C175.824 11.4744 175.198 12.101 174.425 12.101H173.107C172.334 12.101 171.708 11.4744 171.708 10.7014V7.98438H175.824Z" fill="black"/>
            <path d="M40.0547 16.0938H35.938V13.3768C35.938 12.6038 36.5647 11.9771 37.3377 11.9771H40.0547V16.0938Z" fill="black"/>
            <path d="M44.0463 11.9766H39.9297V16.0932H44.0463V11.9766Z" fill="black"/>
            <path d="M48.0385 11.9766H43.9219V16.0932H48.0385V11.9766Z" fill="black"/>
            <path d="M47.918 16.0938V11.9771H50.6349C51.408 11.9771 52.0346 12.6038 52.0346 13.3768V16.0938H47.918Z" fill="black"/>
            <path d="M64.0156 16.0938H59.899V13.3768C59.899 12.6038 60.5256 11.9771 61.2986 11.9771H64.0156V16.0938Z" fill="black"/>
            <path d="M63.8906 16.0938V11.9771H66.6076C67.3806 11.9771 68.0073 12.6038 68.0073 13.3768V16.0938H63.8906Z" fill="black"/>
            <path d="M79.9838 11.9766H75.8672V16.0932H79.9838V11.9766Z" fill="black"/>
            <path d="M83.9799 11.9766H79.8633V16.0932H83.9799V11.9766Z" fill="black"/>
            <path d="M87.9721 11.9766H83.8555V16.0932H87.9721V11.9766Z" fill="black"/>
            <path d="M87.8516 11.9766H91.9682V14.6935C91.9682 15.4666 91.3416 16.0932 90.5685 16.0932H87.8516V11.9766Z" fill="black"/>
            <path d="M102.688 16.017V16.0993H103.943V14.8438H103.861C103.861 15.4942 103.334 16.017 102.688 16.017Z" fill="black"/>
            <path d="M107.937 11.9766H103.82V16.0932H107.937V11.9766Z" fill="black"/>
            <path d="M111.929 11.9766H107.812V16.0932H111.929V11.9766Z" fill="black"/>
            <path d="M111.809 16.0938V11.9771H114.526C115.299 11.9771 115.925 12.6038 115.925 13.3768V16.0938H111.809Z" fill="black"/>
            <path d="M122.656 16.017V16.0993H123.912V14.8438H123.829C123.829 15.4942 123.303 16.017 122.656 16.017Z" fill="black"/>
            <path d="M123.789 16.0938V13.3768C123.789 12.6038 124.416 11.9771 125.189 11.9771H126.506C127.279 11.9771 127.906 12.6038 127.906 13.3768V16.0938H123.789Z" fill="black"/>
            <path d="M130.641 16.017V16.0993H131.896V14.8438H131.814C131.814 15.4942 131.287 16.017 130.641 16.017Z" fill="black"/>
            <path d="M135.89 11.9766H131.773V16.0932H135.89V11.9766Z" fill="black"/>
            <path d="M139.882 11.9766H135.766V16.0932H139.882V11.9766Z" fill="black"/>
            <path d="M139.758 16.0938V13.3768C139.758 12.6038 140.384 11.9771 141.157 11.9771H142.475C143.248 11.9771 143.874 12.6038 143.874 13.3768V16.0938H139.758Z" fill="black"/>
            <path d="M147.746 16.0938V13.3768C147.746 12.6038 148.373 11.9771 149.146 11.9771H150.463C151.236 11.9771 151.863 12.6038 151.863 13.3768V16.0938H147.746Z" fill="black"/>
            <path d="M162.586 16.017V16.0993H163.842V14.8438H163.759C163.759 15.4942 163.232 16.017 162.586 16.017Z" fill="black"/>
            <path d="M167.836 16.0938H163.719V13.3768C163.719 12.6038 164.346 11.9771 165.119 11.9771H167.836V16.0938Z" fill="black"/>
            <path d="M167.711 16.0938V11.9771H170.428C171.201 11.9771 171.828 12.6038 171.828 13.3768V16.0938H167.711Z" fill="black"/>
            <path d="M175.695 13.1539V14.92C175.695 15.5704 176.222 16.0932 176.873 16.0932H178.639C179.293 16.0932 179.816 15.5663 179.816 14.9158V13.1539C179.812 12.4994 179.285 11.9766 178.635 11.9766H176.873C176.222 11.9766 175.695 12.5035 175.695 13.1539Z" fill="black"/>
            <path d="M34.8047 20.0092V20.0915H36.0603V18.8359H35.9779C35.9779 19.4864 35.451 20.0092 34.8047 20.0092Z" fill="black"/>
            <path d="M40.0541 15.9688H35.9375V20.0854H40.0541V15.9688Z" fill="black"/>
            <path d="M48.043 15.9688V20.0854H45.326C44.553 20.0854 43.9263 19.4587 43.9263 18.6857V15.9688H48.043Z" fill="black"/>
            <path d="M52.0346 15.9688H47.918V20.0854H52.0346V15.9688Z" fill="black"/>
            <path d="M51.9102 20.0859V17.369C51.9102 16.5959 52.5368 15.9693 53.3098 15.9693H54.6271C55.4001 15.9693 56.0268 16.5959 56.0268 17.369V20.0859H51.9102Z" fill="black"/>
            <path d="M64.0156 15.9688V20.0854H61.2986C60.5256 20.0854 59.899 19.4587 59.899 18.6857V15.9688H64.0156Z" fill="black"/>
            <path d="M68.0073 15.9688H63.8906V20.0854H68.0073V15.9688Z" fill="black"/>
            <path d="M67.8828 20.0859V15.9693H70.5998C71.3728 15.9693 71.9995 16.5959 71.9995 17.369V20.0859H67.8828Z" fill="black"/>
            <path d="M79.9838 15.9688H75.8672V20.0854H79.9838V15.9688Z" fill="black"/>
            <path d="M79.8633 15.9688H83.9799V18.6857C83.9799 19.4587 83.3533 20.0854 82.5803 20.0854H79.8633V15.9688Z" fill="black"/>
            <path d="M95.9609 20.0859H91.8443V17.369C91.8443 16.5959 92.4709 15.9693 93.244 15.9693H95.9609V20.0859Z" fill="black"/>
            <path d="M99.9526 15.9688H95.8359V20.0854H99.9526V15.9688Z" fill="black"/>
            <path d="M103.945 15.9688H99.8281V20.0854H103.945V15.9688Z" fill="black"/>
            <path d="M107.937 15.9688H103.82V20.0854H107.937V15.9688Z" fill="black"/>
            <path d="M111.929 15.9688H107.812V20.0854H111.929V15.9688Z" fill="black"/>
            <path d="M115.925 15.9688H111.809V20.0854H115.925V15.9688Z" fill="black"/>
            <path d="M119.917 15.9688H115.801V20.0854H119.917V15.9688Z" fill="black"/>
            <path d="M123.914 15.9688H119.797V20.0854H123.914V15.9688Z" fill="black"/>
            <path d="M127.906 15.9688H123.789V20.0854H127.906V15.9688Z" fill="black"/>
            <path d="M131.898 15.9688H127.781V20.0854H131.898V15.9688Z" fill="black"/>
            <path d="M135.89 15.9688H131.773V20.0854H135.89V15.9688Z" fill="black"/>
            <path d="M139.882 15.9688H135.766V20.0854H139.882V15.9688Z" fill="black"/>
            <path d="M151.863 15.9688V18.6857C151.863 19.4587 151.237 20.0854 150.464 20.0854H149.146C148.373 20.0854 147.747 19.4587 147.747 18.6857V15.9688H151.863Z" fill="black"/>
            <path d="M159.852 20.0859H157.135C156.362 20.0859 155.735 19.4593 155.735 18.6863V17.369C155.735 16.5959 156.362 15.9693 157.135 15.9693H159.852V20.0859Z" fill="black"/>
            <path d="M163.843 15.9688H159.727V20.0854H163.843V15.9688Z" fill="black"/>
            <path d="M167.835 15.9688H163.719V20.0854H167.835V15.9688Z" fill="black"/>
            <path d="M171.828 15.9688H167.711V20.0854H171.828V15.9688Z" fill="black"/>
            <path d="M36.0625 24.0859H31.9459V21.369C31.9459 20.5959 32.5725 19.9693 33.3455 19.9693H36.0625V24.0859Z" fill="black"/>
            <path d="M35.9375 19.9688H40.0541V22.6857C40.0541 23.4587 39.4275 24.0854 38.6545 24.0854H35.9375V19.9688Z" fill="black"/>
            <path d="M72 19.9688V22.6857C72 23.4587 71.3734 24.0854 70.6003 24.0854H69.283C68.51 24.0854 67.8834 23.4587 67.8834 22.6857V19.9688H72Z" fill="black"/>
            <path d="M79.9883 19.9688V22.6857C79.9883 23.4587 79.3616 24.0854 78.5886 24.0854H77.2713C76.4983 24.0854 75.8716 23.4587 75.8716 22.6857V19.9688H79.9883Z" fill="black"/>
            <path d="M95.9609 19.9688V24.0854H93.244C92.4709 24.0854 91.8443 23.4587 91.8443 22.6857V19.9688H95.9609Z" fill="black"/>
            <path d="M99.9526 19.9688H95.8359V24.0854H99.9526V19.9688Z" fill="black"/>
            <path d="M115.925 19.9688H111.809V24.0854H115.925V19.9688Z" fill="black"/>
            <path d="M123.914 19.9688H119.797V24.0854H123.914V19.9688Z" fill="black"/>
            <path d="M127.906 19.9688H123.789V24.0854H127.906V19.9688Z" fill="black"/>
            <path d="M131.898 19.9688H127.781V24.0854H131.898V19.9688Z" fill="black"/>
            <path d="M135.89 19.9688H131.773V24.0854H135.89V19.9688Z" fill="black"/>
            <path d="M139.882 19.9688H135.766V24.0854H139.882V19.9688Z" fill="black"/>
            <path d="M139.758 24.0859V21.369C139.758 20.5959 140.384 19.9693 141.157 19.9693H142.475C143.248 19.9693 143.874 20.5959 143.874 21.369V24.0859H139.758Z" fill="black"/>
            <path d="M151.742 24.0859V21.369C151.742 20.5959 152.369 19.9693 153.142 19.9693H154.459C155.232 19.9693 155.859 20.5959 155.859 21.369V24.0859H151.742Z" fill="black"/>
            <path d="M163.843 19.9688H159.727V24.0854H163.843V19.9688Z" fill="black"/>
            <path d="M171.828 19.9688H167.711V24.0854H171.828V19.9688Z" fill="black"/>
            <path d="M36.062 23.9609H31.9453V28.0776H36.062V23.9609Z" fill="black"/>
            <path d="M39.9297 25.1383V26.9043C39.9297 27.5548 40.4566 28.0776 41.107 28.0776H42.8731C43.5276 28.0776 44.0504 27.5506 44.0504 26.9002V25.1383C44.0463 24.4838 43.5194 23.9609 42.869 23.9609H41.107C40.4566 23.9609 39.9297 24.4879 39.9297 25.1383Z" fill="black"/>
            <path d="M46.7852 27.9936V28.0759H48.0407V26.8203H47.9584C47.9584 27.4707 47.4315 27.9936 46.7852 27.9936Z" fill="black"/>
            <path d="M47.918 28.0781V25.3611C47.918 24.5881 48.5446 23.9615 49.3176 23.9615H50.6349C51.408 23.9615 52.0346 24.5881 52.0346 25.3611V28.0781H47.918Z" fill="black"/>
            <path d="M55.9062 25.1383V26.9043C55.9062 27.5548 56.4332 28.0776 57.0836 28.0776H58.8496C59.5042 28.0776 60.027 27.5506 60.027 26.9002V25.1383C60.0229 24.4838 59.496 23.9609 58.8455 23.9609H57.0836C56.4332 23.9609 55.9062 24.4879 55.9062 25.1383Z" fill="black"/>
            <path d="M63.8906 25.1383V26.9043C63.8906 27.5548 64.4176 28.0776 65.068 28.0776H66.834C67.4886 28.0776 68.0114 27.5506 68.0114 26.9002V25.1383C68.0073 24.4838 67.4803 23.9609 66.8299 23.9609H65.068C64.4176 23.9609 63.8906 24.4879 63.8906 25.1383Z" fill="black"/>
            <path d="M70.7422 27.9936V28.0759H71.9978V26.8203H71.9154C71.9154 27.4707 71.3885 27.9936 70.7422 27.9936Z" fill="black"/>
            <path d="M71.875 28.0781V25.3611C71.875 24.5881 72.5016 23.9615 73.2747 23.9615H74.592C75.365 23.9615 75.9916 24.5881 75.9916 25.3611V28.0781H71.875Z" fill="black"/>
            <path d="M78.7305 27.9936V28.0759H79.986V26.8203H79.9037C79.9037 27.4707 79.3768 27.9936 78.7305 27.9936Z" fill="black"/>
            <path d="M79.8633 28.0781V25.3611C79.8633 24.5881 80.4899 23.9615 81.2629 23.9615H82.5803C83.3533 23.9615 83.9799 24.5881 83.9799 25.3611V28.0781H79.8633Z" fill="black"/>
            <path d="M87.8516 28.0781V25.3611C87.8516 24.5881 88.4782 23.9615 89.2512 23.9615H90.5685C91.3416 23.9615 91.9682 24.5881 91.9682 25.3611V28.0781H87.8516Z" fill="black"/>
            <path d="M94.7031 27.9936V28.0759H95.9587V26.8203H95.8764C95.8764 27.4707 95.3494 27.9936 94.7031 27.9936Z" fill="black"/>
            <path d="M99.9526 23.9609H95.8359V28.0776H99.9526V23.9609Z" fill="black"/>
            <path d="M103.82 25.1383V26.9043C103.82 27.5548 104.347 28.0776 104.998 28.0776H106.764C107.418 28.0776 107.941 27.5506 107.941 26.9002V25.1383C107.937 24.4838 107.41 23.9609 106.76 23.9609H104.998C104.347 23.9609 103.82 24.4879 103.82 25.1383Z" fill="black"/>
            <path d="M115.925 23.9609H111.809V28.0776H115.925V23.9609Z" fill="black"/>
            <path d="M118.664 27.9936V28.0759H119.92V26.8203H119.837C119.837 27.4707 119.31 27.9936 118.664 27.9936Z" fill="black"/>
            <path d="M123.914 23.9609H119.797V28.0776H123.914V23.9609Z" fill="black"/>
            <path d="M131.898 23.9609H127.781V28.0776H131.898V23.9609Z" fill="black"/>
            <path d="M139.882 23.9609H135.766V28.0776H139.882V23.9609Z" fill="black"/>
            <path d="M143.754 25.1383V26.9043C143.754 27.5548 144.281 28.0776 144.931 28.0776H146.697C147.352 28.0776 147.875 27.5506 147.875 26.9002V25.1383C147.871 24.4838 147.344 23.9609 146.693 23.9609H144.931C144.281 23.9609 143.754 24.4879 143.754 25.1383Z" fill="black"/>
            <path d="M155.859 23.9609V26.6779C155.859 27.4509 155.233 28.0776 154.46 28.0776H153.142C152.369 28.0776 151.743 27.4509 151.743 26.6779V23.9609H155.859Z" fill="black"/>
            <path d="M163.843 23.9609H159.727V28.0776H163.843V23.9609Z" fill="black"/>
            <path d="M166.578 27.9936V28.0759H167.834V26.8203H167.751C167.751 27.4707 167.224 27.9936 166.578 27.9936Z" fill="black"/>
            <path d="M171.828 23.9609H167.711V28.0776H171.828V23.9609Z" fill="black"/>
            <path d="M175.695 25.1383V26.9043C175.695 27.5548 176.222 28.0776 176.873 28.0776H178.639C179.293 28.0776 179.816 27.5506 179.816 26.9002V25.1383C179.812 24.4838 179.285 23.9609 178.635 23.9609H176.873C176.222 23.9609 175.695 24.4879 175.695 25.1383Z" fill="black"/>
            <path d="M36.062 27.9531H31.9453V32.0698H36.062V27.9531Z" fill="black"/>
            <path d="M48.043 32.0703H45.326C44.553 32.0703 43.9263 31.4437 43.9263 30.6707V29.3533C43.9263 28.5803 44.553 27.9537 45.326 27.9537H48.043V32.0703Z" fill="black"/>
            <path d="M52.0346 27.9531H47.918V32.0698H52.0346V27.9531Z" fill="black"/>
            <path d="M51.9102 32.0703V27.9537H54.6271C55.4001 27.9537 56.0268 28.5803 56.0268 29.3533V32.0703H51.9102Z" fill="black"/>
            <path d="M59.8984 32.0703V29.3533C59.8984 28.5803 60.5251 27.9537 61.2981 27.9537H62.6154C63.3884 27.9537 64.0151 28.5803 64.0151 29.3533V32.0703H59.8984Z" fill="black"/>
            <path d="M72 32.0703H67.8834V29.3533C67.8834 28.5803 68.51 27.9537 69.283 27.9537H72V32.0703Z" fill="black"/>
            <path d="M75.9916 27.9531H71.875V32.0698H75.9916V27.9531Z" fill="black"/>
            <path d="M79.9838 27.9531H75.8672V32.0698H79.9838V27.9531Z" fill="black"/>
            <path d="M79.8633 27.9531H83.9799V30.6701C83.9799 31.4431 83.3533 32.0698 82.5803 32.0698H79.8633V27.9531Z" fill="black"/>
            <path d="M91.9688 27.9531V32.0698H89.2518C88.4788 32.0698 87.8521 31.4431 87.8521 30.6701V27.9531H91.9688Z" fill="black"/>
            <path d="M95.9604 27.9531H91.8438V32.0698H95.9604V27.9531Z" fill="black"/>
            <path d="M99.9526 27.9531H95.8359V32.0698H99.9526V27.9531Z" fill="black"/>
            <path d="M110.676 31.9857V32.0681H111.931V30.8125H111.849C111.849 31.4629 111.322 31.9857 110.676 31.9857Z" fill="black"/>
            <path d="M115.925 27.9531H111.809V32.0698H115.925V27.9531Z" fill="black"/>
            <path d="M119.917 27.9531H115.801V32.0698H119.917V27.9531Z" fill="black"/>
            <path d="M123.914 27.9531H119.797V32.0698H123.914V27.9531Z" fill="black"/>
            <path d="M127.906 27.9531H123.789V32.0698H127.906V27.9531Z" fill="black"/>
            <path d="M127.781 27.9531H131.898V30.6701C131.898 31.4431 131.271 32.0698 130.498 32.0698H127.781V27.9531Z" fill="black"/>
            <path d="M139.887 27.9531V30.6701C139.887 31.4431 139.26 32.0698 138.487 32.0698H137.17C136.397 32.0698 135.77 31.4431 135.77 30.6701V27.9531H139.887Z" fill="black"/>
            <path d="M163.844 27.9531V32.0698H161.127C160.354 32.0698 159.727 31.4431 159.727 30.6701V27.9531H163.844Z" fill="black"/>
            <path d="M167.835 27.9531H163.719V32.0698H167.835V27.9531Z" fill="black"/>
            <path d="M171.828 27.9531H167.711V32.0698H171.828V27.9531Z" fill="black"/>
            <path d="M171.703 32.0703V27.9537H174.42C175.193 27.9537 175.82 28.5803 175.82 29.3533V32.0703H171.703Z" fill="black"/>
            <path d="M12.1055 36.0625H7.98883V33.3455C7.98883 32.5725 8.61548 31.9459 9.38849 31.9459H12.1055V36.0625Z" fill="black"/>
            <path d="M16.0932 31.9453H11.9766V36.062H16.0932V31.9453Z" fill="black"/>
            <path d="M15.9727 36.0625V31.9459H18.6896C19.4626 31.9459 20.0893 32.5725 20.0893 33.3455V36.0625H15.9727Z" fill="black"/>
            <path d="M23.957 33.1227V34.8887C23.957 35.5391 24.484 36.062 25.1344 36.062H26.9004C27.555 36.062 28.0778 35.535 28.0778 34.8846V33.1227C28.0737 32.4681 27.5467 31.9453 26.8963 31.9453H25.1344C24.484 31.9453 23.957 32.4722 23.957 33.1227Z" fill="black"/>
            <path d="M36.062 31.9453H31.9453V36.062H36.062V31.9453Z" fill="black"/>
            <path d="M40.0541 31.9453H35.9375V36.062H40.0541V31.9453Z" fill="black"/>
            <path d="M39.9297 36.0625V33.3455C39.9297 32.5725 40.5563 31.9459 41.3293 31.9459H42.6467C43.4197 31.9459 44.0463 32.5725 44.0463 33.3455V36.0625H39.9297Z" fill="black"/>
            <path d="M52.0352 31.9453V36.062H49.3182C48.5452 36.062 47.9185 35.4353 47.9185 34.6623V31.9453H52.0352Z" fill="black"/>
            <path d="M56.0268 31.9453H51.9102V36.062H56.0268V31.9453Z" fill="black"/>
            <path d="M64.0156 31.9453V34.6623C64.0156 35.4353 63.389 36.062 62.616 36.062H61.2986C60.5256 36.062 59.899 35.4353 59.899 34.6623V31.9453H64.0156Z" fill="black"/>
            <path d="M72 31.9453V34.6623C72 35.4353 71.3734 36.062 70.6003 36.062H69.283C68.51 36.062 67.8834 35.4353 67.8834 34.6623V31.9453H72Z" fill="black"/>
            <path d="M95.9604 31.9453H91.8438V36.062H95.9604V31.9453Z" fill="black"/>
            <path d="M99.9526 31.9453H95.8359V36.062H99.9526V31.9453Z" fill="black"/>
            <path d="M103.945 31.9453H99.8281V36.062H103.945V31.9453Z" fill="black"/>
            <path d="M107.937 31.9453H103.82V36.062H107.937V31.9453Z" fill="black"/>
            <path d="M111.929 31.9453H107.812V36.062H111.929V31.9453Z" fill="black"/>
            <path d="M115.925 31.9453H111.809V36.062H115.925V31.9453Z" fill="black"/>
            <path d="M127.906 31.9453V34.6623C127.906 35.4353 127.28 36.062 126.507 36.062H125.189C124.416 36.062 123.79 35.4353 123.79 34.6623V31.9453H127.906Z" fill="black"/>
            <path d="M130.641 35.9779V36.0603H131.896V34.8047H131.814C131.814 35.4551 131.287 35.9779 130.641 35.9779Z" fill="black"/>
            <path d="M131.773 36.0625V33.3455C131.773 32.5725 132.4 31.9459 133.173 31.9459H134.49C135.263 31.9459 135.89 32.5725 135.89 33.3455V36.0625H131.773Z" fill="black"/>
            <path d="M138.625 35.9779V36.0603H139.881V34.8047H139.798C139.798 35.4551 139.271 35.9779 138.625 35.9779Z" fill="black"/>
            <path d="M143.879 36.0625H139.762V33.3455C139.762 32.5725 140.389 31.9459 141.162 31.9459H143.879V36.0625Z" fill="black"/>
            <path d="M143.754 36.0625V33.3455C143.754 32.5725 144.381 31.9459 145.154 31.9459H146.471C147.244 31.9459 147.871 32.5725 147.871 33.3455V36.0625H143.754Z" fill="black"/>
            <path d="M167.836 31.9453V36.062H165.119C164.346 36.062 163.719 35.4353 163.719 34.6623V31.9453H167.836Z" fill="black"/>
            <path d="M171.828 31.9453H167.711V36.062H171.828V31.9453Z" fill="black"/>
            <path d="M175.82 31.9453H171.703V36.062H175.82V31.9453Z" fill="black"/>
            <path d="M179.812 31.9453H175.695V36.062H179.812V31.9453Z" fill="black"/>
            <path d="M183.808 31.9453H179.691V36.062H183.808V31.9453Z" fill="black"/>
            <path d="M187.8 31.9453H183.684V36.062H187.8V31.9453Z" fill="black"/>
            <path d="M187.68 36.0625V31.9459H190.397C191.17 31.9459 191.796 32.5725 191.796 33.3455V36.0625H187.68Z" fill="black"/>
            <path d="M203.773 36.0625H201.056C200.283 36.0625 199.657 35.4359 199.657 34.6628V33.3455C199.657 32.5725 200.283 31.9459 201.056 31.9459H203.773V36.0625Z" fill="black"/>
            <path d="M207.765 31.9453H203.648V36.062H207.765V31.9453Z" fill="black"/>
            <path d="M207.641 36.0625V31.9459H210.358C211.131 31.9459 211.757 32.5725 211.757 33.3455V36.0625H207.641Z" fill="black"/>
            <path d="M0 37.1149V38.8809C0 39.5313 0.52693 40.0541 1.17736 40.0541H2.9434C3.59794 40.0541 4.12075 39.5272 4.12075 38.8768V37.1149C4.11664 36.4603 3.58971 35.9375 2.93928 35.9375H1.17736C0.52693 35.9375 0 36.4644 0 37.1149Z" fill="black"/>
            <path d="M12.101 35.9375H7.98438V40.0541H12.101V35.9375Z" fill="black"/>
            <path d="M20.0898 35.9375V40.0541H17.3729C16.5999 40.0541 15.9732 39.4275 15.9732 38.6545V35.9375H20.0898Z" fill="black"/>
            <path d="M19.9648 40.0547V35.938H22.6818C23.4548 35.938 24.0815 36.5647 24.0815 37.3377V40.0547H19.9648Z" fill="black"/>
            <path d="M36.0625 35.9375V40.0541H33.3455C32.5725 40.0541 31.9459 39.4275 31.9459 38.6545V35.9375H36.0625Z" fill="black"/>
            <path d="M35.9375 35.9375H40.0541V38.6545C40.0541 39.4275 39.4275 40.0541 38.6545 40.0541H35.9375V35.9375Z" fill="black"/>
            <path d="M43.9219 37.1149V38.8809C43.9219 39.5313 44.4488 40.0541 45.0992 40.0541H46.8653C47.5198 40.0541 48.0426 39.5272 48.0426 38.8768V37.1149C48.0385 36.4603 47.5116 35.9375 46.8612 35.9375H45.0992C44.4488 35.9375 43.9219 36.4644 43.9219 37.1149Z" fill="black"/>
            <path d="M56.0268 35.9375H51.9102V40.0541H56.0268V35.9375Z" fill="black"/>
            <path d="M63.8906 37.1149V38.8809C63.8906 39.5313 64.4176 40.0541 65.068 40.0541H66.834C67.4886 40.0541 68.0114 39.5272 68.0114 38.8768V37.1149C68.0073 36.4603 67.4803 35.9375 66.8299 35.9375H65.068C64.4176 35.9375 63.8906 36.4644 63.8906 37.1149Z" fill="black"/>
            <path d="M71.875 37.1149V38.8809C71.875 39.5313 72.4019 40.0541 73.0524 40.0541H74.8184C75.4729 40.0541 75.9958 39.5272 75.9958 38.8768V37.1149C75.9916 36.4603 75.4647 35.9375 74.8143 35.9375H73.0524C72.4019 35.9375 71.875 36.4644 71.875 37.1149Z" fill="black"/>
            <path d="M79.8633 40.0547V37.3377C79.8633 36.5647 80.4899 35.938 81.2629 35.938H82.5803C83.3533 35.938 83.9799 36.5647 83.9799 37.3377V40.0547H79.8633Z" fill="black"/>
            <path d="M95.9609 35.9375V40.0541H93.244C92.4709 40.0541 91.8443 39.4275 91.8443 38.6545V35.9375H95.9609Z" fill="black"/>
            <path d="M99.9526 35.9375H95.8359V40.0541H99.9526V35.9375Z" fill="black"/>
            <path d="M107.941 35.9375V40.0541H105.224C104.451 40.0541 103.825 39.4275 103.825 38.6545V35.9375H107.941Z" fill="black"/>
            <path d="M111.929 35.9375H107.812V40.0541H111.929V35.9375Z" fill="black"/>
            <path d="M115.925 35.9375H111.809V40.0541H115.925V35.9375Z" fill="black"/>
            <path d="M119.797 40.0547V37.3377C119.797 36.5647 120.424 35.938 121.197 35.938H122.514C123.287 35.938 123.914 36.5647 123.914 37.3377V40.0547H119.797Z" fill="black"/>
            <path d="M131.898 40.0547H129.181C128.408 40.0547 127.782 39.428 127.782 38.655V37.3377C127.782 36.5647 128.408 35.938 129.181 35.938H131.898V40.0547Z" fill="black"/>
            <path d="M135.89 35.9375H131.773V40.0541H135.89V35.9375Z" fill="black"/>
            <path d="M139.882 35.9375H135.766V40.0541H139.882V35.9375Z" fill="black"/>
            <path d="M139.758 35.9375H143.874V38.6545C143.874 39.4275 143.248 40.0541 142.475 40.0541H139.758V35.9375Z" fill="black"/>
            <path d="M159.727 40.0547V37.3377C159.727 36.5647 160.353 35.938 161.126 35.938H162.444C163.217 35.938 163.843 36.5647 163.843 37.3377V40.0547H159.727Z" fill="black"/>
            <path d="M171.828 35.9375V38.6545C171.828 39.4275 171.201 40.0541 170.428 40.0541H169.111C168.338 40.0541 167.711 39.4275 167.711 38.6545V35.9375H171.828Z" fill="black"/>
            <path d="M191.797 35.9375V40.0541H189.08C188.307 40.0541 187.68 39.4275 187.68 38.6545V35.9375H191.797Z" fill="black"/>
            <path d="M195.789 35.9375H191.672V40.0541H195.789V35.9375Z" fill="black"/>
            <path d="M195.664 40.0547V37.3377C195.664 36.5647 196.291 35.938 197.064 35.938H198.381C199.154 35.938 199.781 36.5647 199.781 37.3377V40.0547H195.664Z" fill="black"/>
            <path d="M211.762 35.9375V38.6545C211.762 39.4275 211.135 40.0541 210.362 40.0541H209.045C208.272 40.0541 207.645 39.4275 207.645 38.6545V35.9375H211.762Z" fill="black"/>
            <path d="M12.101 39.9297H7.98438V44.0463H12.101V39.9297Z" fill="black"/>
            <path d="M11.9766 44.0469V41.3299C11.9766 40.5569 12.6032 39.9302 13.3762 39.9302H14.6935C15.4666 39.9302 16.0932 40.5569 16.0932 41.3299V44.0469H11.9766Z" fill="black"/>
            <path d="M24.082 39.9297V44.0463H21.3651C20.592 44.0463 19.9654 43.4197 19.9654 42.6467V39.9297H24.082Z" fill="black"/>
            <path d="M28.0776 39.9297H23.9609V44.0463H28.0776V39.9297Z" fill="black"/>
            <path d="M27.9531 44.0469V39.9302H30.6701C31.4431 39.9302 32.0698 40.5569 32.0698 41.3299V44.0469H27.9531Z" fill="black"/>
            <path d="M56.0273 39.9297V44.0463H53.3104C52.5374 44.0463 51.9107 43.4197 51.9107 42.6467V39.9297H56.0273Z" fill="black"/>
            <path d="M60.0229 39.9297H55.9062V44.0463H60.0229V39.9297Z" fill="black"/>
            <path d="M59.8984 44.0469V41.3299C59.8984 40.5569 60.5251 39.9302 61.2981 39.9302H62.6154C63.3884 39.9302 64.0151 40.5569 64.0151 41.3299V44.0469H59.8984Z" fill="black"/>
            <path d="M66.75 43.9623V44.0446H68.0056V42.7891H67.9232C67.9232 43.4395 67.3963 43.9623 66.75 43.9623Z" fill="black"/>
            <path d="M67.8828 44.0469V41.3299C67.8828 40.5569 68.5095 39.9302 69.2825 39.9302H70.5998C71.3728 39.9302 71.9995 40.5569 71.9995 41.3299V44.0469H67.8828Z" fill="black"/>
            <path d="M83.9805 39.9297V42.6467C83.9805 43.4197 83.3538 44.0463 82.5808 44.0463H81.2635C80.4905 44.0463 79.8638 43.4197 79.8638 42.6467V39.9297H83.9805Z" fill="black"/>
            <path d="M99.9531 39.9297V44.0463H97.2361C96.4631 44.0463 95.8365 43.4197 95.8365 42.6467V39.9297H99.9531Z" fill="black"/>
            <path d="M99.8281 44.0469V41.3299C99.8281 40.5569 100.455 39.9302 101.228 39.9302H102.545C103.318 39.9302 103.945 40.5569 103.945 41.3299V44.0469H99.8281Z" fill="black"/>
            <path d="M115.926 39.9297V42.6467C115.926 43.4197 115.299 44.0463 114.526 44.0463H113.209C112.436 44.0463 111.809 43.4197 111.809 42.6467V39.9297H115.926Z" fill="black"/>
            <path d="M118.664 43.9623V44.0446H119.92V42.7891H119.837C119.837 43.4395 119.31 43.9623 118.664 43.9623Z" fill="black"/>
            <path d="M123.914 39.9297H119.797V44.0463H123.914V39.9297Z" fill="black"/>
            <path d="M123.789 44.0469V39.9302H126.506C127.279 39.9302 127.906 40.5569 127.906 41.3299V44.0469H123.789Z" fill="black"/>
            <path d="M139.887 39.9297V42.6467C139.887 43.4197 139.26 44.0463 138.487 44.0463H137.17C136.397 44.0463 135.77 43.4197 135.77 42.6467V39.9297H139.887Z" fill="black"/>
            <path d="M151.742 41.107V42.8731C151.742 43.5235 152.269 44.0463 152.92 44.0463H154.686C155.34 44.0463 155.863 43.5194 155.863 42.869V41.107C155.859 40.4525 155.332 39.9297 154.681 39.9297H152.92C152.269 39.9297 151.742 40.4566 151.742 41.107Z" fill="black"/>
            <path d="M163.844 39.9297V42.6467C163.844 43.4197 163.217 44.0463 162.444 44.0463H161.127C160.354 44.0463 159.727 43.4197 159.727 42.6467V39.9297H163.844Z" fill="black"/>
            <path d="M175.824 44.0469H173.107C172.334 44.0469 171.708 43.4202 171.708 42.6472V41.3299C171.708 40.5569 172.334 39.9302 173.107 39.9302H175.824V44.0469Z" fill="black"/>
            <path d="M175.695 44.0469V41.3299C175.695 40.5569 176.322 39.9302 177.095 39.9302H178.412C179.185 39.9302 179.812 40.5569 179.812 41.3299V44.0469H175.695Z" fill="black"/>
            <path d="M182.555 43.9623V44.0446H183.81V42.7891H183.728C183.728 43.4395 183.201 43.9623 182.555 43.9623Z" fill="black"/>
            <path d="M183.684 44.0469V41.3299C183.684 40.5569 184.31 39.9302 185.083 39.9302H186.401C187.174 39.9302 187.8 40.5569 187.8 41.3299V44.0469H183.684Z" fill="black"/>
            <path d="M195.789 39.9297V42.6467C195.789 43.4197 195.162 44.0463 194.389 44.0463H193.072C192.299 44.0463 191.672 43.4197 191.672 42.6467V39.9297H195.789Z" fill="black"/>
            <path d="M6.85156 47.9623V48.0446H8.10714V46.7891H8.0248C8.0248 47.4395 7.49787 47.9623 6.85156 47.9623Z" fill="black"/>
            <path d="M12.101 43.9219H7.98438V48.0385H12.101V43.9219Z" fill="black"/>
            <path d="M32.0703 43.9219V48.0385H29.3533C28.5803 48.0385 27.9537 47.4119 27.9537 46.6389V43.9219H32.0703Z" fill="black"/>
            <path d="M36.062 43.9219H31.9453V48.0385H36.062V43.9219Z" fill="black"/>
            <path d="M40.0541 43.9219H35.9375V48.0385H40.0541V43.9219Z" fill="black"/>
            <path d="M39.9297 48.0391V43.9224H42.6467C43.4197 43.9224 44.0463 44.5491 44.0463 45.3221V48.0391H39.9297Z" fill="black"/>
            <path d="M68.0078 48.0391H65.2908C64.5178 48.0391 63.8912 47.4124 63.8912 46.6394V45.3221C63.8912 44.5491 64.5178 43.9224 65.2908 43.9224H68.0078V48.0391Z" fill="black"/>
            <path d="M71.9995 43.9219H67.8828V48.0385H71.9995V43.9219Z" fill="black"/>
            <path d="M71.875 48.0391V45.3221C71.875 44.5491 72.5016 43.9224 73.2747 43.9224H74.592C75.365 43.9224 75.9916 44.5491 75.9916 45.3221V48.0391H71.875Z" fill="black"/>
            <path d="M87.9727 48.0391H85.2557C84.4827 48.0391 83.856 47.4124 83.856 46.6394V45.3221C83.856 44.5491 84.4827 43.9224 85.2557 43.9224H87.9727V48.0391Z" fill="black"/>
            <path d="M87.8516 48.0391V43.9224H90.5685C91.3416 43.9224 91.9682 44.5491 91.9682 45.3221V48.0391H87.8516Z" fill="black"/>
            <path d="M119.918 48.0391H115.801V45.3221C115.801 44.5491 116.428 43.9224 117.201 43.9224H119.918V48.0391Z" fill="black"/>
            <path d="M123.914 43.9219H119.797V48.0385H123.914V43.9219Z" fill="black"/>
            <path d="M123.789 43.9219H127.906V46.6389C127.906 47.4119 127.279 48.0385 126.506 48.0385H123.789V43.9219Z" fill="black"/>
            <path d="M131.773 45.0992V46.8653C131.773 47.5157 132.3 48.0385 132.951 48.0385H134.717C135.371 48.0385 135.894 47.5116 135.894 46.8612V45.0992C135.89 44.4447 135.363 43.9219 134.713 43.9219H132.951C132.3 43.9219 131.773 44.4488 131.773 45.0992Z" fill="black"/>
            <path d="M143.879 48.0391H139.762V45.3221C139.762 44.5491 140.389 43.9224 141.162 43.9224H143.879V48.0391Z" fill="black"/>
            <path d="M147.871 43.9219H143.754V48.0385H147.871V43.9219Z" fill="black"/>
            <path d="M147.746 48.0391V43.9224H150.463C151.236 43.9224 151.863 44.5491 151.863 45.3221V48.0391H147.746Z" fill="black"/>
            <path d="M155.734 45.0992V46.8653C155.734 47.5157 156.261 48.0385 156.912 48.0385H158.678C159.332 48.0385 159.855 47.5116 159.855 46.8612V45.0992C159.851 44.4447 159.324 43.9219 158.674 43.9219H156.912C156.261 43.9219 155.734 44.4488 155.734 45.0992Z" fill="black"/>
            <path d="M163.719 45.0992V46.8653C163.719 47.5157 164.246 48.0385 164.896 48.0385H166.662C167.317 48.0385 167.84 47.5116 167.84 46.8612V45.0992C167.835 44.4447 167.308 43.9219 166.658 43.9219H164.896C164.246 43.9219 163.719 44.4488 163.719 45.0992Z" fill="black"/>
            <path d="M183.809 48.0391H179.692V45.3221C179.692 44.5491 180.319 43.9224 181.092 43.9224H183.809V48.0391Z" fill="black"/>
            <path d="M183.684 43.9219H187.8V46.6389C187.8 47.4119 187.174 48.0385 186.401 48.0385H183.684V43.9219Z" fill="black"/>
            <path d="M195.664 45.0992V46.8653C195.664 47.5157 196.191 48.0385 196.841 48.0385H198.607C199.262 48.0385 199.785 47.5116 199.785 46.8612V45.0992C199.781 44.4447 199.254 43.9219 198.603 43.9219H196.841C196.191 43.9219 195.664 44.4488 195.664 45.0992Z" fill="black"/>
            <path d="M207.641 48.0391V45.3221C207.641 44.5491 208.267 43.9224 209.04 43.9224H210.358C211.131 43.9224 211.757 44.5491 211.757 45.3221V48.0391H207.641Z" fill="black"/>
            <path d="M8.10938 52.0312H3.99274V49.3143C3.99274 48.5413 4.61938 47.9146 5.39239 47.9146H8.10938V52.0312Z" fill="black"/>
            <path d="M7.98438 47.9141H12.101V50.631C12.101 51.4041 11.4744 52.0307 10.7014 52.0307H7.98438V47.9141Z" fill="black"/>
            <path d="M15.9727 52.0312V49.3143C15.9727 48.5413 16.5993 47.9146 17.3723 47.9146H18.6896C19.4626 47.9146 20.0893 48.5413 20.0893 49.3143V52.0312H15.9727Z" fill="black"/>
            <path d="M23.9609 49.0914V50.8575C23.9609 51.5079 24.4879 52.0307 25.1383 52.0307H26.9043C27.5589 52.0307 28.0817 51.5038 28.0817 50.8533V49.0914C28.0776 48.4369 27.5506 47.9141 26.9002 47.9141H25.1383C24.4879 47.9141 23.9609 48.441 23.9609 49.0914Z" fill="black"/>
            <path d="M36.0625 47.9141V52.0307H33.3455C32.5725 52.0307 31.9459 51.4041 31.9459 50.631V47.9141H36.0625Z" fill="black"/>
            <path d="M40.0541 47.9141H35.9375V52.0307H40.0541V47.9141Z" fill="black"/>
            <path d="M44.0463 47.9141H39.9297V52.0307H44.0463V47.9141Z" fill="black"/>
            <path d="M48.0385 47.9141H43.9219V52.0307H48.0385V47.9141Z" fill="black"/>
            <path d="M47.918 52.0312V49.3143C47.918 48.5413 48.5446 47.9146 49.3176 47.9146H50.6349C51.408 47.9146 52.0346 48.5413 52.0346 49.3143V52.0312H47.918Z" fill="black"/>
            <path d="M79.9883 52.0312H77.2713C76.4983 52.0312 75.8716 51.4046 75.8716 50.6316V49.3143C75.8716 48.5413 76.4983 47.9146 77.2713 47.9146H79.9883V52.0312Z" fill="black"/>
            <path d="M79.8633 52.0312V47.9146H82.5803C83.3533 47.9146 83.9799 48.5413 83.9799 49.3143V52.0312H79.8633Z" fill="black"/>
            <path d="M91.9688 47.9141V50.631C91.9688 51.4041 91.3421 52.0307 90.5691 52.0307H89.2518C88.4788 52.0307 87.8521 51.4041 87.8521 50.631V47.9141H91.9688Z" fill="black"/>
            <path d="M107.812 49.0914V50.8575C107.812 51.5079 108.339 52.0307 108.99 52.0307H110.756C111.41 52.0307 111.933 51.5038 111.933 50.8533V49.0914C111.929 48.4369 111.402 47.9141 110.752 47.9141H108.99C108.339 47.9141 107.812 48.441 107.812 49.0914Z" fill="black"/>
            <path d="M114.672 51.9545V52.0368H115.927V50.7812H115.845C115.845 51.4317 115.318 51.9545 114.672 51.9545Z" fill="black"/>
            <path d="M119.917 47.9141H115.801V52.0307H119.917V47.9141Z" fill="black"/>
            <path d="M123.914 47.9141H119.797V52.0307H123.914V47.9141Z" fill="black"/>
            <path d="M138.625 51.9545V52.0368H139.881V50.7812H139.798C139.798 51.4317 139.271 51.9545 138.625 51.9545Z" fill="black"/>
            <path d="M143.874 47.9141H139.758V52.0307H143.874V47.9141Z" fill="black"/>
            <path d="M147.871 47.9141H143.754V52.0307H147.871V47.9141Z" fill="black"/>
            <path d="M151.863 47.9141H147.746V52.0307H151.863V47.9141Z" fill="black"/>
            <path d="M151.742 52.0312V49.3143C151.742 48.5413 152.369 47.9146 153.142 47.9146H154.459C155.232 47.9146 155.859 48.5413 155.859 49.3143V52.0312H151.742Z" fill="black"/>
            <path d="M158.594 51.9545V52.0368H159.849V50.7812H159.767C159.767 51.4317 159.24 51.9545 158.594 51.9545Z" fill="black"/>
            <path d="M159.727 52.0312V49.3143C159.727 48.5413 160.353 47.9146 161.126 47.9146H162.444C163.217 47.9146 163.843 48.5413 163.843 49.3143V52.0312H159.727Z" fill="black"/>
            <path d="M166.578 51.9545V52.0368H167.834V50.7812H167.751C167.751 51.4317 167.224 51.9545 166.578 51.9545Z" fill="black"/>
            <path d="M167.711 52.0312V49.3143C167.711 48.5413 168.338 47.9146 169.111 47.9146H170.428C171.201 47.9146 171.828 48.5413 171.828 49.3143V52.0312H167.711Z" fill="black"/>
            <path d="M183.809 47.9141V50.631C183.809 51.4041 183.182 52.0307 182.409 52.0307H181.092C180.319 52.0307 179.692 51.4041 179.692 50.631V47.9141H183.809Z" fill="black"/>
            <path d="M191.797 52.0312H189.08C188.307 52.0312 187.68 51.4046 187.68 50.6316V49.3143C187.68 48.5413 188.307 47.9146 189.08 47.9146H191.797V52.0312Z" fill="black"/>
            <path d="M191.672 52.0312V47.9146H194.389C195.162 47.9146 195.789 48.5413 195.789 49.3143V52.0312H191.672Z" fill="black"/>
            <path d="M199.656 49.0914V50.8575C199.656 51.5079 200.183 52.0307 200.834 52.0307H202.6C203.254 52.0307 203.777 51.5038 203.777 50.8533V49.0914C203.773 48.4369 203.246 47.9141 202.596 47.9141H200.834C200.183 47.9141 199.656 48.441 199.656 49.0914Z" fill="black"/>
            <path d="M206.512 51.9545V52.0368H207.767V50.7812H207.685C207.685 51.4317 207.158 51.9545 206.512 51.9545Z" fill="black"/>
            <path d="M211.757 47.9141H207.641V52.0307H211.757V47.9141Z" fill="black"/>
            <path d="M8.10883 51.9141H3.99219V56.0307H8.10883V51.9141Z" fill="black"/>
            <path d="M14.8398 55.9467V56.029H16.0954V54.7734H16.0131C16.0131 55.4239 15.4862 55.9467 14.8398 55.9467Z" fill="black"/>
            <path d="M20.0893 51.9141H15.9727V56.0307H20.0893V51.9141Z" fill="black"/>
            <path d="M19.9648 56.0312V53.3143C19.9648 52.5413 20.5915 51.9146 21.3645 51.9146H22.6818C23.4548 51.9146 24.0815 52.5413 24.0815 53.3143V56.0312H19.9648Z" fill="black"/>
            <path d="M26.8203 55.9467V56.029H28.0759V54.7734H27.9936C27.9936 55.4239 27.4666 55.9467 26.8203 55.9467Z" fill="black"/>
            <path d="M27.9531 56.0312V53.3143C27.9531 52.5413 28.5798 51.9146 29.3528 51.9146H30.6701C31.4431 51.9146 32.0698 52.5413 32.0698 53.3143V56.0312H27.9531Z" fill="black"/>
            <path d="M40.0547 51.9141V56.0307H37.3377C36.5647 56.0307 35.938 55.4041 35.938 54.631V51.9141H40.0547Z" fill="black"/>
            <path d="M44.0463 51.9141H39.9297V56.0307H44.0463V51.9141Z" fill="black"/>
            <path d="M48.0385 51.9141H43.9219V56.0307H48.0385V51.9141Z" fill="black"/>
            <path d="M51.9102 53.0914V54.8575C51.9102 55.5079 52.4371 56.0307 53.0875 56.0307H54.8536C55.5081 56.0307 56.0309 55.5038 56.0309 54.8533V53.0914C56.0268 52.4369 55.4999 51.9141 54.8494 51.9141H53.0875C52.4371 51.9141 51.9102 52.441 51.9102 53.0914Z" fill="black"/>
            <path d="M63.8906 56.0312V53.3143C63.8906 52.5413 64.5173 51.9146 65.2903 51.9146H66.6076C67.3806 51.9146 68.0073 52.5413 68.0073 53.3143V56.0312H63.8906Z" fill="black"/>
            <path d="M71.875 56.0312V53.3143C71.875 52.5413 72.5016 51.9146 73.2747 51.9146H74.592C75.365 51.9146 75.9916 52.5413 75.9916 53.3143V56.0312H71.875Z" fill="black"/>
            <path d="M83.9805 51.9141V56.0307H81.2635C80.4905 56.0307 79.8638 55.4041 79.8638 54.631V51.9141H83.9805Z" fill="black"/>
            <path d="M83.8555 56.0312V51.9146H86.5724C87.3455 51.9146 87.9721 52.5413 87.9721 53.3143V56.0312H83.8555Z" fill="black"/>
            <path d="M103.945 56.0312H99.8287V53.3143C99.8287 52.5413 100.455 51.9146 101.228 51.9146H103.945V56.0312Z" fill="black"/>
            <path d="M103.82 56.0312V51.9146H106.537C107.31 51.9146 107.937 52.5413 107.937 53.3143V56.0312H103.82Z" fill="black"/>
            <path d="M115.926 56.0312H113.209C112.436 56.0312 111.809 55.4046 111.809 54.6316V53.3143C111.809 52.5413 112.436 51.9146 113.209 51.9146H115.926V56.0312Z" fill="black"/>
            <path d="M119.917 51.9141H115.801V56.0307H119.917V51.9141Z" fill="black"/>
            <path d="M123.914 51.9141H119.797V56.0307H123.914V51.9141Z" fill="black"/>
            <path d="M127.781 53.0914V54.8575C127.781 55.5079 128.308 56.0307 128.959 56.0307H130.725C131.379 56.0307 131.902 55.5038 131.902 54.8533V53.0914C131.898 52.4369 131.371 51.9141 130.721 51.9141H128.959C128.308 51.9141 127.781 52.441 127.781 53.0914Z" fill="black"/>
            <path d="M134.633 55.9467V56.029H135.888V54.7734H135.806C135.806 55.4239 135.279 55.9467 134.633 55.9467Z" fill="black"/>
            <path d="M139.887 56.0312H135.77V53.3143C135.77 52.5413 136.397 51.9146 137.17 51.9146H139.887V56.0312Z" fill="black"/>
            <path d="M143.874 51.9141H139.758V56.0307H143.874V51.9141Z" fill="black"/>
            <path d="M147.871 51.9141H143.754V56.0307H147.871V51.9141Z" fill="black"/>
            <path d="M147.746 51.9141H151.863V54.631C151.863 55.4041 151.236 56.0307 150.463 56.0307H147.746V51.9141Z" fill="black"/>
            <path d="M159.852 56.0312H157.135C156.362 56.0312 155.735 55.4046 155.735 54.6316V53.3143C155.735 52.5413 156.362 51.9146 157.135 51.9146H159.852V56.0312Z" fill="black"/>
            <path d="M163.843 51.9141H159.727V56.0307H163.843V51.9141Z" fill="black"/>
            <path d="M167.835 51.9141H163.719V56.0307H167.835V51.9141Z" fill="black"/>
            <path d="M167.711 51.9141H171.828V54.631C171.828 55.4041 171.201 56.0307 170.428 56.0307H167.711V51.9141Z" fill="black"/>
            <path d="M195.789 51.9141V56.0307H193.072C192.299 56.0307 191.672 55.4041 191.672 54.631V51.9141H195.789Z" fill="black"/>
            <path d="M195.664 56.0312V51.9146H198.381C199.154 51.9146 199.781 52.5413 199.781 53.3143V56.0312H195.664Z" fill="black"/>
            <path d="M207.77 56.0312H203.653V53.3143C203.653 52.5413 204.28 51.9146 205.053 51.9146H207.77V56.0312Z" fill="black"/>
            <path d="M207.641 51.9141H211.757V54.631C211.757 55.4041 211.131 56.0307 210.358 56.0307H207.641V51.9141Z" fill="black"/>
            <path d="M8.10938 55.9062V58.6232C8.10938 59.3962 7.48273 60.0229 6.70972 60.0229H5.39239C4.61938 60.0229 3.99274 59.3962 3.99274 58.6232V55.9062H8.10938Z" fill="black"/>
            <path d="M16.0977 60.0234H11.981V57.3065C11.981 56.5334 12.6077 55.9068 13.3807 55.9068H16.0977V60.0234Z" fill="black"/>
            <path d="M20.0893 55.9062H15.9727V60.0229H20.0893V55.9062Z" fill="black"/>
            <path d="M28.0781 60.0234H25.3611C24.5881 60.0234 23.9615 59.3968 23.9615 58.6238V57.3065C23.9615 56.5334 24.5881 55.9068 25.3611 55.9068H28.0781V60.0234Z" fill="black"/>
            <path d="M32.0698 55.9062H27.9531V60.0229H32.0698V55.9062Z" fill="black"/>
            <path d="M31.9453 60.0234V55.9068H34.6623C35.4353 55.9068 36.062 56.5334 36.062 57.3065V60.0234H31.9453Z" fill="black"/>
            <path d="M38.7969 59.9389V60.0212H40.0524V58.7656H39.9701C39.9701 59.4161 39.4432 59.9389 38.7969 59.9389Z" fill="black"/>
            <path d="M44.0463 55.9062H39.9297V60.0229H44.0463V55.9062Z" fill="black"/>
            <path d="M48.0385 55.9062H43.9219V60.0229H48.0385V55.9062Z" fill="black"/>
            <path d="M47.918 60.0234V55.9068H50.6349C51.408 55.9068 52.0346 56.5334 52.0346 57.3065V60.0234H47.918Z" fill="black"/>
            <path d="M55.9062 57.0836V58.8496C55.9062 59.5001 56.4332 60.0229 57.0836 60.0229H58.8496C59.5042 60.0229 60.027 59.496 60.027 58.8455V57.0836C60.0229 56.4291 59.496 55.9062 58.8455 55.9062H57.0836C56.4332 55.9062 55.9062 56.4332 55.9062 57.0836Z" fill="black"/>
            <path d="M68.0078 55.9062V58.6232C68.0078 59.3962 67.3812 60.0229 66.6082 60.0229H65.2908C64.5178 60.0229 63.8912 59.3962 63.8912 58.6232V55.9062H68.0078Z" fill="black"/>
            <path d="M75.9916 55.9062H71.875V60.0229H75.9916V55.9062Z" fill="black"/>
            <path d="M75.8672 60.0234V55.9068H78.5842C79.3572 55.9068 79.9838 56.5334 79.9838 57.3065V60.0234H75.8672Z" fill="black"/>
            <path d="M87.9727 55.9062V60.0229H85.2557C84.4827 60.0229 83.856 59.3962 83.856 58.6232V55.9062H87.9727Z" fill="black"/>
            <path d="M87.8516 60.0234V55.9068H90.5685C91.3416 55.9068 91.9682 56.5334 91.9682 57.3065V60.0234H87.8516Z" fill="black"/>
            <path d="M103.945 55.9062H99.8281V60.0229H103.945V55.9062Z" fill="black"/>
            <path d="M107.937 55.9062H103.82V60.0229H107.937V55.9062Z" fill="black"/>
            <path d="M107.812 60.0234V55.9068H110.529C111.302 55.9068 111.929 56.5334 111.929 57.3065V60.0234H107.812Z" fill="black"/>
            <path d="M119.918 55.9062V60.0229H117.201C116.428 60.0229 115.801 59.3962 115.801 58.6232V55.9062H119.918Z" fill="black"/>
            <path d="M123.914 55.9062H119.797V60.0229H123.914V55.9062Z" fill="black"/>
            <path d="M123.789 60.0234V57.3065C123.789 56.5334 124.416 55.9068 125.189 55.9068H126.506C127.279 55.9068 127.906 56.5334 127.906 57.3065V60.0234H123.789Z" fill="black"/>
            <path d="M135.891 60.0234H131.774V57.3065C131.774 56.5334 132.401 55.9068 133.174 55.9068H135.891V60.0234Z" fill="black"/>
            <path d="M139.882 55.9062H135.766V60.0229H139.882V55.9062Z" fill="black"/>
            <path d="M143.874 55.9062H139.758V60.0229H143.874V55.9062Z" fill="black"/>
            <path d="M143.754 55.9062H147.871V58.6232C147.871 59.3962 147.244 60.0229 146.471 60.0229H143.754V55.9062Z" fill="black"/>
            <path d="M151.742 57.0836V58.8496C151.742 59.5001 152.269 60.0229 152.92 60.0229H154.686C155.34 60.0229 155.863 59.496 155.863 58.8455V57.0836C155.859 56.4291 155.332 55.9062 154.681 55.9062H152.92C152.269 55.9062 151.742 56.4332 151.742 57.0836Z" fill="black"/>
            <path d="M163.844 55.9062V60.0229H161.127C160.354 60.0229 159.727 59.3962 159.727 58.6232V55.9062H163.844Z" fill="black"/>
            <path d="M167.835 55.9062H163.719V60.0229H167.835V55.9062Z" fill="black"/>
            <path d="M175.695 57.0836V58.8496C175.695 59.5001 176.222 60.0229 176.873 60.0229H178.639C179.293 60.0229 179.816 59.496 179.816 58.8455V57.0836C179.812 56.4291 179.285 55.9062 178.635 55.9062H176.873C176.222 55.9062 175.695 56.4332 175.695 57.0836Z" fill="black"/>
            <path d="M183.684 57.0836V58.8496C183.684 59.5001 184.211 60.0229 184.861 60.0229H186.627C187.282 60.0229 187.804 59.496 187.804 58.8455V57.0836C187.8 56.4291 187.273 55.9062 186.623 55.9062H184.861C184.211 55.9062 183.684 56.4332 183.684 57.0836Z" fill="black"/>
            <path d="M199.781 55.9062V58.6232C199.781 59.3962 199.155 60.0229 198.382 60.0229H197.064C196.291 60.0229 195.665 59.3962 195.665 58.6232V55.9062H199.781Z" fill="black"/>
            <path d="M207.77 55.9062V58.6232C207.77 59.3962 207.143 60.0229 206.37 60.0229H205.053C204.28 60.0229 203.653 59.3962 203.653 58.6232V55.9062H207.77Z" fill="black"/>
            <path d="M0 64.0156L0 61.2986C0 60.5256 0.626648 59.899 1.39966 59.899H2.71698C3.48999 59.899 4.11664 60.5256 4.11664 61.2986V64.0156H0Z" fill="black"/>
            <path d="M16.0977 59.8984V64.0151H13.3807C12.6077 64.0151 11.981 63.3884 11.981 62.6154V59.8984H16.0977Z" fill="black"/>
            <path d="M20.0893 59.8984H15.9727V64.0151H20.0893V59.8984Z" fill="black"/>
            <path d="M36.0625 59.8984V64.0151H33.3455C32.5725 64.0151 31.9459 63.3884 31.9459 62.6154V59.8984H36.0625Z" fill="black"/>
            <path d="M40.0541 59.8984H35.9375V64.0151H40.0541V59.8984Z" fill="black"/>
            <path d="M44.0463 59.8984H39.9297V64.0151H44.0463V59.8984Z" fill="black"/>
            <path d="M52.0352 59.8984V64.0151H49.3182C48.5452 64.0151 47.9185 63.3884 47.9185 62.6154V59.8984H52.0352Z" fill="black"/>
            <path d="M51.9102 64.0156V61.2986C51.9102 60.5256 52.5368 59.899 53.3098 59.899H54.6271C55.4001 59.899 56.0268 60.5256 56.0268 61.2986V64.0156H51.9102Z" fill="black"/>
            <path d="M58.7656 63.9311V64.0134H60.0212V62.7578H59.9389C59.9389 63.4082 59.4119 63.9311 58.7656 63.9311Z" fill="black"/>
            <path d="M59.8984 64.0156V61.2986C59.8984 60.5256 60.5251 59.899 61.2981 59.899H62.6154C63.3884 59.899 64.0151 60.5256 64.0151 61.2986V64.0156H59.8984Z" fill="black"/>
            <path d="M75.9961 59.8984V64.0151H73.2791C72.5061 64.0151 71.8795 63.3884 71.8795 62.6154V59.8984H75.9961Z" fill="black"/>
            <path d="M79.9838 59.8984H75.8672V64.0151H79.9838V59.8984Z" fill="black"/>
            <path d="M79.8633 64.0156V61.2986C79.8633 60.5256 80.4899 59.899 81.2629 59.899H82.5803C83.3533 59.899 83.9799 60.5256 83.9799 61.2986V64.0156H79.8633Z" fill="black"/>
            <path d="M91.9682 59.8984H87.8516V64.0151H91.9682V59.8984Z" fill="black"/>
            <path d="M98.6953 63.9311V64.0134H99.9509V62.7578H99.8686C99.8686 63.4082 99.3416 63.9311 98.6953 63.9311Z" fill="black"/>
            <path d="M103.945 59.8984H99.8281V64.0151H103.945V59.8984Z" fill="black"/>
            <path d="M107.937 59.8984H103.82V64.0151H107.937V59.8984Z" fill="black"/>
            <path d="M107.812 59.8984H111.929V62.6154C111.929 63.3884 111.302 64.0151 110.529 64.0151H107.812V59.8984Z" fill="black"/>
            <path d="M123.914 59.8984V62.6154C123.914 63.3884 123.287 64.0151 122.514 64.0151H121.197C120.424 64.0151 119.797 63.3884 119.797 62.6154V59.8984H123.914Z" fill="black"/>
            <path d="M135.891 59.8984V62.6154C135.891 63.3884 135.264 64.0151 134.491 64.0151H133.174C132.401 64.0151 131.774 63.3884 131.774 62.6154V59.8984H135.891Z" fill="black"/>
            <path d="M155.734 61.0758V62.8418C155.734 63.4923 156.261 64.0151 156.912 64.0151H158.678C159.332 64.0151 159.855 63.4881 159.855 62.8377V61.0758C159.851 60.4213 159.324 59.8984 158.674 59.8984H156.912C156.261 59.8984 155.734 60.4254 155.734 61.0758Z" fill="black"/>
            <path d="M167.836 59.8984V64.0151H165.119C164.346 64.0151 163.719 63.3884 163.719 62.6154V59.8984H167.836Z" fill="black"/>
            <path d="M171.828 59.8984H167.711V64.0151H171.828V59.8984Z" fill="black"/>
            <path d="M171.703 64.0156V61.2986C171.703 60.5256 172.33 59.899 173.103 59.899H174.42C175.193 59.899 175.82 60.5256 175.82 61.2986V64.0156H171.703Z" fill="black"/>
            <path d="M4.11664 63.8906H0V68.0073H4.11664V63.8906Z" fill="black"/>
            <path d="M7.98438 68.0078V65.2908C7.98438 64.5178 8.61102 63.8912 9.38403 63.8912H10.7014C11.4744 63.8912 12.101 64.5178 12.101 65.2908V68.0078H7.98438Z" fill="black"/>
            <path d="M20.0898 63.8906V66.6076C20.0898 67.3806 19.4632 68.0073 18.6902 68.0073H17.3729C16.5999 68.0073 15.9732 67.3806 15.9732 66.6076V63.8906H20.0898Z" fill="black"/>
            <path d="M28.0781 68.0078H25.3611C24.5881 68.0078 23.9615 67.3812 23.9615 66.6082V65.2908C23.9615 64.5178 24.5881 63.8912 25.3611 63.8912H28.0781V68.0078Z" fill="black"/>
            <path d="M27.9531 68.0078V65.2908C27.9531 64.5178 28.5798 63.8912 29.3528 63.8912H30.6701C31.4431 63.8912 32.0698 64.5178 32.0698 65.2908V68.0078H27.9531Z" fill="black"/>
            <path d="M44.0508 63.8906V68.0073H41.3338C40.5608 68.0073 39.9341 67.3806 39.9341 66.6076V63.8906H44.0508Z" fill="black"/>
            <path d="M43.9219 68.0078V63.8912H46.6389C47.4119 63.8912 48.0385 64.5178 48.0385 65.2908V68.0078H43.9219Z" fill="black"/>
            <path d="M60.0234 68.0078H57.3065C56.5334 68.0078 55.9068 67.3812 55.9068 66.6082V65.2908C55.9068 64.5178 56.5334 63.8912 57.3065 63.8912H60.0234V68.0078Z" fill="black"/>
            <path d="M64.0151 63.8906H59.8984V68.0073H64.0151V63.8906Z" fill="black"/>
            <path d="M91.9688 63.8906V66.6076C91.9688 67.3806 91.3421 68.0073 90.5691 68.0073H89.2518C88.4788 68.0073 87.8521 67.3806 87.8521 66.6076V63.8906H91.9688Z" fill="black"/>
            <path d="M99.9531 68.0078H97.2361C96.4631 68.0078 95.8365 67.3812 95.8365 66.6082V65.2908C95.8365 64.5178 96.4631 63.8912 97.2361 63.8912H99.9531V68.0078Z" fill="black"/>
            <path d="M99.8281 63.8906H103.945V66.6076C103.945 67.3806 103.318 68.0073 102.545 68.0073H99.8281V63.8906Z" fill="black"/>
            <path d="M110.676 67.9232V68.0056H111.931V66.75H111.849C111.849 67.4004 111.322 67.9232 110.676 67.9232Z" fill="black"/>
            <path d="M111.809 68.0078V65.2908C111.809 64.5178 112.435 63.8912 113.208 63.8912H114.526C115.299 63.8912 115.925 64.5178 115.925 65.2908V68.0078H111.809Z" fill="black"/>
            <path d="M126.648 67.9232V68.0056H127.904V66.75H127.822C127.822 67.4004 127.295 67.9232 126.648 67.9232Z" fill="black"/>
            <path d="M127.781 68.0078V65.2908C127.781 64.5178 128.408 63.8912 129.181 63.8912H130.498C131.271 63.8912 131.898 64.5178 131.898 65.2908V68.0078H127.781Z" fill="black"/>
            <path d="M138.625 67.9232V68.0056H139.881V66.75H139.798C139.798 67.4004 139.271 67.9232 138.625 67.9232Z" fill="black"/>
            <path d="M143.879 68.0078H139.762V65.2908C139.762 64.5178 140.389 63.8912 141.162 63.8912H143.879V68.0078Z" fill="black"/>
            <path d="M147.871 63.8906H143.754V68.0073H147.871V63.8906Z" fill="black"/>
            <path d="M151.863 63.8906H147.746V68.0073H151.863V63.8906Z" fill="black"/>
            <path d="M151.742 68.0078V65.2908C151.742 64.5178 152.369 63.8912 153.142 63.8912H154.459C155.232 63.8912 155.859 64.5178 155.859 65.2908V68.0078H151.742Z" fill="black"/>
            <path d="M179.816 68.0078H177.099C176.326 68.0078 175.7 67.3812 175.7 66.6082V65.2908C175.7 64.5178 176.326 63.8912 177.099 63.8912H179.816V68.0078Z" fill="black"/>
            <path d="M179.691 68.0078V65.2908C179.691 64.5178 180.318 63.8912 181.091 63.8912H182.408C183.181 63.8912 183.808 64.5178 183.808 65.2908V68.0078H179.691Z" fill="black"/>
            <path d="M191.797 68.0078H187.68V65.2908C187.68 64.5178 188.307 63.8912 189.08 63.8912H191.797V68.0078Z" fill="black"/>
            <path d="M191.672 68.0078V65.2908C191.672 64.5178 192.299 63.8912 193.072 63.8912H194.389C195.162 63.8912 195.789 64.5178 195.789 65.2908V68.0078H191.672Z" fill="black"/>
            <path d="M203.773 68.0078H201.056C200.283 68.0078 199.657 67.3812 199.657 66.6082V65.2908C199.657 64.5178 200.283 63.8912 201.056 63.8912H203.773V68.0078Z" fill="black"/>
            <path d="M207.765 63.8906H203.648V68.0073H207.765V63.8906Z" fill="black"/>
            <path d="M207.641 68.0078V63.8912H210.358C211.131 63.8912 211.757 64.5178 211.757 65.2908V68.0078H207.641Z" fill="black"/>
            <path d="M4.11664 67.8828H0V71.9995H4.11664V67.8828Z" fill="black"/>
            <path d="M12.101 67.8828H7.98438V71.9995H12.101V67.8828Z" fill="black"/>
            <path d="M35.9375 72V69.283C35.9375 68.51 36.5641 67.8834 37.3372 67.8834H38.6545C39.4275 67.8834 40.0541 68.51 40.0541 69.283V72H35.9375Z" fill="black"/>
            <path d="M48.0385 67.8828H43.9219V71.9995H48.0385V67.8828Z" fill="black"/>
            <path d="M47.918 72V69.283C47.918 68.51 48.5446 67.8834 49.3176 67.8834H50.6349C51.408 67.8834 52.0346 68.51 52.0346 69.283V72H47.918Z" fill="black"/>
            <path d="M64.0156 67.8828V70.5998C64.0156 71.3728 63.389 71.9995 62.616 71.9995H61.2986C60.5256 71.9995 59.899 71.3728 59.899 70.5998V67.8828H64.0156Z" fill="black"/>
            <path d="M71.875 69.0602V70.8262C71.875 71.4766 72.4019 71.9995 73.0524 71.9995H74.8184C75.4729 71.9995 75.9958 71.4725 75.9958 70.8221V69.0602C75.9916 68.4056 75.4647 67.8828 74.8143 67.8828H73.0524C72.4019 67.8828 71.875 68.4097 71.875 69.0602Z" fill="black"/>
            <path d="M83.8555 69.0602V70.8262C83.8555 71.4766 84.3824 71.9995 85.0328 71.9995H86.7989C87.4534 71.9995 87.9762 71.4725 87.9762 70.8221V69.0602C87.9721 68.4056 87.4452 67.8828 86.7947 67.8828H85.0328C84.3824 67.8828 83.8555 68.4097 83.8555 69.0602Z" fill="black"/>
            <path d="M111.934 72H109.217C108.444 72 107.817 71.3734 107.817 70.6003V69.283C107.817 68.51 108.444 67.8834 109.217 67.8834H111.934V72Z" fill="black"/>
            <path d="M115.925 67.8828H111.809V71.9995H115.925V67.8828Z" fill="black"/>
            <path d="M119.917 67.8828H115.801V71.9995H119.917V67.8828Z" fill="black"/>
            <path d="M123.914 67.8828H119.797V71.9995H123.914V67.8828Z" fill="black"/>
            <path d="M127.906 67.8828H123.789V71.9995H127.906V67.8828Z" fill="black"/>
            <path d="M127.781 67.8828H131.898V70.5998C131.898 71.3728 131.271 71.9995 130.498 71.9995H127.781V67.8828Z" fill="black"/>
            <path d="M134.633 71.9154V71.9978H135.888V70.7422H135.806C135.806 71.3926 135.279 71.9154 134.633 71.9154Z" fill="black"/>
            <path d="M139.887 72H135.77V69.283C135.77 68.51 136.397 67.8834 137.17 67.8834H139.887V72Z" fill="black"/>
            <path d="M139.758 67.8828H143.874V70.5998C143.874 71.3728 143.248 71.9995 142.475 71.9995H139.758V67.8828Z" fill="black"/>
            <path d="M151.863 67.8828H147.746V71.9995H151.863V67.8828Z" fill="black"/>
            <path d="M158.594 71.9154V71.9978H159.849V70.7422H159.767C159.767 71.3926 159.24 71.9154 158.594 71.9154Z" fill="black"/>
            <path d="M163.844 72H159.727V69.283C159.727 68.51 160.354 67.8834 161.127 67.8834H163.844V72Z" fill="black"/>
            <path d="M167.835 67.8828H163.719V71.9995H167.835V67.8828Z" fill="black"/>
            <path d="M171.828 67.8828H167.711V71.9995H171.828V67.8828Z" fill="black"/>
            <path d="M171.703 72V67.8834H174.42C175.193 67.8834 175.82 68.51 175.82 69.283V72H171.703Z" fill="black"/>
            <path d="M191.797 67.8828V70.5998C191.797 71.3728 191.17 71.9995 190.397 71.9995H189.08C188.307 71.9995 187.68 71.3728 187.68 70.5998V67.8828H191.797Z" fill="black"/>
            <path d="M195.664 69.0602V70.8262C195.664 71.4766 196.191 71.9995 196.841 71.9995H198.607C199.262 71.9995 199.785 71.4725 199.785 70.8221V69.0602C199.781 68.4056 199.254 67.8828 198.603 67.8828H196.841C196.191 67.8828 195.664 68.4097 195.664 69.0602Z" fill="black"/>
            <path d="M207.77 67.8828V71.9995H205.053C204.28 71.9995 203.653 71.3728 203.653 70.5998V67.8828H207.77Z" fill="black"/>
            <path d="M207.641 67.8828H211.757V70.5998C211.757 71.3728 211.131 71.9995 210.358 71.9995H207.641V67.8828Z" fill="black"/>
            <path d="M4.11719 71.875V74.592C4.11719 75.365 3.49054 75.9916 2.71753 75.9916H1.40021C0.627197 75.9916 0.000549316 75.365 0.000549316 74.592V71.875H4.11719Z" fill="black"/>
            <path d="M12.1055 71.875V75.9916H9.38849C8.61548 75.9916 7.98883 75.365 7.98883 74.592V71.875H12.1055Z" fill="black"/>
            <path d="M16.0932 71.875H11.9766V75.9916H16.0932V71.875Z" fill="black"/>
            <path d="M20.0893 71.875H15.9727V75.9916H20.0893V71.875Z" fill="black"/>
            <path d="M24.0815 71.875H19.9648V75.9916H24.0815V71.875Z" fill="black"/>
            <path d="M28.0776 71.875H23.9609V75.9916H28.0776V71.875Z" fill="black"/>
            <path d="M27.9531 75.9922V71.8755H30.6701C31.4431 71.8755 32.0698 72.5022 32.0698 73.2752V75.9922H27.9531Z" fill="black"/>
            <path d="M34.8047 75.9076V75.9899H36.0603V74.7344H35.9779C35.9779 75.3848 35.451 75.9076 34.8047 75.9076Z" fill="black"/>
            <path d="M40.0541 71.875H35.9375V75.9916H40.0541V71.875Z" fill="black"/>
            <path d="M48.043 71.875V74.592C48.043 75.365 47.4163 75.9916 46.6433 75.9916H45.326C44.553 75.9916 43.9263 75.365 43.9263 74.592V71.875H48.043Z" fill="black"/>
            <path d="M67.8828 73.0524V74.8184C67.8828 75.4688 68.4097 75.9916 69.0602 75.9916H70.8262C71.4808 75.9916 72.0036 75.4647 72.0036 74.8143V73.0524C71.9995 72.3978 71.4725 71.875 70.8221 71.875H69.0602C68.4097 71.875 67.8828 72.4019 67.8828 73.0524Z" fill="black"/>
            <path d="M79.8633 73.0524V74.8184C79.8633 75.4688 80.3902 75.9916 81.0406 75.9916H82.8067C83.4612 75.9916 83.984 75.4647 83.984 74.8143V73.0524C83.9799 72.3978 83.453 71.875 82.8026 71.875H81.0406C80.3902 71.875 79.8633 72.4019 79.8633 73.0524Z" fill="black"/>
            <path d="M95.8359 73.0524V74.8184C95.8359 75.4688 96.3629 75.9916 97.0133 75.9916H98.7793C99.4339 75.9916 99.9567 75.4647 99.9567 74.8143V73.0524C99.9526 72.3978 99.4256 71.875 98.7752 71.875H97.0133C96.3629 71.875 95.8359 72.4019 95.8359 73.0524Z" fill="black"/>
            <path d="M102.688 75.9076V75.9899H103.943V74.7344H103.861C103.861 75.3848 103.334 75.9076 102.688 75.9076Z" fill="black"/>
            <path d="M103.82 75.9922V73.2752C103.82 72.5022 104.447 71.8755 105.22 71.8755H106.537C107.31 71.8755 107.937 72.5022 107.937 73.2752V75.9922H103.82Z" fill="black"/>
            <path d="M115.926 71.875V75.9916H113.209C112.436 75.9916 111.809 75.365 111.809 74.592V71.875H115.926Z" fill="black"/>
            <path d="M119.917 71.875H115.801V75.9916H119.917V71.875Z" fill="black"/>
            <path d="M123.914 71.875H119.797V75.9916H123.914V71.875Z" fill="black"/>
            <path d="M123.789 71.875H127.906V74.592C127.906 75.365 127.279 75.9916 126.506 75.9916H123.789V71.875Z" fill="black"/>
            <path d="M135.891 75.9922H131.774V73.2752C131.774 72.5022 132.401 71.8755 133.174 71.8755H135.891V75.9922Z" fill="black"/>
            <path d="M139.882 71.875H135.766V75.9916H139.882V71.875Z" fill="black"/>
            <path d="M151.863 71.875V74.592C151.863 75.365 151.237 75.9916 150.464 75.9916H149.146C148.373 75.9916 147.747 75.365 147.747 74.592V71.875H151.863Z" fill="black"/>
            <path d="M159.852 75.9922H155.735V73.2752C155.735 72.5022 156.362 71.8755 157.135 71.8755H159.852V75.9922Z" fill="black"/>
            <path d="M163.843 71.875H159.727V75.9916H163.843V71.875Z" fill="black"/>
            <path d="M175.824 71.875V74.592C175.824 75.365 175.198 75.9916 174.425 75.9916H173.107C172.334 75.9916 171.708 75.365 171.708 74.592V71.875H175.824Z" fill="black"/>
            <path d="M183.684 75.9922V73.2752C183.684 72.5022 184.31 71.8755 185.083 71.8755H186.401C187.174 71.8755 187.8 72.5022 187.8 73.2752V75.9922H183.684Z" fill="black"/>
            <path d="M191.672 73.0524V74.8184C191.672 75.4688 192.199 75.9916 192.849 75.9916H194.615C195.27 75.9916 195.793 75.4647 195.793 74.8143V73.0524C195.789 72.3978 195.262 71.875 194.611 71.875H192.849C192.199 71.875 191.672 72.4019 191.672 73.0524Z" fill="black"/>
            <path d="M199.656 73.0524V74.8184C199.656 75.4688 200.183 75.9916 200.834 75.9916H202.6C203.254 75.9916 203.777 75.4647 203.777 74.8143V73.0524C203.773 72.3978 203.246 71.875 202.596 71.875H200.834C200.183 71.875 199.656 72.4019 199.656 73.0524Z" fill="black"/>
            <path d="M32.0703 75.8672V79.9838H29.3533C28.5803 79.9838 27.9537 79.3572 27.9537 78.5842V75.8672H32.0703Z" fill="black"/>
            <path d="M36.062 75.8672H31.9453V79.9838H36.062V75.8672Z" fill="black"/>
            <path d="M35.9375 75.8672H40.0541V78.5842C40.0541 79.3572 39.4275 79.9838 38.6545 79.9838H35.9375V75.8672Z" fill="black"/>
            <path d="M46.7852 79.9076V79.9899H48.0407V78.7344H47.9584C47.9584 79.3848 47.4315 79.9076 46.7852 79.9076Z" fill="black"/>
            <path d="M47.918 79.9844V77.2674C47.918 76.4944 48.5446 75.8677 49.3176 75.8677H50.6349C51.408 75.8677 52.0346 76.4944 52.0346 77.2674V79.9844H47.918Z" fill="black"/>
            <path d="M59.8984 77.0445V78.8106C59.8984 79.461 60.4254 79.9838 61.0758 79.9838H62.8418C63.4964 79.9838 64.0192 79.4569 64.0192 78.8065V77.0445C64.0151 76.39 63.4881 75.8672 62.8377 75.8672H61.0758C60.4254 75.8672 59.8984 76.3941 59.8984 77.0445Z" fill="black"/>
            <path d="M91.8438 77.0445V78.8106C91.8438 79.461 92.3707 79.9838 93.0211 79.9838H94.7871C95.4417 79.9838 95.9645 79.4569 95.9645 78.8065V77.0445C95.9604 76.39 95.4335 75.8672 94.783 75.8672H93.0211C92.3707 75.8672 91.8438 76.3941 91.8438 77.0445Z" fill="black"/>
            <path d="M98.6953 79.9076V79.9899H99.9509V78.7344H99.8686C99.8686 79.3848 99.3416 79.9076 98.6953 79.9076Z" fill="black"/>
            <path d="M103.945 79.9844H99.8287V77.2674C99.8287 76.4944 100.455 75.8677 101.228 75.8677H103.945V79.9844Z" fill="black"/>
            <path d="M107.937 75.8672H103.82V79.9838H107.937V75.8672Z" fill="black"/>
            <path d="M107.812 79.9844V75.8677H110.529C111.302 75.8677 111.929 76.4944 111.929 77.2674V79.9844H107.812Z" fill="black"/>
            <path d="M114.672 79.9076V79.9899H115.927V78.7344H115.845C115.845 79.3848 115.318 79.9076 114.672 79.9076Z" fill="black"/>
            <path d="M119.917 75.8672H115.801V79.9838H119.917V75.8672Z" fill="black"/>
            <path d="M123.914 75.8672H119.797V79.9838H123.914V75.8672Z" fill="black"/>
            <path d="M135.89 75.8672H131.773V79.9838H135.89V75.8672Z" fill="black"/>
            <path d="M139.882 75.8672H135.766V79.9838H139.882V75.8672Z" fill="black"/>
            <path d="M143.874 75.8672H139.758V79.9838H143.874V75.8672Z" fill="black"/>
            <path d="M143.754 79.9844V75.8677H146.471C147.244 75.8677 147.871 76.4944 147.871 77.2674V79.9844H143.754Z" fill="black"/>
            <path d="M159.852 75.8672V79.9838H157.135C156.362 79.9838 155.735 79.3572 155.735 78.5842V75.8672H159.852Z" fill="black"/>
            <path d="M159.727 75.8672H163.843V78.5842C163.843 79.3572 163.217 79.9838 162.444 79.9838H159.727V75.8672Z" fill="black"/>
            <path d="M167.711 77.0445V78.8106C167.711 79.461 168.238 79.9838 168.888 79.9838H170.654C171.309 79.9838 171.832 79.4569 171.832 78.8065V77.0445C171.828 76.39 171.301 75.8672 170.65 75.8672H168.888C168.238 75.8672 167.711 76.3941 167.711 77.0445Z" fill="black"/>
            <path d="M174.566 79.9076V79.9899H175.822V78.7344H175.74C175.74 79.3848 175.213 79.9076 174.566 79.9076Z" fill="black"/>
            <path d="M175.695 79.9844V77.2674C175.695 76.4944 176.322 75.8677 177.095 75.8677H178.412C179.185 75.8677 179.812 76.4944 179.812 77.2674V79.9844H175.695Z" fill="black"/>
            <path d="M187.8 75.8672H183.684V79.9838H187.8V75.8672Z" fill="black"/>
            <path d="M206.512 79.9076V79.9899H207.767V78.7344H207.685C207.685 79.3848 207.158 79.9076 206.512 79.9076Z" fill="black"/>
            <path d="M207.641 79.9844V77.2674C207.641 76.4944 208.267 75.8677 209.04 75.8677H210.358C211.131 75.8677 211.757 76.4944 211.757 77.2674V79.9844H207.641Z" fill="black"/>
            <path d="M6.85156 83.8998V83.9821H8.10714V82.7266H8.0248C8.0248 83.377 7.49787 83.8998 6.85156 83.8998Z" fill="black"/>
            <path d="M7.98438 83.9766V81.2596C7.98438 80.4866 8.61102 79.8599 9.38403 79.8599H10.7014C11.4744 79.8599 12.101 80.4866 12.101 81.2596V83.9766H7.98438Z" fill="black"/>
            <path d="M14.8398 83.8998V83.9821H16.0954V82.7266H16.0131C16.0131 83.377 15.4862 83.8998 14.8398 83.8998Z" fill="black"/>
            <path d="M15.9727 83.9766V81.2596C15.9727 80.4866 16.5993 79.8599 17.3723 79.8599H18.6896C19.4626 79.8599 20.0893 80.4866 20.0893 81.2596V83.9766H15.9727Z" fill="black"/>
            <path d="M23.9609 81.0367V82.8028C23.9609 83.4532 24.4879 83.976 25.1383 83.976H26.9043C27.5589 83.976 28.0817 83.4491 28.0817 82.7987V81.0367C28.0776 80.3822 27.5506 79.8594 26.9002 79.8594H25.1383C24.4879 79.8594 23.9609 80.3863 23.9609 81.0367Z" fill="black"/>
            <path d="M42.793 83.8998V83.9821H44.0485V82.7266H43.9662C43.9662 83.377 43.4393 83.8998 42.793 83.8998Z" fill="black"/>
            <path d="M48.043 83.9766H43.9263V81.2596C43.9263 80.4866 44.553 79.8599 45.326 79.8599H48.043V83.9766Z" fill="black"/>
            <path d="M47.918 79.8594H52.0346V82.5764C52.0346 83.3494 51.408 83.976 50.6349 83.976H47.918V79.8594Z" fill="black"/>
            <path d="M63.8906 81.0367V82.8028C63.8906 83.4532 64.4176 83.976 65.068 83.976H66.834C67.4886 83.976 68.0114 83.4491 68.0114 82.7987V81.0367C68.0073 80.3822 67.4803 79.8594 66.8299 79.8594H65.068C64.4176 79.8594 63.8906 80.3863 63.8906 81.0367Z" fill="black"/>
            <path d="M82.7266 83.8998V83.9821H83.9821V82.7266H83.8998C83.8998 83.377 83.3729 83.8998 82.7266 83.8998Z" fill="black"/>
            <path d="M87.9727 83.9766H83.856V81.2596C83.856 80.4866 84.4827 79.8599 85.2557 79.8599H87.9727V83.9766Z" fill="black"/>
            <path d="M87.8516 83.9766V81.2596C87.8516 80.4866 88.4782 79.8599 89.2512 79.8599H90.5685C91.3416 79.8599 91.9682 80.4866 91.9682 81.2596V83.9766H87.8516Z" fill="black"/>
            <path d="M99.9531 83.9766H95.8365V81.2596C95.8365 80.4866 96.4631 79.8599 97.2361 79.8599H99.9531V83.9766Z" fill="black"/>
            <path d="M103.945 79.8594H99.8281V83.976H103.945V79.8594Z" fill="black"/>
            <path d="M111.929 79.8594H107.812V83.976H111.929V79.8594Z" fill="black"/>
            <path d="M115.925 79.8594H111.809V83.976H115.925V79.8594Z" fill="black"/>
            <path d="M119.917 79.8594H115.801V83.976H119.917V79.8594Z" fill="black"/>
            <path d="M123.914 79.8594H119.797V83.976H123.914V79.8594Z" fill="black"/>
            <path d="M123.789 83.9766V81.2596C123.789 80.4866 124.416 79.8599 125.189 79.8599H126.506C127.279 79.8599 127.906 80.4866 127.906 81.2596V83.9766H123.789Z" fill="black"/>
            <path d="M135.89 79.8594H131.773V83.976H135.89V79.8594Z" fill="black"/>
            <path d="M147.871 79.8594H143.754V83.976H147.871V79.8594Z" fill="black"/>
            <path d="M147.746 83.9766V81.2596C147.746 80.4866 148.373 79.8599 149.146 79.8599H150.463C151.236 79.8599 151.863 80.4866 151.863 81.2596V83.9766H147.746Z" fill="black"/>
            <path d="M170.57 83.8998V83.9821H171.826V82.7266H171.744C171.744 83.377 171.217 83.8998 170.57 83.8998Z" fill="black"/>
            <path d="M175.824 83.9766H171.708V81.2596C171.708 80.4866 172.334 79.8599 173.107 79.8599H175.824V83.9766Z" fill="black"/>
            <path d="M175.695 79.8594H179.812V82.5764C179.812 83.3494 179.185 83.976 178.412 83.976H175.695V79.8594Z" fill="black"/>
            <path d="M187.801 79.8594V83.976H185.084C184.311 83.976 183.684 83.3494 183.684 82.5764V79.8594H187.801Z" fill="black"/>
            <path d="M191.796 79.8594H187.68V83.976H191.796V79.8594Z" fill="black"/>
            <path d="M191.672 83.9766V79.8599H194.389C195.162 79.8599 195.789 80.4866 195.789 81.2596V83.9766H191.672Z" fill="black"/>
            <path d="M203.773 83.9766H201.056C200.283 83.9766 199.657 83.3499 199.657 82.5769V81.2596C199.657 80.4866 200.283 79.8599 201.056 79.8599H203.773V83.9766Z" fill="black"/>
            <path d="M207.765 79.8594H203.648V83.976H207.765V79.8594Z" fill="black"/>
            <path d="M211.757 79.8594H207.641V83.976H211.757V79.8594Z" fill="black"/>
            <path d="M8.10938 87.9766H5.39239C4.61938 87.9766 3.99274 87.3499 3.99274 86.5769V85.2596C3.99274 84.4866 4.61938 83.8599 5.39239 83.8599H8.10938V87.9766Z" fill="black"/>
            <path d="M12.101 83.8594H7.98438V87.976H12.101V83.8594Z" fill="black"/>
            <path d="M16.0932 83.8594H11.9766V87.976H16.0932V83.8594Z" fill="black"/>
            <path d="M20.0893 83.8594H15.9727V87.976H20.0893V83.8594Z" fill="black"/>
            <path d="M19.9648 87.9766V83.8599H22.6818C23.4548 83.8599 24.0815 84.4866 24.0815 85.2596V87.9766H19.9648Z" fill="black"/>
            <path d="M34.8047 87.892V87.9743H36.0603V86.7188H35.9779C35.9779 87.3692 35.451 87.892 34.8047 87.892Z" fill="black"/>
            <path d="M40.0547 87.9766H35.938V85.2596C35.938 84.4866 36.5647 83.8599 37.3377 83.8599H40.0547V87.9766Z" fill="black"/>
            <path d="M44.0463 83.8594H39.9297V87.976H44.0463V83.8594Z" fill="black"/>
            <path d="M48.0385 83.8594H43.9219V87.976H48.0385V83.8594Z" fill="black"/>
            <path d="M72 87.9766H69.283C68.51 87.9766 67.8834 87.3499 67.8834 86.5769V85.2596C67.8834 84.4866 68.51 83.8599 69.283 83.8599H72V87.9766Z" fill="black"/>
            <path d="M71.875 87.9766V85.2596C71.875 84.4866 72.5016 83.8599 73.2747 83.8599H74.592C75.365 83.8599 75.9916 84.4866 75.9916 85.2596V87.9766H71.875Z" fill="black"/>
            <path d="M83.9805 87.9766H79.8638V85.2596C79.8638 84.4866 80.4905 83.8599 81.2635 83.8599H83.9805V87.9766Z" fill="black"/>
            <path d="M83.8555 83.8594H87.9721V86.5764C87.9721 87.3494 87.3455 87.976 86.5724 87.976H83.8555V83.8594Z" fill="black"/>
            <path d="M94.7031 87.892V87.9743H95.9587V86.7188H95.8764C95.8764 87.3692 95.3494 87.892 94.7031 87.892Z" fill="black"/>
            <path d="M99.9526 83.8594H95.8359V87.976H99.9526V83.8594Z" fill="black"/>
            <path d="M99.8281 83.8594H103.945V86.5764C103.945 87.3494 103.318 87.976 102.545 87.976H99.8281V83.8594Z" fill="black"/>
            <path d="M111.934 83.8594V86.5764C111.934 87.3494 111.307 87.976 110.534 87.976H109.217C108.444 87.976 107.817 87.3494 107.817 86.5764V83.8594H111.934Z" fill="black"/>
            <path d="M123.914 83.8594V86.5764C123.914 87.3494 123.287 87.976 122.514 87.976H121.197C120.424 87.976 119.797 87.3494 119.797 86.5764V83.8594H123.914Z" fill="black"/>
            <path d="M130.641 87.892V87.9743H131.896V86.7188H131.814C131.814 87.3692 131.287 87.892 130.641 87.892Z" fill="black"/>
            <path d="M135.89 83.8594H131.773V87.976H135.89V83.8594Z" fill="black"/>
            <path d="M139.882 83.8594H135.766V87.976H139.882V83.8594Z" fill="black"/>
            <path d="M143.874 83.8594H139.758V87.976H143.874V83.8594Z" fill="black"/>
            <path d="M147.871 83.8594H143.754V87.976H147.871V83.8594Z" fill="black"/>
            <path d="M159.852 87.9766H157.135C156.362 87.9766 155.735 87.3499 155.735 86.5769V85.2596C155.735 84.4866 156.362 83.8599 157.135 83.8599H159.852V87.9766Z" fill="black"/>
            <path d="M163.843 83.8594H159.727V87.976H163.843V83.8594Z" fill="black"/>
            <path d="M167.835 83.8594H163.719V87.976H167.835V83.8594Z" fill="black"/>
            <path d="M171.828 83.8594H167.711V87.976H171.828V83.8594Z" fill="black"/>
            <path d="M175.82 83.8594H171.703V87.976H175.82V83.8594Z" fill="black"/>
            <path d="M195.789 83.8594H191.672V87.976H195.789V83.8594Z" fill="black"/>
            <path d="M195.664 87.9766V85.2596C195.664 84.4866 196.291 83.8599 197.064 83.8599H198.381C199.154 83.8599 199.781 84.4866 199.781 85.2596V87.9766H195.664Z" fill="black"/>
            <path d="M211.762 83.8594V86.5764C211.762 87.3494 211.135 87.976 210.362 87.976H209.045C208.272 87.976 207.645 87.3494 207.645 86.5764V83.8594H211.762Z" fill="black"/>
            <path d="M0 89.0289V90.795C0 91.4454 0.52693 91.9682 1.17736 91.9682H2.9434C3.59794 91.9682 4.12075 91.4413 4.12075 90.7908V89.0289C4.11664 88.3744 3.58971 87.8516 2.93928 87.8516H1.17736C0.52693 87.8516 0 88.3785 0 89.0289Z" fill="black"/>
            <path d="M16.0932 87.8516H11.9766V91.9682H16.0932V87.8516Z" fill="black"/>
            <path d="M24.082 87.8516V91.9682H21.3651C20.592 91.9682 19.9654 91.3416 19.9654 90.5685V87.8516H24.082Z" fill="black"/>
            <path d="M23.9609 91.9688V89.2518C23.9609 88.4788 24.5876 87.8521 25.3606 87.8521H26.6779C27.4509 87.8521 28.0776 88.4788 28.0776 89.2518V91.9688H23.9609Z" fill="black"/>
            <path d="M30.8125 91.8842V91.9665H32.0681V90.7109H31.9857C31.9857 91.3614 31.4588 91.8842 30.8125 91.8842Z" fill="black"/>
            <path d="M36.0625 91.9688H31.9459V89.2518C31.9459 88.4788 32.5725 87.8521 33.3455 87.8521H36.0625V91.9688Z" fill="black"/>
            <path d="M35.9375 87.8516H40.0541V90.5685C40.0541 91.3416 39.4275 91.9682 38.6545 91.9682H35.9375V87.8516Z" fill="black"/>
            <path d="M48.043 87.8516V90.5685C48.043 91.3416 47.4163 91.9682 46.6433 91.9682H45.326C44.553 91.9682 43.9263 91.3416 43.9263 90.5685V87.8516H48.043Z" fill="black"/>
            <path d="M59.8984 89.0289V90.795C59.8984 91.4454 60.4254 91.9682 61.0758 91.9682H62.8418C63.4964 91.9682 64.0192 91.4413 64.0192 90.7908V89.0289C64.0151 88.3744 63.4881 87.8516 62.8377 87.8516H61.0758C60.4254 87.8516 59.8984 88.3785 59.8984 89.0289Z" fill="black"/>
            <path d="M78.7305 91.8842V91.9665H79.986V90.7109H79.9037C79.9037 91.3614 79.3768 91.8842 78.7305 91.8842Z" fill="black"/>
            <path d="M83.9799 87.8516H79.8633V91.9682H83.9799V87.8516Z" fill="black"/>
            <path d="M95.9609 91.9688H91.8443V89.2518C91.8443 88.4788 92.4709 87.8521 93.244 87.8521H95.9609V91.9688Z" fill="black"/>
            <path d="M99.9526 87.8516H95.8359V91.9682H99.9526V87.8516Z" fill="black"/>
            <path d="M127.906 91.9688H125.189C124.416 91.9688 123.79 91.3421 123.79 90.5691V89.2518C123.79 88.4788 124.416 87.8521 125.189 87.8521H127.906V91.9688Z" fill="black"/>
            <path d="M131.898 87.8516H127.781V91.9682H131.898V87.8516Z" fill="black"/>
            <path d="M131.773 87.8516H135.89V90.5685C135.89 91.3416 135.263 91.9682 134.49 91.9682H131.773V87.8516Z" fill="black"/>
            <path d="M147.871 87.8516V91.9682H145.154C144.381 91.9682 143.754 91.3416 143.754 90.5685V87.8516H147.871Z" fill="black"/>
            <path d="M147.746 91.9688V89.2518C147.746 88.4788 148.373 87.8521 149.146 87.8521H150.463C151.236 87.8521 151.863 88.4788 151.863 89.2518V91.9688H147.746Z" fill="black"/>
            <path d="M167.835 87.8516H163.719V91.9682H167.835V87.8516Z" fill="black"/>
            <path d="M175.82 87.8516H171.703V91.9682H175.82V87.8516Z" fill="black"/>
            <path d="M183.809 91.9688H181.092C180.319 91.9688 179.692 91.3421 179.692 90.5691V89.2518C179.692 88.4788 180.319 87.8521 181.092 87.8521H183.809V91.9688Z" fill="black"/>
            <path d="M187.8 87.8516H183.684V91.9682H187.8V87.8516Z" fill="black"/>
            <path d="M191.796 87.8516H187.68V91.9682H191.796V87.8516Z" fill="black"/>
            <path d="M191.672 87.8516H195.789V90.5685C195.789 91.3416 195.162 91.9682 194.389 91.9682H191.672V87.8516Z" fill="black"/>
            <path d="M199.656 89.0289V90.795C199.656 91.4454 200.183 91.9682 200.834 91.9682H202.6C203.254 91.9682 203.777 91.4413 203.777 90.7908V89.0289C203.773 88.3744 203.246 87.8516 202.596 87.8516H200.834C200.183 87.8516 199.656 88.3785 199.656 89.0289Z" fill="black"/>
            <path d="M12.1055 95.9609H7.98883V93.244C7.98883 92.4709 8.61548 91.8443 9.38849 91.8443H12.1055V95.9609Z" fill="black"/>
            <path d="M16.0932 91.8438H11.9766V95.9604H16.0932V91.8438Z" fill="black"/>
            <path d="M15.9727 95.9609V91.8443H18.6896C19.4626 91.8443 20.0893 92.4709 20.0893 93.244V95.9609H15.9727Z" fill="black"/>
            <path d="M26.8203 95.8764V95.9587H28.0759V94.7031H27.9936C27.9936 95.3536 27.4666 95.8764 26.8203 95.8764Z" fill="black"/>
            <path d="M32.0703 95.9609H27.9537V93.244C27.9537 92.4709 28.5803 91.8443 29.3533 91.8443H32.0703V95.9609Z" fill="black"/>
            <path d="M36.062 91.8438H31.9453V95.9604H36.062V91.8438Z" fill="black"/>
            <path d="M39.9297 93.0211V94.7871C39.9297 95.4376 40.4566 95.9604 41.107 95.9604H42.8731C43.5276 95.9604 44.0504 95.4335 44.0504 94.783V93.0211C44.0463 92.3666 43.5194 91.8438 42.869 91.8438H41.107C40.4566 91.8438 39.9297 92.3707 39.9297 93.0211Z" fill="black"/>
            <path d="M52.0352 95.9609H49.3182C48.5452 95.9609 47.9185 95.3343 47.9185 94.5613V93.244C47.9185 92.4709 48.5452 91.8443 49.3182 91.8443H52.0352V95.9609Z" fill="black"/>
            <path d="M51.9102 95.9609V91.8443H54.6271C55.4001 91.8443 56.0268 92.4709 56.0268 93.244V95.9609H51.9102Z" fill="black"/>
            <path d="M72 95.9609H69.283C68.51 95.9609 67.8834 95.3343 67.8834 94.5613V93.244C67.8834 92.4709 68.51 91.8443 69.283 91.8443H72V95.9609Z" fill="black"/>
            <path d="M75.9916 91.8438H71.875V95.9604H75.9916V91.8438Z" fill="black"/>
            <path d="M79.9838 91.8438H75.8672V95.9604H79.9838V91.8438Z" fill="black"/>
            <path d="M83.9799 91.8438H79.8633V95.9604H83.9799V91.8438Z" fill="black"/>
            <path d="M83.8555 95.9609V91.8443H86.5724C87.3455 91.8443 87.9721 92.4709 87.9721 93.244V95.9609H83.8555Z" fill="black"/>
            <path d="M95.9609 91.8438V95.9604H93.244C92.4709 95.9604 91.8443 95.3337 91.8443 94.5607V91.8438H95.9609Z" fill="black"/>
            <path d="M99.9526 91.8438H95.8359V95.9604H99.9526V91.8438Z" fill="black"/>
            <path d="M103.945 91.8438H99.8281V95.9604H103.945V91.8438Z" fill="black"/>
            <path d="M103.82 95.9609V91.8443H106.537C107.31 91.8443 107.937 92.4709 107.937 93.244V95.9609H103.82Z" fill="black"/>
            <path d="M131.898 91.8438V94.5607C131.898 95.3337 131.272 95.9604 130.499 95.9604H129.181C128.408 95.9604 127.782 95.3337 127.782 94.5607V91.8438H131.898Z" fill="black"/>
            <path d="M163.844 95.9609H161.127C160.354 95.9609 159.727 95.3343 159.727 94.5613V93.244C159.727 92.4709 160.354 91.8443 161.127 91.8443H163.844V95.9609Z" fill="black"/>
            <path d="M167.835 91.8438H163.719V95.9604H167.835V91.8438Z" fill="black"/>
            <path d="M171.828 91.8438H167.711V95.9604H171.828V91.8438Z" fill="black"/>
            <path d="M175.82 91.8438H171.703V95.9604H175.82V91.8438Z" fill="black"/>
            <path d="M175.695 95.9609V91.8443H178.412C179.185 91.8443 179.812 92.4709 179.812 93.244V95.9609H175.695Z" fill="black"/>
            <path d="M182.555 95.8764V95.9587H183.81V94.7031H183.728C183.728 95.3536 183.201 95.8764 182.555 95.8764Z" fill="black"/>
            <path d="M187.8 91.8438H183.684V95.9604H187.8V91.8438Z" fill="black"/>
            <path d="M194.531 95.8764V95.9587H195.787V94.7031H195.704C195.704 95.3536 195.178 95.8764 194.531 95.8764Z" fill="black"/>
            <path d="M195.664 95.9609V93.244C195.664 92.4709 196.291 91.8443 197.064 91.8443H198.381C199.154 91.8443 199.781 92.4709 199.781 93.244V95.9609H195.664Z" fill="black"/>
            <path d="M207.641 93.0211V94.7871C207.641 95.4376 208.168 95.9604 208.818 95.9604H210.584C211.239 95.9604 211.761 95.4335 211.761 94.783V93.0211C211.757 92.3666 211.23 91.8438 210.58 91.8438H208.818C208.168 91.8438 207.641 92.3707 207.641 93.0211Z" fill="black"/>
            <path d="M12.1055 95.8359V98.5529C12.1055 99.3259 11.4788 99.9526 10.7058 99.9526H9.38849C8.61548 99.9526 7.98883 99.3259 7.98883 98.5529V95.8359H12.1055Z" fill="black"/>
            <path d="M20.0893 95.8359H15.9727V99.9526H20.0893V95.8359Z" fill="black"/>
            <path d="M24.0815 95.8359H19.9648V99.9526H24.0815V95.8359Z" fill="black"/>
            <path d="M28.0776 95.8359H23.9609V99.9526H28.0776V95.8359Z" fill="black"/>
            <path d="M32.0698 95.8359H27.9531V99.9526H32.0698V95.8359Z" fill="black"/>
            <path d="M36.062 95.8359H31.9453V99.9526H36.062V95.8359Z" fill="black"/>
            <path d="M56.0268 95.8359H51.9102V99.9526H56.0268V95.8359Z" fill="black"/>
            <path d="M64.0156 99.9531H61.2986C60.5256 99.9531 59.899 99.3265 59.899 98.5535V97.2361C59.899 96.4631 60.5256 95.8365 61.2986 95.8365H64.0156V99.9531Z" fill="black"/>
            <path d="M63.8906 99.9531V95.8365H66.6076C67.3806 95.8365 68.0073 96.4631 68.0073 97.2361V99.9531H63.8906Z" fill="black"/>
            <path d="M75.9916 95.8359H71.875V99.9526H75.9916V95.8359Z" fill="black"/>
            <path d="M87.9721 95.8359H83.8555V99.9526H87.9721V95.8359Z" fill="black"/>
            <path d="M99.9526 95.8359H95.8359V99.9526H99.9526V95.8359Z" fill="black"/>
            <path d="M103.945 95.8359H99.8281V99.9526H103.945V95.8359Z" fill="black"/>
            <path d="M107.937 95.8359H103.82V99.9526H107.937V95.8359Z" fill="black"/>
            <path d="M111.929 95.8359H107.812V99.9526H111.929V95.8359Z" fill="black"/>
            <path d="M115.925 95.8359H111.809V99.9526H115.925V95.8359Z" fill="black"/>
            <path d="M119.917 95.8359H115.801V99.9526H119.917V95.8359Z" fill="black"/>
            <path d="M123.914 95.8359H119.797V99.9526H123.914V95.8359Z" fill="black"/>
            <path d="M123.789 99.9531V97.2361C123.789 96.4631 124.416 95.8365 125.189 95.8365H126.506C127.279 95.8365 127.906 96.4631 127.906 97.2361V99.9531H123.789Z" fill="black"/>
            <path d="M143.879 99.9531H141.162C140.389 99.9531 139.762 99.3265 139.762 98.5535V97.2361C139.762 96.4631 140.389 95.8365 141.162 95.8365H143.879V99.9531Z" fill="black"/>
            <path d="M143.754 99.9531V97.2361C143.754 96.4631 144.381 95.8365 145.154 95.8365H146.471C147.244 95.8365 147.871 96.4631 147.871 97.2361V99.9531H143.754Z" fill="black"/>
            <path d="M171.828 95.8359H167.711V99.9526H171.828V95.8359Z" fill="black"/>
            <path d="M179.812 95.8359H175.695V99.9526H179.812V95.8359Z" fill="black"/>
            <path d="M183.808 95.8359H179.691V99.9526H183.808V95.8359Z" fill="black"/>
            <path d="M187.8 95.8359H183.684V99.9526H187.8V95.8359Z" fill="black"/>
            <path d="M191.796 95.8359H187.68V99.9526H191.796V95.8359Z" fill="black"/>
            <path d="M195.789 95.8359H191.672V99.9526H195.789V95.8359Z" fill="black"/>
            <path d="M199.781 95.8359H195.664V99.9526H199.781V95.8359Z" fill="black"/>
            <path d="M199.656 99.9531V97.2361C199.656 96.4631 200.283 95.8365 201.056 95.8365H202.373C203.146 95.8365 203.773 96.4631 203.773 97.2361V99.9531H199.656Z" fill="black"/>
            <path d="M4.11719 103.945H1.40021C0.627197 103.945 0.000549316 103.319 0.000549316 102.546V101.228C0.000549316 100.455 0.627197 99.8287 1.40021 99.8287H4.11719L4.11719 103.945Z" fill="black"/>
            <path d="M3.99219 103.945L3.99219 99.8287H6.70917C7.48218 99.8287 8.10883 100.455 8.10883 101.228V103.945H3.99219Z" fill="black"/>
            <path d="M14.8398 103.861V103.943H16.0954V102.688H16.0131C16.0131 103.338 15.4862 103.861 14.8398 103.861Z" fill="black"/>
            <path d="M20.0893 99.8281H15.9727V103.945H20.0893V99.8281Z" fill="black"/>
            <path d="M36.062 99.8281H31.9453V103.945H36.062V99.8281Z" fill="black"/>
            <path d="M44.0508 103.945H41.3338C40.5608 103.945 39.9341 103.319 39.9341 102.546V101.228C39.9341 100.455 40.5608 99.8287 41.3338 99.8287H44.0508V103.945Z" fill="black"/>
            <path d="M43.9219 103.945V99.8287H46.6389C47.4119 99.8287 48.0385 100.455 48.0385 101.228V103.945H43.9219Z" fill="black"/>
            <path d="M56.0268 99.8281H51.9102V103.945H56.0268V99.8281Z" fill="black"/>
            <path d="M55.9062 103.945V101.228C55.9062 100.455 56.5329 99.8287 57.3059 99.8287H58.6232C59.3962 99.8287 60.0229 100.455 60.0229 101.228V103.945H55.9062Z" fill="black"/>
            <path d="M68.0073 99.8281H63.8906V103.945H68.0073V99.8281Z" fill="black"/>
            <path d="M75.9961 99.8281V102.545C75.9961 103.318 75.3694 103.945 74.5964 103.945H73.2791C72.5061 103.945 71.8795 103.318 71.8795 102.545V99.8281H75.9961Z" fill="black"/>
            <path d="M83.9805 103.945H79.8638V101.228C79.8638 100.455 80.4905 99.8287 81.2635 99.8287H83.9805V103.945Z" fill="black"/>
            <path d="M83.8555 99.8281H87.9721V102.545C87.9721 103.318 87.3455 103.945 86.5724 103.945H83.8555V99.8281Z" fill="black"/>
            <path d="M94.7031 103.861V103.943H95.9587V102.688H95.8764C95.8764 103.338 95.3494 103.861 94.7031 103.861Z" fill="black"/>
            <path d="M99.9526 99.8281H95.8359V103.945H99.9526V99.8281Z" fill="black"/>
            <path d="M115.925 99.8281H111.809V103.945H115.925V99.8281Z" fill="black"/>
            <path d="M119.917 99.8281H115.801V103.945H119.917V99.8281Z" fill="black"/>
            <path d="M119.797 99.8281H123.914V102.545C123.914 103.318 123.287 103.945 122.514 103.945H119.797V99.8281Z" fill="black"/>
            <path d="M135.891 103.945H131.774V101.228C131.774 100.455 132.401 99.8287 133.174 99.8287H135.891V103.945Z" fill="black"/>
            <path d="M135.766 103.945V99.8287H138.483C139.256 99.8287 139.882 100.455 139.882 101.228V103.945H135.766Z" fill="black"/>
            <path d="M147.746 101.005V102.772C147.746 103.422 148.273 103.945 148.923 103.945H150.689C151.344 103.945 151.867 103.418 151.867 102.767V101.005C151.863 100.351 151.336 99.8281 150.685 99.8281H148.923C148.273 99.8281 147.746 100.355 147.746 101.005Z" fill="black"/>
            <path d="M154.602 103.861V103.943H155.857V102.688H155.775C155.775 103.338 155.248 103.861 154.602 103.861Z" fill="black"/>
            <path d="M155.734 103.945V101.228C155.734 100.455 156.361 99.8287 157.134 99.8287H158.451C159.224 99.8287 159.851 100.455 159.851 101.228V103.945H155.734Z" fill="black"/>
            <path d="M171.828 99.8281V103.945H169.111C168.338 103.945 167.711 103.318 167.711 102.545V99.8281H171.828Z" fill="black"/>
            <path d="M175.82 99.8281H171.703V103.945H175.82V99.8281Z" fill="black"/>
            <path d="M179.812 99.8281H175.695V103.945H179.812V99.8281Z" fill="black"/>
            <path d="M195.789 99.8281H191.672V103.945H195.789V99.8281Z" fill="black"/>
            <path d="M202.516 103.861V103.943H203.771V102.688H203.689C203.689 103.338 203.162 103.861 202.516 103.861Z" fill="black"/>
            <path d="M207.77 103.945H203.653V101.228C203.653 100.455 204.28 99.8287 205.053 99.8287H207.77V103.945Z" fill="black"/>
            <path d="M207.641 103.945V101.228C207.641 100.455 208.267 99.8287 209.04 99.8287H210.358C211.131 99.8287 211.757 100.455 211.757 101.228V103.945H207.641Z" fill="black"/>
            <path d="M8.10883 103.82H3.99219V107.937H8.10883V103.82Z" fill="black"/>
            <path d="M12.101 103.82H7.98438V107.937H12.101V103.82Z" fill="black"/>
            <path d="M16.0932 103.82H11.9766V107.937H16.0932V103.82Z" fill="black"/>
            <path d="M20.0893 103.82H15.9727V107.937H20.0893V103.82Z" fill="black"/>
            <path d="M23.9609 104.998V106.764C23.9609 107.414 24.4879 107.937 25.1383 107.937H26.9043C27.5589 107.937 28.0817 107.41 28.0817 106.76V104.998C28.0776 104.343 27.5506 103.82 26.9002 103.82H25.1383C24.4879 103.82 23.9609 104.347 23.9609 104.998Z" fill="black"/>
            <path d="M36.062 103.82H31.9453V107.937H36.062V103.82Z" fill="black"/>
            <path d="M48.043 103.82V106.537C48.043 107.31 47.4163 107.937 46.6433 107.937H45.326C44.553 107.937 43.9263 107.31 43.9263 106.537V103.82H48.043Z" fill="black"/>
            <path d="M56.0268 103.82H51.9102V107.937H56.0268V103.82Z" fill="black"/>
            <path d="M64.0156 107.938H61.2986C60.5256 107.938 59.899 107.311 59.899 106.538V105.221C59.899 104.448 60.5256 103.821 61.2986 103.821H64.0156V107.938Z" fill="black"/>
            <path d="M68.0073 103.82H63.8906V107.937H68.0073V103.82Z" fill="black"/>
            <path d="M67.8828 107.938V103.821H70.5998C71.3728 103.821 71.9995 104.448 71.9995 105.221V107.938H67.8828Z" fill="black"/>
            <path d="M78.7305 107.853V107.935H79.986V106.68H79.9037C79.9037 107.33 79.3768 107.853 78.7305 107.853Z" fill="black"/>
            <path d="M83.9799 103.82H79.8633V107.937H83.9799V103.82Z" fill="black"/>
            <path d="M91.9688 107.938H89.2518C88.4788 107.938 87.8521 107.311 87.8521 106.538V105.221C87.8521 104.448 88.4788 103.821 89.2518 103.821H91.9688V107.938Z" fill="black"/>
            <path d="M95.9604 103.82H91.8438V107.937H95.9604V103.82Z" fill="black"/>
            <path d="M99.9526 103.82H95.8359V107.937H99.9526V103.82Z" fill="black"/>
            <path d="M103.82 104.998V106.764C103.82 107.414 104.347 107.937 104.998 107.937H106.764C107.418 107.937 107.941 107.41 107.941 106.76V104.998C107.937 104.343 107.41 103.82 106.76 103.82H104.998C104.347 103.82 103.82 104.347 103.82 104.998Z" fill="black"/>
            <path d="M115.925 103.82H111.809V107.937H115.925V103.82Z" fill="black"/>
            <path d="M135.891 103.82V107.937H133.174C132.401 107.937 131.774 107.31 131.774 106.537V103.82H135.891Z" fill="black"/>
            <path d="M135.766 103.82H139.882V106.537C139.882 107.31 139.256 107.937 138.483 107.937H135.766V103.82Z" fill="black"/>
            <path d="M155.859 107.938H153.142C152.369 107.938 151.743 107.311 151.743 106.538V105.221C151.743 104.448 152.369 103.821 153.142 103.821H155.859V107.938Z" fill="black"/>
            <path d="M159.851 103.82H155.734V107.937H159.851V103.82Z" fill="black"/>
            <path d="M162.586 107.853V107.935H163.842V106.68H163.759C163.759 107.33 163.232 107.853 162.586 107.853Z" fill="black"/>
            <path d="M163.719 107.938V105.221C163.719 104.448 164.345 103.821 165.118 103.821H166.436C167.209 103.821 167.835 104.448 167.835 105.221V107.938H163.719Z" fill="black"/>
            <path d="M175.824 103.82V107.937H173.107C172.334 107.937 171.708 107.31 171.708 106.537V103.82H175.824Z" fill="black"/>
            <path d="M179.812 103.82H175.695V107.937H179.812V103.82Z" fill="black"/>
            <path d="M183.684 104.998V106.764C183.684 107.414 184.211 107.937 184.861 107.937H186.627C187.282 107.937 187.804 107.41 187.804 106.76V104.998C187.8 104.343 187.273 103.82 186.623 103.82H184.861C184.211 103.82 183.684 104.347 183.684 104.998Z" fill="black"/>
            <path d="M195.789 103.82H191.672V107.937H195.789V103.82Z" fill="black"/>
            <path d="M199.781 103.82H195.664V107.937H199.781V103.82Z" fill="black"/>
            <path d="M203.773 103.82H199.656V107.937H203.773V103.82Z" fill="black"/>
            <path d="M203.648 103.82H207.765V106.537C207.765 107.31 207.138 107.937 206.365 107.937H203.648V103.82Z" fill="black"/>
            <path d="M4.11719 111.93H1.40021C0.627197 111.93 0.000549316 111.303 0.000549316 110.53V109.213C0.000549316 108.44 0.627197 107.813 1.40021 107.813H4.11719L4.11719 111.93Z" fill="black"/>
            <path d="M8.10883 107.812H3.99219V111.929H8.10883V107.812Z" fill="black"/>
            <path d="M16.0932 107.812H11.9766V111.929H16.0932V107.812Z" fill="black"/>
            <path d="M20.0893 107.812H15.9727V111.929H20.0893V107.812Z" fill="black"/>
            <path d="M30.8125 111.853V111.935H32.0681V110.68H31.9857C31.9857 111.33 31.4588 111.853 30.8125 111.853Z" fill="black"/>
            <path d="M36.062 107.812H31.9453V111.929H36.062V107.812Z" fill="black"/>
            <path d="M56.0273 107.812V110.529C56.0273 111.302 55.4007 111.929 54.6277 111.929H53.3104C52.5374 111.929 51.9107 111.302 51.9107 110.529V107.812H56.0273Z" fill="black"/>
            <path d="M68.0073 107.812H63.8906V111.929H68.0073V107.812Z" fill="black"/>
            <path d="M67.8828 107.812H71.9995V110.529C71.9995 111.302 71.3728 111.929 70.5998 111.929H67.8828V107.812Z" fill="black"/>
            <path d="M74.7383 111.853V111.935H75.9939V110.68H75.9115C75.9115 111.33 75.3846 111.853 74.7383 111.853Z" fill="black"/>
            <path d="M79.9883 111.93H75.8716V109.213C75.8716 108.44 76.4983 107.813 77.2713 107.813H79.9883V111.93Z" fill="black"/>
            <path d="M83.9799 107.812H79.8633V111.929H83.9799V107.812Z" fill="black"/>
            <path d="M83.8555 111.93V109.213C83.8555 108.44 84.4821 107.813 85.2551 107.813H86.5724C87.3455 107.813 87.9721 108.44 87.9721 109.213V111.93H83.8555Z" fill="black"/>
            <path d="M99.9526 107.812H95.8359V111.929H99.9526V107.812Z" fill="black"/>
            <path d="M110.676 111.853V111.935H111.931V110.68H111.849C111.849 111.33 111.322 111.853 110.676 111.853Z" fill="black"/>
            <path d="M115.925 107.812H111.809V111.929H115.925V107.812Z" fill="black"/>
            <path d="M115.801 111.93V109.213C115.801 108.44 116.427 107.813 117.2 107.813H118.518C119.291 107.813 119.917 108.44 119.917 109.213V111.93H115.801Z" fill="black"/>
            <path d="M127.781 108.99V110.756C127.781 111.406 128.308 111.929 128.959 111.929H130.725C131.379 111.929 131.902 111.402 131.902 110.752V108.99C131.898 108.335 131.371 107.812 130.721 107.812H128.959C128.308 107.812 127.781 108.339 127.781 108.99Z" fill="black"/>
            <path d="M143.879 111.93H139.762V109.213C139.762 108.44 140.389 107.813 141.162 107.813H143.879V111.93Z" fill="black"/>
            <path d="M143.754 111.93V107.813H146.471C147.244 107.813 147.871 108.44 147.871 109.213V111.93H143.754Z" fill="black"/>
            <path d="M159.852 107.812V111.929H157.135C156.362 111.929 155.735 111.302 155.735 110.529V107.812H159.852Z" fill="black"/>
            <path d="M163.843 107.812H159.727V111.929H163.843V107.812Z" fill="black"/>
            <path d="M163.719 107.812H167.835V110.529C167.835 111.302 167.209 111.929 166.436 111.929H163.719V107.812Z" fill="black"/>
            <path d="M179.812 107.812H175.695V111.929H179.812V107.812Z" fill="black"/>
            <path d="M190.539 111.853V111.935H191.795V110.68H191.712C191.712 111.33 191.185 111.853 190.539 111.853Z" fill="black"/>
            <path d="M195.789 107.812H191.672V111.929H195.789V107.812Z" fill="black"/>
            <path d="M207.641 108.99V110.756C207.641 111.406 208.168 111.929 208.818 111.929H210.584C211.239 111.929 211.761 111.402 211.761 110.752V108.99C211.757 108.335 211.23 107.812 210.58 107.812H208.818C208.168 107.812 207.641 108.339 207.641 108.99Z" fill="black"/>
            <path d="M8.10938 111.805V114.522C8.10938 115.295 7.48273 115.921 6.70972 115.921H5.39239C4.61938 115.921 3.99274 115.295 3.99274 114.522V111.805H8.10938Z" fill="black"/>
            <path d="M16.0977 111.805V115.921H13.3807C12.6077 115.921 11.981 115.295 11.981 114.522V111.805H16.0977Z" fill="black"/>
            <path d="M20.0893 111.805H15.9727V115.921H20.0893V111.805Z" fill="black"/>
            <path d="M24.0815 111.805H19.9648V115.921H24.0815V111.805Z" fill="black"/>
            <path d="M28.0776 111.805H23.9609V115.921H28.0776V111.805Z" fill="black"/>
            <path d="M32.0698 111.805H27.9531V115.921H32.0698V111.805Z" fill="black"/>
            <path d="M36.062 111.805H31.9453V115.921H36.062V111.805Z" fill="black"/>
            <path d="M35.9375 115.922V113.205C35.9375 112.432 36.5641 111.805 37.3372 111.805H38.6545C39.4275 111.805 40.0541 112.432 40.0541 113.205V115.922H35.9375Z" fill="black"/>
            <path d="M47.918 112.982V114.748C47.918 115.399 48.4449 115.921 49.0953 115.921H50.8614C51.5159 115.921 52.0387 115.394 52.0387 114.744V112.982C52.0346 112.328 51.5077 111.805 50.8572 111.805H49.0953C48.4449 111.805 47.918 112.332 47.918 112.982Z" fill="black"/>
            <path d="M68.0078 111.805V114.522C68.0078 115.295 67.3812 115.921 66.6082 115.921H65.2908C64.5178 115.921 63.8912 115.295 63.8912 114.522V111.805H68.0078Z" fill="black"/>
            <path d="M75.9961 115.922H73.2791C72.5061 115.922 71.8795 115.295 71.8795 114.522V113.205C71.8795 112.432 72.5061 111.805 73.2791 111.805H75.9961V115.922Z" fill="black"/>
            <path d="M75.8672 111.805H79.9838V114.522C79.9838 115.295 79.3572 115.921 78.5842 115.921H75.8672V111.805Z" fill="black"/>
            <path d="M95.9609 115.922H91.8443V113.205C91.8443 112.432 92.4709 111.805 93.244 111.805H95.9609V115.922Z" fill="black"/>
            <path d="M99.9526 111.805H95.8359V115.921H99.9526V111.805Z" fill="black"/>
            <path d="M103.945 111.805H99.8281V115.921H103.945V111.805Z" fill="black"/>
            <path d="M107.937 111.805H103.82V115.921H107.937V111.805Z" fill="black"/>
            <path d="M111.929 111.805H107.812V115.921H111.929V111.805Z" fill="black"/>
            <path d="M115.925 111.805H111.809V115.921H115.925V111.805Z" fill="black"/>
            <path d="M118.664 115.845V115.927H119.92V114.672H119.837C119.837 115.322 119.31 115.845 118.664 115.845Z" fill="black"/>
            <path d="M119.797 115.922V113.205C119.797 112.432 120.424 111.805 121.197 111.805H122.514C123.287 111.805 123.914 112.432 123.914 113.205V115.922H119.797Z" fill="black"/>
            <path d="M130.641 115.845V115.927H131.896V114.672H131.814C131.814 115.322 131.287 115.845 130.641 115.845Z" fill="black"/>
            <path d="M131.773 115.922V113.205C131.773 112.432 132.4 111.805 133.173 111.805H134.49C135.263 111.805 135.89 112.432 135.89 113.205V115.922H131.773Z" fill="black"/>
            <path d="M143.874 111.805H139.758V115.921H143.874V111.805Z" fill="black"/>
            <path d="M147.871 111.805H143.754V115.921H147.871V111.805Z" fill="black"/>
            <path d="M151.863 111.805H147.746V115.921H151.863V111.805Z" fill="black"/>
            <path d="M151.742 115.922V113.205C151.742 112.432 152.369 111.805 153.142 111.805H154.459C155.232 111.805 155.859 112.432 155.859 113.205V115.922H151.742Z" fill="black"/>
            <path d="M175.824 115.922H173.107C172.334 115.922 171.708 115.295 171.708 114.522V113.205C171.708 112.432 172.334 111.805 173.107 111.805H175.824V115.922Z" fill="black"/>
            <path d="M179.812 111.805H175.695V115.921H179.812V111.805Z" fill="black"/>
            <path d="M183.808 111.805H179.691V115.921H183.808V111.805Z" fill="black"/>
            <path d="M187.8 111.805H183.684V115.921H187.8V111.805Z" fill="black"/>
            <path d="M191.796 111.805H187.68V115.921H191.796V111.805Z" fill="black"/>
            <path d="M195.789 111.805H191.672V115.921H195.789V111.805Z" fill="black"/>
            <path d="M199.781 111.805H195.664V115.921H199.781V111.805Z" fill="black"/>
            <path d="M203.773 111.805H199.656V115.921H203.773V111.805Z" fill="black"/>
            <path d="M203.648 115.922V111.805H206.365C207.138 111.805 207.765 112.432 207.765 113.205V115.922H203.648Z" fill="black"/>
            <path d="M0 116.982V118.748C0 119.399 0.52693 119.921 1.17736 119.921H2.9434C3.59794 119.921 4.12075 119.394 4.12075 118.744V116.982C4.11664 116.328 3.58971 115.805 2.93928 115.805H1.17736C0.52693 115.805 0 116.332 0 116.982Z" fill="black"/>
            <path d="M20.0893 115.805H15.9727V119.921H20.0893V115.805Z" fill="black"/>
            <path d="M19.9648 115.805H24.0815V118.522C24.0815 119.295 23.4548 119.921 22.6818 119.921H19.9648V115.805Z" fill="black"/>
            <path d="M36.062 115.805H31.9453V119.921H36.062V115.805Z" fill="black"/>
            <path d="M39.9297 119.922V117.205C39.9297 116.432 40.5563 115.805 41.3293 115.805H42.6467C43.4197 115.805 44.0463 116.432 44.0463 117.205V119.922H39.9297Z" fill="black"/>
            <path d="M55.9062 116.982V118.748C55.9062 119.399 56.4332 119.921 57.0836 119.921H58.8496C59.5042 119.921 60.027 119.394 60.027 118.744V116.982C60.0229 116.328 59.496 115.805 58.8455 115.805H57.0836C56.4332 115.805 55.9062 116.332 55.9062 116.982Z" fill="black"/>
            <path d="M95.9609 115.805V119.921H93.244C92.4709 119.921 91.8443 119.295 91.8443 118.522V115.805H95.9609Z" fill="black"/>
            <path d="M99.9526 115.805H95.8359V119.921H99.9526V115.805Z" fill="black"/>
            <path d="M99.8281 115.805H103.945V118.522C103.945 119.295 103.318 119.921 102.545 119.921H99.8281V115.805Z" fill="black"/>
            <path d="M111.929 115.805H107.812V119.921H111.929V115.805Z" fill="black"/>
            <path d="M115.925 115.805H111.809V119.921H115.925V115.805Z" fill="black"/>
            <path d="M119.917 115.805H115.801V119.921H119.917V115.805Z" fill="black"/>
            <path d="M123.914 115.805H119.797V119.921H123.914V115.805Z" fill="black"/>
            <path d="M127.906 115.805H123.789V119.921H127.906V115.805Z" fill="black"/>
            <path d="M131.898 115.805H127.781V119.921H131.898V115.805Z" fill="black"/>
            <path d="M131.773 115.805H135.89V118.522C135.89 119.295 135.263 119.921 134.49 119.921H131.773V115.805Z" fill="black"/>
            <path d="M143.874 115.805H139.758V119.921H143.874V115.805Z" fill="black"/>
            <path d="M147.871 115.805H143.754V119.921H147.871V115.805Z" fill="black"/>
            <path d="M147.746 115.805H151.863V118.522C151.863 119.295 151.236 119.921 150.463 119.921H147.746V115.805Z" fill="black"/>
            <path d="M163.844 119.922H159.727V117.205C159.727 116.432 160.354 115.805 161.127 115.805H163.844V119.922Z" fill="black"/>
            <path d="M163.719 119.922V117.205C163.719 116.432 164.345 115.805 165.118 115.805H166.436C167.209 115.805 167.835 116.432 167.835 117.205V119.922H163.719Z" fill="black"/>
            <path d="M187.801 115.805V119.921H185.084C184.311 119.921 183.684 119.295 183.684 118.522V115.805H187.801Z" fill="black"/>
            <path d="M191.796 115.805H187.68V119.921H191.796V115.805Z" fill="black"/>
            <path d="M195.789 115.805H191.672V119.921H195.789V115.805Z" fill="black"/>
            <path d="M203.773 115.805V119.921H201.056C200.283 119.921 199.657 119.295 199.657 118.522V115.805H203.773Z" fill="black"/>
            <path d="M207.765 115.805H203.648V119.921H207.765V115.805Z" fill="black"/>
            <path d="M207.641 119.922V117.205C207.641 116.432 208.267 115.805 209.04 115.805H210.358C211.131 115.805 211.757 116.432 211.757 117.205V119.922H207.641Z" fill="black"/>
            <path d="M2.85938 123.829V123.912H4.11495V122.656H4.03262C4.03262 123.307 3.50569 123.829 2.85938 123.829Z" fill="black"/>
            <path d="M8.10938 123.914H3.99274V121.197C3.99274 120.424 4.61938 119.797 5.39239 119.797H8.10938V123.914Z" fill="black"/>
            <path d="M12.101 119.797H7.98438V123.914H12.101V119.797Z" fill="black"/>
            <path d="M16.0932 119.797H11.9766V123.914H16.0932V119.797Z" fill="black"/>
            <path d="M20.0893 119.797H15.9727V123.914H20.0893V119.797Z" fill="black"/>
            <path d="M23.9609 120.974V122.74C23.9609 123.391 24.4879 123.914 25.1383 123.914H26.9043C27.5589 123.914 28.0817 123.387 28.0817 122.736V120.974C28.0776 120.32 27.5506 119.797 26.9002 119.797H25.1383C24.4879 119.797 23.9609 120.324 23.9609 120.974Z" fill="black"/>
            <path d="M30.8125 123.829V123.912H32.0681V122.656H31.9857C31.9857 123.307 31.4588 123.829 30.8125 123.829Z" fill="black"/>
            <path d="M36.062 119.797H31.9453V123.914H36.062V119.797Z" fill="black"/>
            <path d="M44.0508 119.797V122.514C44.0508 123.287 43.4241 123.914 42.6511 123.914H41.3338C40.5608 123.914 39.9341 123.287 39.9341 122.514V119.797H44.0508Z" fill="black"/>
            <path d="M50.7812 123.829V123.912H52.0368V122.656H51.9545C51.9545 123.307 51.4276 123.829 50.7812 123.829Z" fill="black"/>
            <path d="M51.9102 123.914V121.197C51.9102 120.424 52.5368 119.797 53.3098 119.797H54.6271C55.4001 119.797 56.0268 120.424 56.0268 121.197V123.914H51.9102Z" fill="black"/>
            <path d="M63.8906 120.974V122.74C63.8906 123.391 64.4176 123.914 65.068 123.914H66.834C67.4886 123.914 68.0114 123.387 68.0114 122.736V120.974C68.0073 120.32 67.4803 119.797 66.8299 119.797H65.068C64.4176 119.797 63.8906 120.324 63.8906 120.974Z" fill="black"/>
            <path d="M70.7422 123.829V123.912H71.9978V122.656H71.9154C71.9154 123.307 71.3885 123.829 70.7422 123.829Z" fill="black"/>
            <path d="M71.875 123.914V121.197C71.875 120.424 72.5016 119.797 73.2747 119.797H74.592C75.365 119.797 75.9916 120.424 75.9916 121.197V123.914H71.875Z" fill="black"/>
            <path d="M83.8555 120.974V122.74C83.8555 123.391 84.3824 123.914 85.0328 123.914H86.7989C87.4534 123.914 87.9762 123.387 87.9762 122.736V120.974C87.9721 120.32 87.4452 119.797 86.7947 119.797H85.0328C84.3824 119.797 83.8555 120.324 83.8555 120.974Z" fill="black"/>
            <path d="M111.934 119.797V122.514C111.934 123.287 111.307 123.914 110.534 123.914H109.217C108.444 123.914 107.817 123.287 107.817 122.514V119.797H111.934Z" fill="black"/>
            <path d="M143.879 119.797V122.514C143.879 123.287 143.252 123.914 142.479 123.914H141.162C140.389 123.914 139.762 123.287 139.762 122.514V119.797H143.879Z" fill="black"/>
            <path d="M151.742 120.974V122.74C151.742 123.391 152.269 123.914 152.92 123.914H154.686C155.34 123.914 155.863 123.387 155.863 122.736V120.974C155.859 120.32 155.332 119.797 154.681 119.797H152.92C152.269 119.797 151.742 120.324 151.742 120.974Z" fill="black"/>
            <path d="M163.843 119.797H159.727V123.914H163.843V119.797Z" fill="black"/>
            <path d="M171.828 123.914H169.111C168.338 123.914 167.711 123.287 167.711 122.514V121.197C167.711 120.424 168.338 119.797 169.111 119.797H171.828V123.914Z" fill="black"/>
            <path d="M171.703 123.914V119.797H174.42C175.193 119.797 175.82 120.424 175.82 121.197V123.914H171.703Z" fill="black"/>
            <path d="M195.789 119.797H191.672V123.914H195.789V119.797Z" fill="black"/>
            <path d="M4.11719 127.906H0.000549316V125.189C0.000549316 124.416 0.627197 123.79 1.40021 123.79H4.11719L4.11719 127.906Z" fill="black"/>
            <path d="M8.10883 123.789H3.99219V127.906H8.10883V123.789Z" fill="black"/>
            <path d="M16.0932 123.789H11.9766V127.906H16.0932V123.789Z" fill="black"/>
            <path d="M20.0893 123.789H15.9727V127.906H20.0893V123.789Z" fill="black"/>
            <path d="M32.0703 127.906H29.3533C28.5803 127.906 27.9537 127.28 27.9537 126.507V125.189C27.9537 124.416 28.5803 123.79 29.3533 123.79H32.0703V127.906Z" fill="black"/>
            <path d="M36.062 123.789H31.9453V127.906H36.062V123.789Z" fill="black"/>
            <path d="M52.0352 127.906H47.9185V125.189C47.9185 124.416 48.5452 123.79 49.3182 123.79H52.0352V127.906Z" fill="black"/>
            <path d="M56.0268 123.789H51.9102V127.906H56.0268V123.789Z" fill="black"/>
            <path d="M55.9062 127.906V125.189C55.9062 124.416 56.5329 123.79 57.3059 123.79H58.6232C59.3962 123.79 60.0229 124.416 60.0229 125.189V127.906H55.9062Z" fill="black"/>
            <path d="M66.75 127.822V127.904H68.0056V126.648H67.9232C67.9232 127.299 67.3963 127.822 66.75 127.822Z" fill="black"/>
            <path d="M72 127.906H67.8834V125.189C67.8834 124.416 68.51 123.79 69.283 123.79H72V127.906Z" fill="black"/>
            <path d="M75.9916 123.789H71.875V127.906H75.9916V123.789Z" fill="black"/>
            <path d="M75.8672 127.906V125.189C75.8672 124.416 76.4938 123.79 77.2668 123.79H78.5842C79.3572 123.79 79.9838 124.416 79.9838 125.189V127.906H75.8672Z" fill="black"/>
            <path d="M103.945 127.906H101.228C100.455 127.906 99.8287 127.28 99.8287 126.507V125.189C99.8287 124.416 100.455 123.79 101.228 123.79H103.945V127.906Z" fill="black"/>
            <path d="M103.82 127.906V123.79H106.537C107.31 123.79 107.937 124.416 107.937 125.189V127.906H103.82Z" fill="black"/>
            <path d="M119.918 127.906H115.801V125.189C115.801 124.416 116.428 123.79 117.201 123.79H119.918V127.906Z" fill="black"/>
            <path d="M119.797 127.906V123.79H122.514C123.287 123.79 123.914 124.416 123.914 125.189V127.906H119.797Z" fill="black"/>
            <path d="M127.781 124.966V126.732C127.781 127.383 128.308 127.906 128.959 127.906H130.725C131.379 127.906 131.902 127.379 131.902 126.728V124.966C131.898 124.312 131.371 123.789 130.721 123.789H128.959C128.308 123.789 127.781 124.316 127.781 124.966Z" fill="black"/>
            <path d="M135.766 127.906V125.189C135.766 124.416 136.392 123.79 137.165 123.79H138.483C139.256 123.79 139.882 124.416 139.882 125.189V127.906H135.766Z" fill="black"/>
            <path d="M163.844 123.789V126.506C163.844 127.279 163.217 127.906 162.444 127.906H161.127C160.354 127.906 159.727 127.279 159.727 126.506V123.789H163.844Z" fill="black"/>
            <path d="M175.824 123.789V127.906H173.107C172.334 127.906 171.708 127.279 171.708 126.506V123.789H175.824Z" fill="black"/>
            <path d="M175.695 127.906V123.79H178.412C179.185 123.79 179.812 124.416 179.812 125.189V127.906H175.695Z" fill="black"/>
            <path d="M187.801 127.906H185.084C184.311 127.906 183.684 127.28 183.684 126.507V125.189C183.684 124.416 184.311 123.79 185.084 123.79H187.801V127.906Z" fill="black"/>
            <path d="M191.796 123.789H187.68V127.906H191.796V123.789Z" fill="black"/>
            <path d="M195.789 123.789H191.672V127.906H195.789V123.789Z" fill="black"/>
            <path d="M195.664 127.906V123.79H198.381C199.154 123.79 199.781 124.416 199.781 125.189V127.906H195.664Z" fill="black"/>
            <path d="M4.11664 127.781H0V131.898H4.11664V127.781Z" fill="black"/>
            <path d="M8.10883 127.781H3.99219V131.898H8.10883V127.781Z" fill="black"/>
            <path d="M16.0932 127.781H11.9766V131.898H16.0932V127.781Z" fill="black"/>
            <path d="M15.9727 127.781H20.0893V130.498C20.0893 131.271 19.4626 131.898 18.6896 131.898H15.9727V127.781Z" fill="black"/>
            <path d="M23.9609 128.959V130.725C23.9609 131.375 24.4879 131.898 25.1383 131.898H26.9043C27.5589 131.898 28.0817 131.371 28.0817 130.721V128.959C28.0776 128.304 27.5506 127.781 26.9002 127.781H25.1383C24.4879 127.781 23.9609 128.308 23.9609 128.959Z" fill="black"/>
            <path d="M36.062 127.781H31.9453V131.898H36.062V127.781Z" fill="black"/>
            <path d="M40.0541 127.781H35.9375V131.898H40.0541V127.781Z" fill="black"/>
            <path d="M39.9297 131.898V129.181C39.9297 128.408 40.5563 127.782 41.3293 127.782H42.6467C43.4197 127.782 44.0463 128.408 44.0463 129.181V131.898H39.9297Z" fill="black"/>
            <path d="M52.0352 127.781V131.898H49.3182C48.5452 131.898 47.9185 131.271 47.9185 130.498V127.781H52.0352Z" fill="black"/>
            <path d="M56.0268 127.781H51.9102V131.898H56.0268V127.781Z" fill="black"/>
            <path d="M64.0156 131.898H61.2986C60.5256 131.898 59.899 131.272 59.899 130.499V129.181C59.899 128.408 60.5256 127.782 61.2986 127.782H64.0156V131.898Z" fill="black"/>
            <path d="M68.0073 127.781H63.8906V131.898H68.0073V127.781Z" fill="black"/>
            <path d="M67.8828 127.781H71.9995V130.498C71.9995 131.271 71.3728 131.898 70.5998 131.898H67.8828V127.781Z" fill="black"/>
            <path d="M87.9727 131.898H85.2557C84.4827 131.898 83.856 131.272 83.856 130.499V129.181C83.856 128.408 84.4827 127.782 85.2557 127.782H87.9727V131.898Z" fill="black"/>
            <path d="M91.9682 127.781H87.8516V131.898H91.9682V127.781Z" fill="black"/>
            <path d="M95.9604 127.781H91.8438V131.898H95.9604V127.781Z" fill="black"/>
            <path d="M95.8359 131.898V129.181C95.8359 128.408 96.4626 127.782 97.2356 127.782H98.5529C99.3259 127.782 99.9526 128.408 99.9526 129.181V131.898H95.8359Z" fill="black"/>
            <path d="M107.937 127.781H103.82V131.898H107.937V127.781Z" fill="black"/>
            <path d="M119.917 127.781H115.801V131.898H119.917V127.781Z" fill="black"/>
            <path d="M123.914 127.781H119.797V131.898H123.914V127.781Z" fill="black"/>
            <path d="M139.887 127.781V131.898H137.17C136.397 131.898 135.77 131.271 135.77 130.498V127.781H139.887Z" fill="black"/>
            <path d="M143.874 127.781H139.758V131.898H143.874V127.781Z" fill="black"/>
            <path d="M143.754 131.898V129.181C143.754 128.408 144.381 127.782 145.154 127.782H146.471C147.244 127.782 147.871 128.408 147.871 129.181V131.898H143.754Z" fill="black"/>
            <path d="M179.816 127.781V131.898H177.099C176.326 131.898 175.7 131.271 175.7 130.498V127.781H179.816Z" fill="black"/>
            <path d="M179.691 131.898V129.181C179.691 128.408 180.318 127.782 181.091 127.782H182.408C183.181 127.782 183.808 128.408 183.808 129.181V131.898H179.691Z" fill="black"/>
            <path d="M199.781 127.781H195.664V131.898H199.781V127.781Z" fill="black"/>
            <path d="M203.773 127.781H199.656V131.898H203.773V127.781Z" fill="black"/>
            <path d="M207.765 127.781H203.648V131.898H207.765V127.781Z" fill="black"/>
            <path d="M207.641 131.898V127.782H210.358C211.131 127.782 211.757 128.408 211.757 129.181V131.898H207.641Z" fill="black"/>
            <path d="M4.11664 131.773H0V135.89H4.11664V131.773Z" fill="black"/>
            <path d="M8.10883 131.773H3.99219V135.89H8.10883V131.773Z" fill="black"/>
            <path d="M16.0977 131.773V134.49C16.0977 135.263 15.471 135.89 14.698 135.89H13.3807C12.6077 135.89 11.981 135.263 11.981 134.49V131.773H16.0977Z" fill="black"/>
            <path d="M36.0625 131.773V135.89H33.3455C32.5725 135.89 31.9459 135.263 31.9459 134.49V131.773H36.0625Z" fill="black"/>
            <path d="M40.0541 131.773H35.9375V135.89H40.0541V131.773Z" fill="black"/>
            <path d="M56.0273 131.773V135.89H53.3104C52.5374 135.89 51.9107 135.263 51.9107 134.49V131.773H56.0273Z" fill="black"/>
            <path d="M55.9062 135.891V131.774H58.6232C59.3962 131.774 60.0229 132.401 60.0229 133.174V135.891H55.9062Z" fill="black"/>
            <path d="M70.7422 135.806V135.888H71.9978V134.633H71.9154C71.9154 135.283 71.3885 135.806 70.7422 135.806Z" fill="black"/>
            <path d="M75.9961 135.891H71.8795V133.174C71.8795 132.401 72.5061 131.774 73.2791 131.774H75.9961V135.891Z" fill="black"/>
            <path d="M75.8672 135.891V131.774H78.5842C79.3572 131.774 79.9838 132.401 79.9838 133.174V135.891H75.8672Z" fill="black"/>
            <path d="M95.9604 131.773H91.8438V135.89H95.9604V131.773Z" fill="black"/>
            <path d="M102.688 135.806V135.888H103.943V134.633H103.861C103.861 135.283 103.334 135.806 102.688 135.806Z" fill="black"/>
            <path d="M107.937 131.773H103.82V135.89H107.937V131.773Z" fill="black"/>
            <path d="M114.672 135.806V135.888H115.927V134.633H115.845C115.845 135.283 115.318 135.806 114.672 135.806Z" fill="black"/>
            <path d="M119.917 131.773H115.801V135.89H119.917V131.773Z" fill="black"/>
            <path d="M123.914 131.773H119.797V135.89H123.914V131.773Z" fill="black"/>
            <path d="M127.906 131.773H123.789V135.89H127.906V131.773Z" fill="black"/>
            <path d="M127.781 135.891V131.774H130.498C131.271 131.774 131.898 132.401 131.898 133.174V135.891H127.781Z" fill="black"/>
            <path d="M143.879 131.773V134.49C143.879 135.263 143.252 135.89 142.479 135.89H141.162C140.389 135.89 139.762 135.263 139.762 134.49V131.773H143.879Z" fill="black"/>
            <path d="M146.613 135.806V135.888H147.869V134.633H147.787C147.787 135.283 147.26 135.806 146.613 135.806Z" fill="black"/>
            <path d="M147.746 135.891V133.174C147.746 132.401 148.373 131.774 149.146 131.774H150.463C151.236 131.774 151.863 132.401 151.863 133.174V135.891H147.746Z" fill="black"/>
            <path d="M167.711 132.951V134.717C167.711 135.367 168.238 135.89 168.888 135.89H170.654C171.309 135.89 171.832 135.363 171.832 134.713V132.951C171.828 132.296 171.301 131.773 170.65 131.773H168.888C168.238 131.773 167.711 132.3 167.711 132.951Z" fill="black"/>
            <path d="M191.797 135.891H187.68V133.174C187.68 132.401 188.307 131.774 189.08 131.774H191.797V135.891Z" fill="black"/>
            <path d="M195.789 131.773H191.672V135.89H195.789V131.773Z" fill="black"/>
            <path d="M199.781 131.773H195.664V135.89H199.781V131.773Z" fill="black"/>
            <path d="M203.773 131.773H199.656V135.89H203.773V131.773Z" fill="black"/>
            <path d="M211.762 131.773V134.49C211.762 135.263 211.135 135.89 210.362 135.89H209.045C208.272 135.89 207.645 135.263 207.645 134.49V131.773H211.762Z" fill="black"/>
            <path d="M4.11719 135.766V139.882H1.40021C0.627197 139.882 0.000549316 139.256 0.000549316 138.483V135.766H4.11719Z" fill="black"/>
            <path d="M8.10883 135.766H3.99219V139.882H8.10883V135.766Z" fill="black"/>
            <path d="M7.98438 139.883V135.766H10.7014C11.4744 135.766 12.101 136.393 12.101 137.166V139.883H7.98438Z" fill="black"/>
            <path d="M20.0898 139.883H17.3729C16.5999 139.883 15.9732 139.256 15.9732 138.483V137.166C15.9732 136.393 16.5999 135.766 17.3729 135.766H20.0898V139.883Z" fill="black"/>
            <path d="M24.0815 135.766H19.9648V139.882H24.0815V135.766Z" fill="black"/>
            <path d="M28.0776 135.766H23.9609V139.882H28.0776V135.766Z" fill="black"/>
            <path d="M27.9531 139.883V135.766H30.6701C31.4431 135.766 32.0698 136.393 32.0698 137.166V139.883H27.9531Z" fill="black"/>
            <path d="M40.0541 135.766H35.9375V139.882H40.0541V135.766Z" fill="black"/>
            <path d="M48.043 139.883H45.326C44.553 139.883 43.9263 139.256 43.9263 138.483V137.166C43.9263 136.393 44.553 135.766 45.326 135.766H48.043V139.883Z" fill="black"/>
            <path d="M47.918 139.883V135.766H50.6349C51.408 135.766 52.0346 136.393 52.0346 137.166V139.883H47.918Z" fill="black"/>
            <path d="M60.0234 135.766V139.882H57.3065C56.5334 139.882 55.9068 139.256 55.9068 138.483V135.766H60.0234Z" fill="black"/>
            <path d="M64.0151 135.766H59.8984V139.882H64.0151V135.766Z" fill="black"/>
            <path d="M68.0073 135.766H63.8906V139.882H68.0073V135.766Z" fill="black"/>
            <path d="M71.9995 135.766H67.8828V139.882H71.9995V135.766Z" fill="black"/>
            <path d="M75.9916 135.766H71.875V139.882H75.9916V135.766Z" fill="black"/>
            <path d="M79.9838 135.766H75.8672V139.882H79.9838V135.766Z" fill="black"/>
            <path d="M83.9799 135.766H79.8633V139.882H83.9799V135.766Z" fill="black"/>
            <path d="M87.9721 135.766H83.8555V139.882H87.9721V135.766Z" fill="black"/>
            <path d="M91.9682 135.766H87.8516V139.882H91.9682V135.766Z" fill="black"/>
            <path d="M95.9604 135.766H91.8438V139.882H95.9604V135.766Z" fill="black"/>
            <path d="M99.9526 135.766H95.8359V139.882H99.9526V135.766Z" fill="black"/>
            <path d="M103.945 135.766H99.8281V139.882H103.945V135.766Z" fill="black"/>
            <path d="M107.937 135.766H103.82V139.882H107.937V135.766Z" fill="black"/>
            <path d="M111.929 135.766H107.812V139.882H111.929V135.766Z" fill="black"/>
            <path d="M115.925 135.766H111.809V139.882H115.925V135.766Z" fill="black"/>
            <path d="M115.801 135.766H119.917V138.483C119.917 139.256 119.291 139.882 118.518 139.882H115.801V135.766Z" fill="black"/>
            <path d="M127.906 135.766H123.789V139.882H127.906V135.766Z" fill="black"/>
            <path d="M131.898 135.766H127.781V139.882H131.898V135.766Z" fill="black"/>
            <path d="M135.766 139.883V137.166C135.766 136.393 136.392 135.766 137.165 135.766H138.483C139.256 135.766 139.882 136.393 139.882 137.166V139.883H135.766Z" fill="black"/>
            <path d="M147.871 139.883H145.154C144.381 139.883 143.754 139.256 143.754 138.483V137.166C143.754 136.393 144.381 135.766 145.154 135.766H147.871V139.883Z" fill="black"/>
            <path d="M151.863 135.766H147.746V139.882H151.863V135.766Z" fill="black"/>
            <path d="M155.859 135.766H151.742V139.882H155.859V135.766Z" fill="black"/>
            <path d="M155.734 139.883V135.766H158.451C159.224 135.766 159.851 136.393 159.851 137.166V139.883H155.734Z" fill="black"/>
            <path d="M171.703 139.883V137.166C171.703 136.393 172.33 135.766 173.103 135.766H174.42C175.193 135.766 175.82 136.393 175.82 137.166V139.883H171.703Z" fill="black"/>
            <path d="M186.547 139.798V139.881H187.802V138.625H187.72C187.72 139.275 187.193 139.798 186.547 139.798Z" fill="black"/>
            <path d="M191.796 135.766H187.68V139.882H191.796V135.766Z" fill="black"/>
            <path d="M203.773 135.766V138.483C203.773 139.256 203.147 139.882 202.374 139.882H201.056C200.283 139.882 199.657 139.256 199.657 138.483V135.766H203.773Z" fill="black"/>
            <path d="M12.1055 139.758V142.475C12.1055 143.248 11.4788 143.874 10.7058 143.874H9.38849C8.61548 143.874 7.98883 143.248 7.98883 142.475V139.758H12.1055Z" fill="black"/>
            <path d="M32.0703 139.758V142.475C32.0703 143.248 31.4437 143.874 30.6707 143.874H29.3533C28.5803 143.874 27.9537 143.248 27.9537 142.475V139.758H32.0703Z" fill="black"/>
            <path d="M40.0547 139.758V143.874H37.3377C36.5647 143.874 35.938 143.248 35.938 142.475V139.758H40.0547Z" fill="black"/>
            <path d="M39.9297 143.875V141.158C39.9297 140.385 40.5563 139.758 41.3293 139.758H42.6467C43.4197 139.758 44.0463 140.385 44.0463 141.158V143.875H39.9297Z" fill="black"/>
            <path d="M52.0352 139.758V142.475C52.0352 143.248 51.4085 143.874 50.6355 143.874H49.3182C48.5452 143.874 47.9185 143.248 47.9185 142.475V139.758H52.0352Z" fill="black"/>
            <path d="M64.0156 139.758V142.475C64.0156 143.248 63.389 143.874 62.616 143.874H61.2986C60.5256 143.874 59.899 143.248 59.899 142.475V139.758H64.0156Z" fill="black"/>
            <path d="M79.9883 139.758V142.475C79.9883 143.248 79.3616 143.874 78.5886 143.874H77.2713C76.4983 143.874 75.8716 143.248 75.8716 142.475V139.758H79.9883Z" fill="black"/>
            <path d="M87.9727 139.758V143.874H85.2557C84.4827 143.874 83.856 143.248 83.856 142.475V139.758H87.9727Z" fill="black"/>
            <path d="M87.8516 139.758H91.9682V142.475C91.9682 143.248 91.3416 143.874 90.5685 143.874H87.8516V139.758Z" fill="black"/>
            <path d="M111.929 139.758H107.812V143.874H111.929V139.758Z" fill="black"/>
            <path d="M115.925 139.758H111.809V143.874H115.925V139.758Z" fill="black"/>
            <path d="M127.906 139.758V143.874H125.189C124.416 143.874 123.79 143.248 123.79 142.475V139.758H127.906Z" fill="black"/>
            <path d="M131.898 139.758H127.781V143.874H131.898V139.758Z" fill="black"/>
            <path d="M134.633 143.79V143.873H135.888V142.617H135.806C135.806 143.268 135.279 143.79 134.633 143.79Z" fill="black"/>
            <path d="M139.882 139.758H135.766V143.874H139.882V139.758Z" fill="black"/>
            <path d="M139.758 143.875V139.758H142.475C143.248 139.758 143.874 140.385 143.874 141.158V143.875H139.758Z" fill="black"/>
            <path d="M151.863 139.758V142.475C151.863 143.248 151.237 143.874 150.464 143.874H149.146C148.373 143.874 147.747 143.248 147.747 142.475V139.758H151.863Z" fill="black"/>
            <path d="M159.851 139.758H155.734V143.874H159.851V139.758Z" fill="black"/>
            <path d="M163.843 139.758H159.727V143.874H163.843V139.758Z" fill="black"/>
            <path d="M163.719 143.875V141.158C163.719 140.385 164.345 139.758 165.118 139.758H166.436C167.209 139.758 167.835 140.385 167.835 141.158V143.875H163.719Z" fill="black"/>
            <path d="M175.824 139.758V143.874H173.107C172.334 143.874 171.708 143.248 171.708 142.475V139.758H175.824Z" fill="black"/>
            <path d="M179.812 139.758H175.695V143.874H179.812V139.758Z" fill="black"/>
            <path d="M183.808 139.758H179.691V143.874H183.808V139.758Z" fill="black"/>
            <path d="M187.8 139.758H183.684V143.874H187.8V139.758Z" fill="black"/>
            <path d="M191.796 139.758H187.68V143.874H191.796V139.758Z" fill="black"/>
            <path d="M195.789 139.758H191.672V143.874H195.789V139.758Z" fill="black"/>
            <path d="M195.664 143.875V141.158C195.664 140.385 196.291 139.758 197.064 139.758H198.381C199.154 139.758 199.781 140.385 199.781 141.158V143.875H195.664Z" fill="black"/>
            <path d="M202.516 143.79V143.873H203.771V142.617H203.689C203.689 143.268 203.162 143.79 202.516 143.79Z" fill="black"/>
            <path d="M207.77 143.875H203.653V141.158C203.653 140.385 204.28 139.758 205.053 139.758H207.77V143.875Z" fill="black"/>
            <path d="M207.641 143.875V141.158C207.641 140.385 208.267 139.758 209.04 139.758H210.358C211.131 139.758 211.757 140.385 211.757 141.158V143.875H207.641Z" fill="black"/>
            <path d="M4.11719 147.867H0.000549316V145.15C0.000549316 144.377 0.627197 143.751 1.40021 143.751H4.11719L4.11719 147.867Z" fill="black"/>
            <path d="M3.99219 147.867L3.99219 143.751H6.70917C7.48218 143.751 8.10883 144.377 8.10883 145.15V147.867H3.99219Z" fill="black"/>
            <path d="M15.9727 144.927V146.693C15.9727 147.344 16.4996 147.867 17.15 147.867H18.9161C19.5706 147.867 20.0934 147.34 20.0934 146.689V144.927C20.0893 144.273 19.5624 143.75 18.9119 143.75H17.15C16.4996 143.75 15.9727 144.277 15.9727 144.927Z" fill="black"/>
            <path d="M23.9609 144.927V146.693C23.9609 147.344 24.4879 147.867 25.1383 147.867H26.9043C27.5589 147.867 28.0817 147.34 28.0817 146.689V144.927C28.0776 144.273 27.5506 143.75 26.9002 143.75H25.1383C24.4879 143.75 23.9609 144.277 23.9609 144.927Z" fill="black"/>
            <path d="M43.9219 144.927V146.693C43.9219 147.344 44.4488 147.867 45.0992 147.867H46.8653C47.5198 147.867 48.0426 147.34 48.0426 146.689V144.927C48.0385 144.273 47.5116 143.75 46.8612 143.75H45.0992C44.4488 143.75 43.9219 144.277 43.9219 144.927Z" fill="black"/>
            <path d="M51.9102 144.927V146.693C51.9102 147.344 52.4371 147.867 53.0875 147.867H54.8536C55.5081 147.867 56.0309 147.34 56.0309 146.689V144.927C56.0268 144.273 55.4999 143.75 54.8494 143.75H53.0875C52.4371 143.75 51.9102 144.277 51.9102 144.927Z" fill="black"/>
            <path d="M62.7578 147.79V147.873H64.0134V146.617H63.9311C63.9311 147.268 63.4041 147.79 62.7578 147.79Z" fill="black"/>
            <path d="M63.8906 147.867V145.15C63.8906 144.377 64.5173 143.751 65.2903 143.751H66.6076C67.3806 143.751 68.0073 144.377 68.0073 145.15V147.867H63.8906Z" fill="black"/>
            <path d="M90.7109 147.79V147.873H91.9665V146.617H91.8842C91.8842 147.268 91.3572 147.79 90.7109 147.79Z" fill="black"/>
            <path d="M91.8438 147.867V145.15C91.8438 144.377 92.4704 143.751 93.2434 143.751H94.5607C95.3337 143.751 95.9604 144.377 95.9604 145.15V147.867H91.8438Z" fill="black"/>
            <path d="M107.941 147.867H105.224C104.451 147.867 103.825 147.241 103.825 146.468V145.15C103.825 144.377 104.451 143.751 105.224 143.751H107.941V147.867Z" fill="black"/>
            <path d="M111.929 143.75H107.812V147.867H111.929V143.75Z" fill="black"/>
            <path d="M115.925 143.75H111.809V147.867H115.925V143.75Z" fill="black"/>
            <path d="M115.801 147.867V145.15C115.801 144.377 116.427 143.751 117.2 143.751H118.518C119.291 143.751 119.917 144.377 119.917 145.15V147.867H115.801Z" fill="black"/>
            <path d="M131.898 143.75V147.867H129.181C128.408 147.867 127.782 147.24 127.782 146.467V143.75H131.898Z" fill="black"/>
            <path d="M135.89 143.75H131.773V147.867H135.89V143.75Z" fill="black"/>
            <path d="M139.882 143.75H135.766V147.867H139.882V143.75Z" fill="black"/>
            <path d="M143.874 143.75H139.758V147.867H143.874V143.75Z" fill="black"/>
            <path d="M143.754 147.867V143.751H146.471C147.244 143.751 147.871 144.377 147.871 145.15V147.867H143.754Z" fill="black"/>
            <path d="M159.852 143.75V146.467C159.852 147.24 159.225 147.867 158.452 147.867H157.135C156.362 147.867 155.735 147.24 155.735 146.467V143.75H159.852Z" fill="black"/>
            <path d="M166.578 147.79V147.873H167.834V146.617H167.751C167.751 147.268 167.224 147.79 166.578 147.79Z" fill="black"/>
            <path d="M167.711 147.867V145.15C167.711 144.377 168.338 143.751 169.111 143.751H170.428C171.201 143.751 171.828 144.377 171.828 145.15V147.867H167.711Z" fill="black"/>
            <path d="M183.809 143.75V147.867H181.092C180.319 147.867 179.692 147.24 179.692 146.467V143.75H183.809Z" fill="black"/>
            <path d="M187.8 143.75H183.684V147.867H187.8V143.75Z" fill="black"/>
            <path d="M191.796 143.75H187.68V147.867H191.796V143.75Z" fill="black"/>
            <path d="M203.773 147.867H201.056C200.283 147.867 199.657 147.241 199.657 146.468V145.15C199.657 144.377 200.283 143.751 201.056 143.751H203.773V147.867Z" fill="black"/>
            <path d="M207.765 143.75H203.648V147.867H207.765V143.75Z" fill="black"/>
            <path d="M4.11664 147.75H0V151.867H4.11664V147.75Z" fill="black"/>
            <path d="M3.99219 147.75H8.10883V150.467C8.10883 151.24 7.48218 151.867 6.70917 151.867H3.99219V147.75Z" fill="black"/>
            <path d="M26.8203 151.783V151.865H28.0759V150.609H27.9936C27.9936 151.26 27.4666 151.783 26.8203 151.783Z" fill="black"/>
            <path d="M32.0703 151.867H27.9537V149.15C27.9537 148.377 28.5803 147.751 29.3533 147.751H32.0703V151.867Z" fill="black"/>
            <path d="M36.062 147.75H31.9453V151.867H36.062V147.75Z" fill="black"/>
            <path d="M35.9375 151.867V149.15C35.9375 148.377 36.5641 147.751 37.3372 147.751H38.6545C39.4275 147.751 40.0541 148.377 40.0541 149.15V151.867H35.9375Z" fill="black"/>
            <path d="M47.918 151.867V149.15C47.918 148.377 48.5446 147.751 49.3176 147.751H50.6349C51.408 147.751 52.0346 148.377 52.0346 149.15V151.867H47.918Z" fill="black"/>
            <path d="M64.0156 151.867H61.2986C60.5256 151.867 59.899 151.241 59.899 150.468V149.15C59.899 148.377 60.5256 147.751 61.2986 147.751H64.0156V151.867Z" fill="black"/>
            <path d="M68.0073 147.75H63.8906V151.867H68.0073V147.75Z" fill="black"/>
            <path d="M67.8828 151.867V147.751H70.5998C71.3728 147.751 71.9995 148.377 71.9995 149.15V151.867H67.8828Z" fill="black"/>
            <path d="M79.9883 151.867H75.8716V149.15C75.8716 148.377 76.4983 147.751 77.2713 147.751H79.9883V151.867Z" fill="black"/>
            <path d="M79.8633 151.867V147.751H82.5803C83.3533 147.751 83.9799 148.377 83.9799 149.15V151.867H79.8633Z" fill="black"/>
            <path d="M91.9688 151.867H89.2518C88.4788 151.867 87.8521 151.241 87.8521 150.468V149.15C87.8521 148.377 88.4788 147.751 89.2518 147.751H91.9688V151.867Z" fill="black"/>
            <path d="M95.9604 147.75H91.8438V151.867H95.9604V147.75Z" fill="black"/>
            <path d="M111.934 147.75V150.467C111.934 151.24 111.307 151.867 110.534 151.867H109.217C108.444 151.867 107.817 151.24 107.817 150.467V147.75H111.934Z" fill="black"/>
            <path d="M139.887 147.75V151.867H137.17C136.397 151.867 135.77 151.24 135.77 150.467V147.75H139.887Z" fill="black"/>
            <path d="M143.874 147.75H139.758V151.867H143.874V147.75Z" fill="black"/>
            <path d="M143.754 147.75H147.871V150.467C147.871 151.24 147.244 151.867 146.471 151.867H143.754V147.75Z" fill="black"/>
            <path d="M167.836 151.867H165.119C164.346 151.867 163.719 151.241 163.719 150.468V149.15C163.719 148.377 164.346 147.751 165.119 147.751H167.836V151.867Z" fill="black"/>
            <path d="M171.828 147.75H167.711V151.867H171.828V147.75Z" fill="black"/>
            <path d="M171.703 151.867V149.15C171.703 148.377 172.33 147.751 173.103 147.751H174.42C175.193 147.751 175.82 148.377 175.82 149.15V151.867H171.703Z" fill="black"/>
            <path d="M187.801 147.75V151.867H185.084C184.311 151.867 183.684 151.24 183.684 150.467V147.75H187.801Z" fill="black"/>
            <path d="M191.796 147.75H187.68V151.867H191.796V147.75Z" fill="black"/>
            <path d="M191.672 151.867V147.751H194.389C195.162 147.751 195.789 148.377 195.789 149.15V151.867H191.672Z" fill="black"/>
            <path d="M207.77 147.75V151.867H205.053C204.28 151.867 203.653 151.24 203.653 150.467V147.75H207.77Z" fill="black"/>
            <path d="M207.641 151.867V149.15C207.641 148.377 208.267 147.751 209.04 147.751H210.358C211.131 147.751 211.757 148.377 211.757 149.15V151.867H207.641Z" fill="black"/>
            <path d="M4.11719 151.742V154.459C4.11719 155.232 3.49054 155.859 2.71753 155.859H1.40021C0.627197 155.859 0.000549316 155.232 0.000549316 154.459V151.742H4.11719Z" fill="black"/>
            <path d="M12.1055 155.859H7.98883V153.142C7.98883 152.369 8.61548 151.743 9.38849 151.743H12.1055V155.859Z" fill="black"/>
            <path d="M16.0932 151.742H11.9766V155.859H16.0932V151.742Z" fill="black"/>
            <path d="M15.9727 155.859V153.142C15.9727 152.369 16.5993 151.743 17.3723 151.743H18.6896C19.4626 151.743 20.0893 152.369 20.0893 153.142V155.859H15.9727Z" fill="black"/>
            <path d="M28.0781 155.859H25.3611C24.5881 155.859 23.9615 155.233 23.9615 154.46V153.142C23.9615 152.369 24.5881 151.743 25.3611 151.743H28.0781V155.859Z" fill="black"/>
            <path d="M32.0698 151.742H27.9531V155.859H32.0698V151.742Z" fill="black"/>
            <path d="M36.062 151.742H31.9453V155.859H36.062V151.742Z" fill="black"/>
            <path d="M39.9297 155.859V153.142C39.9297 152.369 40.5563 151.743 41.3293 151.743H42.6467C43.4197 151.743 44.0463 152.369 44.0463 153.142V155.859H39.9297Z" fill="black"/>
            <path d="M46.7852 155.775V155.857H48.0407V154.602H47.9584C47.9584 155.252 47.4315 155.775 46.7852 155.775Z" fill="black"/>
            <path d="M52.0346 151.742H47.918V155.859H52.0346V151.742Z" fill="black"/>
            <path d="M71.9995 151.742H67.8828V155.859H71.9995V151.742Z" fill="black"/>
            <path d="M74.7383 155.775V155.857H75.9939V154.602H75.9115C75.9115 155.252 75.3846 155.775 74.7383 155.775Z" fill="black"/>
            <path d="M79.9838 151.742H75.8672V155.859H79.9838V151.742Z" fill="black"/>
            <path d="M83.9799 151.742H79.8633V155.859H83.9799V151.742Z" fill="black"/>
            <path d="M95.9604 151.742H91.8438V155.859H95.9604V151.742Z" fill="black"/>
            <path d="M103.82 152.92V154.686C103.82 155.336 104.347 155.859 104.998 155.859H106.764C107.418 155.859 107.941 155.332 107.941 154.681V152.92C107.937 152.265 107.41 151.742 106.76 151.742H104.998C104.347 151.742 103.82 152.269 103.82 152.92Z" fill="black"/>
            <path d="M115.801 155.859V153.142C115.801 152.369 116.427 151.743 117.2 151.743H118.518C119.291 151.743 119.917 152.369 119.917 153.142V155.859H115.801Z" fill="black"/>
            <path d="M122.656 155.775V155.857H123.912V154.602H123.829C123.829 155.252 123.303 155.775 122.656 155.775Z" fill="black"/>
            <path d="M123.789 155.859V153.142C123.789 152.369 124.416 151.743 125.189 151.743H126.506C127.279 151.743 127.906 152.369 127.906 153.142V155.859H123.789Z" fill="black"/>
            <path d="M130.641 155.775V155.857H131.896V154.602H131.814C131.814 155.252 131.287 155.775 130.641 155.775Z" fill="black"/>
            <path d="M131.773 155.859V153.142C131.773 152.369 132.4 151.743 133.173 151.743H134.49C135.263 151.743 135.89 152.369 135.89 153.142V155.859H131.773Z" fill="black"/>
            <path d="M143.879 151.742V154.459C143.879 155.232 143.252 155.859 142.479 155.859H141.162C140.389 155.859 139.762 155.232 139.762 154.459V151.742H143.879Z" fill="black"/>
            <path d="M151.863 155.859H147.747V153.142C147.747 152.369 148.373 151.743 149.146 151.743H151.863V155.859Z" fill="black"/>
            <path d="M151.742 155.859V151.743H154.459C155.232 151.743 155.859 152.369 155.859 153.142V155.859H151.742Z" fill="black"/>
            <path d="M195.789 151.742H191.672V155.859H195.789V151.742Z" fill="black"/>
            <path d="M199.781 151.742H195.664V155.859H199.781V151.742Z" fill="black"/>
            <path d="M199.656 155.859V153.142C199.656 152.369 200.283 151.743 201.056 151.743H202.373C203.146 151.743 203.773 152.369 203.773 153.142V155.859H199.656Z" fill="black"/>
            <path d="M12.1055 155.734V159.851H9.38849C8.61548 159.851 7.98883 159.224 7.98883 158.451V155.734H12.1055Z" fill="black"/>
            <path d="M16.0932 155.734H11.9766V159.851H16.0932V155.734Z" fill="black"/>
            <path d="M32.0703 155.734V159.851H29.3533C28.5803 159.851 27.9537 159.224 27.9537 158.451V155.734H32.0703Z" fill="black"/>
            <path d="M36.062 155.734H31.9453V159.851H36.062V155.734Z" fill="black"/>
            <path d="M38.7969 159.767V159.849H40.0524V158.594H39.9701C39.9701 159.244 39.4432 159.767 38.7969 159.767Z" fill="black"/>
            <path d="M44.0463 155.734H39.9297V159.851H44.0463V155.734Z" fill="black"/>
            <path d="M48.0385 155.734H43.9219V159.851H48.0385V155.734Z" fill="black"/>
            <path d="M52.0346 155.734H47.918V159.851H52.0346V155.734Z" fill="black"/>
            <path d="M56.0268 155.734H51.9102V159.851H56.0268V155.734Z" fill="black"/>
            <path d="M55.9062 159.852V157.135C55.9062 156.362 56.5329 155.735 57.3059 155.735H58.6232C59.3962 155.735 60.0229 156.362 60.0229 157.135V159.852H55.9062Z" fill="black"/>
            <path d="M71.9995 155.734H67.8828V159.851H71.9995V155.734Z" fill="black"/>
            <path d="M75.9916 155.734H71.875V159.851H75.9916V155.734Z" fill="black"/>
            <path d="M79.9838 155.734H75.8672V159.851H79.9838V155.734Z" fill="black"/>
            <path d="M83.9799 155.734H79.8633V159.851H83.9799V155.734Z" fill="black"/>
            <path d="M87.9721 155.734H83.8555V159.851H87.9721V155.734Z" fill="black"/>
            <path d="M91.9682 155.734H87.8516V159.851H91.9682V155.734Z" fill="black"/>
            <path d="M91.8438 155.734H95.9604V158.451C95.9604 159.224 95.3337 159.851 94.5607 159.851H91.8438V155.734Z" fill="black"/>
            <path d="M106.684 159.767V159.849H107.939V158.594H107.857C107.857 159.244 107.33 159.767 106.684 159.767Z" fill="black"/>
            <path d="M107.812 159.852V157.135C107.812 156.362 108.439 155.735 109.212 155.735H110.529C111.302 155.735 111.929 156.362 111.929 157.135V159.852H107.812Z" fill="black"/>
            <path d="M119.917 155.734H115.801V159.851H119.917V155.734Z" fill="black"/>
            <path d="M123.914 155.734H119.797V159.851H123.914V155.734Z" fill="black"/>
            <path d="M127.906 155.734H123.789V159.851H127.906V155.734Z" fill="black"/>
            <path d="M131.898 155.734H127.781V159.851H131.898V155.734Z" fill="black"/>
            <path d="M135.89 155.734H131.773V159.851H135.89V155.734Z" fill="black"/>
            <path d="M151.863 155.734V159.851H149.146C148.373 159.851 147.747 159.224 147.747 158.451V155.734H151.863Z" fill="black"/>
            <path d="M155.859 155.734H151.742V159.851H155.859V155.734Z" fill="black"/>
            <path d="M159.851 155.734H155.734V159.851H159.851V155.734Z" fill="black"/>
            <path d="M163.843 155.734H159.727V159.851H163.843V155.734Z" fill="black"/>
            <path d="M163.719 159.852V155.735H166.436C167.209 155.735 167.835 156.362 167.835 157.135V159.852H163.719Z" fill="black"/>
            <path d="M175.824 159.852H173.107C172.334 159.852 171.708 159.225 171.708 158.452V157.135C171.708 156.362 172.334 155.735 173.107 155.735H175.824V159.852Z" fill="black"/>
            <path d="M179.812 155.734H175.695V159.851H179.812V155.734Z" fill="black"/>
            <path d="M183.808 155.734H179.691V159.851H183.808V155.734Z" fill="black"/>
            <path d="M187.8 155.734H183.684V159.851H187.8V155.734Z" fill="black"/>
            <path d="M191.796 155.734H187.68V159.851H191.796V155.734Z" fill="black"/>
            <path d="M195.789 155.734H191.672V159.851H195.789V155.734Z" fill="black"/>
            <path d="M199.781 155.734H195.664V159.851H199.781V155.734Z" fill="black"/>
            <path d="M207.641 159.852V157.135C207.641 156.362 208.267 155.735 209.04 155.735H210.358C211.131 155.735 211.757 156.362 211.757 157.135V159.852H207.641Z" fill="black"/>
            <path d="M3.99219 163.844V161.127C3.99219 160.354 4.61884 159.727 5.39184 159.727H6.70917C7.48218 159.727 8.10883 160.354 8.10883 161.127V163.844H3.99219Z" fill="black"/>
            <path d="M16.0977 159.727V163.843H13.3807C12.6077 163.843 11.981 163.217 11.981 162.444V159.727H16.0977Z" fill="black"/>
            <path d="M15.9727 163.844V161.127C15.9727 160.354 16.5993 159.727 17.3723 159.727H18.6896C19.4626 159.727 20.0893 160.354 20.0893 161.127V163.844H15.9727Z" fill="black"/>
            <path d="M23.9609 160.904V162.67C23.9609 163.32 24.4879 163.843 25.1383 163.843H26.9043C27.5589 163.843 28.0817 163.316 28.0817 162.666V160.904C28.0776 160.249 27.5506 159.727 26.9002 159.727H25.1383C24.4879 159.727 23.9609 160.253 23.9609 160.904Z" fill="black"/>
            <path d="M36.0625 159.727V163.843H33.3455C32.5725 163.843 31.9459 163.217 31.9459 162.444V159.727H36.0625Z" fill="black"/>
            <path d="M40.0541 159.727H35.9375V163.843H40.0541V159.727Z" fill="black"/>
            <path d="M44.0463 159.727H39.9297V163.843H44.0463V159.727Z" fill="black"/>
            <path d="M48.0385 159.727H43.9219V163.843H48.0385V159.727Z" fill="black"/>
            <path d="M52.0346 159.727H47.918V163.843H52.0346V159.727Z" fill="black"/>
            <path d="M58.7656 163.759V163.842H60.0212V162.586H59.9389C59.9389 163.236 59.4119 163.759 58.7656 163.759Z" fill="black"/>
            <path d="M59.8984 163.844V161.127C59.8984 160.354 60.5251 159.727 61.2981 159.727H62.6154C63.3884 159.727 64.0151 160.354 64.0151 161.127V163.844H59.8984Z" fill="black"/>
            <path d="M72 159.727V162.444C72 163.217 71.3734 163.843 70.6003 163.843H69.283C68.51 163.843 67.8834 163.217 67.8834 162.444V159.727H72Z" fill="black"/>
            <path d="M79.9883 159.727V162.444C79.9883 163.217 79.3616 163.843 78.5886 163.843H77.2713C76.4983 163.843 75.8716 163.217 75.8716 162.444V159.727H79.9883Z" fill="black"/>
            <path d="M87.9727 159.727V163.843H85.2557C84.4827 163.843 83.856 163.217 83.856 162.444V159.727H87.9727Z" fill="black"/>
            <path d="M87.8516 159.727H91.9682V162.444C91.9682 163.217 91.3416 163.843 90.5685 163.843H87.8516V159.727Z" fill="black"/>
            <path d="M99.9531 163.844H97.2361C96.4631 163.844 95.8365 163.217 95.8365 162.444V161.127C95.8365 160.354 96.4631 159.727 97.2361 159.727H99.9531V163.844Z" fill="black"/>
            <path d="M103.945 159.727H99.8281V163.843H103.945V159.727Z" fill="black"/>
            <path d="M107.937 159.727H103.82V163.843H107.937V159.727Z" fill="black"/>
            <path d="M111.929 159.727H107.812V163.843H111.929V159.727Z" fill="black"/>
            <path d="M119.918 159.727V162.444C119.918 163.217 119.291 163.843 118.518 163.843H117.201C116.428 163.843 115.801 163.217 115.801 162.444V159.727H119.918Z" fill="black"/>
            <path d="M131.898 159.727V163.843H129.181C128.408 163.843 127.782 163.217 127.782 162.444V159.727H131.898Z" fill="black"/>
            <path d="M135.89 159.727H131.773V163.843H135.89V159.727Z" fill="black"/>
            <path d="M143.754 160.904V162.67C143.754 163.32 144.281 163.843 144.931 163.843H146.697C147.352 163.843 147.875 163.316 147.875 162.666V160.904C147.871 160.249 147.344 159.727 146.693 159.727H144.931C144.281 159.727 143.754 160.253 143.754 160.904Z" fill="black"/>
            <path d="M155.859 159.727V163.843H153.142C152.369 163.843 151.743 163.217 151.743 162.444V159.727H155.859Z" fill="black"/>
            <path d="M155.734 159.727H159.851V162.444C159.851 163.217 159.224 163.843 158.451 163.843H155.734V159.727Z" fill="black"/>
            <path d="M167.835 159.727H163.719V163.843H167.835V159.727Z" fill="black"/>
            <path d="M179.812 159.727H175.695V163.843H179.812V159.727Z" fill="black"/>
            <path d="M187.801 159.727V163.843H185.084C184.311 163.843 183.684 163.217 183.684 162.444V159.727H187.801Z" fill="black"/>
            <path d="M187.68 159.727H191.796V162.444C191.796 163.217 191.17 163.843 190.397 163.843H187.68V159.727Z" fill="black"/>
            <path d="M199.781 159.727H195.664V163.843H199.781V159.727Z" fill="black"/>
            <path d="M199.656 163.844V161.127C199.656 160.354 200.283 159.727 201.056 159.727H202.373C203.146 159.727 203.773 160.354 203.773 161.127V163.844H199.656Z" fill="black"/>
            <path d="M206.512 163.759V163.842H207.767V162.586H207.685C207.685 163.236 207.158 163.759 206.512 163.759Z" fill="black"/>
            <path d="M211.757 159.727H207.641V163.843H211.757V159.727Z" fill="black"/>
            <path d="M2.85938 167.751V167.834H4.11495V166.578H4.03262C4.03262 167.229 3.50569 167.751 2.85938 167.751Z" fill="black"/>
            <path d="M8.10883 163.719H3.99219V167.835H8.10883V163.719Z" fill="black"/>
            <path d="M7.98438 167.836V165.119C7.98438 164.346 8.61102 163.719 9.38403 163.719H10.7014C11.4744 163.719 12.101 164.346 12.101 165.119V167.836H7.98438Z" fill="black"/>
            <path d="M44.0508 163.719V167.835H41.3338C40.5608 167.835 39.9341 167.209 39.9341 166.436V163.719H44.0508Z" fill="black"/>
            <path d="M48.0385 163.719H43.9219V167.835H48.0385V163.719Z" fill="black"/>
            <path d="M52.0346 163.719H47.918V167.835H52.0346V163.719Z" fill="black"/>
            <path d="M56.0268 163.719H51.9102V167.835H56.0268V163.719Z" fill="black"/>
            <path d="M60.0229 163.719H55.9062V167.835H60.0229V163.719Z" fill="black"/>
            <path d="M59.8984 163.719H64.0151V166.436C64.0151 167.209 63.3884 167.835 62.6154 167.835H59.8984V163.719Z" fill="black"/>
            <path d="M103.945 163.719H99.8281V167.835H103.945V163.719Z" fill="black"/>
            <path d="M111.934 163.719V167.835H109.217C108.444 167.835 107.817 167.209 107.817 166.436V163.719H111.934Z" fill="black"/>
            <path d="M111.809 167.836V163.719H114.526C115.299 163.719 115.925 164.346 115.925 165.119V167.836H111.809Z" fill="black"/>
            <path d="M119.797 164.896V166.662C119.797 167.313 120.324 167.835 120.974 167.835H122.74C123.395 167.835 123.918 167.308 123.918 166.658V164.896C123.914 164.242 123.387 163.719 122.736 163.719H120.974C120.324 163.719 119.797 164.246 119.797 164.896Z" fill="black"/>
            <path d="M135.891 163.719V167.835H133.174C132.401 167.835 131.774 167.209 131.774 166.436V163.719H135.891Z" fill="black"/>
            <path d="M139.882 163.719H135.766V167.835H139.882V163.719Z" fill="black"/>
            <path d="M139.758 167.836V165.119C139.758 164.346 140.384 163.719 141.157 163.719H142.475C143.248 163.719 143.874 164.346 143.874 165.119V167.836H139.758Z" fill="black"/>
            <path d="M146.613 167.751V167.834H147.869V166.578H147.787C147.787 167.229 147.26 167.751 146.613 167.751Z" fill="black"/>
            <path d="M147.746 167.836V165.119C147.746 164.346 148.373 163.719 149.146 163.719H150.463C151.236 163.719 151.863 164.346 151.863 165.119V167.836H147.746Z" fill="black"/>
            <path d="M163.844 167.836H159.727V165.119C159.727 164.346 160.354 163.719 161.127 163.719H163.844V167.836Z" fill="black"/>
            <path d="M167.835 163.719H163.719V167.835H167.835V163.719Z" fill="black"/>
            <path d="M171.828 163.719H167.711V167.835H171.828V163.719Z" fill="black"/>
            <path d="M175.82 163.719H171.703V167.835H175.82V163.719Z" fill="black"/>
            <path d="M179.812 163.719H175.695V167.835H179.812V163.719Z" fill="black"/>
            <path d="M179.691 167.836V163.719H182.408C183.181 163.719 183.808 164.346 183.808 165.119V167.836H179.691Z" fill="black"/>
            <path d="M195.789 167.836H193.072C192.299 167.836 191.672 167.209 191.672 166.436V165.119C191.672 164.346 192.299 163.719 193.072 163.719H195.789V167.836Z" fill="black"/>
            <path d="M199.781 163.719H195.664V167.835H199.781V163.719Z" fill="black"/>
            <path d="M207.77 167.836H203.653V165.119C203.653 164.346 204.28 163.719 205.053 163.719H207.77V167.836Z" fill="black"/>
            <path d="M207.641 163.719H211.757V166.436C211.757 167.209 211.131 167.835 210.358 167.835H207.641V163.719Z" fill="black"/>
            <path d="M4.11719 171.828H1.40021C0.627197 171.828 0.000549316 171.201 0.000549316 170.428V169.111C0.000549316 168.338 0.627197 167.711 1.40021 167.711H4.11719L4.11719 171.828Z" fill="black"/>
            <path d="M8.10883 167.711H3.99219V171.828H8.10883V167.711Z" fill="black"/>
            <path d="M16.0977 171.828H13.3807C12.6077 171.828 11.981 171.201 11.981 170.428V169.111C11.981 168.338 12.6077 167.711 13.3807 167.711H16.0977V171.828Z" fill="black"/>
            <path d="M20.0893 167.711H15.9727V171.828H20.0893V167.711Z" fill="black"/>
            <path d="M24.0815 167.711H19.9648V171.828H24.0815V167.711Z" fill="black"/>
            <path d="M23.9609 171.828V169.111C23.9609 168.338 24.5876 167.711 25.3606 167.711H26.6779C27.4509 167.711 28.0776 168.338 28.0776 169.111V171.828H23.9609Z" fill="black"/>
            <path d="M31.9453 168.888V170.654C31.9453 171.305 32.4722 171.828 33.1227 171.828H34.8887C35.5433 171.828 36.0661 171.301 36.0661 170.65V168.888C36.062 168.234 35.535 167.711 34.8846 167.711H33.1227C32.4722 167.711 31.9453 168.238 31.9453 168.888Z" fill="black"/>
            <path d="M48.043 167.711V171.828H45.326C44.553 171.828 43.9263 171.201 43.9263 170.428V167.711H48.043Z" fill="black"/>
            <path d="M52.0346 167.711H47.918V171.828H52.0346V167.711Z" fill="black"/>
            <path d="M60.0234 167.711V170.428C60.0234 171.201 59.3968 171.828 58.6238 171.828H57.3065C56.5334 171.828 55.9068 171.201 55.9068 170.428V167.711H60.0234Z" fill="black"/>
            <path d="M68.0078 171.828H63.8912V169.111C63.8912 168.338 64.5178 167.711 65.2908 167.711H68.0078V171.828Z" fill="black"/>
            <path d="M67.8828 171.828V167.711H70.5998C71.3728 167.711 71.9995 168.338 71.9995 169.111V171.828H67.8828Z" fill="black"/>
            <path d="M74.7383 171.744V171.826H75.9939V170.57H75.9115C75.9115 171.221 75.3846 171.744 74.7383 171.744Z" fill="black"/>
            <path d="M75.8672 171.828V169.111C75.8672 168.338 76.4938 167.711 77.2668 167.711H78.5842C79.3572 167.711 79.9838 168.338 79.9838 169.111V171.828H75.8672Z" fill="black"/>
            <path d="M83.8555 168.888V170.654C83.8555 171.305 84.3824 171.828 85.0328 171.828H86.7989C87.4534 171.828 87.9762 171.301 87.9762 170.65V168.888C87.9721 168.234 87.4452 167.711 86.7947 167.711H85.0328C84.3824 167.711 83.8555 168.238 83.8555 168.888Z" fill="black"/>
            <path d="M99.9531 171.828H97.2361C96.4631 171.828 95.8365 171.201 95.8365 170.428V169.111C95.8365 168.338 96.4631 167.711 97.2361 167.711H99.9531V171.828Z" fill="black"/>
            <path d="M103.945 167.711H99.8281V171.828H103.945V167.711Z" fill="black"/>
            <path d="M115.926 167.711V170.428C115.926 171.201 115.299 171.828 114.526 171.828H113.209C112.436 171.828 111.809 171.201 111.809 170.428V167.711H115.926Z" fill="black"/>
            <path d="M127.781 168.888V170.654C127.781 171.305 128.308 171.828 128.959 171.828H130.725C131.379 171.828 131.902 171.301 131.902 170.65V168.888C131.898 168.234 131.371 167.711 130.721 167.711H128.959C128.308 167.711 127.781 168.238 127.781 168.888Z" fill="black"/>
            <path d="M147.871 171.828H145.154C144.381 171.828 143.754 171.201 143.754 170.428V169.111C143.754 168.338 144.381 167.711 145.154 167.711H147.871V171.828Z" fill="black"/>
            <path d="M151.863 167.711H147.746V171.828H151.863V167.711Z" fill="black"/>
            <path d="M151.742 171.828V167.711H154.459C155.232 167.711 155.859 168.338 155.859 169.111V171.828H151.742Z" fill="black"/>
            <path d="M163.844 167.711V170.428C163.844 171.201 163.217 171.828 162.444 171.828H161.127C160.354 171.828 159.727 171.201 159.727 170.428V167.711H163.844Z" fill="black"/>
            <path d="M175.82 167.711H171.703V171.828H175.82V167.711Z" fill="black"/>
            <path d="M179.812 167.711H175.695V171.828H179.812V167.711Z" fill="black"/>
            <path d="M179.691 167.711H183.808V170.428C183.808 171.201 183.181 171.828 182.408 171.828H179.691V167.711Z" fill="black"/>
            <path d="M187.68 168.888V170.654C187.68 171.305 188.207 171.828 188.857 171.828H190.623C191.278 171.828 191.8 171.301 191.8 170.65V168.888C191.796 168.234 191.269 167.711 190.619 167.711H188.857C188.207 167.711 187.68 168.238 187.68 168.888Z" fill="black"/>
            <path d="M199.781 167.711H195.664V171.828H199.781V167.711Z" fill="black"/>
            <path d="M207.77 167.711V170.428C207.77 171.201 207.143 171.828 206.37 171.828H205.053C204.28 171.828 203.653 171.201 203.653 170.428V167.711H207.77Z" fill="black"/>
            <path d="M8.10938 171.703V175.82H5.39239C4.61938 175.82 3.99274 175.193 3.99274 174.42V171.703H8.10938Z" fill="black"/>
            <path d="M7.98438 175.82V173.103C7.98438 172.33 8.61102 171.704 9.38403 171.704H10.7014C11.4744 171.704 12.101 172.33 12.101 173.103V175.82H7.98438Z" fill="black"/>
            <path d="M34.8047 175.736V175.818H36.0603V174.562H35.9779C35.9779 175.213 35.451 175.736 34.8047 175.736Z" fill="black"/>
            <path d="M40.0547 175.82H35.938V173.103C35.938 172.33 36.5647 171.704 37.3377 171.704H40.0547V175.82Z" fill="black"/>
            <path d="M39.9297 175.82V171.704H42.6467C43.4197 171.704 44.0463 172.33 44.0463 173.103V175.82H39.9297Z" fill="black"/>
            <path d="M52.0346 171.703H47.918V175.82H52.0346V171.703Z" fill="black"/>
            <path d="M68.0078 171.703V175.82H65.2908C64.5178 175.82 63.8912 175.193 63.8912 174.42V171.703H68.0078Z" fill="black"/>
            <path d="M71.9995 171.703H67.8828V175.82H71.9995V171.703Z" fill="black"/>
            <path d="M75.9916 171.703H71.875V175.82H75.9916V171.703Z" fill="black"/>
            <path d="M79.9838 171.703H75.8672V175.82H79.9838V171.703Z" fill="black"/>
            <path d="M91.8438 175.82V173.103C91.8438 172.33 92.4704 171.704 93.2434 171.704H94.5607C95.3337 171.704 95.9604 172.33 95.9604 173.103V175.82H91.8438Z" fill="black"/>
            <path d="M98.6953 175.736V175.818H99.9509V174.562H99.8686C99.8686 175.213 99.3416 175.736 98.6953 175.736Z" fill="black"/>
            <path d="M103.945 171.703H99.8281V175.82H103.945V171.703Z" fill="black"/>
            <path d="M119.797 172.88V174.647C119.797 175.297 120.324 175.82 120.974 175.82H122.74C123.395 175.82 123.918 175.293 123.918 174.642V172.88C123.914 172.226 123.387 171.703 122.736 171.703H120.974C120.324 171.703 119.797 172.23 119.797 172.88Z" fill="black"/>
            <path d="M135.891 175.82H133.174C132.401 175.82 131.774 175.194 131.774 174.421V173.103C131.774 172.33 132.401 171.704 133.174 171.704H135.891V175.82Z" fill="black"/>
            <path d="M135.766 175.82V173.103C135.766 172.33 136.392 171.704 137.165 171.704H138.483C139.256 171.704 139.882 172.33 139.882 173.103V175.82H135.766Z" fill="black"/>
            <path d="M151.863 171.703V175.82H149.146C148.373 175.82 147.747 175.193 147.747 174.42V171.703H151.863Z" fill="black"/>
            <path d="M151.742 171.703H155.859V174.42C155.859 175.193 155.232 175.82 154.459 175.82H151.742V171.703Z" fill="black"/>
            <path d="M171.828 175.82H169.111C168.338 175.82 167.711 175.194 167.711 174.421V173.103C167.711 172.33 168.338 171.704 169.111 171.704H171.828V175.82Z" fill="black"/>
            <path d="M175.82 171.703H171.703V175.82H175.82V171.703Z" fill="black"/>
            <path d="M179.812 171.703H175.695V175.82H179.812V171.703Z" fill="black"/>
            <path d="M182.555 175.736V175.818H183.81V174.562H183.728C183.728 175.213 183.201 175.736 182.555 175.736Z" fill="black"/>
            <path d="M183.684 175.82V173.103C183.684 172.33 184.31 171.704 185.083 171.704H186.401C187.174 171.704 187.8 172.33 187.8 173.103V175.82H183.684Z" fill="black"/>
            <path d="M190.539 175.736V175.818H191.795V174.562H191.712C191.712 175.213 191.185 175.736 190.539 175.736Z" fill="black"/>
            <path d="M195.789 175.82H191.672V173.103C191.672 172.33 192.299 171.704 193.072 171.704H195.789V175.82Z" fill="black"/>
            <path d="M199.781 171.703H195.664V175.82H199.781V171.703Z" fill="black"/>
            <path d="M11.9766 176.873V178.639C11.9766 179.289 12.5035 179.812 13.1539 179.812H14.92C15.5745 179.812 16.0973 179.285 16.0973 178.635V176.873C16.0932 176.218 15.5663 175.695 14.9158 175.695H13.1539C12.5035 175.695 11.9766 176.222 11.9766 176.873Z" fill="black"/>
            <path d="M23.9609 176.873V178.639C23.9609 179.289 24.4879 179.812 25.1383 179.812H26.9043C27.5589 179.812 28.0817 179.285 28.0817 178.635V176.873C28.0776 176.218 27.5506 175.695 26.9002 175.695H25.1383C24.4879 175.695 23.9609 176.222 23.9609 176.873Z" fill="black"/>
            <path d="M36.0625 179.812H31.9459V177.096C31.9459 176.323 32.5725 175.696 33.3455 175.696H36.0625V179.812Z" fill="black"/>
            <path d="M40.0541 175.695H35.9375V179.812H40.0541V175.695Z" fill="black"/>
            <path d="M39.9297 175.695H44.0463V178.412C44.0463 179.185 43.4197 179.812 42.6467 179.812H39.9297V175.695Z" fill="black"/>
            <path d="M52.0352 175.695V179.812H49.3182C48.5452 179.812 47.9185 179.185 47.9185 178.412V175.695H52.0352Z" fill="black"/>
            <path d="M56.0268 175.695H51.9102V179.812H56.0268V175.695Z" fill="black"/>
            <path d="M60.0229 175.695H55.9062V179.812H60.0229V175.695Z" fill="black"/>
            <path d="M59.8984 179.812V175.696H62.6154C63.3884 175.696 64.0151 176.323 64.0151 177.096V179.812H59.8984Z" fill="black"/>
            <path d="M79.9838 175.695H75.8672V179.812H79.9838V175.695Z" fill="black"/>
            <path d="M83.9799 175.695H79.8633V179.812H83.9799V175.695Z" fill="black"/>
            <path d="M83.8555 179.812V175.696H86.5724C87.3455 175.696 87.9721 176.323 87.9721 177.096V179.812H83.8555Z" fill="black"/>
            <path d="M90.7109 179.736V179.818H91.9665V178.562H91.8842C91.8842 179.213 91.3572 179.736 90.7109 179.736Z" fill="black"/>
            <path d="M95.9604 175.695H91.8438V179.812H95.9604V175.695Z" fill="black"/>
            <path d="M99.9526 175.695H95.8359V179.812H99.9526V175.695Z" fill="black"/>
            <path d="M103.945 175.695H99.8281V179.812H103.945V175.695Z" fill="black"/>
            <path d="M107.937 175.695H103.82V179.812H107.937V175.695Z" fill="black"/>
            <path d="M111.929 175.695H107.812V179.812H111.929V175.695Z" fill="black"/>
            <path d="M115.925 175.695H111.809V179.812H115.925V175.695Z" fill="black"/>
            <path d="M115.801 179.812V175.696H118.518C119.291 175.696 119.917 176.323 119.917 177.096V179.812H115.801Z" fill="black"/>
            <path d="M127.906 179.812H125.189C124.416 179.812 123.79 179.186 123.79 178.413V177.096C123.79 176.323 124.416 175.696 125.189 175.696H127.906V179.812Z" fill="black"/>
            <path d="M127.781 179.812V177.096C127.781 176.323 128.408 175.696 129.181 175.696H130.498C131.271 175.696 131.898 176.323 131.898 177.096V179.812H127.781Z" fill="black"/>
            <path d="M138.625 179.736V179.818H139.881V178.562H139.798C139.798 179.213 139.271 179.736 138.625 179.736Z" fill="black"/>
            <path d="M143.879 179.812H139.762V177.096C139.762 176.323 140.389 175.696 141.162 175.696H143.879V179.812Z" fill="black"/>
            <path d="M143.754 179.812V177.096C143.754 176.323 144.381 175.696 145.154 175.696H146.471C147.244 175.696 147.871 176.323 147.871 177.096V179.812H143.754Z" fill="black"/>
            <path d="M155.734 176.873V178.639C155.734 179.289 156.261 179.812 156.912 179.812H158.678C159.332 179.812 159.855 179.285 159.855 178.635V176.873C159.851 176.218 159.324 175.695 158.674 175.695H156.912C156.261 175.695 155.734 176.222 155.734 176.873Z" fill="black"/>
            <path d="M163.719 176.873V178.639C163.719 179.289 164.246 179.812 164.896 179.812H166.662C167.317 179.812 167.84 179.285 167.84 178.635V176.873C167.835 176.218 167.308 175.695 166.658 175.695H164.896C164.246 175.695 163.719 176.222 163.719 176.873Z" fill="black"/>
            <path d="M179.812 175.695H175.695V179.812H179.812V175.695Z" fill="black"/>
            <path d="M183.808 175.695H179.691V179.812H183.808V175.695Z" fill="black"/>
            <path d="M187.8 175.695H183.684V179.812H187.8V175.695Z" fill="black"/>
            <path d="M191.796 175.695H187.68V179.812H191.796V175.695Z" fill="black"/>
            <path d="M195.789 175.695H191.672V179.812H195.789V175.695Z" fill="black"/>
            <path d="M199.781 175.695H195.664V179.812H199.781V175.695Z" fill="black"/>
            <path d="M199.656 179.812V175.696H202.373C203.146 175.696 203.773 176.323 203.773 177.096V179.812H199.656Z" fill="black"/>
            <path d="M207.641 179.812V177.096C207.641 176.323 208.267 175.696 209.04 175.696H210.358C211.131 175.696 211.757 176.323 211.757 177.096V179.812H207.641Z" fill="black"/>
            <path d="M36.0625 179.688V183.804H33.3455C32.5725 183.804 31.9459 183.177 31.9459 182.404V179.688H36.0625Z" fill="black"/>
            <path d="M35.9375 179.688H40.0541V182.404C40.0541 183.177 39.4275 183.804 38.6545 183.804H35.9375V179.688Z" fill="black"/>
            <path d="M56.0273 179.688V183.804H53.3104C52.5374 183.804 51.9107 183.177 51.9107 182.404V179.688H56.0273Z" fill="black"/>
            <path d="M60.0229 179.688H55.9062V183.804H60.0229V179.688Z" fill="black"/>
            <path d="M59.8984 179.688H64.0151V182.404C64.0151 183.177 63.3884 183.804 62.6154 183.804H59.8984V179.688Z" fill="black"/>
            <path d="M72 183.805H69.283C68.51 183.805 67.8834 183.178 67.8834 182.405V181.088C67.8834 180.315 68.51 179.688 69.283 179.688H72V183.805Z" fill="black"/>
            <path d="M75.9916 179.688H71.875V183.804H75.9916V179.688Z" fill="black"/>
            <path d="M79.9838 179.688H75.8672V183.804H79.9838V179.688Z" fill="black"/>
            <path d="M87.9727 179.688V183.804H85.2557C84.4827 183.804 83.856 183.177 83.856 182.404V179.688H87.9727Z" fill="black"/>
            <path d="M91.9682 179.688H87.8516V183.804H91.9682V179.688Z" fill="black"/>
            <path d="M95.9604 179.688H91.8438V183.804H95.9604V179.688Z" fill="black"/>
            <path d="M99.9526 179.688H95.8359V183.804H99.9526V179.688Z" fill="black"/>
            <path d="M115.925 179.688H111.809V183.804H115.925V179.688Z" fill="black"/>
            <path d="M119.917 179.688H115.801V183.804H119.917V179.688Z" fill="black"/>
            <path d="M119.797 183.805V179.688H122.514C123.287 179.688 123.914 180.315 123.914 181.088V183.805H119.797Z" fill="black"/>
            <path d="M130.641 183.728V183.81H131.896V182.555H131.814C131.814 183.205 131.287 183.728 130.641 183.728Z" fill="black"/>
            <path d="M135.891 183.805H131.774V181.088C131.774 180.315 132.401 179.688 133.174 179.688H135.891V183.805Z" fill="black"/>
            <path d="M139.882 179.688H135.766V183.804H139.882V179.688Z" fill="black"/>
            <path d="M143.874 179.688H139.758V183.804H143.874V179.688Z" fill="black"/>
            <path d="M167.711 180.865V182.631C167.711 183.281 168.238 183.804 168.888 183.804H170.654C171.309 183.804 171.832 183.277 171.832 182.627V180.865C171.828 180.21 171.301 179.688 170.65 179.688H168.888C168.238 179.688 167.711 180.214 167.711 180.865Z" fill="black"/>
            <path d="M179.812 179.688H175.695V183.804H179.812V179.688Z" fill="black"/>
            <path d="M195.789 179.688H191.672V183.804H195.789V179.688Z" fill="black"/>
            <path d="M199.781 179.688H195.664V183.804H199.781V179.688Z" fill="black"/>
            <path d="M203.773 179.688H199.656V183.804H203.773V179.688Z" fill="black"/>
            <path d="M211.762 179.688V182.404C211.762 183.177 211.135 183.804 210.362 183.804H209.045C208.272 183.804 207.645 183.177 207.645 182.404V179.688H211.762Z" fill="black"/>
            <path d="M38.7969 187.72V187.802H40.0524V186.547H39.9701C39.9701 187.197 39.4432 187.72 38.7969 187.72Z" fill="black"/>
            <path d="M39.9297 187.805V185.088C39.9297 184.315 40.5563 183.688 41.3293 183.688H42.6467C43.4197 183.688 44.0463 184.315 44.0463 185.088V187.805H39.9297Z" fill="black"/>
            <path d="M60.0229 183.688H55.9062V187.804H60.0229V183.688Z" fill="black"/>
            <path d="M63.8906 184.865V186.631C63.8906 187.281 64.4176 187.804 65.068 187.804H66.834C67.4886 187.804 68.0114 187.277 68.0114 186.627V184.865C68.0073 184.21 67.4803 183.688 66.8299 183.688H65.068C64.4176 183.688 63.8906 184.214 63.8906 184.865Z" fill="black"/>
            <path d="M79.9838 183.688H75.8672V187.804H79.9838V183.688Z" fill="black"/>
            <path d="M91.9682 183.688H87.8516V187.804H91.9682V183.688Z" fill="black"/>
            <path d="M99.9526 183.688H95.8359V187.804H99.9526V183.688Z" fill="black"/>
            <path d="M103.82 184.865V186.631C103.82 187.281 104.347 187.804 104.998 187.804H106.764C107.418 187.804 107.941 187.277 107.941 186.627V184.865C107.937 184.21 107.41 183.688 106.76 183.688H104.998C104.347 183.688 103.82 184.214 103.82 184.865Z" fill="black"/>
            <path d="M115.925 183.688H111.809V187.804H115.925V183.688Z" fill="black"/>
            <path d="M119.917 183.688H115.801V187.804H119.917V183.688Z" fill="black"/>
            <path d="M123.914 183.688H119.797V187.804H123.914V183.688Z" fill="black"/>
            <path d="M127.906 183.688H123.789V187.804H127.906V183.688Z" fill="black"/>
            <path d="M131.898 183.688H127.781V187.804H131.898V183.688Z" fill="black"/>
            <path d="M135.89 183.688H131.773V187.804H135.89V183.688Z" fill="black"/>
            <path d="M139.882 183.688H135.766V187.804H139.882V183.688Z" fill="black"/>
            <path d="M139.758 183.688H143.874V186.404C143.874 187.177 143.248 187.804 142.475 187.804H139.758V183.688Z" fill="black"/>
            <path d="M151.863 187.805H149.146C148.373 187.805 147.747 187.178 147.747 186.405V185.088C147.747 184.315 148.373 183.688 149.146 183.688H151.863V187.805Z" fill="black"/>
            <path d="M155.859 183.688H151.742V187.804H155.859V183.688Z" fill="black"/>
            <path d="M155.734 187.805V183.688H158.451C159.224 183.688 159.851 184.315 159.851 185.088V187.805H155.734Z" fill="black"/>
            <path d="M163.719 184.865V186.631C163.719 187.281 164.246 187.804 164.896 187.804H166.662C167.317 187.804 167.84 187.277 167.84 186.627V184.865C167.835 184.21 167.308 183.688 166.658 183.688H164.896C164.246 183.688 163.719 184.214 163.719 184.865Z" fill="black"/>
            <path d="M179.812 183.688H175.695V187.804H179.812V183.688Z" fill="black"/>
            <path d="M183.684 184.865V186.631C183.684 187.281 184.211 187.804 184.861 187.804H186.627C187.282 187.804 187.804 187.277 187.804 186.627V184.865C187.8 184.21 187.273 183.688 186.623 183.688H184.861C184.211 183.688 183.684 184.214 183.684 184.865Z" fill="black"/>
            <path d="M195.789 183.688H191.672V187.804H195.789V183.688Z" fill="black"/>
            <path d="M203.773 183.688V186.404C203.773 187.177 203.147 187.804 202.374 187.804H201.056C200.283 187.804 199.657 187.177 199.657 186.404V183.688H203.773Z" fill="black"/>
            <path d="M40.0547 191.797H37.3377C36.5647 191.797 35.938 191.17 35.938 190.397V189.08C35.938 188.307 36.5647 187.68 37.3377 187.68H40.0547V191.797Z" fill="black"/>
            <path d="M44.0463 187.68H39.9297V191.796H44.0463V187.68Z" fill="black"/>
            <path d="M43.9219 191.797V187.68H46.6389C47.4119 187.68 48.0385 188.307 48.0385 189.08V191.797H43.9219Z" fill="black"/>
            <path d="M60.0234 187.68V191.796H57.3065C56.5334 191.796 55.9068 191.17 55.9068 190.397V187.68H60.0234Z" fill="black"/>
            <path d="M59.8984 191.797V187.68H62.6154C63.3884 187.68 64.0151 188.307 64.0151 189.08V191.797H59.8984Z" fill="black"/>
            <path d="M72 191.797H69.283C68.51 191.797 67.8834 191.17 67.8834 190.397V189.08C67.8834 188.307 68.51 187.68 69.283 187.68H72V191.797Z" fill="black"/>
            <path d="M75.9916 187.68H71.875V191.796H75.9916V187.68Z" fill="black"/>
            <path d="M79.9838 187.68H75.8672V191.796H79.9838V187.68Z" fill="black"/>
            <path d="M79.8633 191.797V187.68H82.5803C83.3533 187.68 83.9799 188.307 83.9799 189.08V191.797H79.8633Z" fill="black"/>
            <path d="M86.7188 191.712V191.795H87.9743V190.539H87.892C87.892 191.189 87.3651 191.712 86.7188 191.712Z" fill="black"/>
            <path d="M91.9682 187.68H87.8516V191.796H91.9682V187.68Z" fill="black"/>
            <path d="M95.9604 187.68H91.8438V191.796H95.9604V187.68Z" fill="black"/>
            <path d="M99.9526 187.68H95.8359V191.796H99.9526V187.68Z" fill="black"/>
            <path d="M110.676 191.712V191.795H111.931V190.539H111.849C111.849 191.189 111.322 191.712 110.676 191.712Z" fill="black"/>
            <path d="M115.925 187.68H111.809V191.796H115.925V187.68Z" fill="black"/>
            <path d="M119.917 187.68H115.801V191.796H119.917V187.68Z" fill="black"/>
            <path d="M123.914 187.68H119.797V191.796H123.914V187.68Z" fill="black"/>
            <path d="M127.906 187.68H123.789V191.796H127.906V187.68Z" fill="black"/>
            <path d="M127.781 187.68H131.898V190.397C131.898 191.17 131.271 191.796 130.498 191.796H127.781V187.68Z" fill="black"/>
            <path d="M139.887 187.68V190.397C139.887 191.17 139.26 191.796 138.487 191.796H137.17C136.397 191.796 135.77 191.17 135.77 190.397V187.68H139.887Z" fill="black"/>
            <path d="M159.852 187.68V190.397C159.852 191.17 159.225 191.796 158.452 191.796H157.135C156.362 191.796 155.735 191.17 155.735 190.397V187.68H159.852Z" fill="black"/>
            <path d="M166.578 191.712V191.795H167.834V190.539H167.751C167.751 191.189 167.224 191.712 166.578 191.712Z" fill="black"/>
            <path d="M167.711 191.797V189.08C167.711 188.307 168.338 187.68 169.111 187.68H170.428C171.201 187.68 171.828 188.307 171.828 189.08V191.797H167.711Z" fill="black"/>
            <path d="M179.812 187.68H175.695V191.796H179.812V187.68Z" fill="black"/>
            <path d="M190.539 191.712V191.795H191.795V190.539H191.712C191.712 191.189 191.185 191.712 190.539 191.712Z" fill="black"/>
            <path d="M195.789 187.68H191.672V191.796H195.789V187.68Z" fill="black"/>
            <path d="M195.664 191.797V187.68H198.381C199.154 187.68 199.781 188.307 199.781 189.08V191.797H195.664Z" fill="black"/>
            <path d="M31.9453 195.789V193.072C31.9453 192.299 32.572 191.672 33.345 191.672H34.6623C35.4353 191.672 36.062 192.299 36.062 193.072V195.789H31.9453Z" fill="black"/>
            <path d="M44.0508 191.672V195.789H41.3338C40.5608 195.789 39.9341 195.162 39.9341 194.389V191.672H44.0508Z" fill="black"/>
            <path d="M43.9219 191.672H48.0385V194.389C48.0385 195.162 47.4119 195.789 46.6389 195.789H43.9219V191.672Z" fill="black"/>
            <path d="M51.9102 195.789V193.072C51.9102 192.299 52.5368 191.672 53.3098 191.672H54.6271C55.4001 191.672 56.0268 192.299 56.0268 193.072V195.789H51.9102Z" fill="black"/>
            <path d="M64.0156 191.672V195.789H61.2986C60.5256 195.789 59.899 195.162 59.899 194.389V191.672H64.0156Z" fill="black"/>
            <path d="M63.8906 195.789V193.072C63.8906 192.299 64.5173 191.672 65.2903 191.672H66.6076C67.3806 191.672 68.0073 192.299 68.0073 193.072V195.789H63.8906Z" fill="black"/>
            <path d="M75.9961 191.672V195.789H73.2791C72.5061 195.789 71.8795 195.162 71.8795 194.389V191.672H75.9961Z" fill="black"/>
            <path d="M79.9838 191.672H75.8672V195.789H79.9838V191.672Z" fill="black"/>
            <path d="M83.9799 191.672H79.8633V195.789H83.9799V191.672Z" fill="black"/>
            <path d="M87.9721 191.672H83.8555V195.789H87.9721V191.672Z" fill="black"/>
            <path d="M91.9682 191.672H87.8516V195.789H91.9682V191.672Z" fill="black"/>
            <path d="M95.9604 191.672H91.8438V195.789H95.9604V191.672Z" fill="black"/>
            <path d="M99.9526 191.672H95.8359V195.789H99.9526V191.672Z" fill="black"/>
            <path d="M103.945 191.672H99.8281V195.789H103.945V191.672Z" fill="black"/>
            <path d="M107.937 191.672H103.82V195.789H107.937V191.672Z" fill="black"/>
            <path d="M111.929 191.672H107.812V195.789H111.929V191.672Z" fill="black"/>
            <path d="M115.925 191.672H111.809V195.789H115.925V191.672Z" fill="black"/>
            <path d="M119.917 191.672H115.801V195.789H119.917V191.672Z" fill="black"/>
            <path d="M127.906 191.672V194.389C127.906 195.162 127.28 195.789 126.507 195.789H125.189C124.416 195.789 123.79 195.162 123.79 194.389V191.672H127.906Z" fill="black"/>
            <path d="M130.641 195.704V195.787H131.896V194.531H131.814C131.814 195.182 131.287 195.704 130.641 195.704Z" fill="black"/>
            <path d="M131.773 195.789V193.072C131.773 192.299 132.4 191.672 133.173 191.672H134.49C135.263 191.672 135.89 192.299 135.89 193.072V195.789H131.773Z" fill="black"/>
            <path d="M143.879 195.789H141.162C140.389 195.789 139.762 195.162 139.762 194.389V193.072C139.762 192.299 140.389 191.672 141.162 191.672H143.879V195.789Z" fill="black"/>
            <path d="M147.871 191.672H143.754V195.789H147.871V191.672Z" fill="black"/>
            <path d="M151.863 191.672H147.746V195.789H151.863V191.672Z" fill="black"/>
            <path d="M151.742 195.789V193.072C151.742 192.299 152.369 191.672 153.142 191.672H154.459C155.232 191.672 155.859 192.299 155.859 193.072V195.789H151.742Z" fill="black"/>
            <path d="M167.836 195.789H163.719V193.072C163.719 192.299 164.346 191.672 165.119 191.672H167.836V195.789Z" fill="black"/>
            <path d="M171.828 191.672H167.711V195.789H171.828V191.672Z" fill="black"/>
            <path d="M174.566 195.704V195.787H175.822V194.531H175.74C175.74 195.182 175.213 195.704 174.566 195.704Z" fill="black"/>
            <path d="M179.812 191.672H175.695V195.789H179.812V191.672Z" fill="black"/>
            <path d="M183.808 191.672H179.691V195.789H183.808V191.672Z" fill="black"/>
            <path d="M187.8 191.672H183.684V195.789H187.8V191.672Z" fill="black"/>
            <path d="M191.796 191.672H187.68V195.789H191.796V191.672Z" fill="black"/>
            <path d="M195.789 191.672H191.672V195.789H195.789V191.672Z" fill="black"/>
            <path d="M199.781 191.672H195.664V195.789H199.781V191.672Z" fill="black"/>
            <path d="M203.773 191.672H199.656V195.789H203.773V191.672Z" fill="black"/>
            <path d="M203.648 195.789V193.072C203.648 192.299 204.275 191.672 205.048 191.672H206.365C207.138 191.672 207.765 192.299 207.765 193.072V195.789H203.648Z" fill="black"/>
            <path d="M36.062 195.664H31.9453V199.781H36.062V195.664Z" fill="black"/>
            <path d="M35.9375 199.781V197.064C35.9375 196.291 36.5641 195.665 37.3372 195.665H38.6545C39.4275 195.665 40.0541 196.291 40.0541 197.064V199.781H35.9375Z" fill="black"/>
            <path d="M56.0268 195.664H51.9102V199.781H56.0268V195.664Z" fill="black"/>
            <path d="M55.9062 199.781V195.665H58.6232C59.3962 195.665 60.0229 196.291 60.0229 197.064V199.781H55.9062Z" fill="black"/>
            <path d="M66.75 199.697V199.779H68.0056V198.523H67.9232C67.9232 199.174 67.3963 199.697 66.75 199.697Z" fill="black"/>
            <path d="M67.8828 199.781V197.064C67.8828 196.291 68.5095 195.665 69.2825 195.665H70.5998C71.3728 195.665 71.9995 196.291 71.9995 197.064V199.781H67.8828Z" fill="black"/>
            <path d="M83.9799 195.664H79.8633V199.781H83.9799V195.664Z" fill="black"/>
            <path d="M83.8555 195.664H87.9721V198.381C87.9721 199.154 87.3455 199.781 86.5724 199.781H83.8555V195.664Z" fill="black"/>
            <path d="M107.937 195.664H103.82V199.781H107.937V195.664Z" fill="black"/>
            <path d="M107.812 195.664H111.929V198.381C111.929 199.154 111.302 199.781 110.529 199.781H107.812V195.664Z" fill="black"/>
            <path d="M119.917 195.664H115.801V199.781H119.917V195.664Z" fill="black"/>
            <path d="M131.898 199.781H127.782V197.064C127.782 196.291 128.408 195.665 129.181 195.665H131.898V199.781Z" fill="black"/>
            <path d="M135.89 195.664H131.773V199.781H135.89V195.664Z" fill="black"/>
            <path d="M147.871 195.664V199.781H145.154C144.381 199.781 143.754 199.154 143.754 198.381V195.664H147.871Z" fill="black"/>
            <path d="M151.863 195.664H147.746V199.781H151.863V195.664Z" fill="black"/>
            <path d="M162.586 199.697V199.779H163.842V198.523H163.759C163.759 199.174 163.232 199.697 162.586 199.697Z" fill="black"/>
            <path d="M167.835 195.664H163.719V199.781H167.835V195.664Z" fill="black"/>
            <path d="M171.828 195.664H167.711V199.781H171.828V195.664Z" fill="black"/>
            <path d="M175.82 195.664H171.703V199.781H175.82V195.664Z" fill="black"/>
            <path d="M179.812 195.664H175.695V199.781H179.812V195.664Z" fill="black"/>
            <path d="M183.808 195.664H179.691V199.781H183.808V195.664Z" fill="black"/>
            <path d="M191.796 195.664H187.68V199.781H191.796V195.664Z" fill="black"/>
            <path d="M199.781 195.664V198.381C199.781 199.154 199.155 199.781 198.382 199.781H197.064C196.291 199.781 195.665 199.154 195.665 198.381V195.664H199.781Z" fill="black"/>
            <path d="M36.0625 199.656V202.373C36.0625 203.146 35.4359 203.773 34.6628 203.773H33.3455C32.5725 203.773 31.9459 203.146 31.9459 202.373V199.656H36.0625Z" fill="black"/>
            <path d="M56.0268 199.656H51.9102V203.773H56.0268V199.656Z" fill="black"/>
            <path d="M60.0229 199.656H55.9062V203.773H60.0229V199.656Z" fill="black"/>
            <path d="M64.0151 199.656H59.8984V203.773H64.0151V199.656Z" fill="black"/>
            <path d="M68.0073 199.656H63.8906V203.773H68.0073V199.656Z" fill="black"/>
            <path d="M71.9995 199.656H67.8828V203.773H71.9995V199.656Z" fill="black"/>
            <path d="M71.875 203.773V199.657H74.592C75.365 199.657 75.9916 200.283 75.9916 201.056V203.773H71.875Z" fill="black"/>
            <path d="M83.9805 199.656V202.373C83.9805 203.146 83.3538 203.773 82.5808 203.773H81.2635C80.4905 203.773 79.8638 203.146 79.8638 202.373V199.656H83.9805Z" fill="black"/>
            <path d="M102.688 203.689V203.771H103.943V202.516H103.861C103.861 203.166 103.334 203.689 102.688 203.689Z" fill="black"/>
            <path d="M107.937 199.656H103.82V203.773H107.937V199.656Z" fill="black"/>
            <path d="M115.926 203.773H113.209C112.436 203.773 111.809 203.147 111.809 202.374V201.056C111.809 200.283 112.436 199.657 113.209 199.657H115.926V203.773Z" fill="black"/>
            <path d="M119.917 199.656H115.801V203.773H119.917V199.656Z" fill="black"/>
            <path d="M131.898 199.656H127.781V203.773H131.898V199.656Z" fill="black"/>
            <path d="M135.89 199.656H131.773V203.773H135.89V199.656Z" fill="black"/>
            <path d="M139.882 199.656H135.766V203.773H139.882V199.656Z" fill="black"/>
            <path d="M139.758 203.773V201.056C139.758 200.283 140.384 199.657 141.157 199.657H142.475C143.248 199.657 143.874 200.283 143.874 201.056V203.773H139.758Z" fill="black"/>
            <path d="M151.863 199.656H147.746V203.773H151.863V199.656Z" fill="black"/>
            <path d="M158.594 203.689V203.771H159.849V202.516H159.767C159.767 203.166 159.24 203.689 158.594 203.689Z" fill="black"/>
            <path d="M163.844 203.773H159.727V201.056C159.727 200.283 160.354 199.657 161.127 199.657H163.844V203.773Z" fill="black"/>
            <path d="M167.835 199.656H163.719V203.773H167.835V199.656Z" fill="black"/>
            <path d="M183.809 199.656V203.773H181.092C180.319 203.773 179.692 203.146 179.692 202.373V199.656H183.809Z" fill="black"/>
            <path d="M187.8 199.656H183.684V203.773H187.8V199.656Z" fill="black"/>
            <path d="M191.796 199.656H187.68V203.773H191.796V199.656Z" fill="black"/>
            <path d="M191.672 203.773V199.657H194.389C195.162 199.657 195.789 200.283 195.789 201.056V203.773H191.672Z" fill="black"/>
            <path d="M199.656 200.834V202.6C199.656 203.25 200.183 203.773 200.834 203.773H202.6C203.254 203.773 203.777 203.246 203.777 202.596V200.834C203.773 200.179 203.246 199.656 202.596 199.656H200.834C200.183 199.656 199.656 200.183 199.656 200.834Z" fill="black"/>
            <path d="M39.9297 204.826V206.592C39.9297 207.242 40.4566 207.765 41.107 207.765H42.8731C43.5276 207.765 44.0504 207.238 44.0504 206.588V204.826C44.0463 204.171 43.5194 203.648 42.869 203.648H41.107C40.4566 203.648 39.9297 204.175 39.9297 204.826Z" fill="black"/>
            <path d="M56.0273 203.648V206.365C56.0273 207.138 55.4007 207.765 54.6277 207.765H53.3104C52.5374 207.765 51.9107 207.138 51.9107 206.365V203.648H56.0273Z" fill="black"/>
            <path d="M68.0078 203.648V207.765H65.2908C64.5178 207.765 63.8912 207.138 63.8912 206.365V203.648H68.0078Z" fill="black"/>
            <path d="M71.9995 203.648H67.8828V207.765H71.9995V203.648Z" fill="black"/>
            <path d="M75.9916 203.648H71.875V207.765H75.9916V203.648Z" fill="black"/>
            <path d="M87.8516 204.826V206.592C87.8516 207.242 88.3785 207.765 89.0289 207.765H90.795C91.4495 207.765 91.9723 207.238 91.9723 206.588V204.826C91.9682 204.171 91.4413 203.648 90.7908 203.648H89.0289C88.3785 203.648 87.8516 204.175 87.8516 204.826Z" fill="black"/>
            <path d="M103.945 207.766H101.228C100.455 207.766 99.8287 207.139 99.8287 206.366V205.049C99.8287 204.276 100.455 203.649 101.228 203.649H103.945V207.766Z" fill="black"/>
            <path d="M107.937 203.648H103.82V207.765H107.937V203.648Z" fill="black"/>
            <path d="M107.812 207.766V205.049C107.812 204.276 108.439 203.649 109.212 203.649H110.529C111.302 203.649 111.929 204.276 111.929 205.049V207.766H107.812Z" fill="black"/>
            <path d="M119.917 203.648H115.801V207.765H119.917V203.648Z" fill="black"/>
            <path d="M119.797 207.766V205.049C119.797 204.276 120.424 203.649 121.197 203.649H122.514C123.287 203.649 123.914 204.276 123.914 205.049V207.766H119.797Z" fill="black"/>
            <path d="M126.648 207.681V207.763H127.904V206.508H127.822C127.822 207.158 127.295 207.681 126.648 207.681Z" fill="black"/>
            <path d="M131.898 203.648H127.781V207.765H131.898V203.648Z" fill="black"/>
            <path d="M147.871 207.766H145.154C144.381 207.766 143.754 207.139 143.754 206.366V205.049C143.754 204.276 144.381 203.649 145.154 203.649H147.871V207.766Z" fill="black"/>
            <path d="M151.863 203.648H147.746V207.765H151.863V203.648Z" fill="black"/>
            <path d="M155.859 203.648H151.742V207.765H155.859V203.648Z" fill="black"/>
            <path d="M159.851 203.648H155.734V207.765H159.851V203.648Z" fill="black"/>
            <path d="M163.843 203.648H159.727V207.765H163.843V203.648Z" fill="black"/>
            <path d="M167.835 203.648H163.719V207.765H167.835V203.648Z" fill="black"/>
            <path d="M167.711 207.766V203.649H170.428C171.201 203.649 171.828 204.276 171.828 205.049V207.766H167.711Z" fill="black"/>
            <path d="M175.695 207.766V205.049C175.695 204.276 176.322 203.649 177.095 203.649H178.412C179.185 203.649 179.812 204.276 179.812 205.049V207.766H175.695Z" fill="black"/>
            <path d="M191.797 203.648V207.765H189.08C188.307 207.765 187.68 207.138 187.68 206.365V203.648H191.797Z" fill="black"/>
            <path d="M195.789 203.648H191.672V207.765H195.789V203.648Z" fill="black"/>
            <path d="M195.664 207.766V205.049C195.664 204.276 196.291 203.649 197.064 203.649H198.381C199.154 203.649 199.781 204.276 199.781 205.049V207.766H195.664Z" fill="black"/>
            <path d="M203.648 204.826V206.592C203.648 207.242 204.175 207.765 204.826 207.765H206.592C207.246 207.765 207.769 207.238 207.769 206.588V204.826C207.765 204.171 207.238 203.648 206.588 203.648H204.826C204.175 203.648 203.648 204.175 203.648 204.826Z" fill="black"/>
            <path d="M35.9375 208.818V210.584C35.9375 211.234 36.4644 211.757 37.1149 211.757H38.8809C39.5354 211.757 40.0583 211.23 40.0583 210.58V208.818C40.0541 208.163 39.5272 207.641 38.8768 207.641H37.1149C36.4644 207.641 35.9375 208.168 35.9375 208.818Z" fill="black"/>
            <path d="M47.918 208.818V210.584C47.918 211.234 48.4449 211.757 49.0953 211.757H50.8614C51.5159 211.757 52.0387 211.23 52.0387 210.58V208.818C52.0346 208.163 51.5077 207.641 50.8572 207.641H49.0953C48.4449 207.641 47.918 208.168 47.918 208.818Z" fill="black"/>
            <path d="M59.8984 208.818V210.584C59.8984 211.234 60.4254 211.757 61.0758 211.757H62.8418C63.4964 211.757 64.0192 211.23 64.0192 210.58V208.818C64.0151 208.163 63.4881 207.641 62.8377 207.641H61.0758C60.4254 207.641 59.8984 208.168 59.8984 208.818Z" fill="black"/>
            <path d="M72 207.641V211.757H69.283C68.51 211.757 67.8834 211.131 67.8834 210.358V207.641H72Z" fill="black"/>
            <path d="M75.9916 207.641H71.875V211.757H75.9916V207.641Z" fill="black"/>
            <path d="M75.8672 211.758V209.041C75.8672 208.268 76.4938 207.641 77.2668 207.641H78.5842C79.3572 207.641 79.9838 208.268 79.9838 209.041V211.758H75.8672Z" fill="black"/>
            <path d="M95.9609 211.758H93.244C92.4709 211.758 91.8443 211.131 91.8443 210.358V209.041C91.8443 208.268 92.4709 207.641 93.244 207.641H95.9609V211.758Z" fill="black"/>
            <path d="M95.8359 211.758V209.041C95.8359 208.268 96.4626 207.641 97.2356 207.641H98.5529C99.3259 207.641 99.9526 208.268 99.9526 209.041V211.758H95.8359Z" fill="black"/>
            <path d="M107.941 207.641V210.358C107.941 211.131 107.315 211.757 106.542 211.757H105.224C104.451 211.757 103.825 211.131 103.825 210.358V207.641H107.941Z" fill="black"/>
            <path d="M115.926 211.758H113.209C112.436 211.758 111.809 211.131 111.809 210.358V209.041C111.809 208.268 112.436 207.641 113.209 207.641H115.926V211.758Z" fill="black"/>
            <path d="M115.801 207.641H119.917V210.358C119.917 211.131 119.291 211.757 118.518 211.757H115.801V207.641Z" fill="black"/>
            <path d="M127.906 211.758H125.189C124.416 211.758 123.79 211.131 123.79 210.358V209.041C123.79 208.268 124.416 207.641 125.189 207.641H127.906V211.758Z" fill="black"/>
            <path d="M127.781 207.641H131.898V210.358C131.898 211.131 131.271 211.757 130.498 211.757H127.781V207.641Z" fill="black"/>
            <path d="M139.887 211.758H137.17C136.397 211.758 135.77 211.131 135.77 210.358V209.041C135.77 208.268 136.397 207.641 137.17 207.641H139.887V211.758Z" fill="black"/>
            <path d="M139.758 211.758V209.041C139.758 208.268 140.384 207.641 141.157 207.641H142.475C143.248 207.641 143.874 208.268 143.874 209.041V211.758H139.758Z" fill="black"/>
            <path d="M155.859 207.641V211.757H153.142C152.369 211.757 151.743 211.131 151.743 210.358V207.641H155.859Z" fill="black"/>
            <path d="M155.734 207.641H159.851V210.358C159.851 211.131 159.224 211.757 158.451 211.757H155.734V207.641Z" fill="black"/>
            <path d="M167.836 207.641V211.757H165.119C164.346 211.757 163.719 211.131 163.719 210.358V207.641H167.836Z" fill="black"/>
            <path d="M167.711 207.641H171.828V210.358C171.828 211.131 171.201 211.757 170.428 211.757H167.711V207.641Z" fill="black"/>
            <path d="M179.816 207.641V211.757H177.099C176.326 211.757 175.7 211.131 175.7 210.358V207.641H179.816Z" fill="black"/>
            <path d="M183.808 207.641H179.691V211.757H183.808V207.641Z" fill="black"/>
            <path d="M183.684 211.758V209.041C183.684 208.268 184.31 207.641 185.083 207.641H186.401C187.174 207.641 187.8 208.268 187.8 209.041V211.758H183.684Z" fill="black"/>
            <path d="M199.656 208.818V210.584C199.656 211.234 200.183 211.757 200.834 211.757H202.6C203.254 211.757 203.777 211.23 203.777 210.58V208.818C203.773 208.163 203.246 207.641 202.596 207.641H200.834C200.183 207.641 199.656 208.168 199.656 208.818Z" fill="black"/>
            <path d="M24.6999 18.8266V9.98936C24.6999 6.06679 24.1663 5.10969 23.9368 4.87967C23.7068 4.65017 22.7497 4.11656 18.8266 4.11656H9.98987C6.06679 4.11656 5.10969 4.65017 4.88019 4.87967C4.65069 5.10969 4.11656 6.06679 4.11656 9.98936V18.8266C4.11656 22.7497 4.65069 23.7068 4.88019 23.9368C5.10969 24.1658 6.06679 24.6999 9.98987 24.6999H18.8266C22.7497 24.6999 23.7068 24.1658 23.9368 23.9368C24.1663 23.7068 24.6999 22.7497 24.6999 18.8266ZM18.8266 0C26.8956 0 28.8165 1.92089 28.8165 9.98936C28.8165 18.0583 28.8165 10.7581 28.8165 18.8266C28.8165 26.8956 26.8956 28.8165 18.8266 28.8165C10.7581 28.8165 18.0583 28.8165 9.98987 28.8165C1.92089 28.8165 0 26.8956 0 18.8266C0 10.7581 0 14.4955 0 9.98936C0 1.92089 1.92089 0 9.98987 0C18.0583 0 10.7581 0 18.8266 0ZM16.3018 8.23328C19.76 8.23328 20.5832 9.05695 20.5832 12.5142C20.5832 15.9725 20.5832 12.844 20.5832 16.3018C20.5832 19.7595 19.76 20.5832 16.3018 20.5832C12.844 20.5832 15.9725 20.5832 12.5147 20.5832C9.05695 20.5832 8.23328 19.7595 8.23328 16.3018C8.23328 12.844 8.23328 14.4455 8.23328 12.5142C8.23328 9.05695 9.05695 8.23328 12.5147 8.23328C15.9725 8.23328 12.844 8.23328 16.3018 8.23328Z" fill="black"/>
            <path d="M206.489 18.8266V9.98936C206.489 6.06679 205.955 5.10969 205.726 4.87967C205.496 4.65017 204.539 4.11656 200.616 4.11656H191.779C187.856 4.11656 186.899 4.65017 186.669 4.87967C186.44 5.10969 185.906 6.06679 185.906 9.98936V18.8266C185.906 22.7497 186.44 23.7068 186.669 23.9368C186.899 24.1658 187.856 24.6999 191.779 24.6999H200.616C204.539 24.6999 205.496 24.1658 205.726 23.9368C205.955 23.7068 206.489 22.7497 206.489 18.8266ZM200.616 0C208.685 0 210.606 1.92089 210.606 9.98936C210.606 18.0583 210.606 10.7581 210.606 18.8266C210.606 26.8956 208.685 28.8165 200.616 28.8165C192.547 28.8165 199.847 28.8165 191.779 28.8165C183.71 28.8165 181.789 26.8956 181.789 18.8266C181.789 10.7581 181.789 14.4955 181.789 9.98936C181.789 1.92089 183.71 0 191.779 0C199.847 0 192.547 0 200.616 0ZM198.091 8.23328C201.549 8.23328 202.372 9.05695 202.372 12.5142C202.372 15.9725 202.372 12.844 202.372 16.3018C202.372 19.7595 201.549 20.5832 198.091 20.5832C194.633 20.5832 197.762 20.5832 194.304 20.5832C190.846 20.5832 190.022 19.7595 190.022 16.3018C190.022 12.844 190.022 14.4455 190.022 12.5142C190.022 9.05695 190.846 8.23328 194.304 8.23328C197.762 8.23328 194.633 8.23328 198.091 8.23328Z" fill="black"/>
            <path d="M24.6999 200.616V191.778C24.6999 187.856 24.1663 186.899 23.9368 186.669C23.7068 186.439 22.7497 185.906 18.8266 185.906H9.98987C6.06679 185.906 5.10969 186.439 4.88019 186.669C4.65069 186.899 4.11656 187.856 4.11656 191.778V200.616C4.11656 204.539 4.65069 205.496 4.88019 205.726C5.10969 205.955 6.06679 206.489 9.98987 206.489H18.8266C22.7497 206.489 23.7068 205.955 23.9368 205.726C24.1663 205.496 24.6999 204.539 24.6999 200.616ZM18.8266 181.789C26.8956 181.789 28.8165 183.71 28.8165 191.778C28.8165 199.847 28.8165 192.547 28.8165 200.616C28.8165 208.685 26.8956 210.606 18.8266 210.606C10.7581 210.606 18.0583 210.606 9.98987 210.606C1.92089 210.606 0 208.685 0 200.616C0 192.547 0 196.285 0 191.778C0 183.71 1.92089 181.789 9.98987 181.789C18.0583 181.789 10.7581 181.789 18.8266 181.789ZM16.3018 190.022C19.76 190.022 20.5832 190.846 20.5832 194.303C20.5832 197.762 20.5832 194.633 20.5832 198.091C20.5832 201.549 19.76 202.372 16.3018 202.372C12.844 202.372 15.9725 202.372 12.5147 202.372C9.05695 202.372 8.23328 201.549 8.23328 198.091C8.23328 194.633 8.23328 196.235 8.23328 194.303C8.23328 190.846 9.05695 190.022 12.5147 190.022C15.9725 190.022 12.844 190.022 16.3018 190.022Z" fill="black"/>
          </svg>
      </div>
    </div>

    <div class="mobile-only">
      <a :href="skifAppLink" target="_blank">
        <skif-button width="full">
          {{ $t('dealerTranslater.confirmDownload') }} SKIF.PRO
        </skif-button>
      </a>
      <skif-button v-if="false" class="mt-6" width="full">
        {{ $t('dealerTranslater.confirmDownload') }} SKIF.PRO MANAGER
      </skif-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    googleSkifLink: '//play.google.com/store/apps/details?id=ru.skifapp.skif',
    iosSkifLink: '//apps.apple.com/ru/app/skif-pro/id1540602214?l=en-GB'
  }),

  computed: {
    skifAppLink() {
      if(this.getMobileOperatingSystem() === 'iOS') {
        return this.iosSkifLink;
      }
      return this.googleSkifLink;
    }
  },

  methods: {
    getMobileOperatingSystem() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    }
  },

  mounted() {
    // занулил demo // fullLogin
    localStorage.setItem('typeLogged', '')
  }
}
</script>

<style lang="scss" scoped>
.admin-panel-btn {
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.mobile-only {
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
  }
}
.confirm-box {
  width: 100%;
  max-width: 550px;
  padding: 25px;
  margin: 0 auto;
  border-radius: 12px;
  box-shadow: 0 4px 16px 0 #00000029;

  @media screen and (max-width: 600px) {
    max-width: 100%;
    box-shadow: unset;
    padding: 5px 5px 25px 5px;
  }

  .warning-msg {
    margin-top: 12px;
    background: #FFE8DB;
    color: #CA5F32;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    border-radius: 12px;
    padding: 10px;
  }

  .columns-2 {
    column-count: 2;
    list-style-type: disc;
    margin-left: 20px;

    @media screen and (max-width: 600px) {
      column-count: 1;
    }

    li {
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      color: #3A7CCA;
      text-decoration: underline;

      @media screen and (max-width: 600px) {
        line-height: 33px;
      }

      &::marker {
        color:  #3A7CCA;
      }
    }
  }

  .header-txt {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 26.4px;
    padding: 10px 0;
    border-radius: 12px;


    @media screen and (max-width: 600px) {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.8px;
    }
  }

  .p-txt {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
  }
}

.qr-box {
  width: 100%;
  max-width: 550px;
  padding: 25px;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    display: none;
  }

  .p-txt-bold {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.8px;
    text-align: center;
    color: #A6AEB8;
  }
}
</style>
