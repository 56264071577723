import moment from 'moment'
export default function defaultValueDate() {
  const endDate = moment()
      .set({ hour: 0, minute: 0, second: 0 })
      .format('YYYY-MM-DD HH:mm:ss'),
    startDate = moment(endDate)
      .subtract(7, 'days')
      .format('YYYY-MM-DD HH:mm:ss')
  return [startDate, endDate]
}
function defaultValueDateEndDay(format = '00:00:00') {
  let hour = 23
  let minute = 59
  let second = 59

  if (format === '00:00:00') {
    hour = 0
    minute = 0
    second = 0
  }

  const endDate = moment()
      .add(1, 'days')
      .set({ hour, minute, second })
      .format('YYYY-MM-DD HH:mm:ss')

  const startDate = moment()
    .set({ hour, minute, second })
    .subtract(7, 'days')
    .format('YYYY-MM-DD HH:mm:ss')

  return [startDate, endDate]
}
export {defaultValueDateEndDay}


/**
 * Метод для копирования строки в буфер обмена
 * @param {string} str - Строка для копирования
 * @returns {void}
 */

export async function copyTextToClipboard(str) {
  try {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);

    const selected = document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
    el.select();

    if (!navigator.clipboard) {
      document.execCommand('copy');
    } else {
      await navigator.clipboard.writeText(str);
    }

    document.body.removeChild(el);

    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  } catch (error) {
    console.error(error);
  }
}



/**
 * Множественное значение
 * @example pluralize(['год','года','лет'], 'Меньше года')(0) => "Меньше года"
 * @example pluralize(['год','года','лет'])(4) => "4 года"
 *
 * @param {array} titles
 * @param {string} empty
 * @returns {Function}
 */
export function pluralize(titles = [], empty = '') {
  if (!(titles && Array.isArray(titles) && titles.length)) {
    return '';
  }

  const cases = [2, 0, 1, 1, 1, 2];
  return count => {
    count = Math.abs(count); // для отрицательных чисел
    if (count === 0 && empty) return empty;
    return titles[
      count % 100 > 4 && count % 100 < 20
        ? 2
        : cases[count % 10 < 5 ? count % 10 : 5]
      ];
  };
}

/**
 * Set cookie
 * @param {string} name - name cookie
 * @param {string} value -value cookie
 * @param {number} days - expired days
 * @param {string} path - path cookie
 * @param {boolean} secure - secure
 * @returns {void}
 * */
export const setCookie = (name, value, days = undefined, path = undefined, secure = undefined) => {
  if (!path) {
    path = '/';
  }
  let security = '';
  if (secure) {
    security = ';secure';
  }
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=' + path + security + '; domain=skif.pro';
};
