import HTTP from '@/api/http'

let getRoutes = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/routes/list', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
// let getFullRoute = function(id,responseHandler, errorHandler){
let getAllRoutes = function (responseHandler, errorHandler) {
  HTTP.get('/routes', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
// }
let getRoute = function (id, responseHandler, errorHandler) {
  HTTP.get('/routes?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

let createRoute = function ({ formData }, responseHandler, errorHandler) {
  return HTTP.post('/routes', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

let updateRoute = function (id, { formData }, responseHandler, errorHandler) {
  HTTP.put('/routes/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

let deleteRoute = function (id, responseHandler, errorHandler) {
  console.log('route delete', id)
  HTTP.delete('/routes', { data: { ids: [id] } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getRoutes,
  getRoute,
  updateRoute,
  deleteRoute,
  createRoute,
  getAllRoutes,
}
