export default {
  data() {
    return {
      isParam12: false,
      paramTitle: 1,
      paramSelected: {},
      sensorUnit: {
        isCreatingUnit: true
      }
    }
  },
  methods: {
    showParam1(param) {
      
      this.paramSelected = JSON.parse(JSON.stringify(param))
      this.paramTitle = 1
      if (this.isCreatingUnit) {
        this.sensorUnit.isCreatingUnit = true
      } else {
        this.sensorUnit.isCreatingUnit = false
      }
      this.isParam12 = true
    },
    showParam2(param) {
      this.paramSelected = JSON.parse(JSON.stringify(param))
      this.paramTitle = 2
      if (this.isCreatingUnit) {
        this.sensorUnit.isCreatingUnit = true
      } else {
        this.sensorUnit.isCreatingUnit = false
      }
      this.isParam12 = true
    },
    saveParam(param) {
      
      if (this.sensorUnit.isCreatingUnit) {
        this.$refs['create-unit'].updateParam(param, this.paramTitle)
      } else {
        this.$refs['update-unit'].updateParam(param, this.paramTitle)
      }
      this.isParam12 = false
    }
  }
}
