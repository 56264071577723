<template>
  <svg
    width="27"
    height="17"
    viewBox="0 0 27 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9618 1.73438H9.86353V14.4887H16.9618V1.73438Z"
      fill="#FF5F00"
    />
    <path
      d="M10.3145 8.11227C10.3145 5.52085 11.5314 3.22237 13.4017 1.73512C12.0271 0.653489 10.292 0 8.39914 0C3.91485 0 0.286865 3.62799 0.286865 8.11227C0.286865 12.5966 3.91485 16.2245 8.39914 16.2245C10.292 16.2245 12.0271 15.5711 13.4017 14.4894C11.5314 13.0247 10.3145 10.7037 10.3145 8.11227Z"
      fill="#EB001B"
    />
    <path
      d="M26.539 8.11227C26.539 12.5966 22.911 16.2245 18.4268 16.2245C16.5339 16.2245 14.7988 15.5711 13.4242 14.4894C15.3171 13.0022 16.5114 10.7037 16.5114 8.11227C16.5114 5.52085 15.2945 3.22237 13.4242 1.73512C14.7988 0.653489 16.5339 0 18.4268 0C22.911 0 26.539 3.65052 26.539 8.11227Z"
      fill="#F79E1B"
    />
  </svg>
</template>

<script>
export default {
  name: 'MastercardIcon'
}
</script>
