import HTTP from '@/api/http'

const getFinance = function (searchString, responseHandler, errorHandler) {
  HTTP.get(`/finance?body={"search":"${searchString}"}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getUnits = function (formData, responseHandler, errorHandler) {
  HTTP.post('/admin_query', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getExtraUnits = function (formData, responseHandler, errorHandler) {
  HTTP.post('/auth_admin_query', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getAdminUnit = function (formData, responseHandler, errorHandler) {
  HTTP.post('/admin_query', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const unitRecovery = function (id, responseHandler, errorHandler) {
  HTTP.put(`/units/recovery/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getTerminalsList = function (data, responseHandler, errorHandler) {
  return HTTP.post('/terminals/list', data, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getTerminals = function (id, responseHandler, errorHandler) {
  return HTTP.get(`/terminals?ids=${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const addTerminals = function (data, responseHandler, errorHandler) {
  HTTP.post('/terminals_bulk', data, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const putTerminals = function (data, responseHandler, errorHandler) {
  return HTTP.put(`/terminals/${data.id}`, data.terminal, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const delTerminals = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/terminals/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const deleteUserFromAdminPanel = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/users/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getRetranslatorslsList = function (data, responseHandler, errorHandler) {
  HTTP.post('/retranslators/list', data, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getRetranslator = function (id, responseHandler, errorHandler) {
  HTTP.get(`/retranslators?ids=${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const addRetranslator = function (data, responseHandler, errorHandler) {
  HTTP.post('/retranslators', data, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const editRetranslator = function (data, responseHandler, errorHandler) {
  HTTP.put(`/retranslators/${data.id}`, data, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const delRetranslator = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/retranslators/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const addCommandTemplate = function (data, responseHandler, errorHandler) {
  HTTP.post('/commands_templates', data, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const editCommandTemplate = function (data, responseHandler, errorHandler) {
  HTTP.put(`/commands_templates/${data.id}`, data, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const deleteCommandTemplate = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/commands_templates/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const dataDeletions = async (form) => {
  const { data } = await HTTP.post('/data_deletions/list', form)
  return data
}

const deleteDataDeletions = async (id) => {
  await HTTP.delete(`/data_deletions/${id}`)
}

const checkImei = async (formData) => {
  const { data } = await HTTP.post('/terminals', formData)

  return data
}
const editTerminal = async (formData) => {
  const { data } = await HTTP.put(`/terminals/${formData.id}`, formData)
  return data
}

export default {
  editTerminal,
  addCommandTemplate,
  editCommandTemplate,
  deleteCommandTemplate,
  getFinance,
  unitRecovery,
  getUnits,
  getExtraUnits,
  getAdminUnit,
  getTerminalsList,
  getTerminals,
  addTerminals,
  putTerminals,
  delTerminals,
  deleteUserFromAdminPanel,
  getRetranslatorslsList,
  getRetranslator,
  addRetranslator,
  editRetranslator,
  delRetranslator,
  dataDeletions,
  deleteDataDeletions,
  checkImei
}
