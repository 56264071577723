import HTTP from '@/api/http'

const getArchiveStates = (id) => {
  return HTTP.get('/units_states/' + id, { needAuth: true })
}
const addNewArchiveState = ({form,id}) => {
  return HTTP.post('/units_states/' + id, form)
}
const copyArchiveState = ({id})=>{
  return HTTP.post('/units_states/' + id + '/copy',)
}
const deleteArchiveState = ({id})=>{
  return HTTP.delete('/units_states/' + id)
}
const editArchiveState = ({form}) => {
  return HTTP.put('/units_states/' + form.id,form)
}
const getUnitsStatesCount = (form) => {
  return HTTP.post('/units_states_count',form)
}

export default {
  getArchiveStates,
  addNewArchiveState,
  copyArchiveState,
  deleteArchiveState,
  editArchiveState,
  getUnitsStatesCount
}
