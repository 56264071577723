import HTTP from '@/api/http'

const registerDealer = function (formData, responseHandler, errorHandler) {
  HTTP.post('/registrate_dealer', formData)
    .then(responseHandler)
    .catch(errorHandler)
}

const createDealer = function (formData, responseHandler, errorHandler) {
  return HTTP.post('/company/create', formData)
    .then(responseHandler)
    .catch(errorHandler)
}

const inviteDealer = function (formData, responseHandler, errorHandler) {
  HTTP.post('/dealers/invite', formData)
    .then(responseHandler)
    .catch(errorHandler)
}

const getInfoDealer = (formData) => {
  return HTTP.get(`/dealer/${formData.inn}`)
}

export default {
  registerDealer,
  inviteDealer,
  createDealer,
  getInfoDealer
}
