import { trailersApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  trailers: [],
  searchedTrailers: [],
  recoveryId: null,
  scrollTop: 0
}
const getters = {
  trailerById: (state) => (id) => {
    return state.trailers.find((trailer) => trailer.id === id)
  },
  allTrailers: (state) => state.trailers,
  searchedTrailers: (state) => state.searchedTrailers,
  trailerScrollTop: (state) => state.scrollTop
}
const actions = {
  async websocketTrailers({ state, dispatch, commit }, webSocket) {
    if (webSocket.operation === 'DELETE') {
      commit('DELETETRAILERSOCKET', webSocket.id)
    } else {
      await trailersApi.getTrailer(
        webSocket.id,
        (response) => {
          let trailer = JSON.parse(JSON.stringify(response.data))
          if (webSocket.operation === 'POST') {
            const isHaveTrailer = state.trailers.some(
              (el) => el.id === trailer.id
            )
            if (!isHaveTrailer) {
              commit('ADD', trailer)
            }
          } else {
            commit('UPDATE', trailer)
          }
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    }
  },
  GET({ commit }) {
    trailersApi.getTrailers(
      (response) => {
        commit('SET', response.data.list)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  RECOVER_TRAILERS({ commit }, data) {
    return new Promise((resolve, reject) => {
      trailersApi.recover(
        data.id,
        (response) => {
          commit('RECOVER', data)
          resolve()
        },
        (error) => {
          console.log(error)
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  SEARCH_TRAILER({ commit }) {
    trailersApi.searchUnitTrailer(
      (response) => {
        commit('SET', response.data.list)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  CREATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      trailersApi.createTrailer(
        formData,
        (response) => {
          commit('ADD_NEW_TRAILER', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      trailersApi.updateTrailer(
        formData.id,
        formData,
        (response) => {
          commit('UPDATE', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE({ commit }, trailerIds) {
    let promiseList = []
    promiseList.push(
      new Promise((resolve, reject) =>
        trailersApi.deleteTrailer(trailerIds, resolve, reject)
      )
    )
    Promise.all(promiseList)
      .then((res) => {
        commit('DELETE', trailerIds)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch((error) => {
        console.log(error)
        eventBus.$showError(error.response.data.message)
      })
  }
}

const mutations = {
  SET(state, trailers) {
    const indexedItems = trailers.map((item, index) => ({
      index,
      ...item
    }))
    state.trailers = indexedItems
  },
  ADD(state, trailer) {
    if (!state.trailers.some((x) => x.id === trailer.id)) {
      state.trailers = [...state.trailers, trailer]
    }
  },
  ADD_NEW_TRAILER(state, trailer) {
    state.trailers.push(trailer)
  },
  UPDATE(state, trailer) {
    const indexOfTrailer = state.trailers.findIndex(
      (elem) => elem.id === trailer.id
    )
    state.trailers = [
      ...state.trailers.slice(0, indexOfTrailer),
      trailer,
      ...state.trailers.slice(indexOfTrailer + 1)
    ]
  },
  DELETE(state, ids) {
    state.trailers = state.trailers.filter((item) => !ids.includes(item.id))
  },
  DELETETRAILERSOCKET(state, id) {
    state.trailers = state.trailers.filter((item) => item.id !== id)
  },
  SET_RECOVERYID(state, id) {
    state.recoveryId = id
  },
  RECOVER(state, units) {
    units.type === 'trailers'
      ? units.itemsDeleted.forEach((unit) =>
          state.trailers.splice(unit.index, 0, unit)
        )
      : console.log('notTrailers')
  },
  SET_TRAILERS_SEARCHED(state, trailers) {
    state.searchedTrailers = trailers
  },
  SET_SCROLL_TOP(state, value) {
    state.scrollTop = value
  }
}
export default {
  namespaced: true,
  state: state,
  getters,
  actions,
  mutations
}
