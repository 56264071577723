import HTTP from '@/api/http'

const setDimensions = (formData, responseHandler, errorHandler) => {
  HTTP.put('/me', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getGeozones = function (query, responseHandler, errorHandler) {
  HTTP.get('/geozones', query, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getGeozonesOnMap = function (responseHandler, errorHandler) {
  HTTP.get('/geozones', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getGeozone = function (id, responseHandler, errorHandler) {
  HTTP.get('/geozones?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createGeozone = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/geozones', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateGeozone = function (
  id,
  { formData },
  responseHandler,
  errorHandler
) {
  HTTP.put('/geozones/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteGeozone = function (data, responseHandler, errorHandler) {
  let ids = []
  data.forEach((geo) => ids.push(geo.id))
  HTTP.delete('/geozones', { data: { ids: ids } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const recover = function (data, responseHandler, errorHandler) {
  HTTP.put('/cancel_delete/' + data.id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getGeozones,
  getGeozone,
  updateGeozone,
  deleteGeozone,
  createGeozone,
  getGeozonesOnMap,
  recover,
  setDimensions
}
