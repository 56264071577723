<template lang="pug">
.relative
  .absolute.flex.items-center.cursor-pointer(
    style='left: 8px; top: 8px;',
    @click='$emit("searching", model)'
  )
    
    svg(width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg")
        path(fill-rule="evenodd" clip-rule="evenodd" d="M14.6286 8.31429C14.6286 7.06544 14.2582 5.84464 13.5644 4.80626C12.8706 3.76788 11.8844 2.95856 10.7307 2.48065C9.57687 2.00274 8.30728 1.87769 7.08243 2.12133C5.85758 2.36497 4.73248 2.96635 3.84941 3.84941C2.96635 4.73248 2.36497 5.85758 2.12133 7.08243C1.87769 8.30728 2.00274 9.57687 2.48065 10.7307C2.95856 11.8844 3.76788 12.8706 4.80626 13.5644C5.84464 14.2582 7.06544 14.6286 8.31429 14.6286C9.82069 14.6285 11.2777 14.0912 12.4234 13.1131L12.6857 13.3754V14.1429L16.8357 18.2929C17.2263 18.6834 17.8594 18.6834 18.25 18.2929L18.2929 18.25C18.6834 17.8594 18.6834 17.2263 18.2929 16.8358L14.1429 12.6857H13.3754L13.1131 12.4234C14.0912 11.2777 14.6285 9.82069 14.6286 8.31429ZM3.94205 8.31429C3.94205 5.88572 5.88491 3.94286 8.31348 3.94286C10.742 3.94286 12.6849 5.88572 12.6849 8.31429C12.6849 10.7429 10.742 12.6857 8.31348 12.6857C5.88491 12.6857 3.94205 10.7429 3.94205 8.31429Z" fill="#292929")
    

  input.rounded.focus_outline-none.pl-4.pr-8.w-full.text-sm.font-medium.border.border-search(
    type='text'
    
    v-model='model'
    @keyup.enter="onEnter"
    :placeholder="placeholder "
    @input='$emit("searching", model)'
  )
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      model: ''
    }
  },
  methods: {
    onEnter() {
      this.$emit('searching', this.model)
    }
  }
}
</script>
<style lang="scss">
.border-search {
  border: none;
  border-radius: 10px;
  background: #edeff3;
  height: 35px;
  padding-left: 36px;
  // placeholder
  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #a0a0a0;
  }
}
</style>
