<template>
  <div class="link_container">
    <div class="icon_container mb-2">
      <svg
        width="53"
        height="52"
        viewBox="0 0 53 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" width="52" height="52" rx="26" fill="#FFE8DB" />
        <path
          d="M26.5 16C20.9772 16 16.5 20.4772 16.5 26C16.5 31.5228 20.9772 36 26.5 36C32.0228 36 36.5 31.5228 36.5 26C36.5 20.4772 32.0228 16 26.5 16Z"
          stroke="#EA7644"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.5 22L26.5 26"
          stroke="#EA7644"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.5 30L26.49 30"
          stroke="#EA7644"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <h1 class="mb-2">Ссылка недействительна</h1>
    <p>
      Если вы видите данное сообщение, это значит, что ссылка-приглашение более
      недействительна.
    </p>
    <div class="footer flex">
      <div class="info-icon">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
            stroke="#2767B6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11 15V11"
            stroke="#2767B6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11 7H11.01"
            stroke="#2767B6"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <span
        >Вам нужно запросить новую ссылку-приглашение (по данной ссылке можно
        перейти только один раз).</span
      >
    </div>
    <!-- push router to previous page -->
    <button
      v-if="!isMobile"
      @click="$router.push('/login')"
      class="back-btn footer"
    >
      На главную
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isMobile: false
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 735
    }
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
<style lang="scss" scoped>
.footer {
  width: 327px;
  align-items: center;
  justify-content: center;
  background-color: #dbebff;
  padding: 10px;
  border-radius: 12px;
  margin-top: 12px;
  text-align: center;
  .info-icon {
    margin-left: 2px;
    margin-right: 4px;
    margin-bottom: 2px;
  }
  span {
    color: #20579a;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}
.link_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  font-family: Source Sans Pro;
}

h1 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: #292929;
}
p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 248px;
  text-align: center;
}
button {
  color: white;
  background: #3a7cca !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}
</style>
