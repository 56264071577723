import HTTP from '@/api/http'

const getTransactions = (form) => {
  return HTTP.post('/bill_transactions_admin', form)
}

const billTransactions = (form) => {
  return HTTP.put('/bill_transaction', form)
}
export default {
  getTransactions,
  billTransactions
}
