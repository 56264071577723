<template>
  <div class="container">
    <div class="branding">
      <LogoIcon />
    </div>

    <div class="container_section">
      <div class="form_title">Партнёр SKIF.PRO</div>
    </div>

    <div class="container_section container_section__company">
      <div class="company_title">
        <div id="big_company_name">{{ info.name }}</div>
        <DoneIcon />
      </div>
    </div>

    <!--        Основной список        -->
    <div class="container_section main_list_section" style="margin-top: 28px">
      <div class="container_section_title">Тип организации:</div>
      <div class="container_section_value" id="company_type">
        {{ info.type.value }}
      </div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Инн:</div>
      <div class="container_section_value" id="company_inn">{{ info.inn }}</div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Адрес:</div>
      <div class="container_section_value" id="company_adress">
        {{ info.address }}
      </div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Услуги:</div>
      <div class="container_section_value" id="company_service">
        {{ info.services }}
      </div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Описание тарифа:</div>
      <div class="container_section_value" id="tarif_desc">
        {{ info.tariff_description }}
      </div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Оферта:</div>
      <div class="container_section_value">
        <a
          :href="info.public_offer_link"
          target="_blank"
          rel="noopener noreferrer"
          id="oferta_link"
          >Загрузить оферту</a
        >
        <DownloadIcon />
      </div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Контактное лицо:</div>
      <div class="container_section_value" id="contact">
        {{ info.contact_person }}
      </div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Email:</div>
      <div class="container_section_value" id="contact_mail">
        {{ info.email }}
      </div>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section main_list_section">
      <div class="container_section_title">Телефон:</div>
      <div class="container_section_value" id="contanct_number">
        {{ info.phone }}
      </div>
      <div class="list_delimiter"></div>
    </div>
    <!--      Конец основной списока        -->

    <div v-if="info.system && info.system.key" class="container_section bank">
      <LogoBank v-if="info.system.key === 'MODULBANK'" />

      <a
        v-if="info.system.key === 'MODULBANK'"
        class="bank_link"
        href="https://modulbank.ru/"
        target="_blank"
        rel="noopener noreferrer"
      >
        modulbank.ru
        <ArrowRightIcon />
      </a>
      <a
        v-else
        class="bank_link"
        href="https://paybox.money/"
        target="_blank"
        rel="noopener noreferrer"
      >
        paybox.money
        <ArrowRightIcon />
      </a>
      <div class="list_delimiter"></div>
    </div>

    <div class="container_section pay_system">
      <VisaIcon />
      <MastercardIcon class="mir-icon" />
      <MIRIcon class="mastercard-icon" />
    </div>
  </div>
</template>

<script>
import { dealersApi } from '@/api'
import LogoIcon from '@/components/partners/assets/LogoIcon.vue'
import DoneIcon from '@/components/partners/assets/DoneIcon.vue'
import DownloadIcon from '@/components/partners/assets/DownloadIcon.vue'
import LogoBank from '@/components/partners/assets/LogoBank.vue'
import ArrowRightIcon from '@/components/partners/assets/ArrowRightIcon.vue'
import VisaIcon from '@/components/partners/assets/VisaIcon.vue'
import MastercardIcon from '@/components/partners/assets/MastercardIcon.vue'
import MIRIcon from '@/components/partners/assets/MIRIcon.vue'

export default {
  name: 'PartnersView',
  components: {
    LogoIcon,
    DoneIcon,
    DownloadIcon,
    LogoBank,
    ArrowRightIcon,
    VisaIcon,
    MastercardIcon,
    MIRIcon
  },

  data() {
    return {
      info: {
        contact_person: '',
        email: '',
        name: '',
        phone: '',
        inn: '',
        address: '',
        public_offer_link: '',
        tariff_description: '',
        services: '',
        system: {
          key: ''
        },
        type: {
          value: ''
        }
      }
    }
  },

  async mounted() {
    const inn = this.$route.fullPath.split('?')[1]
    const { data } = await dealersApi.getInfoDealer({ inn })
    this.info = data
  }
}
</script>

<style lang="stylus" scoped>
body {
    background: #ffffff;
    font-family: sans-serif, 'Source Sans Pro';
    color: #292929;
  }


  a {
    color: #3A7CCA;
    text-decoration: none;
  }



  .container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 360px;
    left: 50%;
    top: 40px;
    transform: translate(-50%,0%);
    overflow: hidden;
    border-radius: 20px;
    background: #F6F7FA;
    margin-bottom: 100px;
  }
  @media all and (max-width: 800px){

    .container {
      top: 16px;
    }

  }

		.container_section {
			position: relative;
			padding: 0px 20px 0px 20px;
			min-height: 28px;
		}

    .container_section__company {
      padding-top: 26px;
    }


		.main_list_section {
			margin-top: 16px;
			padding-bottom: 8px;
		}


		.form_title {
			height: 64px;
			padding-top: 36px;
			text-align: center;
			font-size: 32px;
			font-weight: 700;
			line-height: 120%;
			color: #292929;
		}


		.company_title {
			display: flex;
			align-items: center;
			min-height: 58px;
			background: #ffffff;
			border-radius: 12px;
			box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
			padding: 0px 16px 0px 16px;
		}

		.company_title > div {
			width: calc(100% - 28px);
			font-size: 16px;
		}


		.list_delimiter {
			position: absolute;
			width: calc(100% - 40px);
			left: 50%;
			bottom: 0px;
			transform: translate(-50%,0%);
			height: 1px;
			background: #DCE1E7;
		}


		.container_section_title {
			position: relative;
			float: left;
			display: inline-block;
			width: 147px;
			height: 100%;
			font-size: 16px;
			font-weight: 600;
			line-height: 120%;
		}


		.container_section_value {
			position: relative;
			float: left;
			width: calc(100% - 147px);
			text-align: right;
			width: 172px;
			font-size: 14px;
			font-weight: 400;
			line-height: 120%;
		}


		.bank {
			position: relative;
			height: 58px;
		}


		.bank_icon {
			position: absolute;
			top: 50%;
			left: 20px;
			transform: translate(0%,-50%);
		}


		.bank_link {
			position: absolute;
			display: inlien-block;
			top: 50%;
			right: 20px;
			transform: translate(0%,-50%);
			font-size: 12px;
		}


		.pay_system {
			display: flex;
			height: 54px;
			justify-content: center;
			align-items: center;
		}

.mir-icon, .mastercard-icon {
  margin-left 20px
}
</style>
