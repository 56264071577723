<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.9987 53.3327H9.9987V13.3327H69.9987M69.9987 6.66602H9.9987C6.2987 6.66602 3.33203 9.63268 3.33203 13.3327V53.3327C3.33203 55.1008 4.03441 56.7965 5.28465 58.0467C6.5349 59.297 8.23059 59.9993 9.9987 59.9993H33.332V66.666H26.6654V73.3327H53.332V66.666H46.6654V59.9993H69.9987C71.7668 59.9993 73.4625 59.297 74.7127 58.0467C75.963 56.7965 76.6654 55.1008 76.6654 53.3327V13.3327C76.6654 9.63268 73.6654 6.66602 69.9987 6.66602Z"
      fill="#292929"
    />
  </svg>
</template>
<script>
export default {
  name: 'MoitorIcon'
}
</script>
