<template>
  <div class="wrapper">
    <p v-if="$route.fullPath === '/lk-diler'" class="title">
      {{ $t('lkDiler.title') }}
    </p>
    <div v-if="$route.fullPath === '/lk-diler'" class="links">
      <div class="link">
        <img
          src="@/components/map/tools/help-tools/images/lk-diler/createCompany.svg"
          alt=""
        />
        <router-link
          class="router-link"
          :to="{ path: '/lk-diler/create-company' }"
        >
          {{ $t('lkDiler.create_company') }}
        </router-link>
      </div>
      <div class="link">
        <img
          src="@/components/map/tools/help-tools/images/lk-diler/changeTarif.svg"
          alt=""
        />
        <router-link
          class="router-link"
          :to="{ path: '/lk-diler/change-tarif' }"
        >
          {{ $t('lkDiler.change_tariff') }}
        </router-link>
      </div>
      <div class="link">
        <img
          src="@/components/map/tools/help-tools/images/lk-diler/startTerminal.svg"
          alt=""
        />
        <router-link
          class="router-link"
          :to="{ path: '/lk-diler/start-terminal' }"
        >
          {{ $t('lkDiler.create_terminal') }}
        </router-link>
      </div>
      <div class="link">
        <img
          src="@/components/map/tools/help-tools/images/lk-diler/templateCommand.svg"
          alt=""
        />
        <router-link
          class="router-link"
          :to="{ path: '/lk-diler/template-command' }"
        >
          {{ $t('lkDiler.template_command') }}
        </router-link>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'lkDiler'
}
</script>

<style lang="stylus" scoped>
.link
  display flex
  align-items center
.title
  margin-bottom 26px
  color #333333
  font-size 12px
  font-weight 600
.router-link
  font-size 16px
  font-weight 600
  line-height 40px
  color #5477A9
</style>
