<template>
  <svg
    width="61"
    height="77"
    viewBox="0 0 61 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_13309_10749)">
      <g filter="url(#filter1_d_13309_10749)">
        <path
          d="M30.1763 12.4117C30.5153 11.8628 31.2314 11.8628 31.5703 12.4117L42.4659 30.059C42.8614 30.6996 42.4578 31.5804 41.7689 31.5804L19.9778 31.5804C19.2888 31.5804 18.8853 30.6996 19.2808 30.059L30.1763 12.4117Z"
          fill="#5C9DEB"
        />
        <circle
          cx="30.7889"
          cy="44.039"
          r="14.2111"
          stroke="white"
          stroke-width="8"
        />
      </g>
      <circle
        cx="30.7888"
        cy="44.039"
        r="11.5954"
        transform="rotate(-180 30.7888 44.039)"
        fill="#EB7F35"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_13309_10749"
        x="0.577759"
        y="0"
        width="60.4222"
        height="74.2501"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13309_10749"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_13309_10749"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_13309_10749"
        x="0.577759"
        y="2"
        width="60.4222"
        height="74.2501"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_13309_10749"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_13309_10749"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TargetIcon'
}
</script>
