import HTTP from '@/api/http'


const getAllActions = async () => {
    const { data } = await HTTP.get('/actions')

    return data
}

const saveActions = async (info) => {
    const { data } = await HTTP.put(`units_groups_access/${info.id}`, info.form)
    return data
}

const getAllAction = function (responseHandler, errorHandler) {
    return HTTP.get(`/actions`, { needAuth: true })
        .then(responseHandler)
        .catch(errorHandler)
}

const getAllRoles = function (searchString, responseHandler, errorHandler) {
    return HTTP.get(`/roles`, { needAuth: true })
        .then(responseHandler)
        .catch(errorHandler)
}

const createNewRole = async (formData) => {
    const { data } = await HTTP.post('/roles', {...formData})
        return data
}

const deleteRole = async (roleId, replaceRoleId) => {
    const { data } = await HTTP.delete(`/roles/${roleId}`, {
        data: {
            replace_role: {
                id: replaceRoleId
            }
        }
    })

    return data
}

const addChangeSettings = async (form) => {
    const { data } = await HTTP.put(`/roles/${form.id}`, form)
    return data
}

const getRoleItems = function (ids, responseHandler, errorHandler) {
    HTTP.get(`/roles?ids=${ids}`, { needAuth: true })
        .then(responseHandler)
        .catch(errorHandler)
}

const getGroupsByObject = async (id) => {
    const { data } = await HTTP.get(`/units_groups_access?units_group_id=${id}`)
    return data
}

const putGroupsByObject = async (id) => {
    const { data } = await HTTP.put(`/units_groups_access/${id}` )
    return data
}
const getUsersInfoById = async (id) => {
    const { data } = await HTTP.get(`users?ids=${id}`)
    return data
}

const changeUserRole = async (id, role) => {
    const { data } = await HTTP.put(`users/${id}`, {
        role
    })
    return data
}

const deleteUnit = async (id)=>{
   const {data} = HTTP.delete(`/units/${id}`)
        return data
}


export default {
    getAllActions,
    getAllRoles,
    createNewRole,
    deleteRole,
    getRoleItems,
    addChangeSettings,
    putGroupsByObject,
    getGroupsByObject,
    saveActions,
    getUsersInfoById,
    changeUserRole,
    deleteUnit,
}







