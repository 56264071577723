import { paymentsApi } from '@/api'

const actions = {
  getTransactions(_, form) {
    return paymentsApi.getTransactions(form)
  },
  billTransaction(_, form) {
    return paymentsApi.billTransactions(form)
  }
}

export default {
  namespaced: true,
  actions
}
