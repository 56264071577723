import moment from 'moment'

const getStatus = (speed, date, currentTime, datepoint, ignition) => {
  const minutesDiff = moment(currentTime).diff(moment(date), 'minutes')
  let status = ''
  if (minutesDiff <= 15) {
    if (speed > 0) {
      status = 10 // меньше 15 минут и скорость больше 0
    } else if (ignition && ignition.value === 'Вкл' && speed <= 0) {
      status = 9 // меньше 15 минут и скорость меньше 0
    } else {
      status = 8
    }
  } else if (minutesDiff > 15 && minutesDiff < 60) {
    if (speed > 0) {
      status = 7 // больше или равно 15 минут и меьше 60 минут и скорость больше 0
    } else if (ignition && ignition.value === 'Вкл' && speed <= 0) {
      status = 6 // больше или равно 15 минут и меьше 60 минут и скорость меньше 0
    } else {
      status = 5
    }
  } else {
    if (speed > 0) {
      status = 4 // больше 60 минут и скорость больше 0
    }
    if (ignition && ignition.value === 'Вкл' && speed <= 0) {
      status = 3 // больше 60 минут и скорость меньше 0
    } else {
      status = 2
    }
  }
  if (!datepoint) {
    status = 1
  }
  return status
}

export default getStatus
