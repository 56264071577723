<template lang="pug">
label.flex.justify-between(:class="[`w-${width}`, { 'flex-row-reverse': reverse }]")
  span.cursor-pointer.font-SourceSansPro.text-sm.font-semibold.transition-colors.duration-300(
    v-if='$slots.default',
    :class='model === label ? "text-headerText" : "blue-text"'
  ) 
    slot
  span.inline-block.align-middle.cursor-pointer.relative.skif-radio-outer(
    :class='model === label && "is-checked"'
  )
    span.inline-block.rounded-full.skif-radio.bg-white.transition-colors.duration-300(
      :class='model === label ? "border-headerText" : "border-annotationColor"'
    )
    input.hidden(
      type='radio',
      v-model='model',
      :name='name',
      :value='label',
      @change='$emit("change", $event)'
    )
</template>

<script>
export default {
  props: {
    name: String,
    value: {},
    label: String,
    width: {
      type: String,
      default: 'full'
    },
    reverse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.skif-radio-outer {
  .skif-radio {
    border-width: 2px;
    width: 15px;
    height: 15px;
    position relative

    &:after {
      content: '';
      background-color: #ffffff;
      transition: background-color 0.3s;
      border-radius: 9999px;
      transform: translate(-50%, -50%);
      width: 7.5px;
      height: 7.5px;
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }

  &.is-checked {
    .skif-radio:after {
      background-color: #3C5E8F;
    }
  }
}
</style>
