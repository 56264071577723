<template>
  <div class="list-shorts">
    <p class="list-shorts__title">Регистрация по ссылке-приглашению</p>
    <div class="steps" v-for="(step, index) in shortsList" :key="index">
      <p class="list-shorts__step">{{ step.step }}</p>
      <p class="list-shorts__action">{{ step.action }}</p>
      <img
        class="list-shorts__img"
        alt=""
        :src="
          require(`@/components/map/tools/help-tools/images/register/register-link/${step.img}.png`)
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChangeTarif',
  data() {
    return {
      shortsList: [
        {
          step: '1',
          action: this.$t('lkDiler.register_link.step_1'),
          img: 'step1'
        },
        {
          step: '2',
          action: this.$t('lkDiler.register_link.step_2'),
          img: 'step2'
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-shorts
  &__title
    margin-bottom 20px
    font-size 12px
    font-weight 600
    line-height 15px
    color #677785

  &__step
    font-size 14px
    font-weight 600
    line-height 40px
    color #3C5E8F

  &__action
    font-size 13px
    line-height 20px
    font-weight 600
    color #5477A9
    margin-bottom 20px

  &__img
    margin-bottom 20px
</style>
