<template lang="pug">
.mx-auto.shadow.p-6.flex.flex-col.recovery-password
  span.col-start-1.col-end-2.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ isShowCurrentPassword ? $t("users.change_password_title") : $t("loginPass.recovery.title") }}
  .flex.items-center.mt-3(v-if='isShowCurrentPassword')
    span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mr-2 {{ $t("loginPass.changePass.current") }}
    el-tooltip(
      :open-delay='700',
      effect='dark',
      :content='$t("loginPass.changePass.adminPass")',
      placement='bottom',
      :offset='0',
      :visible-arrow='false'
    )
      svg(
        width='15',
        height='15',
        fill='none',
        xmlns='http://www.w3.org/2000/svg'
      )
        path(
          fill-rule='evenodd',
          clip-rule='evenodd',
          d='M7.5 13.636c3.577 0 6.136-2.559 6.136-6.136S11.077 1.364 7.5 1.364 1.364 3.923 1.364 7.5s2.559 6.136 6.136 6.136zM7.5 15a7.5 7.5 0 110-15 7.5 7.5 0 010 15zM4.964 5.63c.05-1.264.913-2.22 2.527-2.22 1.47 0 2.46.873 2.46 2.064 0 .863-.434 1.47-1.169 1.91-.718.422-.924.717-.924 1.29v.34H6.706L6.7 8.569c-.027-.785.312-1.286 1.075-1.742.673-.407.912-.73.912-1.303 0-.629-.495-1.09-1.257-1.09-.769 0-1.264.461-1.314 1.196H4.964zm2.326 5.96a.715.715 0 01-.729-.717c0-.406.323-.718.73-.718.417 0 .734.312.734.718a.713.713 0 01-.735.718z',
          fill='#2F80ED'
        )
  .relative.mb-2(v-if='isShowCurrentPassword')
    el-input(
      type='password',
      show-password,
      v-model='changePassword.currentPassowrd',
      autocomplete='off',
      :class='{ "input-error": isPasswordError }'
    )
    transition(name='el-zoom-in-top')
      .mt-1.text-notify.text-xs.top-full.left-0.absolute(
        v-if='isPasswordError'
      ) {{ passwordErrorMessage }}
  span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{ isShowCurrentPassword ? $t("loginPass.changePass.newPass") : $t("loginPass.changePass.inputNewPass")}}
  .relative.mb-2
    el-input(
      type='password',
      show-password,
      v-model='changePassword.pass',
      autocomplete='off',
      :class='{ "input-error": isPasswordOne }'
    )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(
        v-if='isPasswordOne && !isPasswordError'
      ) {{ $t("loginPass.errors.pass") }}
    transition(name='el-zoom-in-top')
      .mt-1.text-notify.text-xs(v-if='isNewPasswordError') {{ passwordErrorMessage }}
  span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{ $t("loginPass.changePass.confirmNewPass") }}
  .relative
    el-input(
      type='password',
      show-password,
      v-model='changePassword.checkPass',
      autocomplete='off',
      :class='{ "input-error": isPasswordTwo }'
    )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='isPasswordTwo') {{ $t("loginPass.errors.notMatchPass") }}
  skif-button.mt-6(@click='changePass(changePassword)') {{ $t("users.change_password") }}
</template>
<script>
import { loginApi } from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'App',
  props: ['state', 'logedIn'],
  data() {
    return {
      passwordErrorMessage: '',
      isPasswordError: false,
      isNewPasswordError: false,
      changePassword: {
        currentPassowrd: '',
        pass: '',
        checkPass: ''
      },
      isPasswordOne: false,
      isPasswordTwo: false,
      isShowCurrentPassword: false
    }
  },
  computed: {
    ...mapState('login', {
      me: (state) => state.me
    })
  },
  watch: {
    'changePassword.pass': function (val) {
      this.isPasswordOne = val === ''
    },
    'changePassword.checkPass': function (val) {
      this.isPasswordTwo = val === ''
    }
  },
  methods: {
    valid() {
      let isError = true

      if (!this.changePassword.pass) {
        this.isPasswordOne = !this.changePassword.pass
        isError = false
      }

      if (
        !this.changePassword.checkPass ||
        this.changePassword.checkPass !== this.changePassword.pass
      ) {
        this.isPasswordTwo =
          !this.changePassword.checkPass ||
          this.changePassword.checkPass !== this.changePassword.pass
        isError = false
      }

      return isError
    },
    changePass() {
      if (this.valid()) {
        const formData = {}
        formData.newPassword = this.changePassword.pass

        if (this.changePassword.currentPassowrd) {
          formData.password = this.changePassword.currentPassowrd
        }

        loginApi.changeMyPassword(
          formData,
          () => this.$router.push('/'),
          (error) => {
            this.passwordErrorMessage = error.response.data.message

            if (error.response.data.field === 'password') {
              this.isPasswordError = true
              this.isNewPasswordError = false
            } else if (error.response.data.field === 'newPassword') {
              this.isPasswordError = false
              this.isNewPasswordError = true
            }
          }
        )
      }
    }
  },
  mounted() {
    this.isShowCurrentPassword = !!this.me.need_change_password
  }
}
</script>

<style lang="stylus" scoped>
.recovery-password
  width 428px
  @media(min-width: 320px) and (max-width: 420px)
    width 100%
</style>
