import { getws, initws, closews } from '@/api/ws.js'
import 'leaflet'
import 'leaflet.markercluster'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  data() {
    return {
      ws: null,
      markerClusterNotify: null,
      versionNotifySent: false,
      wsInterval: null
    }
  },
  computed: {
    ...mapGetters('login', ['isOnline', 'getIsUnauthorized']),
    ...mapState('monitoring', {})
  },
  watch: {
    isOnline(val) {
      if (val) {
        this.startWebSocket()
      }
    }
  },
  methods: {
    ...mapActions({
      websocketCommand: 'terminal/websocketCommand',
      websocketTerminals: 'terminal/websocketTerminals',
      websocketEvents: 'events/websocketEvents',
      websocketTrailers: 'trailers/websocketTrailers',
      websocketLocators: 'locators/websocketLocators',
      getMe: 'login/getMe'
    }),
    ...mapMutations({
      setWebsocketMonitoringDatepoint: 'monitoring/setWebsocketMonitoringDatepoint',
      setMonitoringList: 'monitoringNew/setMonitoringList',
      SET_FILTRED_UNITS: 'monitoringNew/SET_FILTRED_UNITS',
      setVuexTerminalsMonitoring: 'monitoringNew/setVuexTerminalsMonitoring',
      setVuextNews: 'properties/setNews',
      setVuexUpdateAppVersion: 'notifications/setVuexUpdateAppVersion'
    }),

    saveNewLastpoint(point) {
      if (!point.unit_id || !point.columns) {
        return
      }
      const marker = point.columns.find(item => item.key === '_marker_latlon')
      if (!marker || !marker.value) {
        return
      }
      this.$store.commit('events/SET_LAST_POINT', {
        coords: marker.value,
        unitId: point.unit_id
      })
    },
    startWebSocket() {
      if (this.markerClusterNotify) {
        this.notificationMarkers = {}
        this.markerClusterNotify.clearLayers()
      } else {
        /* eslint-disable-next-line */
        this.markerClusterNotify = L.markerClusterGroup()
        this.map.addLayer(this.markerClusterNotify)
      }

      if (this.ws) {
        this.ws.onclose = () => {}
        this.ws.close()
        this.ws = null
      }

      initws()

      this.ws = getws()

      this.ws.onopen = () => {
        console.log('Websocket connection opened')
      }

      this.ws.onmessage = event => {
        const websocketData = JSON.parse(event.data)
        if (websocketData.length > 0 && Array.isArray(websocketData[0].columns)) {
          this.setWebsocketMonitoringDatepoint(websocketData)
        }

        if (websocketData.length && websocketData.length > 0 && websocketData[0].templateId) {
          this.$emit('new-notification', websocketData)

          if (this.setNotificationMarker) {
            websocketData.forEach(item => this.setNotificationMarker(item.id, item))
          }
        } else if (websocketData.length && websocketData[0].columns && websocketData.length >= 1) {
          // this.$emit('new-lastpoint-array', websocketData)
          websocketData.forEach(item => this.saveNewLastpoint(item))
          this.setMonitoringList(websocketData)
        } else if (websocketData.length && websocketData[0].columns && websocketData.length === 1) {
          this.$emit('new-lastpoint', websocketData)
          this.saveNewLastpoint(websocketData)
        } else if (websocketData.monitoring_units) {
          // this.SET_FILTRED_UNITS(websocketData.monitoring_units)
          // непонятно зачем этот запрос который выполняется несколько раз если открыть мониторинг
          this.$store.dispatch('monitoringNew/SET_FILTRED_UNITS', websocketData.monitoring_units)
        } else if (websocketData.notify) {
          const { objects, operation, id, hidden } = websocketData.notify
          const userId = websocketData.notify.user_id

          switch (websocketData.notify.objects) {
            case 'users':
              this.$store.dispatch('users/WEBSOCKET_CHANGE', {
                id,
                operation
              })
              break
            case 'units':
              this.$store.dispatch('units/WEBSOCKET_UNITS_CHANGE', {
                id,
                operation
              })

              break
            case 'races':
              this.$store.dispatch('races/WEBSOCKET_CHANGE', {
                id,
                operation
              })
              break
            case 'routes':
              this.$store.dispatch('routes/WEBSOCKET_CHANGE', {
                id,
                operation
              })
              break
            case 'commands':
              this.websocketCommand(websocketData.notify)
              break
            case 'terminals':
              this.websocketTerminals(websocketData.notify)
              break
            case 'events':
              this.websocketEvents(websocketData.notify)
              break
            case 'geozones':
              this.$store.dispatch('geozones/WEBSOCKET_CHANGE', {
                id,
                operation
              })
              break
            case 'trailers':
              this.websocketTrailers(websocketData.notify)
              break
            case 'locators':
              this.websocketLocators(websocketData.notify)
              break
          }

          if (this.userId !== userId) {
            if (!hidden) {
              this.$showChange(websocketData.notify.msg)
            }
            switch (objects) {
              case 'users':
                this.$store.dispatch('users/WEBSOCKET_CHANGE', {
                  id,
                  operation
                })
                break
              case 'company':
                this.$store.dispatch('login/getMe')
                break
              case 'bill_info':
                this.$store.dispatch('login/getMe')
                break
              case 'units':
                this.$store.dispatch('units/WEBSOCKET_UNITS_CHANGE', {
                  id,
                  operation
                })
                break
              case 'notifications':
                this.$store.dispatch('notifications/WEBSOCKET_CHANGE', {
                  id,
                  operation
                })
                break
              case 'unitsgroup':
                this.$store.dispatch('units/WEBSOCKET_UNITSGROUP_CHANGE', {
                  id,
                  operation
                })
                break
              case 'geozones':
                this.$store.dispatch('geozones/WEBSOCKET_CHANGE', {
                  id,
                  operation
                })
                break
              case 'report_templates':
                this.$store.dispatch('reports/WEBSOCKET_CHANGE', {
                  id,
                  operation
                })
                break
              case 'mailings':
                this.$store.dispatch('mailings/WEBSOCKET_CHANGE', {
                  id,
                  operation
                })
                break
              // выходит ошибка в консоли, чтобы ее не было
              case 'terminals':
                break
              case 'commands':
                break
              case 'trips':
                this.$store.dispatch('races/WEBSOCKET_CHANGE', {
                  id,
                  operation
                })
                break
              case 'routes':
                this.$store.dispatch('routes/WEBSOCKET_CHANGE', {
                  id,
                  operation
                })
                break
              default:
                console.error('Notify objects has not been found: ', objects, operation)
            }
          }
        } else if (websocketData.web_version) {
          this.$store.commit('users/SET_ACTIVE_USERS', websocketData.active_users)
          const currentDomain = window.location.hostname
          const currentVerion = process.env.VUE_APP_VERSION
          let vueVersion = websocketData.web_version

          if (websocketData.servers_versions && websocketData.servers_versions.length) {
            const versions = websocketData.servers_versions

            versions.forEach(version => {
              if (Object.keys(version)[0] === currentDomain) {
                vueVersion = version[currentDomain]
              }
            })
          }

          console.log('WS VueVersion:', vueVersion)
          if (process.env.NODE_ENV === 'production' && currentVerion !== vueVersion && !this.versionNotifySent) {
            console.log('--* Vue.env.Version:', process.env.VUE_APP_VERSION)
            this.versionNotifySent = true

            // Обновление версии в сторе
            this.setVuexUpdateAppVersion(true)
          }
        } else if (websocketData.terminals) {
          this.setVuexTerminalsMonitoring(websocketData.terminals)
        } else if (websocketData.news) {
          this.setVuextNews(websocketData.news)
        }
      }

      if (this.ws) {
        this.ws.onopen = () => {
          // console.log('Socket open')
          clearInterval(this.wsInterval)
          this.wsInterval = null
        }
        this.ws.onclose = () => {
          console.log('messages: WebSocket Closed', this.login)
          this.ws = null
          clearInterval(this.wsInterval)
          this.wsInterval = null

          if (this.getIsUnauthorized) {
            this.reconnectInterval()
          }
        }

        this.ws.onerror = error => {
          clearInterval(this.wsInterval)
          this.wsInterval = null
        }
      }
    },
    async check() {
      // Проверка на авторизацию
      try {
        if (this.getIsUnauthorized) {
          await this.getMe()
        }
      } catch (error) {
        console.error('Error fetching user data:', error)
      }

      if (!this.ws || this.ws.readyState === 3) {
        clearInterval(this.wsInterval)
        this.wsInterval = null
        this.startWebSocket()
      } else if (this.ws.readyState === 1) {
        clearInterval(this.wsInterval)
        this.wsInterval = null
      }
    },
    reconnectInterval() {
      if (this.wsInterval) {
        clearInterval(this.wsInterval)
        this.wsInterval = null
      }
      this.wsInterval = setInterval(() => {
        this.check()
        console.log('interval running...', this.wsInterval)
      }, 6000)
    },
    async simulateError() {
      // Закрытие WebSocket соединения с кодом ошибки
      closews(1006, 'Simulated Error') // Код 1006 указывает на некорректное завершение соединения
    }
  }
}
