import { mapActions } from 'vuex'

export default {
  data() {
    return {
      isAddingTables: false,
      isEditingTables: false,
      isReporttemplate: false,
      // isCreatingReporttemplate: false,
      reporttemplateSelected: {
        name: '',
        template_tables: []
      },
      selectedTable: null,
      reportTitle: 1,
      selectedTableIndex: 0,
      isFromAdmin: false
    }
  },
  methods: {
    ...mapActions({
      REPORT_TABLE_VALIDATE: 'reports/REPORT_TABLE_VALIDATE'
    }),
    createReporttemplate() {
      this.reportTitle = 1
      this.isReporttemplate = true
      this.isReport = false
    },
    updateReporttemplate(reporttamplate) {
      this.reportTitle = 2
      this.reporttemplateSelected = reporttamplate
      this.isReporttemplate = true
      this.isFromAdmin = reporttamplate.isAdmin
      // this.isReport = false
    },
    async editTable(table) {
      try {
        this.reporttemplateSelected.template_tables[this.selectedTableIndex] = table

        const { status } = await this.REPORT_TABLE_VALIDATE(table)

        if (status === 200) {
          this.isReporttemplate = true
          this.isEditingTables = false

          this.$store.dispatch('reports/UPDATE_TEMPLATE', this.reporttemplateSelected)
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    updateTable({ table, index, isAdmin }) {
      this.selectedTable = { ...table, isAdmin }
      this.selectedTableIndex = index
      this.isEditingTables = true
      this.isReporttemplate = false
    },
    closeAddTables() {
      this.isAddingTables = false
      this.isReporttemplate = true
      this.isReporttemplate = true
    },
    closeReportTemplate() {
      if (!this.isFromAdmin) {
        this.isReporttemplate = false
        this.isReport = true
        this.reporttemplateSelected = {
          name: '',
          template_tables: []
        }
      } else {
        this.isReporttemplate = false
        this.isAdminFromReportsTemplate = true
      }
    },
    showAddTables({ reporttemplate, title }) {
      this.reporttemplateSelected = reporttemplate
      this.reportTitle = title
      this.isReporttemplate = false
      this.isAddingTables = true
    },
    addedTables(tables) {
      this.reporttemplateSelected.template_tables = [
        ...this.reporttemplateSelected.template_tables,
        ...tables
      ]
      this.isAddingTables = false
      this.isReporttemplate = true

      this.$store.dispatch('reports/UPDATE_TEMPLATE', this.reporttemplateSelected)
    }
  }
}
