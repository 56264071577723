import moment from 'moment'

const sortByAscending = (a, b, type = '') => {
  if (type === 'number') {
    return a - b
  }
  if (type === 'date') {
    return moment(b).unix() - moment(a).unix()
  }
  return a.toLowerCase() < b.toLowerCase() ? 1 : -1
}

const sortByDescending = (a, b, type = '') => {
  if (type === 'number') {
    return b - a
  }
  if (type === 'date') {
    return moment(a).unix() - moment(b).unix()
  }
  return a.toLowerCase() < b.toLowerCase() ? -1 : 1
}

export default {
  asc: sortByAscending,
  desc: sortByDescending
}
