<template>
  <svg
    width="31"
    height="11"
    viewBox="0 0 31 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4223 9.9867H10.8959L12.4749 0.282771H15.0016L13.4223 9.9867ZM8.7705 0.282771L6.36191 6.95721L6.0769 5.51994L6.07716 5.52046L5.22705 1.1565C5.22705 1.1565 5.12426 0.282771 4.0286 0.282771H0.0467235L0 0.447081C0 0.447081 1.21766 0.700425 2.64273 1.55624L4.83769 9.98696H7.47003L11.4895 0.282771H8.7705ZM28.6422 9.9867H30.962L28.9394 0.282511H26.9085C25.9707 0.282511 25.7423 1.00569 25.7423 1.00569L21.9743 9.9867H24.6079L25.1346 8.54528H28.3463L28.6422 9.9867ZM25.8622 6.55409L27.1896 2.92264L27.9364 6.55409H25.8622ZM22.1718 2.61634L22.5324 0.532481C22.5324 0.532481 21.4198 0.109375 20.26 0.109375C19.0063 0.109375 16.029 0.657336 16.029 3.32187C16.029 5.82884 19.5234 5.85999 19.5234 7.17681C19.5234 8.49363 16.389 8.25767 15.3546 7.4273L14.979 9.60617C14.979 9.60617 16.1071 10.1541 17.8307 10.1541C19.5548 10.1541 22.1557 9.26145 22.1557 6.83183C22.1557 4.30877 18.6299 4.07386 18.6299 2.97689C18.6302 1.87967 21.0907 2.02062 22.1718 2.61634Z"
      fill="#2566AF"
    />
  </svg>
</template>
<script>
export default {
  name: 'VisaIcon'
}
</script>
