<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="9"
      fill="#3CD88D"
      fill-opacity="0.2"
      stroke="#22AD6A"
      stroke-width="2"
    />
    <circle cx="10" cy="10" r="4" fill="#31C27D" />
  </svg>
</template>
<script>
export default {
  name: 'CurrentIcon'
}
</script>
