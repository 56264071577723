<script setup>
import { ref, watch } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'

// TEST
const AUTO_HIDE_TIMER = 30 // Таймер на 30 сек
const SECONDS_TIMER = ref(AUTO_HIDE_TIMER)
const LATER_TIMER = 15 // Таймер на 15 минут

const $t = useI18n()

const { useGetters, useMutations } = createVuexHelpers()

import InfoIcon from './assets/icons/InfoIcon.vue'

const { getIsVuexUpdateAppVersion, getIsVuexNotifyAppVersion } = useGetters('notifications', ['getIsVuexUpdateAppVersion', 'getIsVuexNotifyAppVersion'])

const { setVuexUpdateAppVersion, setVuexIsNotifyAppVersion } = useMutations({
  setVuexIsNotifyAppVersion: 'notifications/setVuexIsNotifyAppVersion',
  setVuexUpdateAppVersion: 'notifications/setVuexUpdateAppVersion'
})

const show = ref(false)
let started = false

/**
 * Начало таймера 1 test
 */
const startTimer = () => {
  if (SECONDS_TIMER.value > 0) {
    if (!show.value) {
      showNotify()
    }

    show.value = true
    setTimeout(() => {
      SECONDS_TIMER.value -= 1
      startTimer()
    }, 1000)
  } else {
    setVuexUpdateAppVersion(false)
  }
}

/**
 * Показать уведомление через 15 мин
 */
const showNotifyLater = () => {
  hideNotify()

  setTimeout(() => {
    show.value = false
    showNotify()
    SECONDS_TIMER.value = AUTO_HIDE_TIMER // сброс таймера
    startTimer()
  }, LATER_TIMER * 60 * 1000) // 15 минут Test 2
}

/**
 * Обновление страницы
 */
const updatePage = () => {
  let documentUrl = new URL(document.location.href)
  const dateTime = new Date().getTime()
  const params = new URLSearchParams(documentUrl.search)
  params.set('up', dateTime.toString())
  documentUrl.search = params.toString()
  document.location.href = documentUrl.toString()
}

const showNotify = () => {
  setVuexIsNotifyAppVersion(true)
}

const hideNotify = () => {
  setVuexIsNotifyAppVersion(false)
}

watch(
  getIsVuexUpdateAppVersion,
  newValue => {
    if (newValue && !started) {
      startTimer()
      started = true
    }
  },
  {
    immediate: true
  }
)
</script>

<template>
  <transition name="slide-fade">
    <div v-if="getIsVuexNotifyAppVersion" class="update-notify">
      <InfoIcon class="update-notify__icon" />
      <div class="update-notify__message">
        <div class="title">
          {{ $t('new.version.available') }}
          <span class="timer">{{ SECONDS_TIMER }}</span>
        </div>
        <p class="text">{{ $t('refresh.the.page') }}</p>
        <div class="grid grid-cols-2 gap-2">
          <button class="update-notify__btn" @click="showNotifyLater">{{ $t('remind.me.later') }}</button>
          <button class="update-notify__btn update-notify__btn--blue" @click="updatePage">{{ $t('btn.update') }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>
<style lang="scss" scoped>
.update-notify {
  position: absolute;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  gap: 8px;
  padding: 12px;
  width: 330px;
  top: 16px;
  right: 16px;
  z-index: 12; // 12
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px #00000029;

  &__icon {
    flex-shrink: 0;
  }

  &__message {
    .title {
      position: relative;
      font-family: 'Source Sans Pro';
      font-weight: 600;
      font-size: 16px;
      color: #292929;

      .timer {
        position: absolute;
        right: 0;
        font-family: 'Source Sans Pro';
        font-weight: 400;
        font-size: 12px;
        color: #dce1e7;
      }
    }

    .text {
      margin-top: 10px;
      font-family: 'Source Sans Pro';
      font-weight: 400;
      font-size: 12px;
      color: #a6aeb8;
    }
  }

  &__btn {
    width: 100%;
    border-radius: 6px;
    background-color: #dbebff;
    padding: 4px 0;
    margin-top: 10px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 12px;
    color: #3a7cca;

    &--blue {
      background-color: #2767b6;
      color: #fff;
    }

    :active,
    :focus {
      outline: none;
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-fade-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
