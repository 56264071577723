import HTTP from '@/api/http'

const geosearch = function(query, responseHandler, errorHandler) {
  return HTTP.post(`/geocode_search`, {
    part: query
  })
    .then(responseHandler)
    .catch(errorHandler)
}

const postPoint = function({ formData }, responseHandler, errorHandler) {
  return HTTP.post('/point', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  geosearch,
  postPoint
}
