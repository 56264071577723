<template>
  <div class="z-30 bg-white shadow top-5 left-10 ml-8 absolute grid grid-cols-2 pl-6 pr-4 pt-5 grid-rows-layout w-module rounded-xl">
    <div class="col-start-1 col-end-2 font-bold text-xl text-darkblue flex justify-between">
      <span class="font-SourceSansPro">{{ $t('geozones.create') }}</span>
    </div>
    <div class="flex justify-end col-start-2 items-center">
      <i class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold" @click="$emit('close')"></i>
    </div>
    <div class="flex justify-end col-start-1 col-end-3 border-b border-divider pb-3 mt-6">
      <skif-button class="w-full" variant="normal" @click="$emit('close')" type="button">
        {{ $t('btn.cancel') }}
      </skif-button>
      <skif-button class="w-full ml-6" @click="save" type="button">{{ $t('btn.save') }}</skif-button>
    </div>
    <span class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3">{{ $t('geozones.figure') }}</span
    ><button
      class="col-start-1 col-end-3 h-9 border focus_outline-none rounded focus_border-darkblue transition-colors duration-200 font-bold text-sm text-someGrey"
      type="button"
      @click="
        isIconShowen = false
        isTypeShowen = !isTypeShowen
      "
    >
      <div class="flex items-center justify-between px-3">
        <span>{{ getGeozoneType(geozone.type.key) }}</span
        ><svg class="duration-300" :style="[isTypeShowen ? { transform: 'rotateZ(-180deg)' } : { transform: 'rotateZ(0deg)' }]" width="14" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.266 0L7 4.823 1.734 0 0 1.589 7 8l7-6.411L12.266 0z" fill="#5477A9" />
        </svg>
      </div>
    </button>
    <transition enter-active-class="duration-300" leave-active-class="duration-300" enter-to-class="overflow-hidden max-h-130" leave-class="overflow-hidden max-h-130" leave-to-class="overflow-hidden max-h-0" enter-class="overflow-hidden max-h-0">
      <ul class="col-start-1 col-end-3 border border-divider rounded-sm mt-1" v-show="isTypeShowen">
        <li
          class="leading-8 h-8 block cursor-pointer px-3 font-bold text-sm hover_bg-reallylightblue"
          v-for="item in geozoneType"
          :key="item.key"
          @click="
            geozone.type.key = item.key
            $store.commit('geozones/SHAPE_CHANGE', geozone.type.key)
            geozone.width = 1
            isTypeShowen = false
          "
          :class="geozone.type.key === item.key ? 'text-textmain' : 'text-someGrey'"
        >
          {{ item.value }}
        </li>
      </ul> </transition
    ><span class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3">{{ $t('geozones.name') }}</span>
    <el-input class="col-start-1 col-end-3" v-model="geozone.name" :placeholder="$t('geozones.name')" />
    <!-- <skif-checkbox
      class="mt-4"
      v-model="manualCoordinates"
      label="добавить координаты вручную"
    ></skif-checkbox>
    <transition name="fading" mode="out-in">
      <div
        class="flex flex-col col-start-1 col-end-3 overflow-y-auto mt-4"
        v-if="isCoordsManual &amp;&amp; isPoint"
        style="height: 7vh; max-height: 100px"
        key="1"
      >
        <el-input
          class="mt-2"
          @blur="handleCoordInput"
          style="width: 300px"
          v-model="manualCoords"
          placeholder="48.246991, 69.502258 "
          type="textarea"
          autosize="autosize"
        ></el-input>
      </div>
      <div
        class="flex flex-col col-start-1 col-end-3 overflow-y-auto mt-4"
        style="height: 100px; max-height: 250px"
        key="2"
        v-if="isCoordsManual &amp;&amp; isPolygon"
      >
        <el-input
          class="mt-2"
          @blur="handleCoordInput"
          style="width: 300px"
          v-model="manualCoords"
          placeholder="[[48.32312769985961, 69.6148617], [48.320803199860165, 69.6076411],"
          type="textarea"
          autosize="autosize"
        ></el-input>
      </div> </transition
    > -->
    <span class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3">{{ $t('geozones.type') }}</span
    ><button
      class="col-start-1 col-end-3 h-9 border focus_outline-none rounded focus_border-darkblue transition-colors duration-200 font-bold text-sm text-someGrey"
      type="button"
      @click="
        isTypeShowen = false
        isIconShowen = !isIconShowen
      "
    >
      <div class="flex items-center justify-between px-3">
        <span>{{ getGeozoneIcon(geozone.icon.key) }}</span
        ><svg class="duration-300" :style="[isIconShowen ? { transform: 'rotateZ(-180deg)' } : { transform: 'rotateZ(0deg)' }]" width="14" height="8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.266 0L7 4.823 1.734 0 0 1.589 7 8l7-6.411L12.266 0z" fill="#5477A9" />
        </svg>
      </div>
    </button>
    <transition enter-active-class="duration-300 ease-in" leave-active-class="duration-300" enter-to-class="overflow-hidden max-h-320" leave-class="overflow-hidden max-h-320" leave-to-class="overflow-hidden max-h-0" enter-class="overflow-hidden max-h-0">
      <ul class="col-start-1 col-end-3 border border-divider rounded-sm mt-1" v-show="isIconShowen">
        <li
          class="leading-8 h-8 block cursor-pointer px-3 font-bold text-sm hover_bg-reallylightblue"
          v-for="item in geozoneTypes"
          :key="item.key"
          @click="
            geozone.icon.key = item.key
            $store.commit('geozones/TYPE_CHANGE', geozone.icon.key)
            isIconShowen = false
          "
          :class="geozone.icon.key === item.key ? 'text-textmain' : 'text-someGrey'"
        >
          {{ item.value }}
        </li>
      </ul> </transition
    ><span class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro py-3" v-if="geozone.type.key === 'line'">{{ $t('routesTrips.route.createGeozone.width') }}</span> <el-input class="col-start-1 col-end-3" v-if="geozone.type.key === 'line'" type="number" v-model.number="geozone.width" @input="$store.commit('geozones/SET_WIDTH', geozone.width)" /><span class="col-start-1 text-base font-semibold text-annotationColor font-SourceSansPro pt-4 pb-1">{{ $t('geozones.color') }}</span>
    <div class="flex items-center col-start-1 col-end-3 mb-8 mt-3 h-6" v-model="geozone.color.key">
      <div class="mr-8 rounded-full cursor-pointer transition-all duration-300" v-for="(color, key) in colors" :key="color.key" :style="{ backgroundColor: color.key }" :class="geozone.color.key === color.key ? 'w-6 h-6 border-2 border-headerText' : 'w-5 h-5'" @click="setActiveClass(color)"></div>
    </div>

    <div class="flex col-start-1 col-end-3 mb-6 text-area-XXX">
      <el-input resize="false" :autosize="{ minRows: 4, maxRows: 6 }" :placeholder="$t('new_units.links.description')" v-model="geozone.details" type="textarea" />
    </div>
    <div class="flex flex-col">
      <skif-checkbox v-model="geozone.use_as_address" :label="$t('geozones.use_as_address')" />
      <skif-checkbox class="mb-4" v-model="geozone.is_never_cluster" :label="$t('geozones.never_cluster')" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      isCoordsManual: false,
      manualCoordinates: '',
      isPolygon: true,
      isPoint: false,
      isActive: false,
      manualCoords: '',
      geozone: {
        color: {
          key: ''
        },
        name: 'Геозона',
        type: {
          key: ''
        },
        icon: {
          key: ''
        },
        width: 1,
        geometry: [],
        use_as_address: false,
        details: '',
        is_never_cluster: false
      },
      isTypeShowen: false,
      isIconShowen: false
    }
  },

  computed: {
    ...mapState('dictionary', {
      geozoneType: state => state.geozone_type,
      geozoneTypes: state => state.geo_icon,
      colors: state => state.color_code
    }),
    ...mapState('geozones', {
      currentGeozone: state => state.currentGeozone
    }),
    ...mapGetters('dictionary', ['getGeozoneType', 'getGeozoneIcon'])
  },
  watch: {
    'geozone.type.key': function (val) {
      switch (val) {
        case 'point':
          this.isPoint = true
          this.isPolygon = false
          break
        case 'polygon':
          this.isPoint = false
          this.isPolygon = true
          break
        default:
          this.geozone.geometry = val.latlng
          break
      }
    },
    manualCoordinates(val) {
      if (val) {
        this.isCoordsManual = true
      } else {
        this.isCoordsManual = false
      }
    },
    currentGeozone(val) {
      switch (this.geozone.type.key) {
        case 'point':
          this.geozone.geometry = [val.latlng]
          break
        case 'circle':
          this.geozone.width = val.radius
          this.geozone.geometry = [val.latlng]
          break
        default:
          this.geozone.geometry = val.latlng
          break
      }
    }
  },
  methods: {
    clearFields() {
      this.geozone.name = 'Геозона'
      this.geozone.width = 1
    },

    setActiveClass(color) {
      this.geozone.color.key = color.key
      this.$store.commit('geozones/SET_COLOR', color.key)
    },
    save2() {
      console.log('ok', this.geozone)
    },
    handleCoordInput(val) {
      switch (this.geozone.type.key) {
        case 'polygon':
          this.isPolygon = true
          this.isPoint = false
          console.log('its polygon', this.manualCoords)
          break
        case 'point':
          this.isPolygon = false
          this.isPoint = true
          break
      }
      console.log('val to extract', this.geozone)
    },
    save() {
      const component = this
      switch (this.geozone.type.key) {
        case 'polygon':
          this.geozone.geometry.length < 3 ? this.$showError(this.$t('geozones.errors.min_dots_polygon')) : this.geozone.name === '' ? this.$showError(this.$t('geozones.errors.name')) : this.$store.dispatch('geozones/CREATE_GEOZONE', this.geozone).then(_ => component.$emit('close'))
          break
        case 'circle':
          !this.geozone.width || !(this.geozone.geometry && this.geozone.geometry.length) ? this.$showError(this.$t('geozones.errors.draw_circle')) : this.geozone.name === '' ? this.$showError(this.$t('geozones.errors.name')) : this.$store.dispatch('geozones/CREATE_GEOZONE', this.geozone).then(_ => component.$emit('close'))
          break
        case 'line':
          !this.geozone.geometry || this.geozone.geometry.length < 2 ? this.$showError(this.$t('geozones.errors.min_dots_line')) : this.geozone.name === '' ? this.$showError(this.$t('geozones.errors.name')) : this.geozone.width < 1 ? this.$showError(this.$t('geozones.errors.width')) : this.$store.dispatch('geozones/CREATE_GEOZONE', this.geozone).then(_ => component.$emit('close'))
          break
        case 'point':
          !this.geozone.geometry || this.geozone.geometry.length < 1 ? this.$showError(this.$t('geozones.errors.put_marker')) : this.geozone.name === '' ? this.$showError(this.$t('geozones.errors.name')) : this.$store.dispatch('geozones/CREATE_GEOZONE', this.geozone).then(_ => component.$emit('close'))
          break
        default:
          break
      }
    }
  },
  created() {
    this.geozone.color.key = '#F56C6C'
    this.geozone.type.key = 'polygon'
    this.geozone.icon.key = this.geozoneTypes[0].key
  }
}
</script>
<style>
.fading-enter-active,
.fading-leave-active {
  transition: opacity 0.5s;
}
.fading-enter,
.fading-leave-to {
  opacity: 0;
}
.text-area-XXX {
  .el-textarea__inner {
    max-height: 300px;
    background: #fff;
  }
}
</style>
