import { billingApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  order: null,
  transactions: null,
  tarifHistory: null,
  newOrder: '',
  tariffs: []
}

const getters = {
  tarrifs: (state) => {
    return state.tariffs
  },
  order: (state) => {
    return state.order
  }
}
const actions = {
  async CREATE_ORDER({ commit }, formData) {
    try {
      const data = await billingApi.createOrder(formData)

      commit('SET_CREATE_ORDER', data.data)
      return data
    } catch (error) {
      throw error.response.data
    }
  },
  async CREATE_NEWORDER({ commit }, formData) {
    try {
      const data = await billingApi.createNewOrder(formData)
      commit('SET_CREATE_NEWORDER', data.data)
      return data
    } catch (error) {
      return error.response.data.message
    }
  },
  GET_TRANSACTIONS({ commit }, formData) {
    return new Promise((resolve, reject) => {
      billingApi.transactions(
        formData,
        (response) => {
          commit('SET_TRANSACTIONS', response.data.transactions)
          resolve(response.data)
        },
        (error) => {
          console.log('error', error)
        }
      )
    })
  },
  GET_TARIFFHISTORY({ commit }, formData) {
    return new Promise((resolve, reject) => {
      billingApi.tariffHistory(
        formData,
        (response) => {
          console.log(response)
          commit('SET_TARIFFHISTORY', response.data)
          resolve(response.data)
        },
        (error) => {
          console.log('error', error)
        }
      )
    })
  },
  GET_TARIFFS({ commit }, formData) {
    return new Promise((resolve, reject) => {
      billingApi.tariffHistoryAdmin(
        formData,
        (response) => {
          console.log(response)
          commit('SET_TARIFFS', response.data)
          resolve(response)
        },
        (error) => {
          console.log('error', error)
        }
      )
    })
  }
}
const mutations = {
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions
  },
  SET_CREATE_ORDER(state, orderCreated) {
    state.order = orderCreated
  },
  SET_CREATE_NEWORDER(state, orderCreated) {
    state.order = orderCreated
    state.newOrder = orderCreated
  },
  SET_TARIFFHISTORY(state, tarifHistory) {
    state.tarifHistory = tarifHistory
  },
  SET_TARIFFS(state, tariffs) {
    state.tariffs = tariffs
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
