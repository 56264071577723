<template lang="pug">
.flex.items-center.mb-2
  label.mini-label.flex {{ label }}
    input.radio.mr-2.order-first(
      type='radio',
      :value='value',
      :name='name',
      v-model='radioButtonValue'
    )
</template>

<script>
export default {
  props: ['value', 'name', 'label', 'checked'],
  model: {
    prop: 'checked',
  },

  computed: {
    radioButtonValue: {
      get: function () {
        return this.checked
      },
      set: function () {
        this.$emit('input', this.value)
      },
    },
  },
}
</script>
<style scoped>
.mini-label {
  color: #677785;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
}
input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 3px;
  background-clip: content-box;
  border: 2px solid #d5d8de;
  background-color: none;
  border-radius: 50%;
}

input[type='radio']:checked {
  background-color: #5477a9;
  border: 2px solid #5477a9;
  transition: border 0.4s linear;
  transition: background-color 0.5s ease;
  -webkit-transition: border 0.4s linear;
  -webkit-transition: background-color 0.5s ease;
}
input[type='radio']:focus {
  outline: none;
}
</style>
