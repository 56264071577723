import HTTP from '@/api/http'

const importGeozonesKml = (data) => {
  console.log(data, 'data');
  let fd = new FormData()
  fd.append('file', data.file)
  return HTTP.post(`/loadwln/${data.companyId}/geozones/create`, fd)
}

export default {
  importGeozonesKml,
}