import HTTP from '@/api/http'

const getDictionaryByType = function(type, responseHandler, errorHandler) {
  HTTP.get('/dictionaries/' + type, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getAllDictionary = function(responseHandler, errorHandler) {
  HTTP.get('/dictionaries', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getParamsForUnit = function(responseHandler, errorHandler) {
  HTTP.get('/units/sensors/params', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getAdminModules = function(responseHandler, errorHandler) {
  HTTP.get('/dictionaries/admin_modules', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getDictionaryByType,
  getParamsForUnit,
  getAdminModules,
  getAllDictionary
}
