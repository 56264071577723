import Vue from 'vue'

/**
 * ps: работу с картой в main.vue необходимо производить через статические методы работы с картой,
 * т.к. для main на момент рендеринга карты не существует..
 * */
export class MapInstance {

  static map = Vue.observable({
    map: null,
  });

  static init(map) {
    this.map = map
    this._addListeners()
  }

  static getMap() {
    return this.map;
  }

  static getZoom() {
    return this.map.getZoom();
  }

  static hasLayer(layer) {
    return this.map.hasLayer(layer)
  }

  static removeLayer(layer) {
    return this.map.removeLayer(layer)
  }

  static addLayer(layer) {
    return this.map.addLayer(layer)
  }

  static fitBounds(bounds, options = {}) {
    this.map.fitBounds(bounds, options)
  }

  static setView(center, zoom, options) {
    this.map.setView(center, zoom, options)
  }

  /**
   * Условно закрытые методы
   * */
  static _addListeners() {
    this.map.on('moveend', (e) => {
      // console.log('MAP Move end', e)
    })

    this.map.on('baselayerchange', (e) => {
      // console.log('MAP Baselayer change', e)
    })

    this.map.on('mouseup', (e) => {
      // console.log('MAP mouseup', e)
    })

    this.map.on('mouseout', (e) => {
      // console.log('MAP mouseout', e)
    })

    this.map.on('click', (e) => {
      // console.log('MAP click', e)
    })

    this.map.on('zoomend', (e) => {
      // console.log('MAP zoomend', e)
    })
  }

}
