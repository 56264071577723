<template lang="pug">
.flex(style='width: 250px')
  .filter-container
    el-select(
      filterable,
      :popper-class='"filtration"',
      type='text',
      v-model='filterData',
      size='large',
      @change='handleTypeChange'
    ) 
      el-option(
        v-for='(item, index) in filterableData',
        :key='index',
        :label='item.key',
        :value='item.value'
      )
  span.separation |
  .relative.search-container.cursor-pointer 
    .absolute.inset-y-0.flex.items-center(
      style='right: 8px',
      @click='$emit("loopclick")'
    )
      svg(
        width='24',
        height='24',
        fill='none',
        xmlns='http://www.w3.org/2000/svg'
      )
        path(
          fill-rule='evenodd',
          clip-rule='evenodd',
          d='M17.6 9.8a7.8 7.8 0 10-7.8 7.8 7.819 7.819 0 005.076-1.872l.324.324V17l6 6 1.8-1.8-6-6h-.948l-.324-.324A7.819 7.819 0 0017.6 9.8zm-13.201 0c0-3 2.4-5.4 5.4-5.4 3 0 5.4 2.4 5.4 5.4 0 3-2.4 5.4-5.4 5.4-3 0-5.4-2.4-5.4-5.4z',
          fill='#CFDBEB'
        )

    input.rounded.focus_outline-none.pl-4.pr-8.w-full.text-sm.font-medium.border.border-borderColor(
      type='text',
      style='height: 35px',
      v-model='model',
      v-bind='$attrs',
      v-on:keyup.enter='$emit("loopclick")'
      v-if='!sensor'
    )
    .filter-container.secondSelect(v-else)
      el-select(
        filtrable,
        type='text',
        v-model='currentSensor',
        popper-class='filtration',
        @change='handleSensorType',
        size='small'
      )
        el-option(
          v-for='(item, index) in sensortype',
          :key='index',
          :label='item.value',
          :value='item.key'
        )
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    filterModel: {
      type: String,
    },
    filterableData: {
      type: Array,
    },
    sensor: {
      type: Boolean,
    },
    sensortype: {
      type: Array,
    },
  },
  data() {
    return {
      filter: '',
      filterData: '',
      currentSensor: '',
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    handleSensorType(val) {
      this.$emit('sensorChange', val)
    },
    handleTypeChange(val) {
      this.$emit('lastTypeSearch', val)
    },
  },
  watch: {
    filterData(val) {
      this.$emit('filter', val)
    },
    currentSensor(val) {
      this.$emit('sensor', val)
    },
    filterModel(val) {
      this.filterData = val
    },
  },
}
</script>
<style scoped>
.filter-container {
  width: 160px;
}
.filter-container input {
  width: 100%;
}
.filtration .el-select-dropdown__list {
  width: 260px;
}
.filter-container .el-input__inner:hover {
  border-color: #dfe5eb;
}
.filter-container .el-select .el-input.is-focus .el-input__inner,
.filter-container .el-select .el-input__inner:focus {
  border-right: none !important;
  border-left: none !important;
}

.filter-container .el-input__inner:hover {
  border-color: none !important;
}
.filter-container input {
  border-right: none;
  border-radius: 0;
  border-color: #dfe5eb;
}

.search-container input {
  border-left: none;
  border-radius: 0;
  border-color: #dfe5eb;
}
.separation {
  border-top: 1px solid #dfe5eb;
  border-bottom: 1px solid #dfe5eb;
  padding-top: 2px;
  color: #dfe5eb;
}
.secondSelect {
  border-right: 1px solid #dfe5eb;
}
.secondSelect input {
  height: 35px !important;
}
</style>