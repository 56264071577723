import HTTP from '@/api/http'


const monitoring_filter = function(id, formData , responseHandler, errorHandler) {
  HTTP.put('/monitoring_filter/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
export default {
  monitoring_filter
}