import HTTP from '@/api/http'

const getRegions = function (responseHandler, errorHandler) {
  return HTTP.get('/regions', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getIntegrators = function (value, responseHandler, errorHandler) {
  return HTTP.post('/dealers_admin_query', {
    value,
    from: '0',
    count: '100',
  })
    .then(responseHandler)
    .catch(errorHandler)
}
const editIntegrator = function (formData, responseHandler, errorHandler) {
  return HTTP.put('/dealers/' + formData.id, formData)
    .then(responseHandler)
    .catch(errorHandler)
}
const createIntegrator = function (formData, responseHandler, errorHandler) {
  return HTTP.post('/dealers', formData)
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteIntegrator = function (id, responseHandler, errorHandler) {
  HTTP.delete('/dealers/' + id)
    .then(responseHandler)
    .catch(errorHandler)
}

const addFile = async (id, file) => {
  return await HTTP.post(`/dealers/${id}/public_offer_files`, file)
}

const getIntegrator = async (id) => {
  return await HTTP.get(`/dealers?ids=${id}`)
}

const getAllIntegrators = async () => {
  return await HTTP.get('/dealers', { needAuth: true })
}

export default {
  getRegions,
  editIntegrator,
  getIntegrators,
  createIntegrator,
  deleteIntegrator,
  addFile,
  getIntegrator,
  getAllIntegrators
}
