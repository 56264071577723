import { routesApi, racesApi } from '@/api'

import eventBus from '@/eventBus.js'
const state = {
  route: null,
  geozone: '',
  geozones: [],
  existingGeozones: [],
  points: [],
  routes: [],
  isDrawing: false,
  newGeozones: null,
  addGeozoneEvent: { '': false },
  fitBoundsToGeozone: { '': false },
  geozonesShow: [],
  markerToSetView: [],
  pointsToDraw: [],
  removingMarkers: [],
  drawenGeo: '',
  pointsToDelete: [],
  oldpointsToDelete: [],
  pointsToClean: [],
  removeLastPoint: [],
  oneRoutePoints: [],
  fullRoute: null,
  currentRouteId: null,
  fullRoutes: [],
  geoToEdit: null,
  status: '',
}

const actions = {
  FULL_ROUTES({ commit }, data) {
    commit('get_full_routes', data)
  },
  GET_POINTS_ROUTE({ commit }, data) {
    if (data.type === 'route') {
      routesApi.getRoute(
        data.route.id,
        function (response) {
          commit('SET_ROUTE', response.data)
        },
        function (error) {
          eventBus.$showError(error.response.data.message)
        }
      )
    } else if (data.type === 'race') {
      racesApi.getRace(
        data.race.id,
        function (response) {
          commit('SET_ROUTE', response.data)
          // console.log('response data', response.data)
        },
        function (error) {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  GET_ROUTE_ID({ commit }, routeId) {
    commit('get_rout_id', routeId.id)
  },
  EDIT_ROUTE({ commit }, formData) {
    return new Promise(function (resolve) {
      routesApi.getRoute(
        formData.id,
        function (response) {
          commit('get_route', response.data)
          resolve(response.data)
        },
        function (error) {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  GET_ROUTE({ commit }, formData) {
    commit('get_route', formData)
  },
  CREATE_GEOZONE({ commit }, formData) {
    commit('create_geozone', formData)
  },
  ADD_FROM_EXISTING_GEOZONE({ commit }, formData) {
    commit('add_from_existing_geozone', formData)
  },
  GET_ROUTES({ commit }, formData) {
    return new Promise(function (resolve) {
      routesApi.getRoutes(
        { formData },
        (response) => {
          commit('SET_ROUTES', response.data)
          resolve(response.data.list)
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  DELETE_POINTS({ commit }) {
    commit('DELETE_POINTS')
  },
  async CREATE_ROUTE({ commit }, formData) {
    try {
      const data = await routesApi.createRoute({ formData })
      console.log(data, 'data')
      commit('ADD_ROUTE', data.data)
      commit('setStatus', data.status)
    } catch (err) {
      eventBus.$showError(err.response.data.message)
    }
  },
  UPDATE_ROUTE({ commit }, formData) {
    routesApi.updateRoute(
      formData.id,
      { formData },
      (response) => {
        commit('UPDATE_ROUTE', response.data)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  WEBSOCKET_CHANGE({ commit }, { id, operation }) {
    if (operation === 'DELETE') {
      commit('DELETE_ROUTE', [id])
    } else {
      routesApi.getRoute(
        id,
        (response) => {
          if (operation === 'POST') {
            commit('ADD_ROUTE', response.data)
          } else {
            commit('UPDATE_ROUTE', response.data)
          }
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  DELETE_ROUTES({ commit }, routes) {
    let promiseList = []
    routes.forEach((item) => {
      promiseList.push(
        new Promise(function (resolve, reject) {
          routesApi.deleteRoute(item, resolve, reject)
        })
      )
    })
    Promise.all(promiseList)
      .then(function () {
        commit('DELETE_ROUTE', routes)
      })
      .catch(function (error) {
        eventBus.$showError(error.response.data.message)
      })
  },
}
const mutations = {
  edit_geozone(state, geo) {
    state.geoToEdit = geo
  },
  get_full_routes(state, data) {
    state.fullRoutes = data
    // console.log(state.fullRoutes)
  },
  get_rout_id(state, routeId) {
    state.currentRouteId = routeId
  },
  get_route(state, route) {
    state.currentRouteId = route.id
  },
  REMOVE_MARKERS(state, removingMarkers) {
    state.removingMarkers = removingMarkers
  },
  get_points_route(state, points) {
    state.pointsToDraw = points
  },
  geo_to_delete(state, points) {
    state.pointsToDelete = points
  },
  clean_map(state, points) {
    state.pointsToClean = points
  },
  removeLastPoint(state, points) {
    state.removeLastPoint = points
  },
  old_geo_to_delete(state, points) {
    state.oldpointsToDelete = points
  },
  draw_geo(state, point) {
    state.drawenGeo = point
  },
  create_geozone(state, geozone) {
    state.newGeozones = geozone
    if (!state.geozones.some((x) => x.id === geozone.id)) {
      state.addGeozoneEvent = {
        [geozone.id]: !Object.values(state.addGeozoneEvent)[0],
      }
    }
  },
  add_from_existing_geozone(state, formData) {
    state.existingGeozones = formData
  },
  edit_route(state, formData) {
    state.route = formData
  },

  SET_ROUTES(state, routes) {
    state.routes = routes.list
    state.maxRows = routes.max
  },
  SET_ROUTE(state, route) {
    state.pointsToDraw = route
  },
  ADD_ROUTE(state, editRoute) {
    if (!state.routes.some((x) => x.id === editRoute.id)) {
      state.routes = [...state.routes, editRoute]
    }
  },

  DELETE_POINTS(state, formData) {
    // let points = [...state.existingGeozones, state.geozone]
  },
  DELETE_ROUTE(state, routes) {
    state.routes = state.routes.filter(
      (item) => !routes.map((k) => k).includes(item.id)
    )
  },
  UPDATE_ROUTE(state, route) {
    console.log(state.routes, '1111')
    const indexOfRace = state.routes.findIndex((elem) => elem.id === route.id)
    state.routes = [
      ...state.routes.slice(0, indexOfRace),
      route,
      ...state.routes.slice(indexOfRace + 1),
    ]
    console.log(state.routes, '222')
  },
  START_DRAWING(state) {
    state.isDrawing = true
  },
  END_DRAWING(state) {
    state.isDrawing = false
  },
  FITBOUNDS_TO_GEOZONE(state, fitBoundsToGeozone) {
    state.fitBoundsToGeozone = {
      [fitBoundsToGeozone]: !Object.values(state.fitBoundsToGeozone)[0],
    }
    console.log('State.fitBoundsToGeozone', state.fitBoundsToGeozone)
    console.log('fitBoundsToGeozone', state.fitBoundsToGeozone)
  },

  setStatus(state, payload) {
    state.status = payload
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
