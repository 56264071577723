import HTTP from '@/api/http'

const getProperties = function (type, responseHandler, errorHandler) {
  HTTP.get('/properties/' + type, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getAllProperties = function (responseHandler, errorHandler) {
  HTTP.get('/properties', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getCustomFields = function (responseHandler, errorHandler) {
  HTTP.get('/custom_fields', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const setIntegrator = function (responseHandler, errorHandler) {
  HTTP.get('/dealers', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getAccessRights = function (responseHandler, errorHandler) {
  HTTP.get('/access', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const sendMessagesNew = (id) => {
  return HTTP.put(`/news/${id}/mark_as_read`)
}

export default {
  getProperties,
  getCustomFields,
  getAccessRights,
  setIntegrator,
  getAllProperties,
  sendMessagesNew
}
