import { wialonApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  wialon: [],
  analyzeWialon: [],
  status: ''
}

const getters = {
  wialon: (state) => {
    return state.wialon
  },

  analyzeWialon: (state) => {
    return state.analyzeWialon
  },

  status: (state) => {
    return state.status
  }
}

const mutations = {
  setImportWialon(state, payload) {
    state.wialon = payload
  },

  setAnalyzeWialon(state, payload) {
    state.analyzeWialon = payload
  },

  setStatus(state, payload) {
    state.status = payload
  },

  setClearArray(state) {
    console.log('here')
    ;(state.wialon = []), (state.analyzeWialon = [])
  }
}

const actions = {
  async getImportWialon({ commit }, payload) {
    try {
      const data = await wialonApi.importWialon(payload)
      commit('setStatus', data.status)
      commit('setImportWialon', data.data)
      return data
    } catch (err) {
      commit('setStatus', err.response.status)
      eventBus.$showError(err.response.data.error.message)
      return err.response.status
    }
  },

  async getAnalyze({ commit }, payload) {
    const data = await wialonApi.analyzeWialon(payload)
    commit('setAnalyzeWialon', data.data)
    return data
  },

  async createImport({ commit }, payload) {
    return wialonApi.createImport(payload)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
