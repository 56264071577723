import { companyApi } from '@/api'
import moment from 'moment'
import eventBus from '@/eventBus.js'

const state = {
  sessions: [],
  currentTime: '',
  sessionsDate: '',
  formatTime: '00:00:00',
  trackColors: null
}
const defaultColors = [
  { max: 40, hex_color: '#51AAEB' },
  { max: 80, hex_color: '#623F73' },
  { max: 120, hex_color: '#E37A3A' },
  { max: null, hex_color: '#F56C6C' }
]
const getters = {
  formatTime: state => {
    return state.formatTime
  },
  trackColors: state => {
    return state.trackColors
  }
}
const actions = {
  async EXPORT_COLORS({ commit }, { colors, selectedCompanies }) {
    try {
      const payload = {
        track_gradation: {
          styles: colors
        }
      }

      const updatePromises = selectedCompanies.map(company => {
        return companyApi.updateCompanyProperties(company.id, payload)
      })
      await Promise.all(updatePromises)
      console.log('All companies updated successfully')
    } catch (error) {
      eventBus.$showError(error.response.data.message)
    }
  },
  async SET_TRACKS_COLORS({ commit }, { colors, companyId }) {
    try {
      const payload = {
        track_gradation: {
          styles: colors
        }
      }

      const response = await companyApi.updateCompanyProperties(companyId, payload)
      commit('SET_TRACKS_COLORS', response.data.track_gradation.styles)
    } catch (error) {
      commit('SET_TRACKS_COLORS', defaultColors)
      eventBus.$showError(error.response.data.message)
      console.error('Failed to update colors', error)
    }
  },
  async GET_TRACKS_COLORS({ commit }, companyId) {
    try {
      const response = await companyApi.getCompanyProperties(companyId)
      commit('SET_TRACKS_COLORS', response.data.track_gradation.styles)
    } catch (error) {
      commit('SET_TRACKS_COLORS', defaultColors)
      eventBus.$showError(error.response.data.message)
      console.error('Failed to get colors', error)
    }
  },
  GET_CURRENTTIME({ commit }, currentTime) {
    commit('set_currentTime', currentTime)
  },
  GET_SESSIONS({ commit }, time = 'day') {
    let datetime = ''
    if (time === 'month') {
      datetime = moment().set({ hour: 0, minute: 0, second: 0 }).subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss')
    } else if (time === 'week') {
      datetime = moment().set({ hour: 0, minute: 0, second: 0 }).subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss')
    } else {
      datetime = moment().set({ hour: 0, minute: 0, second: 0 }).format('YYYY-MM-DD HH:mm:ss')
    }
    // if (state.dates.indexOf(datetime) === -1) {
    // commit('SET_DATE', datetime)
    companyApi.getSessions(
      { from: datetime },
      response => {
        commit('SET_SESSIONS', { data: response.data, datetime })
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
    // }
  },
  getFormatTime({ commit, rootGetters }, formatTime) {
    const reportForm = JSON.parse(localStorage.getItem(`reportsForm${rootGetters['login/me'].id}${rootGetters['login/me'].active_company_id}`))

    if (reportForm) {
      const endDate = reportForm.datePicker[1]
      const momentDate = moment(endDate, 'YYYY-MM-DD HH:mm:ss')
      if (formatTime === '23:59:59') {
        momentDate.set({ hour: 23, minute: 59, second: 59 })
      } else {
        momentDate.set({ hour: 0, minute: 0, second: 0 })
      }
      reportForm.datePicker[1] = momentDate.format('YYYY-MM-DD HH:mm:ss')

      localStorage.setItem(`reportsForm${rootGetters['login/me'].id}${rootGetters['login/me'].active_company_id}`, JSON.stringify(reportForm))
    }
    commit('setFormatTime', formatTime)
  }
}

const mutations = {
  SET_TRACKS_COLORS(state, colors) {
    state.trackColors = colors
  },
  SET_SESSIONS(state, session) {
    state.sessions = session.data
    state.sessionsDate = session.datetime
  },
  set_currentTime(state, currentTime) {
    state.currentTime = currentTime
  },
  setFormatTime(state, payload) {
    state.formatTime = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
