import { v4 as uuidv4 } from 'uuid'

let WS
let wsUserId

export const initws = () => {
  try {
    let id = uuidv4()
    wsUserId = id
    WS = new WebSocket(
      `${location.protocol === 'https:' ? 'wss' : 'ws'}://${
        location.host
      }/messages?id=${wsUserId}`
    )
  } catch (e) {
    console.error(e);
  }

  return WS

}
export const getws = () => {
  return WS
}
export const getwsUserId = () => {
  return wsUserId
}

export const closews = () => {
  WS.close()
}

