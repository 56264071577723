import HTTP from '@/api/http'

let getRaces = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/trips/list', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

let getRace = function (id, responseHandler, errorHandler) {
  HTTP.get('/trips?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

let createRace = function (formData, responseHandler, errorHandler) {
  HTTP.post('/trips', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

let updateRace = function (id, formData, responseHandler, errorHandler) {
  HTTP.put('/trips/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

let deleteRace = function (id, responseHandler, errorHandler) {
  HTTP.delete('/trips', { data: { ids: [id] } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getRaces,
  getRace,
  updateRace,
  deleteRace,
  createRace,
}
