import Vue from 'vue';
import { bind, unbind } from 'focus-outside';

/**
 * <div v-focus-outside="closeDropdownMenu">...</div>
 */
export default Vue.directive('focus-outside', {
    bind(element, binding) {
        const { value: handler } = binding;

        if (typeof handler !== 'function') return;

        bind(element, handler);
    },

    unbind(element) {
        unbind(element);
    }
});
