import { mailingsApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  mailings: [],
}
const getters = {
  mailings: (state) => {
    return state.mailings
  },
}
const actions = {
  GET({ commit }) {
    return new Promise(function (resolve) {
      mailingsApi.getMailings(
        (response) => {
          commit('SET', response.data.list)
          resolve(response.data)
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  WEBSOCKET_CHANGE({ commit }, { id, operation }) {
    if (operation === 'DELETE') {
      commit('DELETE', [id])
    } else {
      mailingsApi.getMailingById(
        id,
        (response) => {
          if (operation === 'POST') {
            commit('CREATE', response.data)
          } else {
            commit('UPDATE', response.data)
          }
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  CREATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      mailingsApi.createMailing(
        formData,
        (response) => {
          commit('CREATE', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  TOGGLE({ commit }, { id, formData }) {
    mailingsApi.toggleMailing(
      id,
      formData,
      (response) => {
        commit('UPDATE', response.data)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  UPDATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      mailingsApi.updateMailing(
        formData.id,
        formData,
        (response) => {
          commit('UPDATE', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE({ commit }, mailingIds) {
    let promiseList = []
    mailingIds.forEach((id) =>
      promiseList.push(
        new Promise((resolve, reject) =>
          mailingsApi.deleteMailing(id, resolve, reject)
        )
      )
    )
    Promise.all(promiseList)
      .then((_) => {
        commit('DELETE', mailingIds)
      })
      .catch((error) => {
        eventBus.$showError(error.response.data.message)
      })
  },
}

const mutations = {
  SET(state, mailings) {
    state.mailings = mailings
  },
  CREATE(state, mailing) {
    if (!state.mailings.some((x) => x.id === mailing.id)) {
      state.mailings = [...state.mailings, mailing]
    }
  },
  UPDATE(state, mailing) {
    const indexOfMailing = state.mailings.findIndex(
      (elem) => elem.id === mailing.id
    )
    state.mailings = [
      ...state.mailings.slice(0, indexOfMailing),
      mailing,
      ...state.mailings.slice(indexOfMailing + 1),
    ]
  },
  DELETE(state, ids) {
    state.mailings = state.mailings.filter((item) => !ids.includes(item.id))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
