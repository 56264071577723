import { objectsApi } from '@/api'

const state = {
  ports: [],
};

const actions = {
  async getPorts({ commit }) {
    const ports = await objectsApi.getPorts()
    commit('setPorts', ports.data)
  }
}

const mutations = {
  setPorts(state, payload) {
    state.ports = payload;
  }
}

const getters = {
  ports: (state) => {
    return state.ports;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}