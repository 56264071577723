<template>
  <div class="wrapper">
    <p v-if="$route.fullPath === '/lk-diler/create-company'" class="title">
      {{ $t('lkDiler.create_company_step.title') }}
    </p>
    <div v-if="$route.fullPath === '/lk-diler/create-company'" class="links">
      <div class="link">
        <img
          alt=""
          src="@/components/map/tools/help-tools/images/register/adminPanel.svg"
        />
        <router-link
          class="router-link"
          :to="{ path: '/lk-diler/create-company/admin-panel' }"
        >
          {{ $t('lkDiler.create_company_step.create') }}
        </router-link>
      </div>
      <div class="link">
        <img
          alt=""
          src="@/components/map/tools/help-tools/images/register/registerLink.svg"
        />
        <router-link
          class="router-link"
          :to="{ path: '/lk-diler/create-company/register-link' }"
        >
          {{ $t('lkDiler.create_company_step.register') }}
        </router-link>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'CreateCompany'
}
</script>

<style lang="stylus" scoped>
.link
  display flex
  align-items center
.title
  margin-bottom 26px
  color #333333
  font-size 12px
  font-weight 600
.router-link
  font-size 16px
  font-weight 600
  line-height 40px
  color #5477A9
</style>
