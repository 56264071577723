<template>
  <svg
    style="margin-left: 2px; transform: translate(0px, 1px)"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 7L7 1M7 1H0.5M7 1V7.5" stroke="#3A7CCA" />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRightIcon'
}
</script>
