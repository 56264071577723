<template>
  <svg
    width="35"
    height="10"
    viewBox="0 0 35 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8590_70683)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.1044 0.113281H24.5168C24.871 2.28908 26.996 4.3245 29.3336 4.3245H34.5753C34.6461 4.11394 34.6461 3.8332 34.6461 3.62263C34.6461 1.6574 33.0878 0.113281 31.1044 0.113281Z"
        fill="url(#paint0_linear_8590_70683)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0833 4.67188V9.93591H28.2708V7.12842H31.1041C32.6625 7.12842 34.0083 6.07562 34.4333 4.67188H25.0833Z"
        fill="#4DB45F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.7499 0.113281V9.93947H16.5832C16.5832 9.93947 17.2916 9.93947 17.6457 9.2376C19.5583 5.4475 20.1249 4.3245 20.1249 4.3245H20.4791V9.93947H23.6666V0.113281H20.8333C20.8333 0.113281 20.1249 0.183468 19.7708 0.815152C18.1416 4.04376 17.2916 5.72825 17.2916 5.72825H16.9374V0.113281H13.7499Z"
        fill="#4DB45F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.291382 9.93947V0.113281H3.47891C3.47891 0.113281 4.39975 0.113281 4.89559 1.51702C6.1706 5.23694 6.31227 5.72825 6.31227 5.72825C6.31227 5.72825 6.5956 4.81581 7.72894 1.51702C8.22478 0.113281 9.14562 0.113281 9.14562 0.113281H12.3332V9.93947H9.14562V4.67544H8.79145L7.02061 9.93947H5.60393L3.83308 4.67544H3.47891V9.93947H0.291382Z"
        fill="#4DB45F"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_8590_70683"
        x1="24.4986"
        y1="2.21889"
        x2="34.6461"
        y2="2.21889"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#00B4E6" />
        <stop offset="1" stop-color="#088CCB" />
      </linearGradient>
      <clipPath id="clip0_8590_70683">
        <rect
          width="34.7086"
          height="9.82619"
          fill="white"
          transform="translate(0.291504 0.113281)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MIRIcon'
}
</script>
