<template lang="pug">
button(
  type='button',
  v-on='$listeners',
  :class='disabled ? [btnClass, disabledStyles] : [btnClass, colorVariants]',
  :disabled='disabled'
)
  span
    slot
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '32'
    }
  },
  computed: {
    btnClass() {
      return `rounded-button transition-colors ease-in-out duration-500 font-semibold h-8 text-sm leading-none focus_outline-none ${
        this.width === 28 ? 'w-28' : 'w-' + this.width
      }`
    },
    disabledStyles() {
      return this.disabled
        ? 'cursor-not-allowed bg-white text-disabledText shadow'
        : ''
    },
    colorVariants() {
      switch (this.variant) {
        case 'primary':
          return 'bg-darkblue hover_bg-hoverButton text-white'
        case 'delete':
          return 'bg-notify hover_bg-hoverDelete text-white'
        case 'normal':
          return 'bg-normalButton hover_bg-someCancel text-white'
        case 'drawer':
          return 'bg-white hover_bg-reallylightblue text-darkblue shadow'
        default:
          return ''
      }
    }
  }
}
</script>
