import HTTP from '@/api/http'

const recover = function (id, responseHandler, errorHandler) {
  HTTP.put(`/cancel_delete/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const downloadReport = ({ formData }, responseHandler, errorHandler) => {
  HTTP.get(`/report_excel?body=${JSON.stringify(formData)}`)
    .then(responseHandler)
    .then(errorHandler)
}

const getReport = ({ formData }, responseHandler, errorHandler) => {
  return HTTP.post('/report', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const uploadAttachments = (file, responseHandler, errorHandler) => {
  HTTP.post('/attachments', file, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getAttachments = (id, responseHandler, errorHandler) => {
  HTTP.get(`/attachments/${id}.xlsx`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getListKeys = (tableKey, responseHandler, errorHandler) => {
  HTTP.get(`report_templates/computable_table_fields?table_key=${tableKey}`, {
    needAuth: true
  })
    .then(responseHandler)
    .catch(errorHandler)
}
const checkFormula = (formula, responseHandler, errorHandler) => {
  HTTP.post(`report_templates/table_formula_check`, formula)
    .then(responseHandler)
    .catch(errorHandler)
}

// const getReportTemplate = (responseHandler, errorHandler) => {
//   HTTP.post(
//     '/report_templates/list',
//     {
//       fields: [
//         'id',
//         'name',
//         'is_default',
//         'template_tables.id',
//         'template_tables.name',
//         'template_tables.key'
//       ]
//     },
//     { needAuth: true }
//   )
//     .then(responseHandler)
//     .catch(errorHandler)
// }
const getReportTemplate = (responseHandler, errorHandler) => {
  HTTP.get('/report_templates', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createNewReportTemplate = (formData, responseHandler, errorHandler) => {
  HTTP.post('/report_templates', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getReportTemplateId = (id, responseHandler, errorHandler) => {
  HTTP.get(`/report_templates?ids=${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteReportTemplates = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/report_templates/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)

  // keep this part so when we add recovery we will use the new API request
  // const ids = [id]
  // HTTP.delete('/report_templates', { data: { ids } }, { needAuth: true })
  //   .then(responseHandler)
  //   .catch(errorHandler)
}

const updateReport = (id, formData, responseHandler, errorHandler) => {
  HTTP.put(`report_templates/${id}`, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getTrack = ({ formData }, responseHandler, errorHandler) => {
  HTTP.post('/fasttracks', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getRacePoints = (raceId, responseHandler, errorHandler) => {
  HTTP.get(`trips?ids=${raceId}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getFastTrackGroupReport = data => {
  return HTTP.post(`/fasttracks_group`, data)
}

const postDatePoint = (data, responseHandler, errorHandler) => {
  HTTP.post(`/journal_index`, data).then(responseHandler).catch(errorHandler)
}

const deleteIntervalMessage = async formData => {
  const data = await HTTP.post('/data_deletions', formData)
  return data
}

const listDeletionsMessage = async formData => {
  const data = await HTTP.post('/data_deletions/list', formData)
  return data
}
const deleteMessage = async id => {
  await HTTP.delete(`/data_deletions/${id}`)
}

const getParametrs = async (terminalType, imei) => {
  const data = await HTTP.get(`/terminals/columns/${terminalType}/${imei}`)
  return data
}

const addParametrs = async params => {
  const data = await HTTP.post(`/terminals/columns`, params)
  return data
}

const getReportBarChart = async params => {
  const data = await HTTP.post(`/report/bar_chart`, params)
  return data
}

const checkReportTable = async formData => {
  const data = await HTTP.post('/report_templates/table_validate', formData, {
    needAuth: true
  })

  return data
}

const getTableKeys = async formData => {
  return await HTTP.get(`/report_templates/excel_table_fields?table_id=${formData}`)
}

/**
 * Запрос на получение трека в прямоугольной области карты
 * @param {object} payload - параметры запроса
 * @param {string} payload.process_id - уникальный идентификатор полученный в ответе на запрос /fasttracks
 * @param {array} payload.box - [lat1, lng1, lat2, lng2]
 * lat1, lng1 - координаты нижнего левого угла прямоугольника,
 * lat2, lng2 - координаты верхного правого угла прямоугольника
 * @param {number} payload.zoom - зум
 * @param {function} responseHandler - обработчик успешного
 * @param {function} errorHandler - обработчик ошибки
 * @returns {Promise}
 */
const getBoxTrack = (payload, responseHandler, errorHandler) => {
  HTTP.post('/box_tracks', payload, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

/**
 * Получение списка группировок для таблицы отчета
 * */
const getReportTimeGroupsByKey = async (table_key, responseHandler, errorHandler) => {
  HTTP.get(`/report_templates/time_groups?table_key=${table_key}`)
    .then(responseHandler)
    .catch(errorHandler)
}



export default {
  getReport,
  getReportTemplate,
  getReportTemplateId,
  deleteReportTemplates,
  updateReport,
  getTrack,
  createNewReportTemplate,
  downloadReport,
  uploadAttachments,
  getAttachments,
  getListKeys,
  checkFormula,
  getFastTrackGroupReport,
  getRacePoints,
  postDatePoint,
  deleteIntervalMessage,
  listDeletionsMessage,
  deleteMessage,
  getParametrs,
  addParametrs,
  getReportBarChart,
  checkReportTable,
  recover,
  getTableKeys,
  getBoxTrack,
  getReportTimeGroupsByKey
}
