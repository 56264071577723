<template>
  <div class="container">
    <svg
      width="151"
      height="153"
      viewBox="0 0 151 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.6051 3.73584C72.1219 -0.719934 78.8781 -0.719934 82.3949 3.73584C85.1597 7.23878 90.1116 8.11193 93.9077 5.76586C98.7364 2.78161 105.085 5.09238 106.866 10.4823C108.266 14.7195 112.621 17.2337 116.99 16.3274C122.548 15.1747 127.724 19.5175 127.554 25.1914C127.42 29.6519 130.652 33.5039 135.068 34.1467C140.685 34.9645 144.063 40.8156 141.963 46.0891C140.312 50.2349 142.032 54.96 145.961 57.0745C150.96 59.7641 152.133 66.4177 148.356 70.6548C145.386 73.9858 145.386 79.0142 148.356 82.3452C152.133 86.5823 150.96 93.2359 145.961 95.9255C142.032 98.04 140.312 102.765 141.963 106.911C144.063 112.184 140.685 118.036 135.068 118.853C130.652 119.496 127.42 123.348 127.554 127.809C127.724 133.482 122.548 137.825 116.99 136.673C112.621 135.766 108.266 138.28 106.866 142.518C105.085 147.908 98.7364 150.218 93.9077 147.234C90.1116 144.888 85.1597 145.761 82.3949 149.264C78.8781 153.72 72.1219 153.72 68.6051 149.264C65.8403 145.761 60.8884 144.888 57.0923 147.234C52.2636 150.218 45.9148 147.908 44.1341 142.518C42.7341 138.28 38.3795 135.766 34.0099 136.673C28.4518 137.825 23.2762 133.482 23.4463 127.809C23.58 123.348 20.3479 119.496 15.9319 118.853C10.3146 118.036 6.93651 112.184 9.03693 106.911C10.6882 102.765 8.96839 98.04 5.03858 95.9255C0.0398135 93.2359 -1.1334 86.5823 2.644 82.3452C5.61362 79.0142 5.61362 73.9858 2.644 70.6548C-1.13339 66.4177 0.0398135 59.7641 5.03858 57.0745C8.96839 54.96 10.6882 50.2349 9.03693 46.0891C6.93651 40.8156 10.3146 34.9645 15.9319 34.1467C20.3479 33.5039 23.58 29.6519 23.4463 25.1914C23.2762 19.5175 28.4518 15.1747 34.0099 16.3274C38.3795 17.2337 42.7341 14.7195 44.1341 10.4823C45.9148 5.09238 52.2636 2.78161 57.0923 5.76585C60.8884 8.11193 65.8403 7.23878 68.6051 3.73584Z"
        fill="#3A7CCA"
      />
      <path
        d="M44 79L66 101L114 53"
        stroke="white"
        stroke-width="12"
        stroke-linecap="round"
      />
    </svg>
    <h1 class="title">{{ title }}</h1>
    <p class="subtitle">{{ subTitle }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Вы подписаны на SKIF.PRO',
      subTitle: 'Теперь вы будете получать информационные сообщения.'
    }
  }
}
</script>
<style lang="stylus" scoped>
.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    text-align: center;
    color: #446C9D;
    margin-top: 24px;
  }
  .subtitle{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #292929;
    margin-top: 16px
  }
}
</style>
