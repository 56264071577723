import { initws, getws } from '@/api/wsLocators.js'
import 'leaflet'
import 'leaflet.markercluster'

export default {
  data() {
    return {
      ws: null,
      wsInterval: null,
      isRestart: false
    }
  },
  methods: {
    showError({ code, message, type }) {
      this.error = { code, message, type }

      this.columns = []
    },

    startWebSocket() {
      if (this.ws) {
        this.ws.onclose = function () {}
        this.ws.close()
        this.ws = null
      }
      initws(this.locatorID)

      this.ws = getws()

      this.ws.onmessage = (event) => {
        const websocketData = JSON.parse(event.data)
        if (websocketData.length > 0) {
          this.columns = websocketData[0].columns
          this.unitName = websocketData[0].unit_name
        }
      }

      this.ws.onopen = () => {
        clearInterval(this.wsInterval)
        this.wsInterval = null

        this.error.code = ''
        this.error.message = ''
        this.error.type = ''
      }

      this.ws.onclose = () => {
        console.log('messages: WebSocket Closed!!')

        this.ws = null

        clearInterval(this.wsInterval)

        this.wsInterval = null

        this.reconnectInterval()
      }
      this.ws.onerror = (error) => {
        if (error.type === 'error') {
          clearInterval(this.wsInterval)

          this.wsInterval = null

          if (error.response && error.response.data) {
            this.showError({
              code: `Ошибка ${error.response.data.code}`,
              message: error.response.data.message,
              type: 'known'
            })
          }

          this.reconnectInterval()
        }
      }
    },
    check() {
      if (!this.ws || this.ws.readyState === 3) {
        clearInterval(this.wsInterval)
        this.wsInterval = null
        this.startWebSocket()
        this.getLocator()
      } else if (this.ws.readyState === 1) {
        clearInterval(this.wsInterval)
        this.wsInterval = null
      }

      this.isRestart = false
    },
    reconnectInterval() {
      this.isRestart = true

      if (this.wsInterval) {
        clearInterval(this.wsInterval)
        this.wsInterval = null
      }
      this.wsInterval = setInterval(() => {
        this.check()
        console.log('interval running...', this.wsInterval)
      }, 30000)
    }
  }
}
