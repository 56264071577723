import HTTP from '@/api/http'

const settingsCompany = function (id, { formData }, responseHandler, errorHandler, finallyHandler) {
  HTTP.put('/company/' + id, formData)
    .then(responseHandler)
    .catch(errorHandler)
    .finally(finallyHandler)
}
const getConfigs = (responseHandler, errorHandler) => {
  return HTTP.get('/configs').then(responseHandler).catch(errorHandler)
}

const changeCompany = function (id, responseHandler, errorHandler) {
  HTTP.post('/company/change/' + id)
    .then(responseHandler)
    .catch(errorHandler)
}
const companyForAdminPanel = function (id, responseHandler, errorHandler) {
  HTTP.get('/company?ids=' + id)
    .then(responseHandler)
    .catch(errorHandler)
}

const inviteAll = ({ formData }, responseHandler, errorHandler) => {
  HTTP.post('/company/invite/all', formData, { needAuth: true }).then(responseHandler).catch(errorHandler)
}

const getSessions = (formData, responseHandler, errorHandler) => {
  HTTP.post('/company/sessions/query', formData, { needAuth: true }).then(responseHandler).catch(errorHandler)
}
const getHistory = (formData, responseHandler, errorHandler) => {
  HTTP.post('/company/updates/query', formData, { needAuth: true }).then(responseHandler).catch(errorHandler)
}

const deleteCompany = formData => {
  return HTTP.delete('/company/schedule_delete', { data: { ids: formData.map(el => el.id) } }, { needAuth: true })
}

const cancelDeleteCompany = formData => {
  return HTTP.post('/company/cancel_delete', { ids: formData.map(el => el.id) }, { needAuth: true })
}
const startAutoPay = () => {
  return HTTP.put('/bill_start_auto_pay', { needAuth: true })
}
const stopAutoPay = () => {
  return HTTP.put('/bill_stop_auto_pay', { needAuth: true })
}
const deleteCard = () => {
  return HTTP.put('/bill_remove_card', { needAuth: true })
}
const updateCompanyProperties = (id, payload) => {
  return HTTP.put(`/company/${id}/properties`, payload)
}
const getCompanyProperties = id => {
  return HTTP.get(`/company/${id}/properties`)
}
export default {
  settingsCompany,
  changeCompany,
  inviteAll,
  getSessions,
  getHistory,
  companyForAdminPanel,
  deleteCompany,
  cancelDeleteCompany,
  startAutoPay,
  stopAutoPay,
  deleteCard,
  getConfigs,
  updateCompanyProperties,
  getCompanyProperties
}
