const state = {
  fitBoundsToTrack: { '': false },
  movingMarkerPlay: { '': false },
  movingMarkerPause: { '': false },
  markerSpeed: { '': 10 },
  isTrackFinised: { '': false },
  moveMarkerBack: { '': false, isMovingBack: false }
}

const mutations = {
  FITBOUNDS_TO_TRACKBYDATE(state, fitBoundsToTrack) {
    state.fitBoundsToTrack = {
      [fitBoundsToTrack]: !Object.values(state.fitBoundsToTrack)[0]
    }
  },
  PLAY_MOVINGMARKER(state, [movingMarkerPlay, iconKey]) {
    state.movingMarkerPlay = {
      [movingMarkerPlay]: !Object.values(state.movingMarkerPlay)[0],
      iconKey: iconKey
    }
  },
  PAUSE_MOVINGMARKER(state, movingMarkerPause) {
    state.movingMarkerPause = {
      [movingMarkerPause]: !Object.values(state.movingMarkerPause)[0]
    }
  },
  TRACK_FINISHED(state, isTrackFinised) {
    state.isTrackFinised = {
      [isTrackFinised.id]: !Object.values(state.isTrackFinised)[0],
      isMovingBack: isTrackFinised.isMovingBack
    }
  },
  MOVE_MARKER_BACK(state, moveMarkerBack) {
    state.moveMarkerBack = {
      [moveMarkerBack]: !Object.values(state.moveMarkerBack)[0]
    }
  },

  CHANGE_SPEED(state, markerSpeed) {
    state.markerSpeed = markerSpeed
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
