import { mapState, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      lastpoints: {},
      anns50: [],
      activeAnnotation: {},
      activeMarkers: [],
      activeArrows: [],
      statusIcons: {},
      markersAngle: {},
      firstLoadMonitoringObject: true,
      unionArrTotal: [],
      unionObj: []
    }
  },

  watch: {
    valueTrigged(val) {
      if (val === 'objects') {
        this.clearMonitoring()
        this.$store.commit('map/EYE_TRIGGER', '')
      }
      if (val === 'hideall') {
        this.clearMonitoring()
        this.$store.commit('map/EYE_TRIGGER', 'hideMeasure')
      }
    },
    'activeArrows.length': (val) => {
      if (val) {
        this.$store.commit('monitoring/SET_SHOWMONITORINGBUTTON', true)
      } else {
        this.$store.commit('monitoring/SET_SHOWMONITORINGBUTTON', false)
      }
    }
  },

  computed: {
    ...mapState('company', {
      currentTime: (state) => state.currentTime
    }),
    ...mapState('monitoring', {
      cachedUnits: (state) => state.cachedUnits
    }),
    ...mapState('map', {
      eyeTriggered: (state) => state.eyeTriggered.isTrigged,
      valueTrigged: (state) => state.eyeTriggered.value
    })
  },

  methods: {
    ...mapMutations({
      setUnitObject: 'monitoring/setUnitObjectWebSocket'
    }),
    removeAllArrows() {
      this.clearMonitoring()
      this.activeArrows = []
    },
    removeAll() {
      this.clearMonitoring()
      this.activeMarkers = []
    },
    addActive({ type, id }) {
      if (type === 'marker') {
        this.activeMarkers.push(id)
      } else {
        this.activeArrows.push(id)
      }
    },
    deleteActive({ type, index }) {
      if (type === 'marker') {
        if (this.activeMarkers[index]) {
          this.activeMarkers.splice(index, 1)
        }
      } else if (this.activeArrows[index]) {
        this.activeArrows.splice(index, 1)
        this.$store.commit('monitoring/clearArrows', this.activeArrows)
      }
    },
    setActiveAnnotation(item) {
      this.activeAnnotation = item
    },
    clearLastpoints() {
      this.lastpoints = {}
      this.activeMarkers = []
      this.activeArrows = []
    },
    clearMonitoring() {
      localStorage.setItem(
        'monitoringUnits',
        JSON.stringify({ [this.activeCompanyId]: [] })
      )
      this.$store.commit('monitoring/REMOVE_MARKERS', this.activeMarkers)
      this.activeMarkers = []
      this.activeArrows = []
    },

    newLastpointArray(ann) {
      this.unionArrTotal.push(ann)

      this.unionArrTotal = this.unionArrTotal.flat()

      this.unionArrTotal.map((item) => {
        this.unionObj[item.unit_id] = item
      })

      this.setUnitObject(this.unionObj)

      setTimeout(() => {
        let annotations = {}
        const statusIcons = {}
        let setMarkerData = {}
        let initialSetMarker = {}
        let initialSetArrow = {}
        const markersAngle = {}

        ann.forEach((el) => {
          let item

          if (el && !el.columns) {
            item = el[0]
          } else {
            item = el
          }
          annotations = { ...annotations, [item.unit_id]: item }
          if (
            this.activeAnnotation &&
            this.activeAnnotation.unit_id === item.unit_id
          ) {
            this.activeAnnotation = item
          }
          let markerData
          if (!item.columns) {
            markerData = item[0].columns.find(
              (arr) => arr.key === '_marker_latlon'
            ).value
            console.log('not fond!!!!')
          } else {
            markerData = item.columns.find(
              (arr) => arr.key === '_marker_latlon'
            ).value
          }
          if (
            this.cachedUnits &&
            this.cachedUnits.map((k) => k[0]).includes(item.unit_id)
          ) {
            const thisCachedUnit = this.cachedUnits.find(
              (unit) => unit[0] === item.unit_id
            )
            this.$store.commit(
              'monitoring/DELETE_CACHEDMONITORING',
              item.unit_id
            )
            if (thisCachedUnit[1] === true && thisCachedUnit[2] === true) {
              this.activeMarkers.push(item.unit_id)
              this.activeArrows.push(item.unit_id)
              initialSetArrow = {
                ...initialSetArrow,
                [item.unit_id]: markerData
              }
            } else if (
              thisCachedUnit[1] === true &&
              thisCachedUnit[2] === false
            ) {
              this.activeMarkers.push(item.unit_id)
              initialSetMarker = {
                ...initialSetMarker,
                [item.unit_id]: markerData
              }
            }
          } else {
            setMarkerData = { ...setMarkerData, [item.unit_id]: markerData }
          }
          markersAngle[item.unit_id] = item.columns.find(
            (arr) => arr.key === '_angle'
          ).value
          const date = item.columns.find(
            (arr) => arr.key === '_datepoint'
          ).value
          const speed = item.columns.find((arr) => arr.key === 'speed').value

          const minutesDiff = moment(this.currentTime).diff(
            moment(date),
            'minutes'
          )

          if (minutesDiff < 15) {
            if (speed > 0) {
              statusIcons[item.unit_id] = 6 // меньше 15 минут и скорость больше 0
            } else {
              statusIcons[item.unit_id] = 5 // меньше 15 минут и скорость меньше 0
            }
          } else if (minutesDiff >= 15 && minutesDiff < 60) {
            if (speed > 0) {
              statusIcons[item.unit_id] = 4 // больше или равно 15 минут и меьше 60 минут и скорость больше 0
            } else {
              statusIcons[item.unit_id] = 3 // больше или равно 15 минут и меьше 60 минут и скорость меньше 0
            }
          } else if (speed > 0) {
            statusIcons[item.unit_id] = 2 // больше 60 минут и скорость больше 0
          } else {
            statusIcons[item.unit_id] = 1 // больше 60 минут и скорость меньше 0
          }
        })

        this.lastpoints = { ...this.lastpoints, ...annotations }
        this.statusIcons = { ...this.statusIcons, ...statusIcons }
        this.markersAngle = { ...this.markersAngle, ...markersAngle }

        this.$store.commit('monitoring/SET_MARKERDATA', setMarkerData)
        this.$store.commit('monitoring/SET_MARKERANGLE', markersAngle)
        this.firstLoadMonitoringObject = false
      }, 2000)

      this.anns50 = ann
    },

    newLastpoint(ann) {
      clearTimeout(this.wsFunction)

      this.anns50.push(ann)
      if (this.anns50.length >= 100) {
        this.anns50 = []
      }
    },
    clearAnns50() {
      this.onAnnotations(this.anns50)
    }
  }
}
