import { reportsApi } from '@/api'
import eventBus from '@/eventBus.js'

const defaultState = {
  units: [],
  marker: {},
  markersObjectGroupMarker: new Array(),
  cachedIdToShow: {},
  cachedArrowId: {},
  idToShow: { '': false },
  arrowId: { '': false },
  markerToSetView: { '': false },
  isShowenMonitoringButton: false,
  monitoringAnnotations: [],
  cachedUnits: [],
  track: {},
  removingMarkers: [],
  // REPORT
  isLoading: false,
  report: {},
  maxRows: 0,
  tableData: [],
  columnData: [],
  angle: {},
  isCloseMontoringAnno: false,

  websocketColumn: [],
  unitObjectWebSocket: {},
  isClearingMonitoringTrack: false,
  map: null,
  boxTrack: {}
}

const getters = {
  units: state => {
    return state.units
  },
  websocketColumn: state => {
    return state.websocketColumn
  },
  unitObjectWebSocket: state => {
    return state.unitObjectWebSocket
  },
  getTrack: state => {
    return state.track
  },
  getBoxTrack: state => {
    return state.boxTrack
  }
}

const actions = {
  CLOSEANNOMONITORINGANNO({ commit }, status) {
    commit('CLOSE_MONITORING_ANN', status)
  },
  GET_TRACK({ commit, state }, formData) {
    commit('SET_LOADING', true)
    return new Promise((resolve, reject) => {
      reportsApi.getTrack(
        { formData },
        response => {
          commit('SET_LOADING', false)
          if (response.data.values.length === 0) {
            reject(new Error('No track points!'))
            return
          }
          commit('SET_TRACK', { [formData.units[0].id]: response.data.values, processId: response.data.process_id, formData })
          resolve()
        },
        error => {
          commit('SET_LOADING', false)
          reject(error)
        }
      )
    })
  },
  GET_BOX_TRACK({ commit, state }, formData) {
    commit('SET_LOADING', true)
    return new Promise((resolve, reject) => {
      reportsApi.getTrack(
        { formData },
        response => {
          commit('SET_LOADING', false)
          if (response.data.values.length === 0) {
            reject(new Error('No track points!'))
            return
          }
          commit('SET_BOX_TRACK', { [formData.units[0].id]: response.data, processId: response.data.process_id, formData })
          resolve(response.data)
        },
        error => {
          commit('SET_LOADING', false)
          reject(error)
        }
      )
    })
  },
  // TODO: LEGENDA FOR TRACKS MONITORING AND REPORT
  GET_REPORT({ commit }, formData) {
    commit('SET_LOADING', true)
    reportsApi.getReport(
      { formData },
      response => {
        commit('SET_REPORT', response.data)
        commit('SET_MAXROWS', response.data.report.max_rows)
        commit('SET_TABLEDATA', response.data.report.rows)
        commit('SET_COLUMNDATA', response.data.report.columns)
        commit('SET_LOADING', false)
      },
      error => {
        commit('SET_LOADING', false)
        commit('SET_REPORT', { columns: [], rows: [], max_rows: 0 })
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  getMapForBoxTrack({ commit }, map) {
    commit('setMapForBoxTrack', map)
  }
}

const mutations = {
  clearMonitoringTrack(state) {
    state.isClearingMonitoringTrack = !state.isClearingMonitoringTrack
  },
  clearArrows(state, payload) {
    // console.log('payload', payload)
    if (payload.length) {
      payload.forEach(id => {
        delete state.markerToSetView[id]
      })
    }
  },
  setUnitObjectWebSocket(state, payload) {
    state.unitObjectWebSocket = payload
  },
  CLOSE_MONITORING_ANN(state, status) {
    state.isCloseMontoringAnno = !state.isCloseMontoringAnno
  },
  // REPORT
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading
  },
  SET_OBJECT_MARKER(state, marker) {
    if (marker === 'clear') {
      state.markersObjectGroupMarker = []
    } else {
      state.markersObjectGroupMarker.push(marker)
    }
  },
  SET_REPORT(state, report) {
    state.report = report
  },
  SET_MAXROWS(state, maxRows) {
    state.maxRows = maxRows
  },
  SET_TABLEDATA(state, tableData) {
    state.tableData = tableData
  },
  SET_COLUMNDATA(state, columnData) {
    state.columnData = columnData
  },
  //
  ADD_UNITS(state, unitsIds) {
    state.units = [...state.units, ...unitsIds]
  },
  SET_UNITS(state, unitsIds) {
    state.units = unitsIds
  },
  DELETE_UNITS(state, unitsIds) {
    state.units = state.units.filter(unit => !unitsIds.includes(unit))
  },
  SET_MARKERDATA(state, marker) {
    state.marker = marker
  },
  SET_MARKERANGLE(state, angle) {
    state.angle = angle
  },
  SHOW_MARKER(state, idToShow) {
    state.idToShow = { [idToShow]: !Object.values(state.idToShow)[0] }
  },
  INITIAL_SHOW_MARKER(state, marker) {
    state.cachedIdToShow = marker
  },
  SET_ARROW(state, arrowId) {
    state.arrowId = { [arrowId]: !Object.values(state.arrowId)[0] }
  },
  INITIAL_SET_ARROW(state, marker) {
    state.cachedArrowId = marker
  },
  SET_VIEW_ON_MARKER(state, markerId) {
    Object.keys(state.markerToSetView).forEach(el => {
      if (el !== markerId) {
        state.markerToSetView = {
          ...state.markerToSetView,
          [markerId]: !Object.values(state.markerToSetView)[0]
        }
      }
    })
  },
  REMOVE_MARKERS(state, removingMarkers) {
    state.removingMarkers = removingMarkers
  },
  CACHE_MONITORING(state, cachedUnits) {
    state.cachedUnits = cachedUnits
    if (!state.cachedUnits.length && state.cachedUnits.some(item => item[2] === true)) state.isShowenMonitoringButton = true
  },
  DELETE_CACHEDMONITORING(state, id) {
    state.cachedUnits = state.cachedUnits.filter(unit => unit[0] !== id)
  },
  SET_SHOWMONITORINGBUTTON(state, isShowen) {
    state.isShowenMonitoringButton = isShowen
  },
  SET_MONITORING_ANNS(state, monitoringAnnotations) {
    state.monitoringAnnotations = monitoringAnnotations
  },
  SET_TRACK(state, track) {
    console.log('🚀 ~ SET_TRACK ~ track:', track)
    state.track = Object.freeze(track)
  },
  SET_BOX_TRACK(state, track) {
    state.boxTrack = track
  },
  setWebsocketMonitoringDatepoint(state, payload) {
    state.websocketColumn = payload
  },
  setMapForBoxTrack(state, payload) {
    state.map = payload
  }
}

let strict = true
if (process.env.NODE_ENV === 'production') {
  strict = false
}

export default {
  // strict,
  namespaced: true,
  state: defaultState,
  actions,
  mutations,
  getters
}
