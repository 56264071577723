import HTTP from '@/api/http'

const createOrder = (formData) => {
  return HTTP.post('/bill_init_order', formData, { needAuth: true })
}
const createNewOrder = (formData) => {
  return HTTP.put('/bill_tariff', formData, { needAuth: true })
}
const transactions = function (formData, responseHandler, errorHandler) {
  return HTTP.post('/bill_transactions', formData)
    .then(responseHandler)
    .catch(errorHandler)
}
const tariffHistory = function (formData, responseHandler, errorHandler) {
  return HTTP.post('/bill_tariff_history', formData)
    .then(responseHandler)
    .catch(errorHandler)
}

const tariffUpdate = function (formData, responseHandler, errorHandler) {
  return HTTP.put('/bill_tariff_admin', formData)
    .then(responseHandler)
    .catch(errorHandler)
}
const tariffHistoryAdmin = function (formData, responseHandler, errorHandler) {
  return HTTP.post('/bill_tariff_admin', formData)
    .then(responseHandler)
    .catch(errorHandler)
}
export default {
  createOrder,
  transactions,
  tariffHistory,
  tariffUpdate,
  tariffHistoryAdmin,
  createNewOrder
}
