<template>
  <el-container class="w-full h-full">
    <el-main class="p-0">
      <div ref="map" class="w-full h-full" />
      <img
        class="absolute logo-map-mobile"
        src="@/assets/img/skif-logo.svg"
        alt="SKIF"
      />
      <div
        class="z-10 absolute bottom-5 right-5 flex justify-end gap-5 footer-menu"
      >
        <div class="time text-sm shadow">{{ currentTime }}</div>
        <img class="logo-map" src="@/assets/img/skif-logo.svg" alt="SKIF" />
      </div>
      <div class="unit-container">
        <div class="unit-content" v-if="!loading && columns.length">
          <div>
            <h1 class="truncate">{{ unitName }}</h1>
            <p class="update-time mb-2" :class="updateTimeClass">
              Данные: {{ getTimeAgo }}
            </p>
          </div>
          <section class="info mb-2 flex" style="">
            <p class="address-text">
              <span style="color: #676c72">Адрес:</span>
              {{ address }}
            </p>
            <!-- <div
              class="marker-icon"
              @click="setViewUnit"
              v-html="createIconAuto(this.iconUrl)"
            /> -->
          </section>
          <section v-if="unit.description" class="info item-description mb-2">
            <p v-html="unit.description"></p>
          </section>
          <section v-if="unit.date_to" class="dates">
            <div>
              <p>Ссылка активна до:</p>
              <span>{{ this.humanTime }}</span>
            </div>
            <div>
              <p>Осталось:</p>
              <span>{{ leftTime }}</span>
            </div>
          </section>
        </div>

        <div v-if="loading && columns.length" class="loader-container">
          <span class="loader"></span>
          <p class="mt-3">{{ $t('loading') }}</p>
        </div>

        <div v-if="!loading && !columns.length" class="error-container">
          <img
            v-if="error.type === 'known'"
            src="./images/icon-error.png"
            alt=""
          />
          <img
            v-if="error.type === 'unknown'"
            src="./images/icon-connection.png"
            alt=""
          />
          <p>{{ error.message }}</p>
        </div>
      </div>
      <div class="target-icon" v-show="false">
        <TargetIcon @click.native="setViewUnit" />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { locatorsApi } from '@/api'
import websocketLocatorHandler from '@/mixins/websocketLocatorHandler.js'
import { getMarkerColor } from '@/utils/getMarkerColor.js'
import moment from 'moment'
import 'moment-precise-range-plugin'
import { mapActions } from 'vuex'
import TargetIcon from '@/views/locator/images/TargetIcon'

const OSM = new L.TileLayer(
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  {
    attribution: '',
    maxZoom: 20,
    maxNativeZoom: 18,
    crs: L.CRS.EPSG3857,
    minZoom: 0
  }
)

export default {
  components: {
    TargetIcon
  },
  mixins: [websocketLocatorHandler],
  data() {
    return {
      loading: true,
      map: null,
      renderer: null,
      tileLayer: OSM,
      center: {
        lat: 55.901244,
        lng: 37.618423
      },
      zoom: 17,
      unit: {},
      locatorID: this.$route.query.id,
      currentTime: null,
      timeZoneDiff: moment().utcOffset(),
      columns: [],
      error: {
        code: '',
        message: '',
        type: ''
      },
      unitName: null,
      marker: null,
      angle: 0
    }
  },

  computed: {
    humanTime() {
      const humanHour = moment(this.unit.date_to).format('HH:mm')
      const humanDate = moment(this.unit.date_to).format('DD/MM/YY')
      return `${humanHour} ${humanDate}`
    },

    leftTime() {
      const dateTo = moment(this.unit.date_to).format('YYYY-MM-DD HH:mm:ss')
      const nowDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      const time = moment.preciseDiff(dateTo, nowDate, true)
      return `${time.days}д. ${time.hours}ч. ${time.minutes}м.`
    },

    iconUrl() {
      return `/static/car_icon/${this.unit.icon}.svg`
    },

    iconMarker() {
      return require('./images/Marker.png')
    },

    datepoint() {
      if (this.columns.length) {
        return this.columns.find((c) => c.key === '_datepoint').value || ''
      }

      return ''
    },

    address() {
      if (this.columns.length) {
        return this.columns.find((c) => c.key === 'address').value || ''
      }
      return ''
    },

    markerLatlon() {
      if (this.columns.length) {
        return this.columns.find((c) => c.key === '_marker_latlon').value
      }
      return [this.center.lat, this.center.lng]
    },

    updateTimeClass() {
      let color = 'green'

      if (this.timeDiff > 30) {
        color = 'red'
      } else if (this.timeDiff > 15) {
        color = 'orange'
      } else if (this.timeDiff > 5) {
        color = 'yellow'
      }

      return `time-${color}`
    },

    timeDiff() {
      const dateNow = moment()
      const lastSocketDate = moment(this.datepoint)

      return dateNow.diff(lastSocketDate, 'minutes')
    },

    getTimeAgo() {
      if (this.columns.length) {
        const secondsAgo = this.columns.find(
          (c) => c.key === '_ago_seconds'
        ).value

        moment.locale('ru')
        const duration = moment.duration(secondsAgo * 1000)

        return `${moment.duration(duration._milliseconds).humanize()} назад`
      }

      return ''
    }
  },

  watch: {
    unitName(val) {
      if (val && this.marker) {
        this.marker.setTooltipContent(this.unitName)
      }
    },
    columns() {
      if (!this.map) {
        this.initMap()
      } else if (this.isRestart) {
        this.startWebSocket()
      }

      if (!this.marker) return

      this.marker.setLatLng(this.markerLatlon)
      this.angle = this.columns.find((el) => el.key === '_angle') ? this.columns.find((el) => el.key === '_angle').value : 0
      const locatorIcon = document.querySelector('.locator-icon')
      if (locatorIcon) {
        locatorIcon.style.transform = `rotate(${this.angle}deg)`
      }
    },

    markerLatlon() {
      this.setViewUnit()
    }
  },

  methods: {
    ...mapActions({
      setLocatorView: 'map/setLocatorView'
    }),

    updateCurrentTime() {
      this.currentTime = moment()
        .utc()
        .add(this.timeZoneDiff, 'minutes')
        .format('HH:mm')
    },

    getLocator() {
      locatorsApi.getSingleLocator(
        this.locatorID,
        (response) => {
          this.unit = response.data

          this.error.message = ''
          this.error.type = ''

          this.loading = false
        },
        (error) => {
          if (error.response && error.response.data) {
            this.showError({
              code: `Ошибка`,
              message: error.response.data.message,
              type: 'known'
            })
          }

          this.loading = false
        }
      )
    },

    initMap() {
      const map = L.map(this.$refs.map, {
        attributionControl: false,
        drawControl: true,
        zoomControl: false,
        editable: true,
        doubleClickZoom: false,
        maxZoom: 18,
        minZoom: 0,
        preferCanvas: true
      })

      this.renderer = L.canvas()
      this.map = map

      this.map.setView(this.center, this.zoom)

      OSM.addTo(this.map)

      L.control
        .zoom({
          position: 'bottomright'
        })
        .addTo(map)

      // setTimeout(() => {
      const marker = this.createMarker()

      if (marker) {
        marker.addTo(this.map)

        this.center = {
          lat: this.markerLatlon[0],
          lng: this.markerLatlon[1]
        }

        if (window.innerWidth < 735) {
          this.center.lat -= 0.185
        }

        this.map.setView(this.center, this.zoom)
        this.marker.setLatLng(this.center)
      }
      // }, 2000)
    },

    createMarker() {
      if (this.error.message) return

      const icon = L.divIcon({
        className: 'skif-marker-locator',
        html: this.createMarkerIconHtml(this.iconMarker),
        iconAnchor: [13, 36],
        offset: [0, 18]
      })

      this.marker = new L.Marker(this.markerLatlon, {
        icon,
        draggable: false
      })

      this.marker.bindTooltip(this.unitName, {
        permanent: true,
        className: 'unitName',
        direction: 'bottom',
        offset: [0, 18]
      })

      return this.marker
    },

    createIconAuto(iconUrl, color = 'white') {
      const index = iconUrl.lastIndexOf('_')
      const correctIconUrl = iconUrl.substring(0, index)
      const arrIcon = correctIconUrl.split('/')
      const currentIcon = arrIcon.pop()
      const urlColor = iconUrl.split('_')
      const clr = urlColor.pop()
      const colorIndex = clr.lastIndexOf('.')
      const correctColor = clr.substring(0, colorIndex)

      const img = `
        <div class='skif-marker__img-box skif-marker__img-box__locator'>
          <span class="skif-marker__item">
            <svg class="skif-marker__img" width="56" height="50" viewBox="0 -4 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <use xlink:href="/static/car_icon-new/cars.svg#${currentIcon}" fill="${getMarkerColor(
        correctColor
      )}"></use>
            </svg>
          </span>
        </div>
      `

      const colorStr = color ? `style='color: ${color}; cursor: pointer;'` : ''
      return `<div class='skif-marker__box' ${colorStr}'>${img}</div>`
    },

    createMarkerIconHtml(iconUrl, color = 'white') {
      return `<img src="${iconUrl}" class="locator-icon" style="transform: rotate(${this.angle}deg)" />`
    },

    setViewUnit() {
      this.map.setView(this.markerLatlon, this.maxZoom)
    }
  },

  mounted() {
    this.loading = true
    this.setLocatorView(true)
    this.getLocator()
    this.startWebSocket()
    this.updateCurrentTime()

    setInterval(() => {
      this.updateCurrentTime()
    }, 5000)
  },

  beforeDestroy() {
    this.setLocatorView(false)
  }
}
</script>

<style lang="scss">
@media screen and (max-width: 735px) {
  .skif-marker-locator {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .leaflet-control-zoom-in {
    margin-bottom: 20px !important;
  }

  .lealet-bottom {
    box-shadow: none !important;
  }

  .leaflet-bottom .leaflet-bar {
    box-shadow: none !important;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    border-radius: 12px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .leaflet-bottom .leaflet-bar a {
    line-height: 36px !important;
    box-shadow: 0px 0px 8px 0px #0000001f;
    color: #828d9b !important;
  }

  .leaflet-bottom .leaflet-bar a:hover {
    background: unset !important;
  }

  .leaflet-bottom {
    bottom: 63% !important;
  }

  .marker-icon {
    .skif-marker__item {
      background-color: #eef0f7;
    }
    .skif-marker__img-box {
      &:after {
        border: 3px solid #eef0f7;
      }
    }
  }
}

.markers_map {
  position: relative;
  .marker_set {
    .marker_on_map {
      box-shadow: 0px 0px 12px 0px #00000033;
      position: relative;
      width: 36px;
      height: 36px;
      background-color: #eb7f35;
      border: 8px solid #fff;
      border-radius: 50%;
      z-index: 1;
    }

    .marker_arrow {
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 30px solid #5c9deb;
      position: absolute;
      bottom: -20px;
      left: 18px;
      transform: translateX(-50%) rotate(180deg);
      z-index: -1;
    }
  }
}
</style>

<style lang="scss" scoped>
.logo-map-mobile {
  display: none;
}

.el-main {
  background-color: #fafafa;
}

.unit-container {
  position: absolute;
  max-width: 375px;
  max-height: calc(100vh - 35px);
  width: calc(100% - 40px);
  left: 15px;
  top: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.unit-content {
  max-height: calc(100vh - 67px);
  display: flex;
  flex-direction: column;
}

h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  color: #292929;
}

.update-time {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  &__text {
    color: #a6aeb8;
  }
}

.time-green {
  color: #4abc96;
}

.time-yellow {
  color: #f7ca5a;
}

.time-orange {
  color: #ee8f38;
}

.time-red {
  color: #d9554d;
}

section.info {
  background-color: #eef0f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
}

section.item-description {
  max-height: -webkit-fill-available;
  display: block;
  overflow: auto;
  word-break: break-word;
}

.address-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.info img {
  height: 36px;
  width: 36px;
}

.loader-container,
.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 145px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

span.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #4abc96;
  border-right: 3px solid transparent;
  animation: rotation 1.5s linear infinite;
}

.dates {
  display: flex;
}

.dates div {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.dates div p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #bec7d3;
}

.dates div span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #292929;
}

.time {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 138px;
  height: 36px;
  color: #5477a9;
  background: white;
  border-radius: 10px;
}

@media screen and (max-width: 735px) {
  .footer-menu {
    position: absolute;
    width: calc(100% - 30px);
    height: 36px;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
  }

  .time {
    width: 40px;
    top: 10px;
    right: 10px;
    color: #292929;
    font-size: 12px;
  }

  .logo-map {
    display: none;
    position: absolute;
    left: 0;
  }

  .logo-map-mobile {
    display: inline;
    top: 10px;
    left: 10px;
  }

  .logo {
    width: 100px;
    top: 10px;
    left: 10px;
  }

  .unit-container {
    max-height: calc(100vh - 40px);
    position: absolute;
    max-width: 100%;
    height: 35%;
    width: 100%;
    top: unset;
    left: 0;
    bottom: 0;
    padding: 8px;
    border-radius: 0;
    overflow-y: scroll;
  }

  .target-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 325px;
    right: 24px;
    height: 40px;
    width: 40px;
    background-color: #fff;
    border-radius: 50%;
  }

  .marker-icon {
    width: 70px;
    height: 40px;
  }
}
</style>
