let LOAD = false;
let METRIKA_ID = null;
/**
 * События
 * */
const ACTIONS = {
  'DEMO_LOGIN': 'Вход в демо',
  'DEMO_STEP_1': '1 нажатиe на Далее в Демо - для каждого свое событие',
  'DEMO_STEP_2': '2 нажатиe на Далее в Демо - для каждого свое событие',
  'DEMO_STEP_3': '3 нажатиe на Далее в Демо - для каждого свое событие',
  'DEMO_STEP_4': '4 нажатиe на Далее в Демо - для каждого свое событие',
  'DEMO_STEP_5': '5 нажатиe на Далее в Демо - для каждого свое событие',
  'OPEN_MODULE_REPORTS': 'Открытие модуля Отчеты',
  'OPEN_MODULE_MONITORING': 'Открытие модуля Мониторинг',
  'OPEN_MODULE_OBJECTS': 'Открытие модуля Объекты',
  'OPEN_MODULE_GEOZONES': 'Открытие модуля Геозоны',
  'OPEN_MODULE_USERS': 'Открытие модуля Пользователи',
  'OPEN_MODULE_NOTIFICATIONS': 'Открытие модуля Уведомления',
  'OPEN_MODULE_TRIPS': 'Открытие модуля Рейсы',
  'OPEN_MODULE_MAILING_LIST': 'Открытие модуля Рассылки',
  'VISIT_PAGE_NEWS_UPDATES': 'Переход в Новости и обновления',
  'VISIT_PAGE_WIKI': 'Переход в Справка (Wiki)',
  // TODO:
  'MAKE_REPORT': 'Формирование любого отчета',
  'OPEN_MORE_DETAILS_EVENTS': 'Открытие любой карточки из модулей отдельным событием',
  'SHOW_OBJECT_IN_MAP_FROM_MONITORING': 'Просмотр любого объекта на карте из модуля Мониторинг'
}

const initMetrika = (idMetrika, load = false) => {
    if(load) {
      LOAD = load
      METRIKA_ID = idMetrika
      loadingScriptMetrika();
    }
}

const loadingScriptMetrika = () => {
  (function (d, w, c) {
    (w[c] = w[c] || []).push(function() {
      try {
        w[`yaCounter${METRIKA_ID}`] = new Ya.Metrika({
          id: METRIKA_ID,
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true
        });
      } catch(e) { }
    });

    var n = d.getElementsByTagName("script")[0],
      x = "https://mc.yandex.ru/metrika/watch.js",
      s = d.createElement("script"),
      f = function () { n.parentNode.insertBefore(s, n); };
    for (var i = 0; i < document.scripts.length; i++) {
      if (document.scripts[i].src === x) { return; }
    }
    s.type = "text/javascript";
    s.async = true;
    s.src = x;

    if (w?.opera === "[object Opera]") {
      d.addEventListener("DOMContentLoaded", f, false);
    } else { f(); }
  })(document, window, "yandex_metrika_callbacks");
}

const sendAction = (action) => {
  if (!LOAD) {
    return false;
  }
  try {
    if(typeof window[`yaCounter${METRIKA_ID}`]?.reachGoal === 'function') {
      if (action in ACTIONS) {
        console.log('> M.send: ', action)
        window[`yaCounter${METRIKA_ID}`].reachGoal(action)
      }
    }
  } catch (e) {
    console.error('Metrika', e)
  }
}

export const Metrika = {
  Init: initMetrika,
  Send: sendAction
}


