import HTTP from '@/api/http'

const getUsers = function(data, responseHandler, errorHandler) {
  HTTP.post(
    '/users/query', {},
    { needAuth: true }
  )
    .then(responseHandler)
    .catch(errorHandler)
}
const recover = function (data, responseHandler, errorHandler) {
  HTTP.put('/auth_cancel_delete/' + data.id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getUserById = function(id, responseHandler, errorHandler) {
  HTTP.get('/users?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createUser = function({ formData }, responseHandler, errorHandler) {
  HTTP.post('/users', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateUser = function(id, { formData }, responseHandler, errorHandler) {
  HTTP.put('/users/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteUser = function(data, responseHandler, errorHandler) {
  let ids= []
 data.forEach(geo=> ids.push(geo.id))
  HTTP.delete('company/users?ids=' + ids, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const invite = function(formData, responseHandler, errorHandler) {
  HTTP.post('company/invite', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const approveInvite = function(hash, responseHandler, errorHandler) {
  HTTP.get('/company/invite/' + hash)
    .then(responseHandler)
    .catch(errorHandler)
}

const getLinkTelegramBot = () => {
  return HTTP.get('/configs')
}

export default {
  getUsers,
  getUserById,
  deleteUser,
  invite,
  createUser,
  updateUser,
  approveInvite,
  recover,
  getLinkTelegramBot,
}
