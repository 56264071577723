<template lang="pug">
//- transition-group( name='player')
.qr-container
  .mx-auto.shadow.px-6.py-2.flex.flex-col.overflow-y-auto.panel(
    style='width: 800px; min-width: 500px',
    :style='[haveAccount === "donthaveAccount" ? { height: "auto" } : { height: "" }]'
  )
    span.col-start-1.col-end-2.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t('loginPage.register.title') }}
      //- HAVE ACCOUNT 
    skifRadioOld.mt-4(
      value='haveAccount',
      :label='$t("loginPage.haveAccount") + branding.title',
      name='haveAccount',
      v-model='haveAccount',
      v-if='loginType === "demoLogin" || !isLoggedIn'
    )
    el-collapse-transition
      el-form.mb-8.px-4.w-full.overflow-y-auto(
        label-width='230px',
        ref='haveAccountForm',
        :model='form1',
        :rules='rules',
        :validate-on-rule-change='true',
        v-if='haveAccount === "haveAccount" || loginType === "fullLogin"'
      )
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(:label='$t("loginPage.sixCode")', prop='code')
              el-input(
                :class='{ "input-error": errors.form1.isCodeError }',
                v-model='form1.code',
                type='text',
                placeholder='- - - - - -'
              )
              //- span.relative(style='top:30px;left:5px') error 
        el-row(
          type='flex',
          v-if='loginType === "fullLogin" && moreThanCompany'
        )
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(label='	Компания:', prop='compay')
              el-select.w-full(v-model='form1.company', filterable)
                el-option(
                  style='max-width: 465px',
                  v-for='company in companies',
                  :key='company.id',
                  :label='company.name',
                  :value='company.id'
                ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(label='Тип авто:', prop='typecar')
              el-select(v-model='form1.typecar')
                el-option(
                  v-for='car in unitType',
                  :key='car.key',
                  :label='car.value',
                  :value='car.key'
                ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(label='Имя авто:', prop='carname')
              el-input(
                v-model='form1.carname',
                type='text',
                :placeholder='$t("loginPage.sixCode")'
              ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            .flex.justify-end
              button.mt-4.bg-darkblue.text-white.hover-bg-reallydark(
                style='width: 180px; height: 34px; border-radius: 7px',
                @click.prevent='submitForm1'
              ) {{ $t('loginPage.addObject') }}
      //- END HAVE ACCOUNT 

      //- START DONT HAVE ACCOUNT 
    skifRadioOld(
      value='donthaveAccount',
      :label='$t("loginPage.addObject")',
      name='haveAccount',
      v-model='haveAccount',
      v-if='loginType === "demoLogin" || !isLoggedIn'
    )
      //- STEP 1 
    el-collapse-transition
      el-form.pb-6.px-4.w-full(
        label-width='230px',
        ref='stepone',
        :model='form2',
        :rules='rules',
        v-if='haveAccount === "donthaveAccount" && isFirstStep'
      )
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(:label='$t("loginPage.enterCode")', prop='code')
              el-input(
                v-model='form2.code',
                type='text',
                placeholder='------',
                :class='{ "input-error": errors.form2.isCodeError }'
              ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(label='Ваш телефон:', prop='phone')
              el-input(
                v-model='form2.phone',
                type='number',
                :placeholder='$t("loginPage.phoneHolder")',
                :class='{ "input-error": errors.form2.isPhoneError }'
              )
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(label=' Email:', prop='email')
              el-input(
                v-model='form2.email',
                type='text',
                :placeholder='$t("loginPage.mail")',
                :class='{ "input-error": errors.form2.isEmailError }'
              )
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(
              :label='$t("loginPage.password")',
              prop='password'
            )
              el-input(
                v-model='form2.password',
                type='password',
                show-password,
                :placeholder='$t("loginPage.yourpassword")',
                autocomplete='new-password',
                :class='{ "input-error": errors.form2.isPasswordError }'
              )
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(
              :label='$t("loginPage.autoType")',
              prop='typecar'
            )
              el-select(v-model='form2.typecar', type='number')
                el-option(
                  v-for='car in unitType',
                  :key='car.key',
                  :label='car.value',
                  :value='car.key'
                ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-1(
              :label='$t("loginPage.autoName")',
              prop='carname'
            )
              el-input(
                v-model='form2.carname',
                type='text',
                :placeholder='$t("loginPage.carName")'
              )
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(label='')
              skif-checkbox.mt-4.bl-(v-model='isAcceptTerms')
              span.ml-1 {{ $t('loginPage.accept') }}
                span.link(@click='getTerms') {{ $t('loginPage.offer') }}
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24') 
          .flex.justify-end
            button.mb-6.mt-4.bg-darkblue.text-white.hover-bg-reallydark(
              style='width: 260px; height: 32px; border-radius: 6px',
              @click.prevent='submitFirstStep'
            ) {{ $t('loginPage.register') }}

          //- STEP 2

      el-form.pb-6.px-4.w-full(
        label-width='230px',
        ref='steptwo',
        :model='form3',
        :rules='rules',
        v-if='haveAccount === "donthaveAccount" && !isFirstStep'
      )
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(
              :label='$t("loginPage.orgName")',
              prop='companyName'
            )
              el-input(
                v-model='form3.companyName',
                type='text',
                :placeholder='$t("loginPage.orgName.holder")'
              ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(label='Часовой пояс:', prop='timeZone')
              el-select(v-model='form3.timeZone', type='number')
                el-option(
                  v-for='(item, index) in tzList',
                  :key='item.key',
                  :label='item.value',
                  :value='item.key'
                ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24')
            el-form-item.mb-6(:label='$t("loginPage.name.label")', prop='name')
              el-input(
                v-model='form3.name',
                type='text',
                :placeholder='$t("loginPage.name")'
              ) 
        el-row(type='flex')
          el-col(:xs='16', :sm='18', :md='24', :lg='24', :xl='24') 
          .flex.justify-between
            button.cursor-pointer.mb-6.mt-4.text-darkblue.hover-bg-reallydark(
              style='width: 180px; height: 34px; border-radius: 7px',
              @click.prevent='isFirstStep = true'
            ) {{ $t('loginPage.back') }}
            button.cursor-pointer.mb-6.mt-4.bg-darkblue.text-white.hover-bg-reallydark(
              style='width: 180px; height: 34px; border-radius: 7px',
              @click.prevent='submitFinal'
            ) {{ $t('loginPage.register') }}
</template>

<script>
import { mapState } from 'vuex'
import { loginApi } from '@/api'

export default {
  name: 'qrcode',
  props: ['state', 'logedIn'],

  data() {
    return {
      isAcceptTerms: false,
      isFirstStep: true,
      isNotLogged: false,
      isReady: false,
      isLoggedIn: false,
      isManyCompanies: false,
      moreThanCompany: false,
      loginType: '',
      haveAccount: '',
      companies: [],
      form1: {
        code: '',
        typecar: '',
        carname: '',
        company: ''
      },
      form2: {
        code: '',
        typecar: '',
        carname: '',
        phone: '',
        email: '',
        password: ''
      },
      form3: {
        timeZone: '',
        name: '',
        companyName: ''
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t('loginPage.validation.sixCode'),
            trigger: 'change'
          },
          {
            min: 6,
            max: 6,
            message: this.$t('loginPage.validation.codeType'),

            trigger: 'change'
          }
        ],
        typecar: [
          {
            required: true,
            message: this.$t('loginPage.validation.choosecartype'),
            trigger: 'change'
          }
        ],
        carname: [
          {
            required: true,
            message: this.$t('loginPage.validation.entercarname'),
            trigger: 'change'
          }
        ],
        companyName: [
          {
            required: true,
            message: this.$t('loginPage.orgName'),
            trigger: 'change'
          }
        ],
        timeZone: [
          {
            required: true,
            message: this.$t('loginPage.validation.timezone'),
            trigger: 'change'
          }
        ],
        name: [
          {
            required: true,
            message: this.$t('loginPage.validation.name'),
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: this.$t('loginPage.validation.phone'),
            trigger: 'change'
          }
        ],
        email: [
          {
            type: 'string',
            required: true,
            message: this.$t('loginPage.validation.email'),
            trigger: ['blur', 'change']
            // pattern: /^[^@\s]+@[^@\s]+.[^@\s]+$/,
          },
          {
            type: 'email',
            message: this.$t('loginPage.validation.email.correctform'),
            trigger: ['blur', 'change']
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('loginPage.validation.email.correctform'),
            trigger: 'change'
          }
        ]
      },
      errors: {
        form1: {
          isCodeError: false
        },
        form2: {
          isCodeError: false,
          isPhoneError: false,
          isEmailError: false,
          isPasswordError: false
        }
      }
    }
  },

  computed: {
    ...mapState({
      tzList: (state) => state.properties.timezones,
      langList: (state) => state.properties.languages,
      unitType: (state) => state.properties.unitType
    }),
    ...mapState('login', {
      me: (state) => state.me,
      branding: (state) => state.branding
      // isLoggedDemo: (state) => state.isLoggedDemo,
    })
  },
  watch: {
    'form1.code': function () {
      this.errors.form1.isCodeError = false
    },

    haveAccount(val) {
      console.log('not logged in', val)
      if (
        (!this.isLoggedIn && val === 'haveAccount') ||
        (this.loginType === 'demoLogin' && val === 'haveAccount')
      ) {
        this.$router.push('/login')
        this.haveAccount = ''
      }
    }
  },
  methods: {
    getTerms() {
      if (!this.form2.code || this.form2.code.length !== 6) {
        this.$notify.error({
          duration: 5000,

          title: `${this.$t('error')} \u{1f62a}`,
          message: this.$t('loginPage.validation.enter6code')
        })
      } else {
        this.$store.dispatch('login/GET_TERMS_BY_QR', this.form2.code)
      }
    },
    submitForm1() {
      //  - if not logged in or demo version , send to LOGIN page
      if (this.isLoggedIn && this.loginType !== 'demoLogin') {
        this.$refs.haveAccountForm.validate((valid) => {
          if (valid) {
            console.log('submit!', this.form1)
            const formData = {
              new_user: false,
              code: this.form1.code,
              company: { id: this.form1.company },
              unit_name: this.form1.carname,
              unit_type: { key: this.form1.typecar }
            }
            loginApi.createUnitBox(
              formData,
              (response) => {
                console.log('success', response)
                this.$router.push('/')
              },
              (error) => {
                this.$notify.error({
                  duration: 5000,
                  title: `${this.$t('error')} \u{1f62a}`,
                  message: error.response.data.message || 'error'
                })
                const { data } = error.response
                if (data.field === 'code') {
                  this.errors.form1.isCodeError = true
                }
                console.log('error creating unit_box', error)
              }
            )
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        console.log('not logged in')
        this.$router.push('/login')
        this.haveAccount = ''
      }
    },
    submitFirstStep() {
      if (this.isAcceptTerms) {
        this.$refs.stepone.validate((valid) => {
          if (valid) {
            console.log('submit!', this.form2)
            this.isFirstStep = false
          } else {
            console.log('error submit!!', valid)
            return false
          }
        })
      } else {
        this.$notify.error({
          duration: 5000,
          title: `${this.$t('error')} \u{1f62a}`,
          message: this.$t('loginPage.validation.acceptTerms')
        })
      }
    },
    submitFinal() {
      this.$refs.steptwo.validate((valid) => {
        if (valid) {
          const formData = {
            new_user: true,
            code: this.form2.code,
            userProviderId: this.form2.email,
            type: 'EMAIL',
            company_name: this.form3.companyName,
            name: this.form3.name,
            phone: this.form2.phone,
            password: this.form2.password,
            unit_name: this.form2.carname,
            unit_type: { key: this.form2.typecar },
            timezone: { key: this.form3.timeZone }
          }

          loginApi.createUnitBox(
            formData,
            (response) => {
              console.log('success', response)
              this.$router.push('/')
            },
            (error) => {
              console.log(error.response.data)
              this.$notify.error({
                duration: 5000,
                title: `${this.$t('error')} \u{1f62a}`,
                message: error.response.data.message || 'error'
              })
              const { data } = error.response
              if (data.field === 'code') {
                this.errors.form2.isCodeError = true
                this.isFirstStep = true
              } else if (data.field === 'phone') {
                this.errors.form2.isPhoneError = true
                this.isFirstStep = true
              } else if (data.field === 'userProviderId') {
                this.errors.form2.isEmailError = true
                this.isFirstStep = true
              } else if (data.field === 'password') {
                this.errors.form2.isPasswordError = true
                this.isFirstStep = true
              }
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  },
  created() {
    loginApi.getMe(
      (response) => {
        console.log('logged in', response)
        this.isLoggedIn = true
        this.companies = response.data.companies
        if (this.companies.length > 1) {
          this.moreThanCompany = true
          this.isReady = true
        }
      },
      (error) => {
        console.log('error Maybe not logged in', error)
        this.isLoggedIn = false
        this.isReady = true
      }
    )

    this.$store.dispatch('login/ACTIVATE_QRCODE', 'active')
    this.$store.dispatch('properties/getAllProperties').then((response) => {
      // this.registerForm.tz = this.tzList[0].key
      // this.registerForm.lang = this.langList[1].key

      // Logintype : fullLogin / demoLogin
      this.loginType = localStorage.getItem('typeLogged')
      console.log(this.tzList)
    })
  }
}
</script>

<style lang="stylus" scoped>
.qr-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.termsOfUse {
  margin-top: 26px;
  font-size: 10px;
  line-height: 13px;
  padding: 0 41px;
  text-align: center;
  box-sizing: border-box;

  a {
    color: #5477A9;
  }
}

.el-select {
  width: 100%;
}

.link {
  color: #7892B8;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}
</style>
