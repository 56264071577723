export const getMarkerColor = (urlColor) => {
  const colors = {
    red: '#eb5757',
    green: '#1bb934',
    blue: '#1585d8',
    grey: '#808080bf',
    orange: '#e68a2f'
  }

  return colors[urlColor]
}
