import HTTP from '@/api/http'

const getUnitDrivers = function (unitId, responseHandler, errorHandler) {
  HTTP.post(
    '/units_drivers/list',
    {
      sortField: 'date_from',
      sortDesc: 'false',
      conditions: [
        {
          field: 'unit.id',
          value: unitId,
        },
      ],
    },
    {
      needAuth: true,
    }
  )
    .then(responseHandler)
    .catch(errorHandler)
}
const getUnitDriversFilter = function (
  formData,
  responseHandler,
  errorHandler
) {
  return HTTP.post(
    '/units_drivers/list',
    {
      sortField: 'date_from',
      sortDesc: 'false',
      conditions: [
        {
          field: 'unit.id',
          value: formData.unit,
        },
        {
          field: 'date_from',
          value: formData.date_from,
        },
        {
          field: 'date_to',
          value: formData.date_to,
        },
      ],
    },
    {
      needAuth: true,
    }
  )
    .then(responseHandler)
    .catch(errorHandler)
}
const createUnitDriver = function (formData, responseHandler, errorHandler) {
  HTTP.post('/units_drivers', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const createUnitDriverGroup = function (
  formData,
  responseHandler,
  errorHandler
) {
  HTTP.post('/units_drivers_bulk', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateUnitDriver = function (formData, responseHandler, errorHandler) {
  HTTP.put(`/units_drivers/${formData.id}`, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteUnitDrivers = function (ids, responseHandler, errorHandler) {
  HTTP.delete(`/units_drivers`, { data: { ids } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteUnitShift = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/units_drivers/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getUnitDrivers,
  createUnitDriver,
  updateUnitDriver,
  deleteUnitDrivers,
  getUnitDriversFilter,
  createUnitDriverGroup,
  deleteUnitShift
}
