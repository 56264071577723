import { render, staticRenderFns } from "./changePass.vue?vue&type=template&id=2cee9f44&scoped=true&lang=pug"
import script from "./changePass.vue?vue&type=script&lang=js"
export * from "./changePass.vue?vue&type=script&lang=js"
import style0 from "./changePass.vue?vue&type=style&index=0&id=2cee9f44&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cee9f44",
  null
  
)

export default component.exports