import { archiveApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  openEdit: false,
  isOpenArchiveModal: false,
  unit: null,
  archiveList: [],
  editableArchive: null,
  activeTab: null,
  unitsCount: []
}

const getters = {
  getOpenArchiveModal: (state) => state.isOpenArchiveModal,
  getArchiveList: (state) => state.archiveList,
  getUnit: (state) => state.unit,
  getOpenEdit: (state) => state.openEdit,
  getEditableArchive: (state) => state.editableArchive,
  getActiveTab: (state) => state.activeTab,
  getUnitsCount: (state) => state.unitsCount
}

const actions = {
  async getArchiveStates ({commit},data) {
    try {
      const response = await archiveApi.getArchiveStates(data.id)
      commit('setArchiveList', response.data)
    }catch (e){
      eventBus.$showError(e.response.data.message)
    }
  },
  async addNewArchiveState({commit}, data){
    try {
      const response = await archiveApi.addNewArchiveState(data)
      commit('setArchiveList', response.data)
      return response
    }catch (e){
      eventBus.$showError(e.response.data.message)
    }
  },
  async editArchiveState({commit}, data){
    try {
      const response = await archiveApi.editArchiveState(data)
      commit('setArchiveList', response.data)
      return true
    }catch (e){
      eventBus.$showError(e.response.data.message)
    }
  },
  async copyArchiveState({commit}, data){
    try {
      const response = await archiveApi.copyArchiveState(data)
      commit('setArchiveList', response.data)
    }catch (e){
      eventBus.$showError(e.response.data.message)
    }
  },
  async deleteArchiveState({commit}, data){
    try {
      const response = await archiveApi.deleteArchiveState(data)
      commit('setArchiveList', response.data)
    }catch (e){
      eventBus.$showError(e.response.data.message)
    }
  },
  async getUnitsStatesCount({commit}, data){
    try {
      const response = await archiveApi.getUnitsStatesCount(data)
      commit('setUnitsCount', response.data);
    }catch (e){
      eventBus.$showError(e.response.data.message)
    }
  },
}
const mutations = {
  setOpenArchiveModal (state,payload){
    state.isOpenArchiveModal = payload
  },
  setUnit(state, payload){
    state.unit = payload
  },
  setArchiveList(state,payload){
    payload.forEach((archive,i)=>{
      archive.isCurrent = !i
    })
    state.archiveList = payload
  },
  setOpenEdit(state,payload){
    state.openEdit = payload
  },
  setEditableArchive(state,payload){
    state.editableArchive = payload
  },
  setActiveTab(state,payload){
    state.activeTab = payload
  },
  setUnitsCount(state,payload){
    state.unitsCount = payload
  }
}
export default {
  namespaced: true,
  state: state,
  getters,
  actions,
  mutations
}
