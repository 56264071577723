import HTTP from '@/api/http'

const getMailings = function (responseHandler, errorHandler) {
  HTTP.post(
    '/mailings/list',
    {
      fields: ['id', 'name', 'last_run', 'is_active'],
    },
    { needAuth: true }
  )
    .then(responseHandler)
    .catch(errorHandler)
}

const getMailingById = function (id, responseHandler, errorHandler) {
  HTTP.get(`/mailings?ids=${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createMailing = function (formData, responseHandler, errorHandler) {
  HTTP.post('/mailings', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const toggleMailing = function (id, formData, responseHandler, errorHandler) {
  HTTP.patch('/mailings/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateMailing = function (id, formData, responseHandler, errorHandler) {
  HTTP.put('/mailings/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const deleteMailing = function (id, responseHandler, errorHandler) {
  HTTP.delete('/mailings', { data: { ids: [id] } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getMailings,
  createMailing,
  toggleMailing,
  getMailingById,
  updateMailing,
  deleteMailing,
}
