<template>
  <div>
    <div v-if="isNeedChangePassword" class="password-container">
      <header class="steps-container">
        <el-steps :active="activeStep">
          <el-step>
            <span class="step-title" slot="title">*Принять приглашение</span>
            <check-icon slot="icon" />
          </el-step>
          <el-step>
            <span class="step-title" slot="title">*Установить пароль</span>
            <Current-icon v-if="activeStep === 2" slot="icon" />
            <check-icon v-else slot="icon" />
          </el-step>
          <el-step>
            <span class="step-title" slot="title">Загрузить приложение</span>
            <Empty-icon v-if="activeStep !== 3" slot="icon" />
            <Current-icon v-else slot="icon" />
          </el-step>
        </el-steps>
      </header>
      <div v-if="mainStep === 1" class="content">
        <Success-icon />
        <span class="text">
          Вы успешно приняли приглашение, <br />теперь вам нужно установить
          пароль для входа в SKIF.PRO
        </span>
        <div class="form">
          <div>
            <CustomInput
              v-model="passwordForm.pass"
              :label="$t('loginPage.password')"
              :type="isTypePass ? 'password' : 'text'"
              :placeholder="'Введите пароль '"
              :isHint="true"
              :content="$t('loginPass.recovery.hint')"
              @isPasswordVisible="isTypePass = !isTypePass"
              @input="handlePassword"
              :error="passwordErrorMessage.length ? true : false"
            />
            <span class="errorMessage" v-if="passwordErrorMessage.length">{{
              passwordErrorMessage
            }}</span>
          </div>
          <div>
            <CustomInput
              class="mt-3"
              v-model="passwordForm.confirm"
              :label="'Пароль ещё раз:'"
              :type="isTypePassConfirm ? 'password' : 'text'"
              :placeholder="'Введите пароль '"
              :isHint="true"
              :content="$t('loginPass.recovery.hint')"
              @isPasswordVisible="isTypePassConfirm = !isTypePassConfirm"
              @input="handlePasswordConfirm"
              :error="passwordConfrimErrorMessage.length ? true : false"
            />
            <span v-if="passwordConfrimErrorMessage.length" class="errorMessage"
              >{{ passwordConfrimErrorMessage }}
            </span>
          </div>
        </div>
        <div class="footer">
          <button @click="save">{{ $t('save') }}</button>
        </div>
      </div>
      <div v-else class="content">
        <AppDownload :isNew="true" @open-mobile-version="openMobileVersion" @downloadapp="downloadApp" />
      </div>
    </div>
    <!-- ------------------------------------------ download container  -->
    <div v-else class="password-container">
      <div class="content">
        <AppDownload  @open-mobile-version="openMobileVersion" @downloadapp="downloadApp" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MonitorSvg from '@/assets/icons/MonitorIcon.vue'
import ArrowScreen from '@/assets/icons/ArrowMainScreenIcon.vue'
import CheckIcon from './assets/steps/CheckIcon.vue'
import CurrentIcon from './assets/steps/CurrentIcon.vue'
import EmptyIcon from './assets/steps/EmptyIcon.vue'
import SuccessIcon from './assets/SuccessIcon.vue'
import CustomInput from './components/Custom-input.vue'
import AppDownload from './components/App-download.vue'

import { loginApi } from '@/api'

export default {
  name: 'MobileSecuritySystem',
  components: {
    MonitorSvg,
    ArrowScreen,
    CheckIcon,
    CurrentIcon,
    EmptyIcon,
    SuccessIcon,
    CustomInput,
    AppDownload
  },
  data() {
    return {
      mainStep: 1,
      activeStep: 2,
      passwordForm: {
        pass: '',
        confirm: ''
      },
      isTypePass: true,
      isTypePassConfirm: true,
      passwordErrorMessage: '',
      passwordConfrimErrorMessage: '',
      highRoles: ['SUPERVISOR', 'ADMIN', 'TARIFF_EDITOR', 'CONTROLLER']
    }
  },
  computed: {
    ...mapState('login', {
      isNeedChangePassword: (state) => state.isNeedChangePassword,
      me: (state) => state.me
    })
  },

  methods: {
    openMobileVersion() {
      this.$emit('open-mobile-version')
    },
    downloadApp() {
      this.$store.commit('login/SET_CHANGE_PASSWORD', false)
      window.open('https://skifpro.page.link/76UZ', '_blank')
    },
    save() {
      const formData = {}
      formData.newPassword = this.passwordForm.pass
      if (
        !this.passwordErrorMessage.length &&
        !this.passwordConfrimErrorMessage.length
      ) {
        loginApi.changeMyPassword(
          formData,
          () => {
            // this.$emit('close')
            this.activeStep = 3
            this.mainStep = 2
          },
          (error) => {
            this.passwordErrorMessage = error.response.data.message
          }
        )
      }
    },
    handlePasswordConfirm(val) {
      if (val !== this.passwordForm.pass) {
        this.passwordConfrimErrorMessage = 'Пароли должны совпадать'
      } else {
        this.passwordConfrimErrorMessage = ''
      }
    },
    handlePassword(val) {
      let isHighRole

      if (this.highRoles.includes(this.role)) {
        isHighRole = true
      }

      const strongPasswordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()\-+=\[\]{}|;:',"<.>\/?]).{15,25}$/

      const adminPasswordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()\-+=\[\]{}|;:',"<.>\/?]).{8,25}$/

      const standardPasswordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{5,25}$/

      if (this.isStrongPassword && isHighRole) {
        if (!strongPasswordRegex.test(val)) {
          this.passwordErrorMessage = `Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' " , < . > / ?)`
        } else {
          this.passwordErrorMessage = ''
        }
      } else if (this.isStrongPassword) {
        if (!adminPasswordRegex.test(val)) {
          this.passwordErrorMessage = `Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' " , < . > / ?)`
        } else {
          this.passwordErrorMessage = ''
        }
      } else {
        this.passwordErrorMessage = standardPasswordRegex.test(val)
          ? ''
          : `Пароль должен состоять от 5 до 25 символов и состоять как минимум из одной английской буквы и как минимум одной цифры`
      }
    }
  },
  mounted() {
    this.isStrongPassword = this.me.active_company.strong_password
    this.role = this.me.role.key
  }
}
</script>
<style lang="scss">
.is-finish {
  color: #22ad6a !important;
  border-color: #22ad6a !important;
}
.el-step__head {
  margin-left: 18px;
}
.el-step__icon.is-text {
  border: none !important;
  background: #f6f7fa;
}
.el-step__line {
  margin-left: 30px !important;
  margin-right: 6px !important;
}
.password-container {
  font-family: Source Sans Pro;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;

  .steps-container {
    border-bottom: 1px solid #edeff3;
    background: #f6f7fa;
    width: 100vw;
    height: 87px;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    .step-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      width: 60px;
      display: inline-block;
      margin-top: 4px;
      text-align: center;
    }
  }
  .content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 35px 0;
    .text {
      color: #292929;
      padding: 15px;
      padding-bottom: 2px;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
    .form {
      background-color: #fff;
      width: 360px;
      border-radius: 12px;
      padding: 20px;
      text-align: left;
      .errorMessage {
        font-size: 12px;
        color: #c93131;
      }
    }
  }
}
.download-container {
  width: 100%;
  height: 100vh;
  .header-top {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    &__text {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #292929;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__link {
      background-color: #2767b6;
      display: inline-block;
      margin-top: 20px;
      color: #fff;
      padding: 12px 35px;
      border-radius: 10px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
  }
}
.download-container {
  @media (min-width: 320px) and (max-width: 735px) {
    background: linear-gradient(
      180deg,
      #ffffff 31.77%,
      #9abce4 77.08%,
      #1567c7 100%
    );
  }
}
.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 89%;
    background: #3a7cca;
    padding: 10px;
    border-radius: 12px;
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
  }
}
</style>
