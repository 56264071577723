import Vue from 'vue';

/**
 * <div v-click-outside="closeDropdownMenu">...</div>
 */
export default Vue.directive('click-outside', {
    bind(element, binding) {
        const { value: handler } = binding;

        if (typeof handler !== 'function') return;

        element.clickOutsideHandler = event => {
            event.stopPropagation();

            if (event.target === element || element.contains(event.target)) {
                return;
            }

            handler();
        };

        document.addEventListener('mousedown', element?.clickOutsideHandler, false);
    },

    unbind(element) {
        if (element?.clickOutsideHandler) {
            document.removeEventListener('mousedown', element.clickOutsideHandler, false);
        }
    }
});
