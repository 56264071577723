import { locatorsApi } from '@/api'
import {
  sortHelper
} from '@/components/unitsNew/helpers/index.js'
import eventBus from '@/eventBus.js'

const state = {
  locators: [],
  recoveryId: null,
  searchedLinks: [],
  selectedLink: null,
  immutableLinks: []
}
const getters = {
  locatorById: (state) => (id) => {
    return state.locators.find((locators) => locators.id === id)
  },
  locators: state => state.locators,
  searchedLinks: state => state.searchedLinks,
  selectedLink: state => state.selectedLink,
  immutableLinks: state => state.immutableLinks
}
const actions = {
  async websocketLocators({ state, dispatch, commit }, webSocket) {
    if (webSocket.operation === 'DELETE') {
      commit('DELETELOCATORSOCKET', webSocket.id)
    } else {
      await locatorsApi.getLocator(
        webSocket.id,
        (response) => {
          let locator = JSON.parse(JSON.stringify(response.data))
          if (webSocket.operation === 'POST') {
            const hasLocator = state.locators.some(
              (el) => el.id === locator.id
            )
            if (!hasLocator) {
              commit('ADD', locator)
            }
          } else {
            commit('UPDATE', locator)
          }
        },
        (error) => {
          this.$showError(error.response.data.message)
        }
      )
    }
  },
  GET({ commit }) {
    locatorsApi.getLocators(
      (response) => {
        const { list } = response.data

        commit('SET', list)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  async GET_LOCATOR({ commit }, id) {
    await locatorsApi.getLocator(
      id,
      (response) => {
        const { data } = response

        commit('SET_LOCATOR', data)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  RECOVER_LOCATORS({ commit }, data) {
    return new Promise((resolve, reject) => {
      locatorsApi.recover(
        data.id,
        (response) => {
          commit('RECOVER', data)
          resolve()
        },
        (error) => {
          console.log(error)
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  CREATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      locatorsApi.createLocator(
        formData,
        (response) => {
          commit('ADD', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      locatorsApi.updateLocator(
        formData,
        (response) => {
          commit('UPDATE', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE({ commit }, locatorIds) {
    let promiseList = []
    promiseList.push(
      new Promise((resolve, reject) =>
        locatorsApi.deleteLocator(locatorIds, resolve, reject)
      )
    )
    Promise.all(promiseList)
      .then((res) => {
        commit('DELETE', locatorIds)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch((error) => {
        console.log(error)
        eventBus.$showError(error.response.data.message)
      })
  },
}

const mutations = {
  SET(state, locators) {
    const indexedItems = locators.map((item, index) => ({
      index,
      ...item,
    }))
    state.locators = indexedItems
    state.immutableLinks = indexedItems.slice()
  },
  SET_LOCATOR(state, locator) {
    console.log('locator', locator);
    state.selectedLink = locator
  },
  ADD(state, locator) {
    if (!state.locators.some((x) => x.id === locator.id)) {
      state.locators = [...state.locators, locator]
    }
  },
  UPDATE(state, locator) {
    const indexOfLocator = state.locators.findIndex(
      (elem) => elem.id === locator.id
    )
    state.locators = [
      ...state.locators.slice(0, indexOfLocator),
      locator,
      ...state.locators.slice(indexOfLocator + 1),
    ]
    
    const indexOfImmutableLocator = state.immutableLinks.findIndex(
      (elem) => elem.id === locator.id
    )
    
    state.immutableLinks = [
      ...state.immutableLinks.slice(0, indexOfImmutableLocator),
      locator,
      ...state.immutableLinks.slice(indexOfImmutableLocator + 1),
    ]
  },
  DELETE(state, ids) {
    state.locators = state.locators.filter((item) => !ids.includes(item.id))
    state.immutableLinks = state.immutableLinks.filter((item) => !ids.includes(item.id))
  },
  DELETELOCATORSOCKET(state, id) {
    state.locators = state.locators.filter((item) => item.id !== id)
  },
  SET_RECOVERYID(state, id) {
    state.recoveryId = id
  },
  RECOVER(state, units) {
    units.type === 'locators'
      ? units.itemsDeleted.forEach((unit) =>
          state.locators.splice(unit.index, 0, unit)
        )
      : console.log('notLocators')
  },
  SET_LINKS_SEARCHED(state, links) {
    state.searchedLinks = links
  },
}
export default {
  namespaced: true,
  state: state,
  getters,
  actions,
  mutations,
}
