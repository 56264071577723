import { mapGetters, mapMutations, mapState } from 'vuex'
import createMarker from '@/mixins/createMarker.js'

export default {
  mixins: [createMarker],
  props: {
    markerCluster: {
      type: Object,
      default: () => {}
    },
    markerGroupUnits: { type: Object, default: () => {} }
  },
  data() {
    return {
      // флаг показывать группы или нет
      isActiveGroup: false,
      groupsList: [],
      manuallyActivatedUnits: [],
      isActiveAllEye: false,
      isActiveAllArrow: false,
      isGroupingObjectsStorage: false
    }
  },
  computed: {
    ...mapGetters({
      vuexActiveEye: 'monitoringNew/vuexActiveEye',
      vuexMarkersUnitOnMap: 'monitoringNew/vuexMarkersUnitOnMap',
      vuexActiveArrow: 'monitoringNew/vuexActiveArrow',
      vuexIsActiveAllEye: 'monitoringNew/vuexIsActiveAllEye',
      vuexActiveGroupEye: 'monitoringNew/vuexActiveGroupEye',
      vuexIsActiveAllArrow: 'monitoringNew/vuexIsActiveAllArrow',
      vuexActiveGroupArrow: 'monitoringNew/vuexActiveGroupArrow'
    }),
    ...mapState('login', {
      me: (state) => state.me
    })
  },
  methods: {
    ...mapMutations({
      addVuexMarkersUnitOnMap: 'monitoringNew/addVuexMarkersUnitOnMap',
      addVuexMarkersOnMap: 'monitoringNew/addVuexMarkersOnMap',
      addVuexActiveEye: 'monitoringNew/addVuexActiveEye',
      setVuexIsActiveAllEye: 'monitoringNew/setVuexIsActiveAllEye',
      addVuexActiveGroupEye: 'monitoringNew/addVuexActiveGroupEye',
      deleteVuexActiveArrow: 'monitoringNew/deleteVuexActiveArrow',
      setVuexIsActiveAllArrow: 'monitoringNew/setVuexIsActiveAllArrow',
      deleteVuexMarkersOnMap: 'monitoringNew/deleteVuexMarkersOnMap',
      deleteVuexMarkerUnitOnMap: 'monitoringNew/deleteVuexMarkerUnitOnMap',
      deleteVuexActiveEye: 'monitoringNew/deleteVuexActiveEye',
      deleteVuexActiveGroupEye: 'monitoringNew/deleteVuexActiveGroupEye',
      deleteVuexActiveGroupArrow: 'monitoringNew/deleteVuexActiveGroupArrow',

      addVuexActiveArrow: 'monitoringNew/addVuexActiveArrow',
      addVuexActiveGroupArrow: 'monitoringNew/addVuexActiveGroupArrow'
    }),

    // когда кликаем на глазик
    // обьект, событие клика,
    clickEyeObj(item, event, groupActiveEye, group) {
      const isGroupingObjectsStorage = JSON.parse(
        localStorage.getItem('groupingObjects')
      )

      if (event) event.stopPropagation()

      if (!item.latlon || !item.latlon.value) return

      if (!this.vuexActiveEye.includes(item.id)) {
        if (this.isActiveGroup && groupActiveEye === false) return

        // добавим обьект в стор
        this.addVuexActiveEye(item.id)
        // добавим обьект в виде мапы в стор
        this.addVuexMarkersOnMap({ id: item.id, unit: item })
        // добавим обьект маркер для карты в стор
        this.addVuexMarkersUnitOnMap({
          id: item.id,
          unit: this.helperGetMarker(item, item.id)
        })
        if (
          this.vuexMarkersUnitOnMap[item.id] &&
          !this.markerGroupUnits.hasLayer(this.vuexMarkersUnitOnMap[item.id])
        ) {
          // verify to the grouping in cluster first . if false then we dont need to use cluster
          if (isGroupingObjectsStorage) {
            // добавим в маркер кластер слой (обьект из стора)
            // this.markerCluster.addLayer(this.vuexMarkersUnitOnMap[item.id])
            console.log('here');
            // this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
          } else {
            console.log('here2');
            // markerGroupUnits is just a leaflet normal group (NOT CLUSTER)
            this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
          }
        }
        // если одинаковая длина обьектов в сторе и столько же обьектов на карте
        // то активируем общий глазик
        const activeObjectList = this.monitoringList.filter(
          (el) => el.status >= 2
        )

        if (
          this.vuexActiveEye.length === activeObjectList &&
          Object.keys(this.vuexMarkersUnitOnMap).length === activeObjectList &&
          !this.isActiveGroup
        ) {
          this.setVuexIsActiveAllEye(true)
        }
        // если кликнули на обьект в группе
        if (group) {
          this.groupsList.forEach((group) => {
            if (!group.units.length) return
            const unitsWithData = group.units.filter((el) => el.latlon)

            if (unitsWithData.length) {
              const isAllActive = unitsWithData.every((el) =>
                this.vuexActiveEye.includes(el.id)
              )

              // if (isAllActive) this.addVuexActiveGroupEye(group.group_id)
            }
          })
        }

        // сохраним все в локал сторадж
        this.helperSaveLocalStorageNew()
      } else {
        if (this.isActiveGroup && groupActiveEye) return
        // если обьект есть - удалим
        if (this.vuexActiveArrow.includes(item.id)) {
          this.deleteVuexActiveArrow(item)
        }
        // если общий глазик активный
        if (this.vuexIsActiveAllEye) {
          this.setVuexIsActiveAllEye(false)
          this.setVuexIsActiveAllArrow(false)
        }
        // удаляем слой с обьектом с карты
        if (this.vuexMarkersUnitOnMap[item.id]) {
          const layers = this.markerGroupUnits.getLayers()
          if (layers.length) {
            this.markerGroupUnits.removeLayer(this.vuexMarkersUnitOnMap[item.id])
          }
        }

        this.deleteVuexMarkersOnMap(item)
        this.deleteVuexMarkerUnitOnMap(item)
        this.deleteVuexActiveEye(item)
        this.manuallyActivatedUnits = this.manuallyActivatedUnits.filter(
          (id) => item.id !== id
        )
        // если кликнули на акьтвный обьект в группе
        if (group) {
          this.deleteVuexActiveGroupEye(group)
          this.deleteVuexActiveGroupArrow(group)
          this.vuexActiveGroupEye.forEach((groupId) => {
            this.groupsList.forEach((group) => {
              if (!group.units.length) return
              if (group.group_id === groupId) {
                const unitsWithData = group.units.filter((el) => el.latlon)
                const isAllActive = unitsWithData.every((el) =>
                  this.vuexActiveEye.includes(el.id)
                )
                // if (!isAllActive) this.deleteVuexActiveGroupEye(group)
              }
            })
          })
        }
        this.helperSaveLocalStorageNew()
      }
    },

    // когда кликаем на стрелочку
    // важный момент - когда кликнули на стрелочку надо чтобы закрасиллся и глазик
    clickArrowObj(item, event, groupActiveArrow, group) {
      if (event) event.stopPropagation()
      const isGroupingObjectsStorage = JSON.parse(
        localStorage.getItem('groupingObjects')
      )
      if (!item.latlon) return

      // проверяем есть ли стрелочка в сторе
      if (!this.vuexActiveArrow.includes(item.id)) {
        if (this.isActiveGroup && groupActiveArrow === false) return

        // событие для активной цели на карте (проверить)
        this.$emit('monitoring-manipulation', true)
        // добавляем стрелочку в стор
        this.addVuexActiveArrow(item.id)
        // если не глазика с таким же id - добавляем
        if (!this.vuexActiveEye.includes(item.id)) {
          this.addVuexActiveEye(item.id)
          // добавляем на карту и обьект маркер
          this.addVuexMarkersOnMap({ id: item.id, unit: item })
          this.addVuexMarkersUnitOnMap({
            id: item.id,
            unit: this.helperGetMarker(item, item.id)
          })
          // verify to the grouping in cluster first . if false then we dont need to use cluster
          if (isGroupingObjectsStorage) {
            // добавим в маркер кластер слой (обьект из стора)
            this.markerCluster.addLayer(this.vuexMarkersUnitOnMap[item.id])
          } else {
            // markerGroupUnits is just a leaflet normal group (NOT CLUSTER)
            this.markerGroupUnits.addLayer(this.vuexMarkersUnitOnMap[item.id])
          }
          // добавляем слой в кластер
        }

        // если кол-во стрелочек в сторе === кол-ву маркеров на карте
        // закрашиваем общую стрелочку
        const activeObjectList = this.monitoringList.filter(
          (el) => el.status >= 2
        )
        if (
          this.vuexActiveArrow.length === activeObjectList.length &&
          Object.keys(this.vuexMarkersUnitOnMap).length ===
            activeObjectList.length &&
          !this.isActiveGroup
        ) {
          this.setVuexIsActiveAllArrow(true)
        }

        if (group) {
          this.groupsList.forEach((group) => {
            if (!group.units.length) return
            const unitsWithData = group.units.filter((el) => el.latlon)
            if (unitsWithData.length) {
              const isAllActive = unitsWithData.every((el) =>
                this.vuexActiveArrow.includes(el.id)
              )
              if (isAllActive) {
                // this.addVuexActiveGroupEye(group.group_id)
                // this.addVuexActiveGroupArrow(group.group_id)
              }
            }
          })
        }
        this.helperSaveLocalStorageNew()
      } else {
        // если есть стрелочка то удаляем
        if (this.isActiveGroup && groupActiveArrow) return

        if (this.vuexIsActiveAllArrow) {
          this.setVuexIsActiveAllArrow(false)
          this.saveLocalStorageIsActiveAllIcon('isActiveAllArrow', false)
        }
        this.deleteVuexActiveArrow(item)

        if (group) {
          this.deleteVuexActiveGroupArrow(group)

          this.vuexActiveGroupArrow.forEach((groupId) => {
            this.groupsList.forEach((group) => {
              if (!group.units.length) return

              if (group.group_id === groupId) {
                const unitsWithData = group.units.filter((el) => el.latlon)
                const isAllActive = unitsWithData.every((el) =>
                  this.vuexActiveArrow.includes(el.id)
                )

                // if (!isAllActive) this.deleteVuexActiveGroupArrow(group)
              }
            })
          })
        }
        this.helperSaveLocalStorageNew()
      }
    },

    helperSaveLocalStorageNew() {
      let activeCompanies = localStorage.getItem('activeCompanies') || '[]'
      activeCompanies = JSON.parse(activeCompanies)

      const companyIndex = activeCompanies.findIndex((companyObj) => {
        return companyObj.hasOwnProperty(this.me.active_company_id)
      })

      if (companyIndex !== -1) {
        // Компания уже существует в массиве, обновляем ее данные
        const existingCompany = activeCompanies[companyIndex]
        // Глазики/Стрелочки
        existingCompany[this.me.active_company_id].itemsActiveEye =
          this.vuexActiveEye
        existingCompany[this.me.active_company_id].itemsActiveArrow =
          this.vuexActiveArrow

        existingCompany[this.me.active_company_id].isActiveAllEye =
          this.vuexIsActiveAllEye
        existingCompany[this.me.active_company_id].isActiveAllArrow =
          this.vuexIsActiveAllArrow
        existingCompany[this.me.active_company_id].activeGroupEye =
          this.vuexActiveGroupEye
        existingCompany[this.me.active_company_id].activeGroupArrow =
          this.vuexActiveGroupArrow

        // Сортировки
        existingCompany[this.me.active_company_id].sortOrder =
          this.vuexSortOrder
        existingCompany[this.me.active_company_id].sortName = this.vuexSortName
      } else {
        // Компания не существует в массиве, добавляем новую
        const newCompany = {
          [this.me.active_company_id]: {
            itemsActiveEye: this.vuexActiveEye,
            itemsActiveArrow: this.vuexActiveArrow,
            isActiveAllEye: this.vuexIsActiveAllEye,
            isActiveAllArrow: this.isActiveAllArrow,
            activeGroupArrow: this.vuexActiveGroupArrow,
            activeGroupEye: this.vuexActiveGroupEye,
            sortOrder: this.vuexSortOrder,
            sortName: this.vuexSortName
          }
        }
        activeCompanies.push(newCompany)
      }
      localStorage.setItem('activeCompanies', JSON.stringify(activeCompanies))
    },

    saveLocalStorageIsActiveAllIcon(storageName, item) {
      localStorage.setItem(storageName, item)
    }
  },
  mounted() {
    const isGroupingObjectsStorage = localStorage.getItem(
      'isGroupingObjectsStorage'
    )
    this.isGroupingObjectsStorage = JSON.parse(isGroupingObjectsStorage)
  }
}
