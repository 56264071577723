<template lang="pug">
label.cursor-pointer.inline-block.relative.whitespace-no-wrap(
  style='color: #606266; font-size: 14px',
  :class='{ "cursor-not-allowed": disabled}'
)
  span.relative.inline-block.whitespace-no-wrap.align-middle.leading-none
    input.hidden(
      type='checkbox',
      @change='$emit("change", $event)',
      v-model='model',
      :value='label',
      :disabled='disabled'
    )
    span.h-3.w-3.border.inline-block.skif-checkbox.outline-none(
      :class='[{"is-indeterminate": indeterminate,"border-notify": error,"--error": error },isChecked ? "bg-darkblue border-darkblue" : "border-switch"]',:style='disabled ? disabledStyle : ""'
    )
  span.select-none.pl-2.font-bold
    .skif-checkbox-label.inline-block.align-middle.transition-colors.duration-300.mb-1(
    v-if='label && showLabel',
    style="font-size: 14px; line-height: 19px;"
    :class='[isChecked ? "text-darkblue" : "text-annotationColor", "ml-"+labelMargin]'
  ) {{ label }}
</template>

<script>
export default {
  props: {
    indeterminate: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: [String, Number, Object],
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    labelMargin: {
      type: String,
      default: '0'
    }
  },
  data() {
    return {
      disabledStyle: {
        'background-color': '#f2f6fc',
        'border-color': '#dcdfe6'
      }
    }
  },
  computed: {
    model: {
      get() {
        return this.value || this.indeterminate
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isChecked() {
      if (Array.isArray(this.model)) {
        return this.model.indexOf(this.label) > -1
      }
      return this.model
    }
  }
}
</script>
<style lang="stylus" scoped>
.skif-checkbox {
  border-radius: 2px;
  transition: all linear 0.3s;

  &.--error {
    border: 1px solid #EB5757;
  }

  &:not(.is-indeterminate):after {
    content: url('data:image/svg+xml; utf8, %3Csvg%20width%3D%228%22%20height%3D%226%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%203l2%202%204-4%22%20stroke%3D%22%23fff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
    position: absolute;
    top: -3px;
    left: 2px;
    visibility: hidden;
  }

  &.is-indeterminate:before {
    content: '';
    position: absolute;
    display: block;
    background-color: #FFF;
    height: 4px;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    left: 0;
    right: 0;
    top: 4px;
    border-radius: 2px;
    visibility: hidden;
  }
}

input {
  &:checked ~ .skif-checkbox {
    &:after {
      visibility: visible;
    }

    &.is-indeterminate:before {
      visibility: visible;
    }
  }
}
</style>
