import HTTP from '@/api/http'

export const getWebVersion = async function (responseHandler, errorHandler) {
  try {
    const response= await HTTP.get('/web_version')
    responseHandler(response);
  } catch(e) {
    errorHandler(e);
  }
}

