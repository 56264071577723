<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="9.5" fill="#F6F7FA" stroke="#828D9B" />
  </svg>
</template>
<script>
export default {
  name: 'EmptyIcon'
}
</script>
