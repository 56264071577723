<template lang="pug">
.mx-auto.shadow.p-6(style='width: 428px')
  span.col-start-1.col-end-2.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("loginPage.recovery.title") }}
  .flex.flex-col.relative(v-if='$route.name === "recovery"')
    span.text-annotationColor.font-SourceSansPro.font-semibold {{ $t("loginPage.email") }}
    .relative
      el-input(
        v-model='recoveryForm.user_provider_id',
        :class='{ "input-error": isEmailError }'
      )
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-full.left-0.absolute(v-if='isEmailError') {{ $t("loginPage.errors.emailError") }}
    .flex.justify-between
      skif-button.mt-6(@click='submitForm') {{ $t("loginPage.btn.send") }}
      skif-button.mt-6(variant='normal', @click='$router.push("/login")') {{ $t("loginPass.recovery.back") }}
  .flex.flex-col(v-else)
    p {{ $t("loginPage.recovery.text1") }}
    p {{ $t("loginPage.recovery.text2") }}
    skif-button.my-4(@click='$router.push("/")') {{ $t("loginPage.confirm.submit") }}
</template>

<script>
export default {
  name: 'App',
  props: ['state, logedIn'],
  data() {
    return {
      emailRegExp:
        /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(?:aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ru|[a-z][a-z])$/,
      recoveryForm: {
        user_provider_id: '',
        type: 'EMAIL'
      },
      isEmailError: false
    }
  },
  watch: {
    'recoveryForm.user_provider_id': function (val) {
      this.isEmailError = val === ''
    }
  },
  methods: {
    valid() {
      const email = this.recoveryForm.user_provider_id
      if (email === '' || !this.emailRegExp.test(email.toLowerCase())) {
        this.isEmailError = email === '' || !this.emailRegExp.test(email)
        return false
      }
      return true
    },
    submitForm() {
      if (this.valid()) {
        this.$store.dispatch('login/recovery', this.recoveryForm)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
p {
  margin: 0;
  font-size: 12px;
  line-height: 19px;
  color: #5477A9;
  white-space: nowrap;
}

.rec2 {
  margin: 26px 0;
}
</style>
