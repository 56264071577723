<template>
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="26" cy="26" r="26" fill="url(#paint0_radial_13063_14950)" />
    <path
      d="M15 25.5L24 34.5L39.5 19"
      stroke="#0C7028"
      stroke-width="2"
      stroke-linecap="round"
    />
    <defs>
      <radialGradient
        id="paint0_radial_13063_14950"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(26 26) rotate(90) scale(26)"
      >
        <stop stop-color="#99F7B3" />
        <stop offset="1" stop-color="#D4FBDF" />
      </radialGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'SuccessIcon'
}
</script>
