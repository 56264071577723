import HTTP from '@/api/http'

const sendFeedback = function(formData, responseHandler, errorHandler) {
  HTTP.post('/feedback', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getFeedbackList = function(formData, responseHandler, errorHandler) {
  HTTP.post('/feedback/list', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  sendFeedback,
  getFeedbackList
}
