import { ref } from 'vue'

const basicValidation = ref(null)
const shiftsValidation = ref(null)
const sensorsValidation = ref(null)
const couplingsValidation = ref(null)
const serviceValidation = ref(null)
const parametersValidation = ref(null)
const trailersValidation = ref(null)
const trailersFieldsValidation = ref(null)
const groupsValidation = ref(null)
const linksValidation = ref(null)
const sensorTechParametersValidation = ref(null)

export default {
  basicValidation,
  shiftsValidation,
  sensorsValidation,
  couplingsValidation,
  serviceValidation,
  parametersValidation,
  trailersValidation,
  trailersFieldsValidation,
  groupsValidation,
  linksValidation,
  sensorTechParametersValidation
}
