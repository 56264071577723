<template>
  <div class="flex relative h-screen">
    <div style="width: 22%" class="left-container relative pt-0 pb-8">
      <div v-if="branding.is_powered" class="powered-by w-full">
        <h4>
          <a
            class="link"
            href="https://skif.pro"
            target="_blank"
            rel="noopener noreferrer"
          >
            Powered by Skif.pro
          </a>
        </h4>
      </div>

      <div class="content w-full grid grid-cols-1 grid-rows-3">
        <div class="logo flex justify-center items-center">
          <img
            @click="redirectToLink"
            :src="` /api_v1/${branding.logo}`"
            alt="Logo"
            class="w-1/2 cursor-pointer"
          />
        </div>
        <!-- Login -->
        <div v-if="isLogin" class="flex flex-col items-center justify-center">
          <h2 class="text-2xl font-bold mb-4 title">
            {{ $t('loginPage.login.title') }}
          </h2>
          <div class="mb-4 flex flex-col items-center justify-center px-4">
            <Custom-input
              :label="$t('loginPage.login') + ':'"
              v-model="loginForm.email"
              type="text"
              :placeholder="'Введите e-mail'"
              :errorMessage="$t('loginPage.errors.emailError')"
              :error="isEmailError"
            />
          </div>
          <div class="mb-4 flex flex-col items-center justify-center px-4">
            <div class="container">
              <Custom-input
                :label="$t('loginPage.pass') + ':'"
                v-model="loginForm.pass"
                :type="isTypePass ? 'password' : 'text'"
                :placeholder="$t('loginPass.errors.pass')"
                :errorMessage="$t('loginPage.errors.pass')"
                :error="isPasswordError"
                @isPasswordVisible="isTypePass = !isTypePass"
              />
              <p class="ml-2 text-xs hint">
                {{ $t('loginPage.passHint') }}
              </p>
            </div>
          </div>
          <!-- <div
            class="mb-4 flex flex-col items-start justify-center px-16 w-full"
          >
            <label class="flex items-center justify-start text-sm remember-me">
              <input type="checkbox" class="mr-2 ml-1" />
              Запомнить меня на этом устройстве
            </label>
          </div> -->
          <div class="flex flex-col items-center justify-center">
            <button @click="submitForm" class="w-full login">
              {{ $t('loginPage.login.submit') }}
            </button>
            <button @click="guestLogin" class="w-full demo-login">
              {{ $t('loginPage.login.guest') }}
            </button>
          </div>
        </div>
        <div
          v-else-if="!isLogin && isRecovery"
          class="flex flex-col items-center justify-center"
        >
          <div class="mb-4 flex flex-col items-center justify-center px-4">
            <h2 class="text-2xl font-bold mb-4 title">
              {{ $t('loginPage.recovery.title') }}
            </h2>
            <Custom-input
              v-model="loginForm.email"
              :label="$t('loginPage.login') + ':'"
              type="text"
              :placeholder="'Введите e-mail'"
              :errorMessage="$t('loginPage.errors.emailError')"
              :error="isEmailError"
              :isHint="true"
              :content="$t('loginPass.recovery.hint')"
            />
          </div>
          <button @click="recover" class="w-full login">Отправить</button>
        </div>
        <div class="flex flex-col items-center justify-center mb-4" v-else>
          <div class="font-bold mb-4 text-center">
            <p>
              {{ $t('loginPass.recovery.hint') }}

              <span class="mb-4">({{ loginForm.email }})</span>
            </p>
          </div>
          <div class="mt-4"></div>
          <button @click="goToMain" class="w-full login">
            {{ $t('loginPage.recovery.toMain') }}
          </button>
        </div>

        <!-- footer  -->
        <div v-if="isLogin" class="w-full flex justify-center items-end mb-8">
          <p @click="handleLogin" class="forget-password-btn">
            {{ $t('loginPage.links.lostPass') }}
          </p>
        </div>
        <div
          v-else-if="!isLogin && isRecovery"
          class="w-full flex justify-center items-end mb-8"
        >
          <p @click="isLogin = true" class="forget-password-btn">
            <span>
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_10533_388)">
                  <path
                    d="M20.4425 13.5L6.5575 13.5M6.5575 13.5L13.5 20.4425M6.5575 13.5L13.5 6.5575"
                    stroke="#3A7CCA"
                    stroke-width="1.5"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_10533_388">
                    <rect
                      width="18"
                      height="18"
                      fill="white"
                      transform="translate(13.5 26.2279) rotate(-135)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            {{ $t('loginPage.back') }}
          </p>
        </div>
      </div>
    </div>

    <!-- Right side - Cover Image -->
    <div class="right-container">
      <img
        v-if="branding.background"
        :src="` /api_v1/${branding.background}`"
        alt="Cover Image"
        class="w-full h-full object-cover"
      />
    </div>
    <div
      v-if="branding.contacts"
      class="contacts absolute right-0 bottom-0 m-8 bg-white"
    >
      <el-tooltip
        :offset="10"
        :visible-arrow="false"
        :open-delay="700"
        effect="dark"
        placement="bottom"
        popper-class="tooltip-content"
        :content="branding.contacts"
      >
        <span class="truncate contacts-text">{{ branding.contacts }}</span>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import { Metrika } from '@/services/metrika/metrika'

import { mapGetters, mapState } from 'vuex'
import CustomInput from './components/Custom-input.vue'
import Recovery from './recovery.vue'

export default {
  name: 'login',
  components: { CustomInput, Recovery },
  props: ['state', 'logedIn'],
  data() {
    return {
      isLogin: true,
      isRecovery: false,
      isTypePass: true,
      isSuccess: false,
      coverImage: '',
      isPasswordVisible: false,
      loginForm: {
        email: this.$route.params.username || '',
        pass: ''
      },
      isEmailError: false,
      isPasswordError: false,

      emailRegExp:
        /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(?:[a-z0-9]+)$/
    }
  },
  computed: {
    ...mapState('login', {
      requestedRoute: (state) => state.requestedRoute
    }),
    ...mapGetters({
      branding: 'login/getBranding',
      isRecoverySuccess: 'login/getRecovery'
    }),
    truncatedContacts() {
      const maxLetters = 350
      if (
        this.branding.contacts &&
        this.branding.contacts.length > maxLetters
      ) {
        return `${this.branding.contacts.slice(0, maxLetters)}...`
      }
      return this.branding.contacts
    },
    demoLoginPage() {
      return this.$route.query?.demo
    },
  },
  watch: {
    isRecoverySuccess() {
      this.isRecovery = false
    },
    'loginForm.email': function (val) {
      this.isEmailError = val === ''
    },
    'loginForm.pass': function (val) {
      this.isPasswordError = val === ''
    }
  },
  methods: {
    redirectToLink() {
      if (this.branding && this.branding.site) {
        window.open(this.branding.site, '_blank')
      }
    },
    goToMain() {
      this.isLogin = true
      this.isRecovery = false
    },
    handleLogin() {
      this.isLogin = false
      this.isRecovery = true
    },
    recover() {
      const email = this.loginForm.email.toLowerCase()
      if (!email || !this.emailRegExp.test(email)) {
        this.isEmailError = email === '' || !this.emailRegExp.test(email)
        return
      }
      this.$store.dispatch('login/recovery', {
        user_provider_id: this.loginForm.email,
        type: 'EMAIL'
      })
    },
    valid() {
      let isError = true
      const email = this.loginForm.email.toLowerCase()
      if (!email || !this.emailRegExp.test(email)) {
        this.isEmailError = email === '' || !this.emailRegExp.test(email)
        isError = false
      }
      if (!this.loginForm.pass) {
        this.isPasswordError = !this.loginForm.pass
        isError = false
      }
      return isError
    },
    submitForm() {
      if (this.valid()) {
        this.$store
          .dispatch('login/login', this.loginForm)
          .then(() => {
            if (this.requestedRoute) {
              const regex = /\/company\/change\/([^/]+)(?:\/([^/]+))?/
              const matches = this.requestedRoute.match(regex)
              if (matches) {
                const companyId = matches[1]
                const moduleName = matches[2] || null

                if (companyId && !moduleName) {
                  this.$store.dispatch('login/setCompanyId', { companyId })
                }

                if (companyId && moduleName) {
                  this.$store.dispatch('login/openModuleAdminPanel', {
                    companyId,
                    moduleName
                  })
                }
              }
            }
          })
          .catch((err) => {
            console.log('err', err)
          })
      }
    },

    guestLogin() {
      Metrika.Send('DEMO_LOGIN')
      this.$store.dispatch('login/loginDemoVersion')
    },
  },

  mounted() {
    if(this.demoLoginPage) {
      this.guestLogin();
    }
  }
}
</script>

<style scoped>
.right-container {
  width: 78%;
}
.input,
.btn {
  width: 100%;
  padding: 10px;
  background-color: #edeff3;
  border: none;
  border-radius: 4px;
}

/* Eye icon container styles */
.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-10%);
  cursor: pointer;
}
.demo_version {
  background-color: #fff1e9;
  color: #bd6039;
}
.demo_version:hover {
  background: #ffdfcd !important;
  color: #bd6039 !important;
}
.demo_version:hover {
  background-color: #3c5e8f;
}
.contacts {
  color: var(--black-tone-1, #292929);
  font-family: Source Sans Pro;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 6px;
  height: 28px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  padding: 5px 8px 6px 8px;
  width: 850px;
  overflow: hidden;
}
.contacts-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content {
  height: calc(100vh - 22px);
}
.footer {
  bottom: 50px;
  left: 0;
}
.forget-password-btn {
  padding: 3px 22px;
  border-radius: 36px;
  border: 1px solid #3a7cca;
  box-shadow: 0px 2px 18px 0px rgba(142, 194, 255, 0.8);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  color: #3a7cca;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.login,
.demo-login {
  background-color: #3a7cca;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  color: #fff;
  border-radius: 8px;
  height: 44px;
  outline: none;
  z-index: 99;
}
.demo-login {
  color: #3a7cca;
  background-color: #dbebff;
  height: 36px;
}
.remember-me {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.hint {
  color: #a6aeb8;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
}
.left-container {
  min-width: 440px;
  font-family: Source Sans Pro;
}
.title {
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 14px;
}
.powered-by {
  display: flex;
  background-color: #edeff3;
  justify-content: center;
  align-items: center;
  height: 22px;
}

.powered-by a:hover {
  color: #3a7cca;
  transition: 0.3s all;
}
.powered-by h4 {
  color: #828d9b;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
}

button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4c7caf;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  width: 320px;
  max-width: 320px;
}
/*
.cover-image img {
  max-width: 100%;
  height: auto;
} */
</style>
<!-- Aimen2020++ -->
