import HTTP from '@/api/http'

const addCommand = (formData, responseHandler, errorHandler) => {
  return HTTP.post('/commands', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getCommands = (data) => {
  return HTTP.post('/admin_query', data)
}

const getCommand = (data) => {
  return HTTP.get(`/commands?ids=${data}`)
}

const updateCommand = (data) => {
  return HTTP.put(`/commands/${data.id}`, data)
}

const deleteCommand = (id) => {
  return HTTP.delete(`/commands/${id}`)
}

export default {
  addCommand,
  getCommands,
  getCommand,
  updateCommand,
  deleteCommand,
}
