import { eventsApi } from '@/api'
import eventBus from '@/eventBus.js'

const state = {
  events: [],
  lastPoints: {},
  mapItems: [],
  nameTypes: '',
  // нужно для добавления на карте
  event: {},
  updateEvent: {},
  deleteEvent: '',
  isEventsList: false,
  currentUnit: {}
}

const getters = {
  eventById: state => id => {
    return state.events.find(event => event.id === id)
  },

  mapItems: state => {
    return state.mapItems
  },

  event: state => {
    return state.event
  },

  updateEvent: state => {
    return state.updateEvent
  },

  deleteEvent: state => {
    return state.deleteEvent
  },
  getIsEventsList: state => {
    return state.isEventsList
  },
  getCurrentEventsUnit: state => {
    return state.currentUnit
  },
  getEvents: state => {
    return state.events
  }
}

const actions = {
  getEvents({ commit }, formData) {
    return eventsApi.getEvents(
      formData,
      response => {
        commit('SET_EVENTS', response.data.list)
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  async websocketEvents({ state, dispatch, commit }, webSocket) {
    if (webSocket.operation === 'DELETE') {
      commit('deleteEventFromMap', webSocket.id)
    } else {
      const event = await dispatch('getEvent', webSocket.id)
      if (webSocket.operation === 'POST') {
        const isHaveEvents = state.mapItems.some(el => el.id === event.id)
        if (!isHaveEvents) {
          commit('addEvent', event)
          commit('addEventForMap', event)
        }
      } else {
        commit('updateEvents', event)
        commit('updateEventForMap', event)
      }
    }
  },

  GET_TYPENAMES({ commit }, formData) {
    return eventsApi.getEvents(
      formData,
      response => {
        commit('SET_TYPENAME', response.data.list)
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  GET_MAP_ITEMS({ commit }, formData) {
    return eventsApi.getEvents(
      formData,
      response => {
        commit('SET_MAP_ITEMS', response.data.list)
        return response.data.list
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  GET_LIST({ commit, rootState }, formData) {
    let eventType = rootState.dictionary.event_type
    eventsApi.getEvents(
      formData,
      response => {
        response.data.list.forEach(ev => {
          let eventName = eventType.filter(type => type.key === ev.type.key)

          ev.type = eventName[0]
        })
        commit('SET', response.data.list)
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  async getEvent({ commit }, data) {
    const event = await eventsApi.getEventForWebsocket(data)
    return event.data
  },

  GET_BY_ID({ commit }, id) {
    eventsApi.getEvent(
      id,
      response => {
        commit('SET', response.data.list)
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },

  CREATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      eventsApi.createEvent(
        formData,
        response => {
          commit('ADD', response.data)
          resolve()
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      eventsApi.updateEvent(
        formData.id,
        formData,
        response => {
          commit('UPDATE', response.data)
          resolve()
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  async deleteEvent({ commit }, data) {
    eventsApi
      .deleteEventForWebsocket(data)
      .then(res => {
        commit('deleteEvent', res.data)
      })
      .catch(err => {
        eventBus.$showError(err.response.data.message)
      })
  },
  DELETE({ commit }, eventIds) {
    let promiseList = []
    eventIds.forEach(id =>
      promiseList.push(
        new Promise((resolve, reject) => eventsApi.deleteEvent(id, resolve, reject))
      )
    )
    Promise.all(promiseList)
      .then(_ => {
        commit('DELETE', eventIds)
      })
      .catch(error => {
        eventBus.$showError(error.response.data.message)
      })
  }
}

const mutations = {
  SET_EVENTS(state, events) {
    state.events = events
  },
  setIsEventsList(state, payload) {
    const { unit, value } = payload
    state.isEventsList = value
    state.currentUnit = unit
  },
  SET_TYPENAME(state, nameTypes) {
    state.nameTypes = nameTypes
  },
  SET_LAST_POINT(state, { coords, unitId }) {
    state.lastPoints[unitId] = coords
  },
  SET_MAP_ITEMS(state, items) {
    items = items.filter(
      item =>
        item.latitude &&
        item.longitude &&
        item.type &&
        item.type.key &&
        item.type.key !== 'waybill'
    )
    state.mapItems = items
  },
  SET(state, events) {
    state.events = events
  },
  ADD(state, event) {
    if (!state.events.some(x => x.id === event.id)) {
      state.events = [event, ...state.events]
    }
  },

  addEventForMap(state, payload) {
    state.event = payload
  },

  addEvent(state, payload) {
    if (state.mapItems.length <= 0) {
      state.mapItems.push(payload)
    } else {
      const obj = state.mapItems.find(el => el.imei === payload.imei)
      if (!obj) return
      state.mapItems.push(payload)
    }
  },

  updateEventForMap(state, payload) {
    state.updateEvent = payload
  },

  updateEvents(state, payload) {
    const event = state.mapItems.find(event => event.id === payload.id)
    if (!event) return
    Object.assign(event, payload)
  },

  UPDATE(state, event) {
    const indexOfEvent = state.events.findIndex(elem => elem.id === event.id)
    state.events = [
      ...state.events.slice(0, indexOfEvent),
      event,
      ...state.events.slice(indexOfEvent + 1)
    ]
  },
  deleteEvent(state, payload) {
    const event = state.mapItems.map(el => el.id).indexOf(payload.id)
    state.mapItems.splice(event, 1)
  },
  deleteEventFromMap(state, payload) {
    state.deleteEvent = payload
  },
  DELETE(state, ids) {
    state.events = state.events.filter(item => !ids.includes(item.id))
  }
}
export default {
  namespaced: true,
  state: state,
  getters,
  actions,
  mutations
}
