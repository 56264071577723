import HTTP from '@/api/http'

const getLocators = function (responseHandler, errorHandler) {
  HTTP.post(
    '/locators/list',
    {
      fields: ['unit.id', 'unit.name', 'unit.imei'],
    },
    { needAuth: true }
  )
    .then(responseHandler)
    .catch(errorHandler)
}
const recover = function (data, responseHandler, errorHandler) {
  HTTP.put('/cancel_delete/' + data.id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getLocator = function (id, responseHandler, errorHandler) {
  HTTP.get('/locators?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getSingleLocator = function (id, responseHandler, errorHandler) {
  HTTP.get(
    '/locator?id=' + id,
    {
      fields: ['date_from', 'date_to'],
    },
    { needAuth: false }
  )
    .then(responseHandler)
    .catch(errorHandler)
}

const createLocator = function (formData, responseHandler, errorHandler) {
  HTTP.post('/locators', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateLocator = function (formData, responseHandler, errorHandler) {
  HTTP.put('/locators/' + formData.id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteLocator = function (id, responseHandler, errorHandler) {
  HTTP.delete('/locators', { data: { ids: id } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getLocators,
  getLocator,
  getSingleLocator,
  updateLocator,
  deleteLocator,
  createLocator,
  recover,
}
