<template lang="pug">
sup.w-3-5.h-3-5.absolute.rounded-full.text-white.leading-none.font-medium(
  v-if='value',
  :class='isObject ? "bg-annotationColor" : "bg-notify"',
  :style='[flights ? { left: "12px", top: "5px" } : list ? { left: "11px", top: "0px" } : isObject ? { right: "8px", top: "5px", transform: "translateY(-50%) translateX(100%)" } : { left: "18px", top: "-5px" }, { padding: "1px 4px 2px" }]'
) {{ value }}
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    flights: Boolean,
    list: Boolean,
    isObject: Boolean,
  },
}
</script>
