import HTTP from '@/api/http'

const getNotifications = function (responseHandler, errorHandler) {
  HTTP.post(
    '/notifications/list',
    {
      fields: [
        'id',
        'name',
        'is_active',
        'type.type',
        'type.value',
        'notificationtext',
        'sound.value',
      ],
    },
    { needAuth: true }
  )
    .then(responseHandler)
    .catch(errorHandler)
}
const getNotificationById = function (id, responseHandler, errorHandler) {
  HTTP.get('/notifications?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getNotificationSound = function (
  formData,
  responseHandler,
  errorHandler
) {
  HTTP.get(
    '/notify_sounds/' + formData,
    { responseType: 'blob' },
    { needAuth: true }
  )
    .then(responseHandler)
    .catch(errorHandler)
}
const createNotification = function (
  { formData },
  responseHandler,
  errorHandler
) {
  HTTP.post('/notifications', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const deleteNotification = function (data, responseHandler, errorHandler) {
  let ids = []
  data.forEach((geo) => ids.push(geo.id))
  HTTP.delete('/notifications', { data: { ids } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const recover = function (data, responseHandler, errorHandler) {
  HTTP.put('/cancel_delete/' + data.id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const updateNotification = function (
  id,
  { formData },
  responseHandler,
  errorHandler
) {
  HTTP.put('/notifications/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const toggleNotification = function (
  id,
  { formData },
  responseHandler,
  errorHandler
) {
  HTTP.patch('/notifications/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getNotifications,
  getNotificationById,
  createNotification,
  deleteNotification,
  updateNotification,
  toggleNotification,
  getNotificationSound,
  recover,
}
