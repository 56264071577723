/**
 * BoxTrack Service
 * Fr.AV 2024
 * */
import { reportsApi } from '@/api'
import eventBus from '@/eventBus'
import store from '@/store/store'

const DEBUG = false // Логирование функций модуля
const NOT_FOUND_PROCESS_ID = 422
const DISTANCE_INCREASE = 1 // увеличение на 10%

let BUSS = null
let BUSS_PROCESS_ID = null
let UNIQUE_TRACK_ID_BUSS = null
/**
 * Main
 * */
export const MainBoxTrack = async (map, units) => {
  if (!units?.units || !units?.from || !units?.to) {
    console.error('Не полные данные для построения BoxTrack')
    return
  }

  const boxPointsWindow = makeBoxPoints(map)
  const boxPointsWindowIncrease = makeBoxPointsIncrease(boxPointsWindow)

  // эти данные нужны чтобы передать на бекенд
  const southWestIncrease = boxPointsWindowIncrease.getSouthWest()
  const northEastIncrease = boxPointsWindowIncrease.getNorthEast()

  const payload = {
    southWestIncrease,
    northEastIncrease,
    zoom: map.getZoom(),
    units: units.units,
    from: units.from,
    to: units.to
  }

  BUSS = payload

  await fetchBoxTrack(payload)
}

/**
 * Получение данных box-track
 * payload {
 *   southWest,
 *   northEast,
 *   zoom
 * }
 * */
export const fetchBoxTrack = async payload => {
  try {
    let moduleState = 'getBoxTrack'

    const reportsTracks = store.getters[`monitoring/${moduleState}`]
    const processId = reportsTracks.processId
    const zoom = payload.zoom

    payload = {
      process_id: processId,
      box: [payload.southWestIncrease.lat, payload.southWestIncrease.lng, payload.northEastIncrease.lat, payload.northEastIncrease.lng],
      units: payload.units,
      from: payload.from,
      to: payload.to,
      zoom
    }

    store.commit('monitoringNew/setTrackBoxPoint', { [payload.zoom]: payload.box })
    return await reportsApi.getBoxTrack(
      payload,
      data => responseBoxTrack(data, payload, zoom),
      data => errorHandlerBoxTrack(data, payload)
    )
  } catch (e) {
    console.error(e)
  }
}

/**
 * Обновление хранилища SET_TRACK
 * */
const reportSetTrack = async (unitId, track, uniqueTrackId, processId, formData, zoom) => {
  if (!unitId || !track || !uniqueTrackId || !processId) return

  BUSS_PROCESS_ID = processId

  // Добавление данных в store
  // Для отчета нужен uniqueTrackId а для быстрого трека похоже что нет
  store.commit('monitoring/SET_BOX_TRACK', { [unitId]: track, processId, formData })

  const boxPointIncrease = store.getters['monitoringNew/getTrackBoxPointIncrease']

  if (track.length > 0) {
    const boxTrackCash = {
      zoom,
      uniqueTrackId,
      track,
      boxPointIncrease
    }

    // Положить в КЭШ еще Increase Box
    store.commit('monitoringNew/addCashBoxTrack', boxTrackCash)
  }

  // Возврат Promise, который выполнится сразу после завершения коммитов
  return Promise.resolve()
}

/** =================================== HANDLERS API ====================================== */
const responseBoxTrack = async (response, payload, zoom) => {
  const uniqueTrackId = `[${payload.units[0].id}][${payload.from}-${payload.to}]`
  UNIQUE_TRACK_ID_BUSS = uniqueTrackId

  await reportSetTrack(payload.units[0].id, response.data, uniqueTrackId, payload.process_id, payload, zoom)
}

/**
 * Обработка ошибок box_track
 * */
const errorHandlerBoxTrack = async (error, payload) => {
  const status = error.response.data.status
  if (status === NOT_FOUND_PROCESS_ID) {
    const formData = {
      zoom: payload.zoom,
      units: [{ id: payload.units[0].id }],
      from: payload.from,
      to: payload.to
    }
    await store.dispatch('monitoring/GET_BOX_TRACK', formData)
    await fetchBoxTrack(payload)
  }
  eventBus.$showError(error.response.data.message)
}

/** =================================== ВСПОМОГАТЕЛЬНЫЕ =================================== */

/**
 * Возвращаем углы карты
 * */
export const makeBoxPoints = map => {
  return L.latLngBounds(map.getBounds())
}

/**
 * Возвращаем увеличенные углы карты * на DISTANCE_INCREASE
 * */
export const makeBoxPointsIncrease = box => {
  return box.pad(DISTANCE_INCREASE) // Увеличиваем границы
}

/**
 * Функция срабатывает при изменении Zoom или при перемещении по карте
 * */
export const saveStoreBoxPoints = (zoom, map) => {
  const boxPointsWindow = makeBoxPoints(map)
  const boxPointsWindowIncrease = makeBoxPointsIncrease(boxPointsWindow)

  store.commit('monitoringNew/setTrackBoxPointIncrease', { [zoom]: boxPointsWindowIncrease })
  store.commit('monitoringNew/setTrackBoxPoint', { [zoom]: boxPointsWindow })
}

/**
 * self param === map.vue
 * */
export const moveEndMapForBoxTrack = async self => {
  const boxPointIncrease = store.getters['monitoringNew/getTrackBoxPointIncrease']
  const boxPoint = makeBoxPoints(self.map)
  const formData = self.$store.state.monitoring.boxTrack.formData

  if (!boxPointIncrease[self.map.getZoom()]) {
    await MainBoxTrack(self.map, formData)
    saveStoreBoxPoints(self.map.getZoom(), self.map)
  }
  self.collectMoveEnd()

  if (boxPointIncrease[self.zoom] || boxPointIncrease[self.map.getZoom()]) {
    DEBUG && console.log('> этого', boxPoint._northEast.lat, ',', boxPoint._northEast.lng, '|', boxPoint._southWest.lat, ',', boxPoint._southWest.lng)
    DEBUG && console.log('> в это', boxPointIncrease[self.zoom]._northEast.lat, ',', boxPointIncrease[self.zoom]._northEast.lng, '|', boxPointIncrease[self.zoom]._southWest.lat, ',', boxPointIncrease[self.zoom]._southWest.lng)

    if ((boxPointIncrease[self.zoom] && boxPointIncrease[self.zoom].contains(boxPoint)) || (boxPointIncrease[self.map.getZoom()] && boxPointIncrease[self.map.getZoom()].contains(boxPoint))) {
      DEBUG && console.log('Первый прямоугольник сходится во второй')

      const boxTrackCash = store.getters['monitoringNew/getBoxTrackCash']
      const cashKey = `[${self.zoom}]${UNIQUE_TRACK_ID_BUSS}`
      const cashed = boxTrackCash.hasOwnProperty(cashKey)

      if (cashed) {
        DEBUG && console.log(`ReadCash by Zoom: ${self.zoom} BUSS:`, BUSS)
        DEBUG && console.log('Cash: ', boxTrackCash[`${cashKey}`])
        DEBUG && console.log('GET CASH BUSS_PROCESS_ID', BUSS_PROCESS_ID)

        const track = boxTrackCash[`${cashKey}`].track
        const unitId = BUSS.units[0].id

        store.commit('monitoring/SET_BOX_TRACK', { [unitId]: track, processId: BUSS_PROCESS_ID, formData })
      } else {
        await MainBoxTrack(self.map, formData)
        saveStoreBoxPoints(self.map.getZoom(), self.map)
      }
    } else {
      DEBUG && console.log('Первый прямоугольник не сходится во второй')
      await MainBoxTrack(self.map, formData)
      saveStoreBoxPoints(self.map.getZoom(), self.map)
    }
  }
}

export const toggleOpenedBoxTrack = (show = false) => {
  DEBUG && console.log('toggleOpenedBoxTrack', show)
  store.commit('monitoringNew/toggleOpenedBoxTrack', show)
  if (!show) {
    store.commit('monitoring/SET_BOX_TRACK', null)
    store.commit('monitoringNew/clearCashBoxTrack')
  }
};

