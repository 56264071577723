import HTTP from '@/api/http'

const copySensors = async function (formData, responseHandler, errorHandler) {
  console.log('arman', formData)
  HTTP.post('/units/sensors/add_to_units', formData)
    .then(responseHandler)
    .catch(errorHandler)
}

const copySensor = async function (ids) {
  const { data } = await HTTP.get(`/units/sensors/copy?ids=${ids}`)
  return data
}

const unitsItemSensors = async function (id) {
  const { data } = await HTTP.get(`/units/${id}/sensors`)
  return data
}

const postAllObjects = async function ({ from, count, companies, value, field }) {
  const { data } = await HTTP.post('/admin_query', {
    model: 'units',
    companies: companies || [],
    from,
    count,
    field: field || '',
    value: value || ''
  })
  return data
}

const getObjects = function (responseHandler, errorHandler) {
  HTTP.post(
    '/units/list',
    {
      fields: [
        'id',
        'name',
        'imei',
        'icon',
        'type.value',
        'type.type',
        'phonenumber',
        'phonenumber2',
        'terminal_type.type',
        'terminal_type.value',
        'custom_fields.video_cameras',
        'custom_fields.video_device_name',
        'custom_fields.tripdetector_minmovespeed'
      ]
    },
    {
      needAuth: true
    }
  )
    .then(responseHandler)
    .catch(errorHandler)
}

const getPorts = function (responseHandler, errorHandler) {
  return HTTP.get('/ports', { needAuth: true }).then(responseHandler).catch(errorHandler)
}

const monitoringExtra = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/monitoring_extra', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const copyUnit = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/copy', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getObject = function (id, responseHandler, errorHandler) {
  HTTP.get(`/units?ids=${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const searchObject = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/units/list', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const searchObjectGroup = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/unitsgroup/list', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createObject = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/units', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateObject = function (id, { formData }, responseHandler, errorHandler) {
  HTTP.put(`/units/${id}`, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteObject = function (ids, responseHandler, errorHandler) {
  HTTP.delete('/units', { data: { ids } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const recover = function (id, responseHandler, errorHandler) {
  HTTP.put(`/cancel_delete/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createSensor = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/units/sensors', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createSensorWithUnit = function (id, { formData }, responseHandler, errorHandler) {
  HTTP.post(`/units/${id}/sensors`, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getAllSensor = function (responseHandler, errorHandler) {
  HTTP.get('/units/sensors', { needAuth: true }).then(responseHandler).catch(errorHandler)
}

const getSensorsWithUnit = function (id, responseHandler, errorHandler) {
  HTTP.get(`units/${id}/sensors`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateSensor = function (id, { formData }, responseHandler, errorHandler) {
  HTTP.put(`/units/sensors/${id}`, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteSensor = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/units/sensors/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const universalMethodCalibrations = function (
  { formData },
  responseHandler,
  errorHandler
) {
  HTTP.post('/units/sensors/recalc', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createTO = function (id, { formData }, responseHandler, errorHandler) {
  HTTP.post(`/units/${id}/services`, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createTOwitoutUnit = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/units/services', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getTOwithoutUnit = function (responseHandler, errorHandler) {
  HTTP.get('/units/services', { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getTO = function (id, responseHandler, errorHandler) {
  HTTP.get(`/units/${id}/services`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateTO = function (id, responseHandler, errorHandler) {
  HTTP.put(`/units/services/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const deleteTO = function (id, responseHandler, errorHandler) {
  HTTP.delete(`/units/services/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const lastPoint = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/lastpoints', formData).then(responseHandler).catch(errorHandler)
}

const loadPoints = function (imei, responseHandler, errorHandler) {
  HTTP.post(`/load_points/2019-06-02/${imei}`).then(responseHandler).catch(errorHandler)
}

const click = function (responseHandler, errorHandler) {
  HTTP.post('/report').then(responseHandler).catch(errorHandler)
}

const getGroupObject = function (responseHandler, errorHandler) {
  HTTP.post('/unitsgroup/list', {
    fields: ['id', 'name', 'units.id', 'units.name', 'users_count', 'units.imei']
  })
    .then(responseHandler)
    .catch(errorHandler)
}

const getGroupObjectId = function (id, responseHandler, errorHandler) {
  HTTP.get(`/unitsgroup?ids=${id}`).then(responseHandler).catch(errorHandler)
}

const createUnitGroup = function ({ formData }, responseHandler, errorHandler) {
  HTTP.post('/unitsgroup', formData).then(responseHandler).catch(errorHandler)
}

const deleteUnitGroup = function (id, responseHandler, errorHandler) {
  HTTP.delete('/unitsgroup', { data: { ids: id } })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateUnitGroup = function (id, { formData }, responseHandler, errorHandler) {
  HTTP.put(`/unitsgroup/${id}`, formData).then(responseHandler).catch(errorHandler)
}
const validateSensor = function (formData, responseHandler, errorHandler) {
  HTTP.post(
    `/units/sensors/validate?terminal_type=${formData.terminal_type}`,
    formData.sensors
  )
    .then(responseHandler)
    .catch(errorHandler)
}

const validateObject = function (formData, responseHandler, errorHandler) {
  HTTP.post('/units/validate', formData).then(responseHandler).catch(errorHandler)
}

const getSensorsConnections = function (responseHandler, errorHandler) {
  HTTP.get('/units/sensors/connections').then(responseHandler).catch(errorHandler)
}

const generateRandomImei = async (responseHandler, errorHandler)  => {
  try {
    const resp = await HTTP.get('/generate_imei');
    responseHandler(resp)
  } catch (e) {
    errorHandler(e)
  }
}



export default {
  validateSensor,
  copyUnit,
  searchObject,
  searchObjectGroup,
  getObjects,
  getObject,
  createObject,
  updateObject,
  deleteObject,
  createSensor,
  createSensorWithUnit,
  getAllSensor,
  universalMethodCalibrations,
  createTO,
  createTOwitoutUnit,
  updateSensor,
  deleteSensor,
  getSensorsWithUnit,
  getTO,
  getTOwithoutUnit,
  updateTO,
  deleteTO,
  lastPoint,
  loadPoints,
  click,
  getGroupObject,
  getGroupObjectId,
  createUnitGroup,
  deleteUnitGroup,
  updateUnitGroup,
  monitoringExtra,
  recover,
  getPorts,
  validateObject,
  postAllObjects,
  unitsItemSensors,
  copySensors,
  copySensor,
  getSensorsConnections,
  generateRandomImei
}
