import HTTP from '@/api/http'

const importWialon = (data) => {
  const fd = new FormData()
  fd.append('file', data.file)
  return HTTP.post(`/loadwln/${data.id}/units/analyze_terminals`, fd)
}

const analyzeWialon = (data) => {
  return HTTP.post(`/loadwln/${data.companyId}/units/analyze_params`, {
    id: data.id,
    terminals: data.terminal
  })
    .then((resp) => {
      return resp
    })
    .catch((err) => {
      return err
    })
}

const createImport = (data) => {
  return HTTP.post(`/loadwln/${data.companyId}/units/create`, {
    id: data.id,
    terminals_params: data.terminals
  })
}

export default {
  importWialon,
  analyzeWialon,
  createImport
}
