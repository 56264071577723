import { terminalApi, adminPanel } from '@/api'

import eventBus from '@/eventBus.js'

const state = {
  commands: {
    list: [],
    max: null
  },
  terminals: []
}

const actions = {
  async websocketCommand({ state, dispatch, commit }, webSocket) {
    if (webSocket.operation === 'DELETE') {
      dispatch('deleteCommand', webSocket.id)
    } else {
      const command = await dispatch('getCommand', webSocket.id)
      if (webSocket.operation === 'POST') {
        const isHaveCommand = state.commands.list.some(
          (el) => el.id === command.id
        )
        if (!isHaveCommand) {
          commit('setCommand', command)
        }
      } else {
        commit('commands/updateCommand', command, { root: true })
      }
    }
  },

  async websocketTerminals({ state, dispatch, commit }, webSocket) {
    if (webSocket.operation === 'DELETE') {
      dispatch('deleteTerminal', webSocket.id)
    } else {
      const terminal = await dispatch('getTerminal', webSocket.id)
      if (webSocket.operation === 'POST') {
        const isHaveTerminal = state.terminals.list.some(
          (el) => el.id === terminal.id
        )
        if (!isHaveTerminal) {
          commit('setTerminal', terminal)
        }
      } else {
        commit('updateTerminal', terminal)
      }
    }
  },

  async getTerminals({ commit }, params = {}) {
    const data = await adminPanel.getTerminalsList(params)
    commit('setTerminals', data.data)
  },

  async addCommand({ commit }, command) {
    const data = await terminalApi.addCommand(command)
    commit('setCommand', data.data)
  },

  async getCommand({ commit }, data) {
    const command = await terminalApi.getCommand(data)
    return command.data
  },

  async getTerminal({ commit }, data) {
    const terminal = await adminPanel.getTerminals(data)
    return terminal.data
  },

  async getCommands({ commit }, data) {
    const commands = await terminalApi.getCommands(data)
    commit('setCommands', commands.data)
  },

  async updateCommand({ commit }, data) {
    const command = await terminalApi.updateCommand(data)
    commit('updateCommand', command.data)
  },

  async updateTerminal({ commit }, data) {
    const terminal = await adminPanel.updateTerminal(data)
    commit('updateTerminal', terminal.data)
  },

  async deleteCommand({ commit }, data) {
    terminalApi
      .deleteCommand(data)
      .then((res) => {
        commit('deleteCommand', res.data)
      })
      .catch((err) => {
        eventBus.$showError(err.response.data.message)
      })
  },

  async deleteTerminal({ commit }, data) {
    adminPanel
      .delTerminals(data)
      .then((res) => {
        commit('deleteTerminal', res.data)
      })
      .catch((err) => {
        eventBus.$showError(err.response.data.message)
      })
  }
}

const mutations = {
  setCommands(state, payload) {
    state.commands = payload
  },

  setCommand(state, payload) {
    console.log()
    if (state.commands.list.length <= 0) {
      state.commands.list.unshift(payload)
    } else {
      const obj = state.commands.list.find((el) => el.imei === payload.imei)
      if (!obj) return
      state.commands.list.unshift(payload)
    }
  },

  // updateCommand(state, payload) {
  //   console.log('the state', state)
  //   // let commands = this.state.commands.commandsHistory.list
  //   // const command = this.state.commands.commandsHistory.list.find(
  //   //   (command) => command.id === payload.id
  //   // )
  //   const commandIndex = this.state.commands.commandsHistory.list.findIndex(
  //     (el) => el.id === payload.id
  //   )
  //   this.state.commands.commandsHistory.list[commandIndex] = payload
  //   // command.status = payload.status
  //   // payload.answer ? (command.asnwer = payload.answer) : ''
  //   console.log('commanded unpdated', payload)
  //   // Object.assign(command, payload)
  //   // state.commands.list = [...state.commands.list, { command }]
  // },

  updateTerminal(state, payload) {
    const terminal = state.terminals.list.find(
      (terminal) => terminal.id === payload.id
    )
    Object.assign(terminal, payload)
    // state.terminals = [...state.terminals, { terminal }]
  },

  deleteCommand(state, payload) {
    const command = state.commands.list.map((el) => el.id).indexOf(payload.id)
    state.commands.list.splice(command, 1)
  },

  deleteTerminal(state, payload) {
    const command = state.command.list.map((el) => el.id).indexOf(payload.id)
    state.commands.list.splice(command, 1)
  },

  setTerminals(state, payload) {
    state.terminals = payload
  },

  setTerminal(state, payload) {
    state.terminals.list.unshift(payload)
  }
}

const getters = {
  commands: (state) => {
    return state.commands.list
  },
  terminals: (state) => {
    return state.terminals.list
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
