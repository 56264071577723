const defaultState = {
  isMeasureStarted: false,
  resultModel: null,
  isMeasureFinished: false,
  typeMeasure:'polygon',
  isCanceled :false,
  reset:false
}
const mutations = {
  RESET(state){
    state.reset = !state.reset
  },
  CANCEL_MEASURE(state){
    state.isCanceled = !state.isCanceled
  },
  MEASURE_CHANGE(state, isMeasureStarted) {
    state.isMeasureStarted = isMeasureStarted
  },
  HANDLE_MEASURETYPE(state,val){
    state.typeMeasure = val 
  },
  RESULT_MODEL(state, resultModel) {
    state.resultModel = resultModel
  },
  MEASURE_FINISHED(state) {
    state.isMeasureFinished = !state.isMeasureFinished
    state.resultModel = null
  }
}
export default {
  namespaced: true,
  state: defaultState,
  mutations
}
