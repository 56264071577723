<template lang="pug">
label.inline-flex.relative.text-xs.leading-tight.align-middle.h-5.items-center.cursor-pointer(
  :class="{'big-switch': isBig}"
)
  input.hidden(
    type='checkbox',
    @change='$emit("change", $event)',
    v-model='model',
    :checked='model'
  )
  span.m-0.relative.w-9.border.outline-none.align-middle.transition-colors.duration-300.rounded-full.flex.items-center(
    :class='model ? "bg-darkblue border-darkblue" : "bg-switch border-switch"'
  )
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isBig: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
span {
  height: 18px;

  &:after {
    content: '';
    margin-left: 3px;
    border-radius: 100%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    width: 12px;
    height: 12px;
    background-color: #fff;
  }
}

input {
  &:checked ~ span {
    &:after {
      left: 100%;
      margin-left: 20px;
    }
  }
}

.big-switch {
  > span {
    height: 24px;
    width: 40px;

    &:after {
      width: 18px;
      height: 18px;
    }
  }

  input {
  &:checked ~ span {
    &:after {
      margin-left: 17px;
    }
  }
}
}
</style>
