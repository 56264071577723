import HTTP from '@/api/http'

const getCommandsHistory = (data) => {
  return HTTP.post(`/commands/list`, data)
}

const getCommandsTemplate = (data) => {
  return HTTP.post(`/commands_templates/list`)
}

const sendCommand = (data) => {
  return HTTP.post(`/commands_bulk`, data)
}

export default {
  getCommandsHistory,
  getCommandsTemplate,
  sendCommand,
}