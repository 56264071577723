<template>
  <div class=" inset-0 z-10 table w-full h-full"
    :class="{ 'fixed': !isDealerPage }">
    <div class="table-cell align-middle">
      <div class="mx-auto relative">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDealerPage() {
      return this.$route.path === '/dealer' || this.$route.path === '/confirm'
    }
  },
}
</script>
