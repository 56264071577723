<template>
  <div
    v-if="isFirst"
    class="mx-auto shadow flex flex-col dealer-box"
  >
    <span
      class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPro"
      >{{ $t('dealerTranslater.register') }}</span
    >
    <div class="relative flex flex-col mt-2">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-6 mb-1"
      >
        {{ $t('dealerTranslater.typeOrganization') }}
      </span>
      <el-select
        class="login-form-select"
        v-model="registerForm.organization"
        :class="{ 'input-error': validations.organisation.isOrganisationEmpty }"
      >
        <el-option
          v-for="(item, key) in organizationType"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        />
      </el-select>
      <transition name="el-zoom-in-top">
        <div
          v-if="validations.organisation.isOrganisationEmpty"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ validations.organisation.organisationErrorMessage }}
        </div>
      </transition>
    </div>
    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-6 mb-1"
        >{{ $t('dealerTranslater.nameCompany') }}</span
      >
      <el-input
        type="text"
        v-model="registerForm.orgName"
        :placeholder="$t('dealerTranslater.nameCompany')"
        :class="{ 'input-error': validations.orgName.isOrgNameEmpty }"
      />
      <transition name="el-zoom-in-top">
        <div
          class="text-notify text-xs top-full left-0 absolute"
          v-if="validations.orgName.isOrgNameEmpty"
        >
          {{ validations.orgName.orgNameErrorMsg }}
        </div>
      </transition>
    </div>
    <!-- Адрес -->
    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-6 mb-1"
      >
        {{ $t('dealerTranslater.address') }}
      </span>
      <el-input
        v-model="registerForm.address"
        type="text"
        :placeholder="`${$t('dealerTranslater.enter')} ${$t(
          'dealerTranslater.address'
        ).toLowerCase()}`"
        :class="{ 'input-error': validations.orgName.isAddressEmpty }"
      />
      <transition name="el-zoom-in-top">
        <div
          v-if="validations.address.isAddressEmpty"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ validations.address.addressErrorMsg }}
        </div>
      </transition>
    </div>

    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-6 mb-1"
      >
        {{ $t('dealerTranslater.INN') }}
      </span>
      <el-input
        v-model="registerForm.inn"
        :placeholder="$t('dealerTranslater.enterINN')"
        :class="{ 'input-error': validations.inn.isInnEmpty }"
      />
      <transition name="el-zoom-in-top">
        <div
          v-if="validations.inn.isInnEmpty"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ validations.inn.innErrorMessage }}
        </div>
      </transition>
    </div>
    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-6 mb-1"
        >Телефон:</span
      >
      <el-input
        v-model="registerForm.phone"
        :placeholder="$t('dealerTranslater.enterPhone')"
        :class="{ 'input-error': validations.phone.isPhoneEmpty }"
      />
      <transition name="el-zoom-in-top">
        <div
          v-if="validations.phone.isPhoneEmpty"
          class="text-notify text-xs top-full left-0 absolute"
        >
          {{ validations.phone.phoneErrorMessage }}
        </div>
      </transition>
    </div>
    <div class="flex items-center w-full justify-between mt-5">
      <div class="flex text-xs mt-5">
        <p class="mr-4">{{ $t('loginPage.links.ifHaveAccount') }}</p>
        <router-link class="ml-1 link" tag="p" type="text" to="/login">{{
          $t('loginPage.links.login')
        }}</router-link>
      </div>
      <skif-button class="mt-6" width="1/3" @click="validForNext">{{
        $t('dealerTranslater.next')
      }}</skif-button>
    </div>
  </div>
  <div
    v-else
    class="mx-auto shadow flex flex-col dealer-box"
  >
    <span
      class="col-start-1 col-end-2 font-bold text-darkblue text-xl font-SourceSansPro"
      >{{ $t('dealerTranslater.registerNewDealer') }}</span
    >
    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-8 mb-1"
        >{{ $t('dealerTranslater.email') }}</span
      >
      <el-input
        class="login-form-select"
        type="email"
        :placeholder="$t('dealerTranslater.enterEmail')"
        v-model="registerForm.email"
        :class="{ 'input-error': validations.email.isEmailEmpty }"
      />
      <transition name="el-zoom-in-top">
        <div
          class="text-notify text-xs top-full left-0 absolute"
          v-if="validations.email.isEmailEmpty"
        >
          {{ validations.email.emailErrorMessage }}
        </div>
      </transition>
    </div>
    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-8 mb-1"
        >{{ $t('dealerTranslater.surname') }}</span
      >
      <el-input
        type="text"
        v-model="registerForm.contact"
        :placeholder="$t('dealerTranslater.enterSurname')"
        :class="{ 'input-error': validations.contact.isContactEmpty }"
      />
      <transition name="el-zoom-in-top">
        <div
          class="text-notify text-xs top-full left-0 absolute"
          v-if="validations.contact.isContactEmpty"
        >
          {{ validations.contact.contactErrorMessage }}
        </div>
      </transition>
    </div>
    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-8 mb-1"
        >{{ $t('dealerTranslater.password') }}</span
      >
      <el-input
        type="password"
        autocomplete="new-password"
        v-model="registerForm.password"
        :class="{ 'input-error': validations.password.isPasswordEmpty }"
        show-password="show-password"
        :placeholder="$t('dealerTranslater.enterPassword')"
      />
      <transition name="el-zoom-in-top">
        <div
          class="text-notify text-xs top-full left-0 absolute"
          v-if="validations.password.isPasswordEmpty"
        >
          {{ validations.password.passwordErrorMessage }}
        </div>
      </transition>
    </div>
    <div class="relative flex flex-col">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-8 mb-1"
        >{{ $t('dealerTranslater.rewritePassword') }}</span
      >
      <el-input
        type="password"
        autocomplete="new-password"
        v-model="registerForm.passwordRepeat"
        :class="{ 'input-error': validations.passwordRepeat.isPasswordEmpty }"
        show-password="show-password"
        :placeholder="$t('dealerTranslater.rewritePassword')"
      />
      <transition name="el-zoom-in-top">
        <div
          class="text-notify text-xs top-full left-0 absolute"
          v-if="validations.passwordRepeat.isPasswordEmpty"
        >
          {{ validations.passwordRepeat.passwordErrorMessage }}
        </div>
      </transition>
    </div>
    <div class="flex w-full mb-3 items-center justify-between mt-4">
      <span
        class="text-annotationColor font-SourceSansPro text-sm font-semibold mt-3"
        >{{ $t('dealerTranslater.enterCodeFromImage') }}</span
      >
      <ReloadIcon @click.native="reloadCaptch" style="margin-right: .5rem;" />
    </div>
    <div class="flex flex-row sm:items-center justify-between w-full mb-3 captcha-box">
      <div class="relative">
        <el-input
          style="width: 250px"
          v-model="registerForm.captcha"
          @input="isCaptchaWrong = false"
          :class="{
            'input-error':
              validations.captcha.isCaptchaEmpty ||
              validations.captcha.isCaptchWrong
          }"
        ></el-input>
        <transition name="el-zoom-in-top">
          <div
            class="text-notify text-xs top-full left-0 absolute"
            v-if="validations.captcha.isCaptchaEmpty"
          >
            {{ validations.captcha.captchaWrongErrorMessage }}
          </div>
        </transition>
      </div>
      <img v-if="captchaImage" class="recaptcha" :src="captchaImage" width="248" alt="Captcha" />
    </div>
    <div class="flex items-center w-full justify-between mt-5">
      <div class="flex text-xs mt-5">
        <span
          class="mr-4 cursor-pointer font-bold text-darkblue"
          style="font-size: 16px"
          @click="isFirst = true"
          >{{ $t('dealerTranslater.back') }}</span
        >
      </div>
      <div style="width: 200px;">
        <skif-button class="mt-6" width="full" @click="submitForm">
          {{
            $t('dealerTranslater.goRegister')
          }}
        </skif-button>
      </div>
    </div>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
import { loginApi } from '@/api'
import { mapState } from 'vuex'
import router from '../../router/router'
import ReloadIcon from './assets/ReloadIcon'

export default {
  name: 'dealer',
  components: {
    ReloadIcon
  },
  data() {
    return {
      isFirst: true,
      captchaImage: '',
      emailRegExp: /^[^@\s]+@[^@\s]+.[^@\s]+$/,
      registerForm: {
        organization: 'legal_entity',
        orgName: '',
        inn: '',
        phone: '',
        // second step
        email: '',
        contact: '',
        password: '',
        passwordRepeat: '',
        captcha: ''
      },
      validations: {
        organisation: {
          isOrganisationEmpty: false,
          organisationErrorMessage: 'Введите тип организации'
        },
        orgName: {
          isOrgNameEmpty: false,
          orgNameErrorMsg: 'Введите название компании'
        },
        address: {
          isAddressEmpty: false,
          orgNameErrorMsg: 'Введите название адреса'
        },
        services: {
          isServicesEmpty: false,
          servicesErrorMsg: 'Введите название услуги'
        },
        tariff_description: {
          isTariffdescriptionEmpty: false,
          tariffDescriptionErrorMsg: 'Введите описание тарифа'
        },
        inn: {
          isInnEmpty: false,
          innErrorMessage: 'Введите ИНН'
        },
        phone: { isPhoneEmpty: false, phoneErrorMessage: 'Введите телефон' },
        // second step
        email: { isEmailEmpty: false, emailErrorMessage: 'Введите ваш email ' },
        contact: {
          isContactEmpty: false,
          contactErrorMessage: 'Введите ваш ФИО'
        },
        password: {
          isPasswordEmpty: false,
          passwordErrorMessage: 'Введите пароль',
          passwordLengthError: 'Пароль должен быть от 5 до 25 символов'
        },
        passwordRepeat: {
          isPasswordEmpty: false,
          passwordErrorMessage: 'Подтвердите пароль'
        },
        captcha: {
          isCaptchWrong: false,
          captchaWrongErrorMessage: 'введите код с картинки',
          isCaptchaEmpty: false
        }
      }
    }
  },
  computed: {
    ...mapState('properties', {
      organizationType: (state) => state.organizationType
    })
  },
  watch: {
    'registerForm.organization': function (val) {
      this.validations.organisation.isOrganisationEmpty = !val.length
    },
    'registerForm.orgName': function (val) {
      this.validations.orgName.isOrgNameEmpty = !val.length
    },
    'registerForm.inn': function (val) {
      this.validations.inn.isInnEmpty = !val.length
    },
    'registerForm.phone': function (val) {
      this.validations.phone.isPhoneEmpty = !val.length
    },
    // SECOND STEP
    'registerForm.email': function (val) {
      this.validations.email.isEmailEmpty = !val.length
    },
    'registerForm.contact': function (val) {
      this.validations.contact.isContactEmpty = !val.length
    },
    'registerForm.password': function (val) {
      this.validations.password.isPasswordEmpty = !val.length
      if (val.length < 5) {
        this.validations.password.passwordErrorMessage =
          'Пароль должен быть от 5 до 25 символов'
        this.validations.password.isPasswordEmpty = true
      }
    },
    'registerForm.passwordRepeat': function (val) {
      this.validations.passwordRepeat.isPasswordEmpty = !val.length
      if (val !== this.registerForm.password) {
        this.validations.passwordRepeat.passwordErrorMessage =
          'пароли не совпадают'
        this.validations.passwordRepeat.isPasswordEmpty = true
      }
    },
    'registerForm.captcha': function (val) {
      this.validations.captcha.isCaptchaEmpty = !val.length
    }
  },
  methods: {
    validNextStep() {
      let isError = false
      if (!this.registerForm.organization.length) {
        isError = true
        this.validations.organisation.isOrganisationEmpty = true
      }
      if (!this.registerForm.inn.length) {
        isError = true
        this.validations.inn.isInnEmpty = true
      }
      if (!this.registerForm.orgName.length) {
        isError = true
        this.validations.orgName.isOrgNameEmpty = true
      }
      if (!this.registerForm.phone.length) {
        isError = true
        this.validations.phone.isPhoneEmpty = true
      }

      return isError
    },
    validSecondStep() {
      let isError = false
      if (
        !this.registerForm.email.length ||
        !this.emailRegExp.test(this.registerForm.email)
      ) {
        isError = true
        this.validations.email.isEmailEmpty = true
      }
      if (!this.registerForm.contact.length) {
        isError = true
        this.validations.contact.isContactEmpty = true
      }
      if (!this.registerForm.password.length) {
        isError = true
        this.validations.password.isPasswordEmpty = true
      }
      if (
        !this.registerForm.passwordRepeat.length ||
        this.registerForm.passwordRepeat !== this.registerForm.password
      ) {
        console.log('error password dont match')
        isError = true
        this.validations.passwordRepeat.isPasswordEmpty = true
      }
      if (!this.registerForm.captcha.length) {
        isError = true
        this.validations.captcha.isCaptchaEmpty = true
      }
      return isError
    },
    validForNext() {
      !this.validNextStep() ? (this.isFirst = false) : (this.isFirst = true)
    },
    submitForm() {
      const form = {
        type: { key: this.registerForm.organization },
        name: this.registerForm.orgName,
        email: this.registerForm.email,
        inn: this.registerForm.inn,
        phone: this.registerForm.phone,
        contact_person: this.registerForm.contact,
        password: this.registerForm.password,
        captcha: this.registerForm.captcha,
        address: this.registerForm.address,
        services: this.registerForm.services,
        tariff_description: this.registerForm.tariff_description
      }
      !this.validSecondStep() &&
        this.$store
          .dispatch('dealers/registrateDealer', form)
          .then((response) => {
            console.log('registration dealer sucess', response)
            router.push('confirm')
          })
          .catch((err) => {
            console.log('error', err.errors)
            if (err.errors) {
              err.errors.forEach((error) => {
                switch (error.field) {
                  case 'phone':
                    this.validations.phone.phoneErrorMessage = error.message
                    this.validations.phone.isPhoneEmpty = true
                    this.isFirst = true
                    break
                  case 'inn':
                    this.validations.inn.innErrorMessage = error.message
                    this.validations.inn.isInnEmpty = true
                    this.isFirst = true
                    break
                  case 'password':
                    this.validations.password.passwordErrorMessage =
                      error.message
                    this.validations.password.isPasswordEmpty = true
                    break
                  case 'email':
                    this.validations.email.emailErrorMessage = error.message
                    this.validations.email.isEmailEmpty = true
                    break
                  default:
                    break
                }
              })
            } else if (err.field === 'captcha') {
              this.validations.captcha.captchaWrongErrorMessage = err.message
              this.validations.captcha.isCaptchaEmpty = true
            } else if (err.field === 'password') {
              this.validations.password.passwordErrorMessage = err.message
              this.validations.password.isPasswordEmpty = true
            } else if (err.field === 'userProviderId') {
              this.validations.phone.phoneErrorMessage = err.message
              this.validations.phone.isPhoneEmpty = true
              this.isFirst = true
            }
          })
    },
    reloadCaptch() {
      loginApi.getCaptcha(
        (response) => {
          const blob = new Blob([response.data], {
            type: response.headers['content-type']
          })
          this.captchaImage = URL.createObjectURL(blob)
        },
        (error) => {
          console.log(error)
        }
      )
    }
  },
  mounted() {
    this.$store.dispatch('properties/getAllProperties').then((_) => {})
    loginApi.getCaptcha(
      (response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        })
        this.captchaImage = URL.createObjectURL(blob)
      },

      (error) => {
        console.log(error)
      }
    )
  }
}
</script>
<style lang="scss" scoped>
.recaptcha {
  @media screen and (max-width: 735px) {
    margin-top: 1rem;
  }
}

.dealer-box {
  width: 100%;
  max-width: 650px;
  padding: 30px 36px 35px 60px;

  @media screen and (max-width: 735px) {
    padding: 20px;
    box-shadow: none !important;
  }
}

.termsOfUse {
  margin-top: 26px;
  font-size: 10px;
  line-height: 13px;
  padding: 0 41px;
  text-align: center;
  box-sizing: border-box;

  a {
    color: #5477A9;
  }
}

.el-select {
  width: 100%;
}

.link {
  color: #7892B8;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}

.captcha-box {
  flex-direction: row;
  @media screen and (max-width: 735px) {
    flex-direction: column;
  }
}
</style>
