<template>
  <svg
    width="360"
    height="100"
    viewBox="0 0 360 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8590_70710)">
      <rect width="360" height="100" fill="#1861D0" />
      <path
        d="M297.154 77.6299L236.949 77.792C235.759 77.792 235.164 78.3865 235.164 79.5755L235.083 139.401C235.083 142.428 234.434 144.968 233.136 147.021C231.783 149.075 230.052 150.588 227.943 151.561C225.779 152.534 223.507 152.885 221.127 152.615C218.747 152.399 216.502 151.534 214.393 150.021C212.229 148.508 210.471 146.265 209.119 143.292L147.616 9.77868C145.831 5.88757 145.154 2.37477 145.587 -0.759739C145.966 -3.89424 147.183 -6.40725 149.238 -8.29876C151.24 -10.1903 153.836 -11.2982 157.028 -11.6224C160.219 -11.9467 163.735 -11.2441 167.576 -9.51474L301.698 52.0945C304.403 53.3375 306.485 55.0128 307.946 57.1205C309.406 59.2282 310.244 61.471 310.461 63.8489C310.623 66.1728 310.244 68.3885 309.325 70.4962C308.351 72.5499 306.864 74.2522 304.862 75.6033C302.807 76.9544 300.237 77.6299 297.154 77.6299Z"
        fill="url(#paint0_linear_8590_70710)"
      />
      <rect x="111" y="26" width="138.667" height="48" rx="9.19" fill="white" />
      <path
        d="M126.238 52.0222H134.352V54.7181H126.238V58.7621H139.762V47.9782H131.648V45.2822H139.762V41.2383H126.238V52.0222Z"
        fill="#2B409E"
      />
      <path
        d="M164.104 41.2383H158.695V58.7621H164.104V41.2383Z"
        fill="#2B409E"
      />
      <path
        d="M150.581 43.9343H149.228V46.6302H147.876V41.2383H142.466V58.7621H147.876V53.3702H149.228V56.0661H150.581V58.7621H155.99V54.7181H154.638V52.0222H153.285V47.9782H154.638V45.2822H155.99V41.2383H150.581V43.9343Z"
        fill="#2B409E"
      />
      <path
        d="M180.333 45.2822V41.2383H166.809V58.7621H172.219V52.0222H180.333V47.9782H172.219V45.2822H180.333Z"
        fill="#2B409E"
      />
      <path
        d="M188.448 58.7621H193.857V54.7181H199.267V52.0222H201.972V41.2383H188.448V58.7621ZM196.562 50.6742H193.857V45.2822H196.562V50.6742Z"
        fill="#C7130C"
      />
      <path
        d="M220.905 41.2383V58.7621H234.428V41.2383H220.905ZM229.019 54.7181H226.314V45.2822H229.019V54.7181Z"
        fill="#C7130C"
      />
      <path
        d="M204.676 58.7621H210.086V54.7181H212.791V58.7621H218.2V54.7181H215.495V52.0222H218.2V41.2383H204.676V58.7621ZM212.791 50.6742H210.086V45.2822H212.791V50.6742Z"
        fill="#C7130C"
      />
      <path
        d="M185.743 54.7188H181.686V58.7627H185.743V54.7188Z"
        fill="#C7130C"
      />
      <g filter="url(#filter0_b_8590_70710)">
        <path
          d="M315.925 40.5573L298.095 40.6055C297.742 40.6055 297.566 40.7822 297.566 41.1357L297.542 58.9218C297.542 59.8215 297.35 60.5767 296.965 61.1872C296.565 61.7977 296.052 62.2476 295.427 62.5368C294.787 62.826 294.114 62.9305 293.409 62.8501C292.704 62.7859 292.039 62.5288 291.415 62.0789C290.774 61.629 290.253 60.9623 289.853 60.0786L271.638 20.3853C271.11 19.2285 270.909 18.1842 271.038 17.2523C271.15 16.3204 271.51 15.5733 272.119 15.0109C272.712 14.4486 273.481 14.1192 274.426 14.0228C275.371 13.9264 276.412 14.1353 277.55 14.6494L317.27 32.9657C318.071 33.3352 318.688 33.8333 319.121 34.4599C319.553 35.0865 319.801 35.7533 319.866 36.4602C319.914 37.1511 319.801 37.8099 319.529 38.4365C319.241 39.047 318.8 39.5531 318.207 39.9548C317.599 40.3565 316.838 40.5573 315.925 40.5573Z"
          fill="url(#paint1_linear_8590_70710)"
        />
      </g>
      <g opacity="0.8" filter="url(#filter1_b_8590_70710)">
        <path
          d="M69.7603 56.1451L46.8361 56.2071C46.383 56.2071 46.1564 56.4343 46.1564 56.8888L46.1255 79.7566C46.1255 80.9134 45.8784 81.8843 45.384 82.6693C44.8691 83.4542 44.21 84.0327 43.4068 84.4045C42.5829 84.7763 41.7178 84.9106 40.8116 84.8073C39.9053 84.7247 39.0505 84.3942 38.2473 83.8157C37.4234 83.2373 36.754 82.3801 36.2391 81.2439L12.8206 30.2097C12.1409 28.7224 11.8835 27.3796 12.0482 26.1815C12.1924 24.9834 12.6558 24.0228 13.4385 23.2998C14.2006 22.5768 15.1892 22.1533 16.4044 22.0294C17.6196 21.9054 18.9584 22.174 20.4208 22.835L71.4904 46.3845C72.5202 46.8596 73.3132 47.5 73.8693 48.3056C74.4254 49.1113 74.7447 49.9685 74.8271 50.8775C74.8889 51.7657 74.7447 52.6127 74.3946 53.4183C74.0238 54.2033 73.4574 54.854 72.6953 55.3705C71.9126 55.8869 70.9343 56.1451 69.7603 56.1451Z"
          fill="url(#paint2_linear_8590_70710)"
        />
      </g>
      <g filter="url(#filter2_b_8590_70710)">
        <path
          d="M137.265 77.6309L104.615 77.7192C103.97 77.7192 103.647 78.0428 103.647 78.6901L103.603 111.259C103.603 112.907 103.251 114.29 102.547 115.408C101.814 116.526 100.875 117.35 99.7308 117.879C98.5575 118.409 97.3254 118.6 96.0347 118.453C94.7439 118.335 93.5265 117.864 92.3825 117.041C91.2091 116.217 90.2557 114.996 89.5223 113.378L56.1687 40.6926C55.2007 38.5743 54.834 36.6619 55.0687 34.9555C55.274 33.249 55.9341 31.881 57.0488 30.8512C58.1342 29.8215 59.5422 29.2183 61.273 29.0418C63.0037 28.8653 64.9105 29.2478 66.9933 30.1892L139.729 63.7294C141.196 64.4061 142.325 65.3181 143.117 66.4656C143.909 67.613 144.364 68.834 144.481 70.1285C144.569 71.3936 144.364 72.5999 143.865 73.7473C143.337 74.8653 142.53 75.7921 141.445 76.5276C140.33 77.2631 138.937 77.6309 137.265 77.6309Z"
          fill="url(#paint3_linear_8590_70710)"
        />
      </g>
      <g filter="url(#filter3_b_8590_70710)">
        <path
          d="M28.9979 82.1976L21.4579 82.218C21.3089 82.218 21.2344 82.2925 21.2344 82.4415L21.2242 89.9408C21.2242 90.3202 21.1429 90.6386 20.9803 90.896C20.811 91.1535 20.5942 91.3432 20.33 91.4651C20.059 91.587 19.7745 91.6311 19.4764 91.5972C19.1783 91.5701 18.8972 91.4617 18.633 91.272C18.362 91.0823 18.1418 90.8012 17.9725 90.4286L10.2699 73.6923C10.0463 73.2046 9.96167 72.7642 10.0159 72.3713C10.0633 71.9784 10.2157 71.6634 10.4731 71.4263C10.7238 71.1891 11.049 71.0503 11.4487 71.0096C11.8484 70.969 12.2887 71.057 12.7697 71.2738L29.5669 78.9967C29.9057 79.1525 30.1665 79.3625 30.3494 79.6267C30.5323 79.8909 30.6373 80.1721 30.6644 80.4702C30.6847 80.7615 30.6373 81.0392 30.5221 81.3034C30.4002 81.5608 30.2139 81.7742 29.9632 81.9436C29.7058 82.113 29.384 82.1976 28.9979 82.1976Z"
          fill="url(#paint4_linear_8590_70710)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter4_b_8590_70710)">
        <path
          d="M44.9184 5.8633L40.982 5.87394C40.9041 5.87394 40.8652 5.91296 40.8652 5.991L40.8599 9.91779C40.8599 10.1164 40.8175 10.2832 40.7326 10.418C40.6442 10.5527 40.531 10.6521 40.3931 10.7159C40.2516 10.7798 40.1031 10.8028 39.9474 10.7851C39.7918 10.7709 39.645 10.7141 39.5071 10.6148C39.3656 10.5155 39.2507 10.3683 39.1623 10.1732L35.1409 1.40975C35.0242 1.15435 34.98 0.923775 35.0083 0.718035C35.033 0.512296 35.1126 0.347349 35.247 0.223196C35.3779 0.0990426 35.5476 0.0263242 35.7563 0.00504081C35.965 -0.0162426 36.1949 0.0298715 36.446 0.143383L45.2155 4.18723C45.3924 4.26882 45.5285 4.37878 45.624 4.51713C45.7195 4.65547 45.7743 4.80268 45.7885 4.95876C45.7991 5.11129 45.7743 5.25672 45.7142 5.39507C45.6506 5.52986 45.5533 5.6416 45.4224 5.73028C45.288 5.81896 45.12 5.8633 44.9184 5.8633Z"
          fill="url(#paint5_linear_8590_70710)"
        />
      </g>
      <g opacity="0.8" filter="url(#filter5_b_8590_70710)">
        <path
          d="M353.998 82.1976L346.458 82.218C346.309 82.218 346.234 82.2925 346.234 82.4415L346.224 89.9408C346.224 90.3202 346.143 90.6386 345.98 90.896C345.811 91.1535 345.594 91.3432 345.33 91.4651C345.059 91.587 344.774 91.6311 344.476 91.5972C344.178 91.5701 343.897 91.4617 343.633 91.272C343.362 91.0823 343.142 90.8012 342.972 90.4286L335.27 73.6923C335.046 73.2046 334.962 72.7642 335.016 72.3713C335.063 71.9784 335.216 71.6634 335.473 71.4263C335.724 71.1891 336.049 71.0503 336.449 71.0096C336.848 70.969 337.289 71.057 337.77 71.2738L354.567 78.9967C354.906 79.1525 355.166 79.3625 355.349 79.6267C355.532 79.8909 355.637 80.1721 355.664 80.4702C355.685 80.7615 355.637 81.0392 355.522 81.3034C355.4 81.5608 355.214 81.7742 354.963 81.9436C354.706 82.113 354.384 82.1976 353.998 82.1976Z"
          fill="url(#paint6_linear_8590_70710)"
        />
      </g>
      <g opacity="0.6" filter="url(#filter6_b_8590_70710)">
        <path
          d="M354.586 31.2776L348.797 31.2926C348.683 31.2926 348.625 31.3477 348.625 31.4579L348.618 37.0016C348.618 37.282 348.555 37.5174 348.43 37.7077C348.3 37.898 348.134 38.0382 347.931 38.1284C347.723 38.2185 347.505 38.2511 347.276 38.226C347.047 38.206 346.831 38.1259 346.628 37.9856C346.42 37.8454 346.251 37.6376 346.121 37.3622L340.207 24.9902C340.036 24.6297 339.971 24.3042 340.012 24.0137C340.049 23.7232 340.166 23.4904 340.363 23.3151C340.556 23.1398 340.805 23.0372 341.112 23.0071C341.419 22.9771 341.757 23.0422 342.126 23.2024L355.023 28.9114C355.283 29.0266 355.483 29.1818 355.624 29.3771C355.764 29.5724 355.845 29.7803 355.865 30.0006C355.881 30.2159 355.845 30.4213 355.756 30.6166C355.663 30.8069 355.52 30.9646 355.327 31.0898C355.129 31.215 354.882 31.2776 354.586 31.2776Z"
          fill="url(#paint7_linear_8590_70710)"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_b_8590_70710"
        x="260.848"
        y="3.84823"
        width="69.1799"
        height="69.1785"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.07589" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8590_70710"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8590_70710"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_8590_70710"
        x="1.84823"
        y="11.8482"
        width="83.1445"
        height="83.1434"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.07589" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8590_70710"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8590_70710"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_8590_70710"
        x="44.8482"
        y="18.8482"
        width="109.804"
        height="109.804"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.07589" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8590_70710"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8590_70710"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_8590_70710"
        x="-0.151772"
        y="60.8482"
        width="40.9725"
        height="40.9129"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.07589" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8590_70710"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8590_70710"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_b_8590_70710"
        x="24.8482"
        y="-10.1518"
        width="31.0944"
        height="31.0926"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.07589" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8590_70710"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8590_70710"
          result="shape"
        />
      </filter>
      <filter
        id="filter5_b_8590_70710"
        x="324.848"
        y="60.8482"
        width="40.9725"
        height="40.9129"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.07589" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8590_70710"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8590_70710"
          result="shape"
        />
      </filter>
      <filter
        id="filter6_b_8590_70710"
        x="329.848"
        y="12.8482"
        width="36.1724"
        height="35.5379"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.07589" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8590_70710"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8590_70710"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_8590_70710"
        x1="222.051"
        y1="-33.4908"
        x2="222.051"
        y2="165.64"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#5BE0E0" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8590_70710"
        x1="293.645"
        y1="7.64331"
        x2="293.645"
        y2="66.9795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.947917" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8590_70710"
        x1="40.9874"
        y1="14.3333"
        x2="40.9874"
        y2="89.8207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.947917" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_8590_70710"
        x1="96.467"
        y1="17.3598"
        x2="96.467"
        y2="126.014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.947917" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_8590_70710"
        x1="19.5342"
        y1="68.4858"
        x2="19.5342"
        y2="93.2413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.947917" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_8590_70710"
        x1="39.9776"
        y1="-1.3165"
        x2="39.9776"
        y2="11.646"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.947917" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_8590_70710"
        x1="344.534"
        y1="68.4858"
        x2="344.534"
        y2="93.2413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.947917" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_8590_70710"
        x1="347.32"
        y1="21.1414"
        x2="347.32"
        y2="39.4414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.947917" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <clipPath id="clip0_8590_70710">
        <rect width="360" height="100" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'LogoIcon'
}
</script>
