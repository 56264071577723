import HTTP from '@/api/http'

const getTrailers = function (responseHandler, errorHandler) {
  HTTP.post(
    '/trailers/list',
    {
      fields: ['id', 'name', 'code', 'width'],
    },
    { needAuth: true }
  )
    .then(responseHandler)
    .catch(errorHandler)
}
const recover = function (data, responseHandler, errorHandler) {
  HTTP.put('/cancel_delete/' + data.id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}
const getTrailer = function (id, responseHandler, errorHandler) {
  HTTP.get('/trailers?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createTrailer = function (formData, responseHandler, errorHandler) {
  HTTP.post('/trailers', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateTrailer = function (id, formData, responseHandler, errorHandler) {
  HTTP.put('/trailers/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteTrailer = function (id, responseHandler, errorHandler) {
  HTTP.delete('/trailers', { data: { ids: id } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteTrailerById = async function (id, responseHandler, errorHandler) {
  return HTTP.delete(`/trailers/${id}`, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getTrailers,
  getTrailer,
  updateTrailer,
  deleteTrailer,
  deleteTrailerById,
  createTrailer,
  recover
}
