let WS
const currentUrl = window.location.href
const currentDomain = new URL(currentUrl).hostname

export const initws = (locatorId) => {
  const host = window.location.host === 'localhost:9000' ? currentDomain : window.location.host
  WS = new WebSocket(
    // `${location.protocol === 'https:' ? 'wss' : 'ws'}://${
    //   location.host
    // }/locator_msg?id=${locatorId}`
    `wss://${host}/locator_msg?id=${locatorId}`
  )
  return WS
}

export const getws = () => {
  return WS
}

export const closews = () => {
  WS.close()
}
