import { integratorsApi } from '@/api'

const defaultState = {
	integrators: []
}

const getters = {
	integrators: state => state.integrators
}

const mutations = {
	SET_INTEGRATORS(state, data) {
		state.integrators = data 
	}
}

const actions = {
	async getIntegrators({commit}) {
		try {
			const { data } = await integratorsApi.getAllIntegrators()

			commit('SET_INTEGRATORS', data)
		} catch (err) {
			throw new Error(err)
		}
	}
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations
}
