<template>
  <div class="list-shorts">
    <p class="list-shorts__title">
      {{ $t('lkDiler.start_terminal.create_terminal') }}
    </p>
    <div class="steps" v-for="(step, index) in shortsList" :key="index">
      <p class="list-shorts__step">{{ step.step }}</p>
      <p class="list-shorts__action" v-html="step.action" />
      <img
        class="list-shorts__img"
        alt=""
        :src="
          require(`@/components/map/tools/help-tools/images/startTerminals/${step.img}.png`)
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartTerminal',
  data() {
    return {
      shortsList: [
        {
          step: '1',
          action: this.$t('lkDiler.start_terminal.step_1'),
          img: 'step1'
        },
        {
          step: '2',
          action: `${this.$t('lkDiler.start_terminal.step_2')} <br />
          ${this.$t('lkDiler.start_terminal.step_3')} <br />
          ${this.$t('lkDiler.start_terminal.step_4')} <br />
          ${this.$t('lkDiler.start_terminal.step_5')} <br />
          ${this.$t('lkDiler.start_terminal.step_6')}`,
          img: 'step2'
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
.list-shorts
  &__title
    margin-bottom 20px
    font-size 12px
    font-weight 600
    line-height 15px
    color #677785

  &__step
    font-size 14px
    font-weight 600
    line-height 40px
    color #3C5E8F

  &__action
    font-size 13px
    line-height 20px
    font-weight 600
    color #5477A9
    margin-bottom 20px

  &__img
    margin-bottom 20px
</style>
