<template>
  <svg
    style="float: right; transform: translate(0px, -3px); margin-left: 4px"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 13.5V16.8333C18.5 17.2754 18.3244 17.6993 18.0118 18.0118C17.6993 18.3244 17.2754 18.5 16.8333 18.5H5.16667C4.72464 18.5 4.30072 18.3244 3.98816 18.0118C3.67559 17.6993 3.5 17.2754 3.5 16.8333V13.5"
      stroke="#3A7CCA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.8335 9.33203L11.0002 13.4987L15.1668 9.33203"
      stroke="#3A7CCA"
      stroke-width="1.33333"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 13.5V3.5"
      stroke="#3A7CCA"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon'
}
</script>
