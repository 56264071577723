<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10440_109)">
      <path
        d="M0.916656 11C0.916656 11 4.58332 3.66666 11 3.66666C17.4167 3.66666 21.0833 11 21.0833 11C21.0833 11 17.4167 18.3333 11 18.3333C4.58332 18.3333 0.916656 11 0.916656 11Z"
        :stroke="isActive ? ' #000' : '#A6AEB8'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z"
        :stroke="isActive ? ' #000' : '#A6AEB8'"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_10440_109">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'showPasswordIcon',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
