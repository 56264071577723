import HTTP from '@/api/http'

const getEvents = function (formData, responseHandler, errorHandler) {
  return HTTP.post('/events/list', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const getEventForWebsocket = (id) => {
  return HTTP.get('/events?ids=' + id, { needAuth: true })
}

const getEvent = function (id, responseHandler, errorHandler) {
  return HTTP.get('/events?ids=' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const createEvent = function (formData, responseHandler, errorHandler) {
  HTTP.post('/events', formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const updateEvent = function (id, formData, responseHandler, errorHandler) {
  HTTP.put('/events/' + id, formData, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteEventForWebsocket = function (id, responseHandler, errorHandler) {
  return HTTP.delete('/events/' + id, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

const deleteEvent = function (id, responseHandler, errorHandler) {
  HTTP.delete('/events', { data: { ids: [id] } }, { needAuth: true })
    .then(responseHandler)
    .catch(errorHandler)
}

export default {
  getEvents,
  getEvent,
  updateEvent,
  deleteEvent,
  createEvent,
  getEventForWebsocket,
  deleteEventForWebsocket
}
