import moment from 'moment'
import getStatus from './getObjectStatus.js'

const getMarkerValues = (marker, currentTime) => {
  const updatedLatlon = marker.columns.find(
    (item) => item.key === '_marker_latlon'
  )

  const updatedAngle = marker.columns.find(
    (item) => item.key === '_angle'
  ).value

  const updatedDatepointHuman = marker.columns.find(
    (item) => item.key === '_datepoint'
  ).value

  const updatedDatepoint = moment(updatedDatepointHuman).unix()

  const updatedIgnition = marker.columns.find(
    (item) => item.type && item.type === 'ignition'
  )

  const updatedSpeed = marker.columns.find((arr) => arr.key === 'speed').value

  const updateAddress = marker.columns.find(
    (arr) => arr.key === 'address'
  ).value

  const updatedStatus = getStatus(
    updatedSpeed,
    updatedDatepointHuman,
    currentTime,
    updatedDatepoint,
    updatedIgnition || '',
    updateAddress
  )

  return {
    latlon: updatedLatlon,
    angle: updatedAngle,
    datepointHuman: updatedDatepointHuman,
    datepoint: updatedDatepoint,
    ignition: updatedIgnition,
    status: updatedStatus,
    address: updateAddress
  }
}

export default getMarkerValues
