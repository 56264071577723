<template lang="pug">
input.border.rounded.h-8.focus_outline-none.px-4(
  type='number',
  v-model.number='model',
  :class='[error ? "border-notify" : "border-inputBorder focus_border-inputFocus", disabled && "cursor-not-allowed bg-hoverrow", inputClass]',
  :disabled='disabled'
)
</template>
<script>
export default {
  props: {
    value: {},
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '40',
    },
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    inputClass() {
      return this.width === '40' ? 'w-40' : `w-${this.width}`
    },
  },
}
</script>