<template>
  <div class="container">
    <!-- add class border 1px red -->
    <div :class="['custom-input-container', { 'input-error': error }]">
      <label class="label-placeholder" @click="focusInput">
        {{ label }}
      </label>

      <input
        ref="inputRef"
        v-model="inputValue"
        @focus="isActive = true"
        @blur="onInputBlur"
        @input="$emit('input', inputValue)"
        :class="{ 'has-value': inputValue }"
        class="custom-input"
        :type="type"
        :placeholder="placeholder"
      />

      <div
        @click="$emit('isPasswordVisible')"
        v-if="currentType === 'password'"
        class="password-icon mr-4 cursor-pointer"
      >
        <showPasswordIcon :isActive="type !== 'password'" />
      </div>
      <el-tooltip
        v-else-if="isHint && type === 'text'"
        :offset="0"
        :visible-arrow="false"
        :open-delay="700"
        effect="dark"
        placement="bottom"
        :content="content"
      >
        <span>
          <Hint class="mr-4 cursor-pointer" />
        </span>
      </el-tooltip>
    </div>
    <div class="error-message" v-if="error">{{ errorMessage }}</div>
  </div>
</template>
<script>
import showPasswordIcon from '../assets/showPasswordIcon.vue'
import Hint from '../assets/Hint.vue'

export default {
  components: {
    showPasswordIcon,
    Hint
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    isHint: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      default: 'text'
    },
    placeholder: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      inputValue: '',
      isActive: false,
      currentType: ''
    }
  },
  methods: {
    focusInput() {
      this.$refs.inputRef.focus()
    },
    onInputBlur() {
      if (!this.inputValue) {
        this.isActive = false
      }
    }
  },
  created() {
    this.currentType = this.type
  }
}
</script>

<style scoped>
@keyframes border-animation {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #d3b1b1;
  }
  100% {
    border-color: #c93131;
  }
}
.error-message {
  margin-top: 2px;
  margin-left: 10px;
  color: #c93131;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.input-error {
  border: 1px solid #c93131;
  animation: border-animation 0.3s ease-in;
}
.custom-input-container {
  position: relative;
  height: 48px;
  background-color: #edeff3;
  border-radius: 12px;
  overflow: hidden;
  font-family: Source Sans Pro;
  color: #292929;
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-input {
  width: 100%;
  height: 100%;
  padding: 12px;
  padding-top: 14px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-top: 8px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: none;
}
.label-placeholder {
  position: absolute;
  top: 3px;
  left: 11px;
  color: #292929;

  pointer-events: none;
  color: var(--black-tone-1, #292929);
  font-family: Source Sans Pro;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
}

.custom-input placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  color: #b61111;
}
</style>
