import getMarkerValues from '@/utils/getMarkerValues.js'
import getStatus from '@/utils/getObjectStatus.js'
import moment from 'moment'
import Vue from 'vue'

const state = {
  monitoringList: [],
  monitoringListObj: {},
  filtredUnits: [],
  markersUnitOnMap: [],
  markerCluster: [],
  vuexActiveEye: [],
  vuexActiveArrow: [],
  vuexActiveGroupEye: [],
  vuexActiveGroupArrow: [],
  vuexIsActiveAllArrow: false,
  vuexIsActiveAllEye: false,
  vuexMarkersOnMap: {},
  vuexMarkersUnitOnMap: {},
  vuexActiveEyeLength: '',
  vuexActiveArrowLength: '',
  vuexIsActiveGroup: false,
  vuexAnnotationUnit: {},
  vuexIsAnnotation: false,
  vuexGroupItemsOnMap: [],
  vuexSearchMonitoringListOnMap: [],
  vuexActiveMonitoringButton: false,
  addressSwitch: true,
  sortName: '',
  sortOrder: '',
  searchObject: '',
  vuexTerminalsMonitoring: {},
  trackBoxPoint: {},
  trackBoxPointIncrease: {},
  boxTrackReportOpened: false,
  boxTrackCash: {},
  boxTrackCentered: null,
  polyline: {},
}

const getters = {

  boxTrackIsCentered(state) {
    return state.boxTrackCentered;
  },

  filtredUnits(state) {
    return state.filtredUnits
  },

  boxTrackIsOpened(state) {
    return state.boxTrackReportOpened
  },

  monitoringList(state, getters, rootState, rootGetters) {
    const { units } = rootState.units

    let list = units.map((el) => {
      const monitoringObject = state.monitoringListObj[el.id]
      const isMonitoringObject =
        monitoringObject &&
        monitoringObject.unit_id &&
        monitoringObject.unit_id === el.id
      const currentTime = moment()
        .utc()
        .add(rootGetters['login/timeZoneDiff'], 'hours')
        .format('YYYY-MM-DD HH:mm:ss')

      if (isMonitoringObject) {
        const {
          latlon: updatedLatlon,
          angle: updatedAngle,
          datepoint: updatedDatepoint,
          datepointHuman: updatedDatepointHuman,
          ignition: updatedIgnition,
          speed: updatedSpeed,
          status: updatedStatus,
          address: updateAddress
        } = getMarkerValues(monitoringObject, currentTime)

        // добавляем статусы терминалов к обьектам
        if (
          state.vuexTerminalsMonitoring[el.imei] &&
          state.vuexTerminalsMonitoring[el.imei].imei &&
          el.imei === state.vuexTerminalsMonitoring[el.imei].imei
        ) {
          el.terminal = state.vuexTerminalsMonitoring[el.imei]
        }

        el.datepointHuman = updatedDatepointHuman
        el.datepoint = updatedDatepoint
        el.ignition = updatedIgnition || ''
        el.latlon = updatedLatlon
        el.speed = updatedSpeed
        el.angle = updatedAngle
        el.columns = monitoringObject.columns
        el.status = updatedStatus
        el.address = updateAddress
      } else {
        const updatedStatus = getStatus(
          el.speed,
          el.datepointHuman,
          currentTime,
          el.datepoint,
          el.ignition
        )

        el.status = updatedStatus
      }

      return el
    })

    if (state.filtredUnits.length > 0) {
      list = list.filter((unit) => state.filtredUnits.includes(unit.id))
    } else {
      list = state.filtredUnits
    }
    return list
  },

  markersOnMap(state) {
    return state.markersUnitOnMap
  },

  loadedMarkersCluster(state) {
    return state.markerCluster
  },

  vuexIsActiveAllArrow(state) {
    return state.vuexIsActiveAllArrow
  },

  vuexIsActiveAllEye(state) {
    return state.vuexIsActiveAllEye
  },

  vuexActiveEye(state) {
    return state.vuexActiveEye
  },

  vuexActiveArrow(state) {
    return state.vuexActiveArrow
  },

  vuexMarkersOnMap(state) {
    return state.vuexMarkersOnMap
  },

  vuexMarkersUnitOnMap(state) {
    return state.vuexMarkersUnitOnMap
  },

  vuexActiveGroupEye(state) {
    return state.vuexActiveGroupEye
  },

  vuexActiveGroupArrow(state) {
    return state.vuexActiveGroupArrow
  },

  vuexActiveArrowLength(state) {
    return state.vuexActiveArrowLength
  },

  vuexActiveEyeLength(state) {
    return state.vuexActiveEyeLength
  },

  vuexIsActiveGroup(state) {
    return state.vuexIsActiveGroup
  },

  vuexAnnotationUnit(state) {
    return state.vuexAnnotationUnit
  },

  vuexIsAnnotation(state) {
    return state.vuexIsAnnotation
  },

  vuexGroupItemsOnMap(state) {
    return state.vuexGroupItemsOnMap
  },

  vuexSearchMonitoringListOnMap(state) {
    return state.vuexSearchMonitoringListOnMap
  },

  addressSwitch: (state) => state.addressSwitch,
  vuexSortName: (state) => state.sortName,
  vuexSortOrder: (state) => state.sortOrder,
  vuexSearchObject: (state) => state.searchObject,
  vuexTerminalsMonitoring: (state) => state.vuexTerminalsMonitoring,
  getTrackBoxPoint: (state) => state.trackBoxPoint,
  getTrackBoxPointIncrease: (state) => state.trackBoxPointIncrease,
  getBoxTrackCash: (state) => state.boxTrackCash,
  getPolyline: (state) => state.polyline,
}

const actions = {
  SET_FILTRED_UNITS({ commit }, data) {
    commit('SET_FILTRED_UNITS', data)
  },
  CLEAR_EYE_ARROW({ commit }) {
    commit('clearVuexActiveEye')
    commit('clearVuexActiveArrow')
    commit('clearVuexMarkersOnMap')
    commit('clearVuexActiveGroupEye')
    commit('clearVuexActiveGroupArrow')

    commit('setVuexIsActiveAllEye', false)
    commit('setVuexIsActiveAllArrow', false)
    commit('setVuexIsActiveGroup', false)

    localStorage.removeItem('isActiveAllEye')
    localStorage.removeItem('isActiveAllArrow')
  }
}

const mutations = {
  // boxTrackIsCentered
  setBoxTrackCentered(state, centered) {
    state.boxTrackCentered = centered
  },

  setPolyline(state, payload) {
    state.polyline = payload
  },
  setTrackBoxPointIncrease(state, payload) {
    const key = Object.keys(payload)[0]
    if (state.trackBoxPointIncrease.hasOwnProperty(key)) {
      state.trackBoxPointIncrease[key] = payload[key]
    } else {
      state.trackBoxPointIncrease = { ...state.trackBoxPointIncrease, ...payload }
    }
  },
  setTrackBoxPoint(state, payload) {
    state.trackBoxPoint = payload
  },
  /**
   * Кэширование данных box-track
   *  zoom,
   *  uniqueTrackId,
   *  track
   */
  addCashBoxTrack(state, payload) {
    state.boxTrackCash[`[${payload.zoom}]${payload.uniqueTrackId}`] = {
      boxPointIncrease: payload.boxPointIncrease,
      track: payload.track
    }
  },
  clearCashBoxTrack(state) {
    state.boxTrackCash.length = 0
  },
  /**
   * Ниличие открытого BoxTrack на map...
   * */
  toggleOpenedBoxTrack(state, payload) {
    state.boxTrackReportOpened = payload
  },
  changeCompany(state, payload) {
    state.monitoringList = payload
  },

  setMonitoringList(state, payload) {
    state.monitoringList.push(payload)
    state.monitoringList = state.monitoringList.flat()

    state.monitoringList.map((item) => {
      Vue.set(state.monitoringListObj, item.unit_id, item)
      // state.monitoringListObj[item.unit_id] = item
      return item
    })
    state.monitoringList = Object.values(state.monitoringListObj)
  },

  SET_FILTRED_UNITS(state, filterData) {
    state.filtredUnits = filterData
  },

  setMarkersUnitOnMap(state, payload) {
    state.markersUnitOnMap.push(payload)
  },

  setMarkerCluster(state, payload) {
    state.markerCluster = payload
  },

  setVuexIsActiveAllArrow(state, payload) {
    state.vuexIsActiveAllArrow = payload
  },

  setVuexIsActiveAllEye(state, payload) {
    state.vuexIsActiveAllEye = payload
  },

  addVuexActiveEye(state, payload) {
    state.vuexActiveEye.push(payload)
  },

  addVuexActiveArrow(state, payload) {
    state.vuexActiveArrow.push(payload)
  },

  addVuexMarkersOnMap(state, { id, unit }) {
    state.vuexMarkersOnMap[id] = unit
  },

  addVuexMarkersUnitOnMap(state, { id, unit }) {
    state.vuexMarkersUnitOnMap[id] = unit
  },

  deleteVuexActiveArrow(state, payload) {
    state.vuexActiveArrow.splice(state.vuexActiveArrow.indexOf(payload.id), 1)
  },

  deleteVuexActiveEye(state, payload) {
    state.vuexActiveEye.splice(state.vuexActiveEye.indexOf(payload.id), 1)
  },

  deleteVuexMarkersOnMap(state, payload) {
    delete state.vuexMarkersOnMap[payload.id]
  },

  deleteVuexMarkerUnitOnMap(state, payload) {
    delete state.vuexMarkersUnitOnMap[payload.id]
  },

  addVuexActiveGroupEye(state, payload) {
    if (!state.vuexActiveGroupEye.includes(payload)) {
      state.vuexActiveGroupEye.push(payload)
    }
  },

  addVuexActiveGroupArrow(state, payload) {
    if (!state.vuexActiveGroupArrow.includes(payload)) {
      state.vuexActiveGroupArrow.push(payload)
    }
  },

  deleteVuexActiveGroupArrow(state, payload) {
    const index = state.vuexActiveGroupArrow.findIndex(
      (el) => el === payload.group_id
    )
    if (index !== -1) {
      state.vuexActiveGroupArrow.splice(index, 1)
    }
  },

  deleteVuexActiveGroupEye(state, payload) {
    const index = state.vuexActiveGroupEye.findIndex(
      (el) => el === payload.group_id
    )
    if (index !== -1) {
      state.vuexActiveGroupEye.splice(index, 1)
    }
  },

  setVuexActiveEyeLength(state, payload) {
    state.vuexActiveEyeLength = payload
  },

  setVuexActiveArrowLength(state, payload) {
    state.vuexActiveArrowLength = payload
  },

  clearVuexActiveGroupArrow(state) {
    state.vuexActiveGroupArrow = []
  },

  clearVuexActiveGroupEye(state) {
    state.vuexActiveGroupEye = []
  },

  clearVuexActiveEye(state) {
    state.vuexActiveEye = []
  },

  clearVuexActiveArrow(state) {
    state.vuexActiveArrow = []
  },

  clearVuexMarkersOnMap(state) {
    state.vuexMarkersOnMap = {}
  },

  clearVuexMarkersUnitOnMap(state) {
    state.vuexMarkersUnitOnMap = {}
  },

  setVuexIsActiveGroup(state, payload) {
    state.vuexIsActiveGroup = payload
  },

  setVuexAnnotationUnit(state, payload) {
    state.vuexAnnotationUnit = payload
  },

  setVuexIsAnnotation(state, payload) {
    state.vuexIsAnnotation = payload
  },

  setVuexGroupItemsOnMap(state, payload) {
    state.vuexGroupItemsOnMap = payload
  },

  setVuexSearchMonitoringListOnMap(state, payload) {
    state.vuexSearchMonitoringListOnMap = payload
  },

  setVuexActiveMonitoringButton(state, payload) {
    state.vuexActiveMonitoringButton = payload
  },

  setAddressSwitch(state, value) {
    state.addressSwitch = value
  },

  setVuexSortName(state, payload) {
    state.sortName = payload
  },

  setVuexSortOrder(state, payload) {
    state.sortOrder = payload
  },

  setVuexSearchObject(state, payload) {
    state.searchObject = payload
  },

  setVuexTerminalsMonitoring(state, payload) {
    payload.forEach((el) => {
      if (!Object.keys(state.vuexTerminalsMonitoring).length) {
        state.vuexTerminalsMonitoring[el.imei] = el
      }
      // обновить данные терминала в мониторинге ИЛИ добавить новые
      if (state.vuexTerminalsMonitoring[el.imei] === el.imei) {
        state.vuexTerminalsMonitoring[el.imei] = el
      } else {
        state.vuexTerminalsMonitoring[el.imei] = el
      }
    })
  }
}
let strict = true;
if(process.env.NODE_ENV === 'production') {
  strict = false
}
export default {
  // strict,
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
