import { usersApi, loginApi } from '@/api'
import eventBus from '@/eventBus.js'

const defaultState = {
  users: [],
  allUsers: [],
  activeUsers: [],
  recoveryId: null,
  userById: null
}

const getters = {
  getUserInfo: (state) => state.userById,
  getAllUsers: (state) => state.allUsers,
  usersWithEmail: (state) => {
    return state.users.filter((user) => user.email)
  },
  usersWithPhone: (state) => {
    return state.users.filter((user) => user.phonenumber)
  },
  drivers: (state) => {
    return state.users.filter((user) => user.is_driver)
  },
  userById: (state) => (id) => {
    return state.users.find((user) => user.id === id)
  },
  getDrivers: (state) => state.users.filter((user) => user.is_driver)
}

const actions = {
  RECOVER_USERS({ commit }, data) {
    return new Promise((resolve, reject) => {
      usersApi.recover(
        data.id,
        (response) => {
          commit('RECOVER', data)
          resolve()
        },
        (error) => {
          console.log(error)
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  GET({ commit }) {
    usersApi.getUsers(
      '',
      (response) => {
        commit('SET', response.data.list)
        commit('SET_USERS', response.data.list)
      },
      (error) => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  WEBSOCKET_CHANGE({ commit }, { id, operation }) {
    if (operation === 'DELETE') {
      commit('DELETE', [{ id }])
    } else {
      usersApi.getUserById(
        id,
        (response) => {
          if (operation === 'POST') {
            commit('ADD', response.data[0])
          } else {
            commit('USER_BY_ID', response.data)
            commit('UPDATE', response.data[0])
          }
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  CREATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      usersApi.createUser(
        { formData },
        (response) => {
          commit('ADD', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE({ commit }, formData) {
    return new Promise((resolve, reject) => {
      usersApi.updateUser(
        formData.id,
        { formData },
        (response) => {
          commit('UPDATE', response.data)
          resolve()
        },
        (error) => {
          // eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE_MYSELF({ commit, rootState }, formData) {
    return new Promise((resolve, reject) => {
      loginApi.changeMyself(
        formData,
        (response) => {
          const activeCompany = rootState.login.me.active_company
          commit(
            'login/SAVE_ME',
            { ...response.data, active_company: activeCompany },
            { root: true }
          )
          commit('UPDATE', response.data)
          resolve()
        },
        (error) => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE({ commit }, users) {
    const promiseList = []

    promiseList.push(
      new Promise(function (resolve, reject) {
        usersApi.deleteUser(users, resolve, reject)
      })
    )

    Promise.all(promiseList)
      .then((res) => {
        commit('DELETE', users)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch(function (error) {
        eventBus.$showError(error.response.data.message)
      })
  },
  INVITE_USER(_, formData) {
    return new Promise((resolve, reject) => {
      usersApi.invite(
        formData,
        () => {
          resolve()
        },
        (error) => {
          reject(error.response.data.message)
        }
      )
    })
  }
}

const mutations = {
  SET_RECOVERYID(state, id) {
    state.recoveryId = id
  },
  RECOVER(state, units) {
    units.itemsDeleted.forEach((unit) =>
      state.users.splice(unit.index, 0, unit)
    )
  },
  SET(state, users) {
    const indexedItems = users.map((item, index) => ({
      index,
      ...item
    }))
    state.users = indexedItems
  },
  SET_USERS(state, payload) {
    state.allUsers = payload
  },
  ADD(state, user) {
    console.log('here')
    if (!state.users.some((x) => x.id === user.id)) {
      state.users = [...state.users, user]
    }
  },
  UPDATE(state, user) {
    const indexOfUser = state.users.findIndex((elem) => elem.id === user.id)
    state.users = [
      ...state.users.slice(0, indexOfUser),
      user,
      ...state.users.slice(indexOfUser + 1)
    ]
  },
  DELETE(state, users) {
    state.users = state.users.filter(
      (item) => !users.map((k) => k.id).includes(item.id)
    )
  },

  SET_ACTIVE_USERS(state, activeUsers) {
    state.activeUsers = activeUsers
  },

  USER_BY_ID(state, user) {
    state.userById = user
  }
}

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations
}
