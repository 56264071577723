import { geozonesKmlApi } from '@/api'

const actions = {
  async createImport({ commit }, payload) {
    return await geozonesKmlApi.importGeozonesKml(payload)
  }
}

export default {
  namespaced: true,
  actions,
}